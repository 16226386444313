<div
    class="dropdown-cont"
    (focusout)="onFocusOutDropdown($event)"
    [ngClass]="{ 'no-val': !selectedOption }"
    tabindex="0"
    [formGroup]="form"
    #container
>
    <div class="dropdown-button" #dropdownButton (click)="onFocusInDropdown()">
        <input [id]="field.name" [formControlName]="field.name" [hidden]="true" />
        <div class="dropdown text-ellipsis text-color-dark" >{{selectedLabel}}</div>
        <label [for]="field.name" [ngClass]="{ floating: !!selectedOption }" class="label text-ellipsis">
            {{ field.label }}
        </label>
        <div class="icon"><img [src]="commonIconsUrl.DOWN_CHEVRON_DARK" alt="i" /></div>
    </div>

    <div
        class="width-100 bg-color-white box-shadow-card-box-shadow border-radius-2 options-cont"
        [ngClass]="{ 'show-dropdown': isDropdownOpen }"
        #dropdownList
    >
        <ng-container [ngTemplateOutlet]="searchFieldTemplate" *ngIf="field.searchConfig"></ng-container>

        <div
            *ngFor="let option of getDataList(field.options)"
            class="d-flx gap-8 padding-vertical-8 c-pointer padding-horizontal-16 option"
            (mousedown)="selectOption(option)"
            tabindex="0"
            [attr.aria-selected]="isOptionSelected(option)"
            [ngClass]="{ 'selected-option': isOptionSelected(option) }"
        >
            <div class="d-flx flx-d-clm gap-4">
                <h6 class="font-14">{{ option.label }}</h6>
                <p class="font-12 text-color-medium-grey" *ngIf="option.description">{{ option.description }}</p>
            </div>
        </div>

        <ng-container
            [ngTemplateOutlet]="emptyTemplate"
            *ngIf="field.searchConfig && getDataList(field.options)?.length === 0"
        ></ng-container>
    </div>
</div>

<ng-template #searchFieldTemplate>
    <div class="d-flx a-items-c gap-4 padding-vertical-12 padding-horizontal-16 position-sticky bg-color-white">
        <div class="search-icon d-flx" [innerHTML]="searchIcon"></div>
        <input
            #inputElement
            class="search-input flx-1 font-14"
            [placeholder]="field.searchConfig.placeholder"
            [ngModel]="searchString"
            (ngModelChange)="handleFilterOptions($event)"
        />
    </div>
</ng-template>

<ng-template #emptyTemplate>
    <p class="font-14 text-color-dark-grey padding-horizontal-16 padding-vertical-12">
        No results found! Please edit your search query and try again
    </p>
</ng-template>
