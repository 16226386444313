import { SentryService } from './apis/sentry.service';
import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ErrorTrackingService {
    constructor(private sentryService: SentryService) {}

    init() {
        // if (environment.env !== 'dev') {
            this.sentryService.init();
        // }
    }
}
