import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'distanceFormat' })
export class DistanceFormatPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {}
    transform(distance: number): string {
        if (distance < 500) {
            return `${this.decimalPipe.transform(distance, '1.0-2')} m`;
        }
        return `${this.decimalPipe.transform(distance / 1000, '1.0-2')} Kms`;
    }
}
