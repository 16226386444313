import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WorkspaceService } from '../../services/workspace/workspace.service';
import { SelectCityService } from '../../services/select-city/select-city.service';
import { IProductMap } from '../../interfaces/product-interface';
import { Subscription } from 'rxjs';
import { IPopularWorkspaceBrief } from '../../../modules/flexi/flexi-home/interfaces/popular-workspaces.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
// @ts-ignore
import TrendIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/trend-flat.svg';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives/directives.module';

@Component({
    standalone: true,
    selector: 'app-popular-workspaces',
    templateUrl: './popular-workspaces.component.html',
    styleUrls: ['./popular-workspaces.component.scss'],
    imports: [CommonModule, DirectivesModule],
})
export class PopularWorkspacesComponent implements OnInit, OnDestroy {
    CommonIconsUrl = CommonIconsUrl;
    popularWorkspaces: IPopularWorkspaceBrief[] = [];
    showLoader = true;
    trendIcon = this.sanitizer.bypassSecurityTrustHtml(TrendIcon);
    constructor(
        private workspaceService: WorkspaceService,
        private selectCityService: SelectCityService,
        private sanitizer: DomSanitizer
    ) {}
    selectCitySub: Subscription;
    @Input() heading: string;
    @Input() product?: IProductMap;
    @Output() clickWorkspace: EventEmitter<IPopularWorkspaceBrief> = new EventEmitter();
    ngOnInit() {
        this.selectCitySub = this.selectCityService.getSelectedCitySync().subscribe(data => {
            if (data.city && this.product) {
                const { city } = data;
                this.showLoader = true;
                this.workspaceService
                    .getPopularWorkspacesByCityAndProduct(city._id, this.product.productNameBackend)
                    .subscribe(
                        res => {
                            if (res?.workspaces) {
                                this.popularWorkspaces = res.workspaces;
                            }
                        },
                        () => {
                            this.showLoader = false;
                        },
                        () => {
                            this.showLoader = false;
                        }
                    );
            }
        });
    }

    ngOnDestroy() {
        if (this.selectCitySub) {
            this.selectCitySub.unsubscribe();
        }
    }

    handleWorkspacePillClick(workspace: IPopularWorkspaceBrief) {
        this.clickWorkspace.emit(workspace);
    }
}
