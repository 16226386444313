import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-search-typeahead-no-result',
    templateUrl: './search-typeahead-no-result.component.html',
    styleUrls: ['./search-typeahead-no-result.component.scss'],
    standalone: true,
})
export class SearchTypeaheadNoResultComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
