<div (clickOutside)="toggleOptions(false)" appClickOutside>
    <div class="d-flx menu-cont a-items-c c-pointer" (click)="toggleOptions(true)">
        <div class="title">{{ title }}</div>
        <div class="icon-cont d-flx a-items-c">
            <span class="caret-up"></span>
        </div>
    </div>
    <div class="options-wrapper" *ngIf="showOptions">
        <div class="options-cont full-width" *ngIf="showLoader">
            <div class="location-workspace-cont d-flx j-cont-space-bet">
                <div class="location-cont">
                    <div *ngFor="let i of [1, 2, 3, 4, 5, 6]" class="shimmer location-name-shimmmer"></div>
                </div>
                <div class="workspace-cont">
                    <div class="workspace d-flx c-pointer" *ngFor="let i of [1, 2, 3, 4, 5, 6]">
                        <div class="shimmer image-cont-shimmer"></div>
                        <div class="d-flx flx-d-clm j-cont-space-bet">
                            <div class="shimmer name-shimmer"></div>
                            <div class="shimmer address-shimmer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="options-cont" [ngClass]="optionsModalClass" *ngIf="!showLoader">
            <div class="location-workspace-cont d-flx j-cont-space-bet">
                <div class="location-cont" *ngIf="!popularWorkspacesByCity.onlyWorkspaces">
                    <div class="heading-cont">
                        <div class="heading">{{ locationHeading }}</div>
                    </div>
                    <ng-container
                        *ngFor="let location of popularWorkspacesByCity.localities.slice(0, 8); let i = index"
                    >
                        <div
                            class="location d-flx c-pointer"
                            [ngClass]="{ selected: location.isSelected }"
                            (click)="onLocationClick(i)"
                        >
                            <div class="name-cont text-ellipsis">{{ location.locality }}</div>
                            <img
                                [src]="
                                    location.isSelected
                                        ? commonIconsUrl.RIGHT_CHEVRON_PRIMARY
                                        : commonIconsUrl.RIGHT_CHEVRON_GREY
                                "
                                alt="i"
                            />
                        </div>
                    </ng-container>
                    <app-link-with-icon
                        [LinkText]="'See all locations'"
                        [url]="popularWorkspacesByCity.cityWorkspaceRedirectUrl"
                        [isExternalUrl]="false"
                        [linkColor]="'primary'"
                    ></app-link-with-icon>
                </div>
                <div class="workspace-cont">
                    <div class="heading-cont">
                        <div class="heading">{{ workspaceHeading }}</div>
                    </div>
                    <div *ngIf="!popularWorkspacesByCity.onlyWorkspaces">
                        <ng-container *ngFor="let location of popularWorkspacesByCity.localities">
                            <div *ngIf="location.isSelected">
                                <ng-container
                                    *ngTemplateOutlet="workspacesTemplate; context: { location: location }"
                                ></ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="popularWorkspacesByCity.onlyWorkspaces">
                        <ng-container
                            *ngTemplateOutlet="
                                workspacesTemplate;
                                context: { location: popularWorkspacesByCity.localities }
                            "
                        ></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #workspacesTemplate let-location="location">
    <ng-container *ngFor="let workspace of location.workspaces.slice(0, 6)">
        <a [routerLink]="workspace.redirectUrl">
            <div class="workspace d-flx c-pointer">
                <div class="image-cont">
                    <img
                        appImageShimmerLazyLoad
                        class="image-cover"
                        [imageSrc]="workspace?.spaceImage?.url"
                        [alt]="workspace?.spaceImage?.alt"
                    />
                </div>
                <div class="d-flx flx-d-clm">
                    <div class="name text-ellipsis">{{ workspace.name }}</div>
                    <div class="d-flx">
                        <div class="type">{{ workspace.spaceType }}</div>
                        <div class="location text-ellipsis">| {{ workspace.locality }}</div>
                    </div>
                </div>
            </div>
        </a>
    </ng-container>
    <div class="see-all-spaces-link">
        <app-link-with-icon
            [LinkText]="location.locality ? 'See all workspaces in ' + location.locality : 'See all workspaces'"
            [url]="location.locationWorkspaceRedirectUrl"
            [isExternalUrl]="false"
            [linkColor]="'primary'"
        ></app-link-with-icon>
    </div>
</ng-template>
