import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IDropdownFieldConfig } from '../../interfaces/field.interface';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
    constructor() {}
    @Input() form: UntypedFormGroup;
    @Input() field: IDropdownFieldConfig;

    commonIconsUrl = CommonIconsUrl;

    ngOnInit() {
        const defaultOption = this.field.options.find(optionItem => optionItem.isDefault);
        // Setting the selected value as the default one in the dropdown if it exists
        if (defaultOption) {
            this.form.controls[this.field.name].setValue(defaultOption.value);
        } else {
            this.form.controls[this.field.name].setValue('');
        }
    }
}
