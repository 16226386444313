import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[appDropdown]'
})
export class DropdownDirective implements AfterViewInit {

    @Input('config') appDropdown: {
        trigger?: {
            topLeft: string;
            topRight: string;
            bottomLeft: string;
            bottomRight: string;
        },
        dropdown?: {
            topLeft: string;
            topRight: string;
            bottomLeft: string;
            bottomRight: string;
        }
    };
    // FIXME currently this directive need the dropdownMenu to be unconditionally present, ie for not showing it up display none is needed.
    @Input() dropdownMenu: ElementRef;

    @Input('toggleDropdown') set setDropdown(show: boolean) {
        if (this.dropdownMenu) {
            this.toggleDropdown(show);
        }
    }
    triggerClass;
    dropdownClass;

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.renderer.setStyle(this.dropdownMenu.nativeElement, 'display', 'none');
    }

    private toggleDropdown(show) {
        if (show) {
            this.renderer.setStyle(this.dropdownMenu.nativeElement, 'display', 'block');
            this.positionDropdown();
        } else {
            this.renderer.removeClass(this.el.nativeElement, this.triggerClass);
            this.renderer.removeClass(this.dropdownMenu.nativeElement, this.dropdownClass);
            this.renderer.setStyle(this.dropdownMenu.nativeElement, 'display', 'none');
        }
    }

    private positionDropdown() {
        const triggerRect = this.el.nativeElement.getBoundingClientRect();
        const menuRect = this.dropdownMenu.nativeElement.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        const top = triggerRect.bottom;
        const left = triggerRect.left;

        let isBottom = false;
        let isLeft = false;

        if (top + menuRect.height < viewportHeight) {
            isBottom = true;
        }

        if (left + menuRect.width < viewportWidth) {
            isLeft = true;
        }

        if (isBottom) {
            if (isLeft) {
                this.triggerClass = this.appDropdown.trigger?.bottomLeft;
                this.dropdownClass = this.appDropdown.dropdown?.bottomLeft;
            } else {
                this.triggerClass = this.appDropdown.trigger?.bottomRight;
                this.dropdownClass = this.appDropdown.dropdown?.bottomRight;
            }
        } else {
            if (isLeft) {
                this.triggerClass = this.appDropdown.trigger?.topLeft;
                this.dropdownClass = this.appDropdown.dropdown?.topLeft;
            } else {
                this.triggerClass = this.appDropdown.trigger?.topRight;
                this.dropdownClass = this.appDropdown.dropdown?.topRight;
            }
        }

        this.renderer.addClass(this.el.nativeElement, this.triggerClass);
        this.renderer.addClass(this.dropdownMenu.nativeElement, this.dropdownClass);
    }
}
