<div class="space-cont d-flx flx-d-row c-pointer width-100" [ngClass]="cssClass">
    <div class="internal-cont d-flx flx-d-row a-items-b width-100">
        <div class="icon-cont d-flx j-cont-cen a-items-c">
            <img [src]="commonIconsUrl.WORKSPACE" alt="i" />
        </div>
        <div class="desc-cont d-flx j-cont-space-bet width-100">
            <div class="d-flx flx-d-clm workspace-desc-container">
                <div class="name">
                    <h4 class="font-14 font-normal text-ellipsis">
                        {{ searchedWorkspace?.name
                        }}{{ searchedWorkspace?.buildingName ? ' - ' + searchedWorkspace?.buildingName : '' }}
                    </h4>
                </div>
                <div class="address text-ellipsis">
                    <p class="font-12 text-color-lighter-grey">
                        {{ searchedWorkspace?.location }}{{ ', ' }}{{ searchedWorkspace?.city }}
                    </p>
                </div>
            </div>
            <div class="space-type">
                <p class="font-12 text-color-lighter-grey">{{ searchedWorkspace?.spaceType | titlecase }}</p>
            </div>
        </div>
    </div>
</div>
