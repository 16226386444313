import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IHttpResponse } from '../../interfaces/HTTPResponse.interface';
import { ErrorService } from '../../../core/services/error/error.service';
import {
    ICommonAmenities,
    IDedicatedPricePlan,
    IDedicatedWorkspaceDetails,
    IDedicatedWorkspaceListSlugAPIResponseData,
    IFlexiPricePlan,
    IFlexiWorkspaceDetails,
    IGetProductWorkspacesOffersForCity,
    IGetAvailableProductsAtWorkspace,
    IGetWorkspacesBySlugsPayload,
    IGetWorkspacesBySlugsResponse,
    INearbyLocationWorkspacesByCity,
    IParsedWorkspaceConnectivityDetails,
    IWorkspaceConnectivityDetails,
    IWorkspaceListAPIPayload,
    IWorkspaceListByPlaceIdAPIPayload,
    IWorkspaceListMapAPIPayload,
    IWorkspacesByRegion,
    IWorkspaceSocialTag,
    IWorkspaceTag,
    IGetMostBookedWorkspaces,
} from '../../interfaces/workspace.interface';
import { ImageBaseUrl, PromotionConstants, WebsiteBaseUrls } from '../../../core/constants/constants';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import {
    IPopularWorkspaces,
    IPopularWorkspacesByCityAndProductAPIResponse,
} from '../../../modules/flexi/flexi-home/interfaces/popular-workspaces.interface';
import {
    buttonType,
    citySlug,
    connectivityLineSuffix,
    connectivityNameSuffix,
    fontSizes,
    productKeys,
    products,
    productsMap,
    UrlPatternRegex,
    WorkspaceSocialTagId,
    WorkspaceStatus,
} from '../../enums/enums';
import { GeolocationService, IGeoLocationDataResp } from '../../../core/services/geolocation/geolocation.service';
import { DateTimeService } from '../../../core/services/date-time/date-time.service';
import { CommonService } from '../common/common.service';
import { IFilterConfig, IFiltersConfig } from '../../standalone-components/filters/interfaces/filters.interface';
import { IDateObj } from '../../interfaces/date.interface';
import { IAmenitiesGroup } from '../../interfaces/space-details.interface';
import { WorkspaceSeatType } from '../../standalone-components/workspace-plan-inventory-card/workspace-plan-inventory-card.enum';
import {
    IFlexiInventoryBasePlanGenerator,
    IInventoryBasePlanGenerator,
    IWorkspacePlanInventoryCardConfig,
} from '../../standalone-components/workspace-plan-inventory-card/workspace-plan-inventory-card.interface';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { WorkspacePopularityScoreThreshold } from '../../constants/constants';
import { DistanceFormatPipe } from '../../pipe/distance-format.pipe';
import {
    IMeetingRoomNearbySpacesAPIResponse,
    IMeetingRoomsWorkspaceSpaceDetail,
} from '../../../modules/meeting-rooms/meeting-rooms.interface';
import { IImage } from '../../interfaces/common.interface';
import {
    MeetingRoomInventoryState,
    MeetingRoomInventorySubState,
} from '../../../modules/meeting-rooms/meeting-rooms.enums';
import { MOCenterTypeMap } from '../../../modules/managed-office/managed-office.enum';
import { CapacityUnitType } from '../../standalone-components/filters/enum/filters.enum';
import { INeighbourhoods } from '../../component-modules/space-details/space-location-details/space-location-details.interface';
import { IProductKeysBackend } from '../../interfaces/product-interface';

export enum searchIntentEnum {
    LOCATION = 'LOCATION',
    WORKSPACE = 'WORKSPACE',
}

export interface IFiltersPayload {
    selectedFilters?: { [key: string]: string | string[] };
    latitude?: string;
    longitude?: string;
}

export interface ILocalityFilterDataAPIPayload {
    selectedFilters?: { [key: string]: string | string[] };
}

export interface ILocalityFilterDataAPIResponse {
    popularLocations: Array<{
        googlePlaceId: string;
        slug: string;
        name: string;
    }>;
    selectedLocations: Array<{
        googlePlaceId: string;
        slug: string;
        name: string;
    }>;
}

let ctaLinks = null;
@Injectable({
    providedIn: 'root',
})
export class WorkspaceService {
    private serviceUrl = `${environment.baseUrl}/workspace`;
    constructor(
        private http: HttpClient,
        private geolocationService: GeolocationService,
        private analyticsService: AnalyticsService,
        private errorService: ErrorService,
        private dateTimeService: DateTimeService,
        private commonService: CommonService,
        private distanceFormatPipe: DistanceFormatPipe
    ) {}
    getWorkspaces(payload: IWorkspaceListAPIPayload): Observable<IDedicatedWorkspaceListSlugAPIResponseData> {
        const url = `${environment.baseUrl}/workspace/list-slug/`;

        return new Observable(observer => {
            const geolocationData = this.geolocationService.getGeoLocationDataSync();
            // Update the lat-long values, if payload doesnt have it already and we have users geo-coords
            if (geolocationData.success && !(payload.latitude && payload.longitude)) {
                payload = {
                    ...payload,
                    latitude: geolocationData.geolocation?.latitude,
                    longitude: geolocationData.geolocation?.longitude,
                };
            }
            this.http
                .post<any>(url, { ...payload })
                .pipe(
                    map((res: IHttpResponse<IDedicatedWorkspaceListSlugAPIResponseData>) => {
                        if (res && res.success) {
                            return res.data;
                        } else {
                            console.warn('Something went wrong in workspace listing API', res, payload);
                        }
                    }),
                    catchError(this.errorService.handleError)
                )
                .subscribe(result => observer.next(result));
        });
    }

    getWorkspacesUsingPlaceId(payload: IWorkspaceListByPlaceIdAPIPayload): Observable<any> {
        const url = `${environment.baseUrl}/workspace/list-autosuggest/`;
        return new Observable(observer => {
            this.geolocationService
                .getGeolocationIfAllowed()
                .subscribe(({ success, geolocation }: IGeoLocationDataResp) => {
                    // Update the lat-long values, if payload doesnt have it already and we have users geo-coords
                    if (success && !(payload.latitude && payload.longitude)) {
                        payload = {
                            ...payload,
                            latitude: geolocation?.latitude,
                            longitude: geolocation?.longitude,
                        };
                    }
                    this.http
                        .post<any>(url, { ...payload })
                        .pipe(
                            map((res: IHttpResponse<any>) => {
                                if (res && res.success) {
                                    return res.data;
                                } else {
                                    console.warn(
                                        'Something went wrong in workspace listing using placeId API',
                                        payload
                                    );
                                }
                            }),
                            catchError(this.errorService.handleError)
                        )
                        .subscribe(result => observer.next(result));
                });
        });
    }

    getTrialWorkspaces(payload: IWorkspaceListAPIPayload): Observable<any> {
        const url = `${environment.baseUrl}/workspace/web/flexi/list-slug/`;
        return new Observable(observer => {
            const geolocationData = this.geolocationService.getGeoLocationDataSync();
            // Update the lat-long values, if payload doesnt have it already and we have users geo-coords
            if (geolocationData.success && !(payload.latitude && payload.longitude)) {
                payload = {
                    ...payload,
                    latitude: geolocationData.geolocation?.latitude,
                    longitude: geolocationData.geolocation?.longitude,
                };
            }
            this.http
                .post<any>(url, { ...payload })
                .pipe(
                    map((res: IHttpResponse<any>) => {
                        if (res && res.success) {
                            return res.data;
                        } else {
                            console.warn('Something went wrong in flexi workspace listing API', payload);
                        }
                    }),
                    catchError(this.errorService.handleError)
                )
                .subscribe(result => observer.next(result));
        });
    }

    getTrialMapViewData(payload: IWorkspaceListMapAPIPayload): Observable<any> {
        const url = `${environment.baseUrl}/workspace/web/flexi/list-map/`;
        return new Observable(observer => {
            const geolocationData = this.geolocationService.getGeoLocationDataSync();
            // Update the lat-long values, if payload doesnt have it already and we have users geo-coords
            if (geolocationData.success && !(payload.latitude && payload.longitude)) {
                payload = {
                    ...payload,
                    latitude: geolocationData.geolocation?.latitude,
                    longitude: geolocationData.geolocation?.longitude,
                };
            }
            this.http
                .post<any>(url, { ...payload })
                .pipe(
                    map((res: IHttpResponse<any>) => {
                        if (res && res.success) {
                            return res.data;
                        } else {
                            console.warn('Something went wrong in flexi mapview list API', res, payload);
                        }
                    }),
                    catchError(this.errorService.handleError)
                )
                .subscribe(result => observer.next(result));
        });
    }

    getMapViewData(payload: IWorkspaceListMapAPIPayload): Observable<any> {
        const url = `${environment.baseUrl}/workspace/list-map/`;
        return new Observable(observer => {
            const geolocationData = this.geolocationService.getGeoLocationDataSync();
            // Update the lat-long values, if payload doesnt have it already and we have users geo-coords
            if (geolocationData.success && !(payload.latitude && payload.longitude)) {
                payload = {
                    ...payload,
                    latitude: geolocationData.geolocation?.latitude,
                    longitude: geolocationData.geolocation?.longitude,
                };
            }

            this.http
                .post<any>(url, { ...payload })
                .pipe(
                    map((res: IHttpResponse<any>) => {
                        if (res && res.success) {
                            return res.data;
                        } else {
                            console.warn('Something went wrong in mapview list API', res, payload);
                        }
                    }),
                    catchError(this.errorService.handleError)
                )
                .subscribe(result => {
                    observer.next(result);
                });
        });
    }

    // Do we need to add lat-long data in here?
    getWorkspacesAndLocation(searchString: string, cityId: string, productBackendKey?: string): Observable<any> {
        return new Observable(observer => {
            this.geolocationService
                .getGeolocationIfAllowed()
                .subscribe(({ success, geolocation }: IGeoLocationDataResp) => {
                    const queryParams = new HttpParams()
                        .set('searchString', searchString)
                        .set('cityId', cityId)
                        .set('latitude', success ? `${geolocation?.latitude}` : '')
                        .set('longitude', success ? `${geolocation?.longitude}` : '')
                        .set('products', productBackendKey || '');
                    const url = `${environment.baseUrl}/locations/autocomplete-with-workspace/`;
                    this.http
                        .get<any>(url, { params: queryParams })
                        .pipe(
                            tap(res =>
                                this.analyticsService.trackAutoSuggestSearch({
                                    searchString,
                                    cityId,
                                    productBackendKey,
                                })
                            ),
                            map((res: IHttpResponse<any>) => {
                                if (res && res.success) {
                                    const {
                                        locationSuggestions = [],
                                        workspaceSuggestions = [],
                                        searchIntent,
                                    } = res.data;
                                    const parsedLocationSuggestions = locationSuggestions.map(location => ({
                                        ...location,
                                        isLocation: true,
                                        name: location.display,
                                    }));
                                    const parsedWorkspaceSuggestions = workspaceSuggestions.map(workspace => ({
                                        ...workspace,
                                        product: productBackendKey,
                                    }));
                                    return searchIntent === searchIntentEnum.WORKSPACE
                                        ? [...parsedWorkspaceSuggestions, ...parsedLocationSuggestions]
                                        : [...parsedLocationSuggestions, ...parsedWorkspaceSuggestions];
                                } else {
                                    console.warn(
                                        'Something went wrong in workspace and location list API',
                                        queryParams
                                    );
                                }
                            }),
                            catchError(this.errorService.handleError)
                        )
                        .subscribe(result => {
                            observer.next(result);
                        });
                });
        });
    }

    searchWorkspace(cityId: string, searchString: string, productBackendKey?: string): Observable<any> {
        return new Observable(observer => {
            this.geolocationService
                .getGeolocationIfAllowed()
                .subscribe(({ success, geolocation }: IGeoLocationDataResp) => {
                    const queryParams = new HttpParams()
                        .set('searchString', searchString)
                        .set('cityId', cityId)
                        .set('latitude', success ? `${geolocation?.latitude}` : '')
                        .set('longitude', success ? `${geolocation?.longitude}` : '')
                        .set('products', productBackendKey || '');
                    const url = `${environment.baseUrl}/workspace/autocomplete/`;
                    return this.http
                        .get<any>(url, { params: queryParams })
                        .pipe(
                            tap(res =>
                                this.analyticsService.trackAutoSuggestWorkspaceSearch({
                                    searchString,
                                    cityId,
                                    products: productBackendKey,
                                })
                            ),
                            map((res: IHttpResponse<any>) => {
                                if (res && res.success) {
                                    return res.data;
                                } else {
                                    console.warn('Something went wrong in search worksapces API', queryParams);
                                }
                            }),
                            catchError(this.errorService.handleError)
                        )
                        .subscribe(result => observer.next(result));
                });
        });
    }

    getWorkspacesByCity(cityId: string, productBackendKey: string) {
        // TODO :: set query params as above, use res and res.success here and return res.data
        let url = `${environment.baseUrl}/locations/city/${cityId}/popular-area/workspace-count`;
        if (productBackendKey) {
            url = `${environment.baseUrl}/locations/city/${cityId}/popular-area/workspace-count?products=${productBackendKey}`;
        }
        return this.http.get<IHttpResponse<IWorkspacesByRegion>>(url).pipe(catchError(this.errorService.handleError));
    }

    getNearbyLocationWorkspacesByCity(
        cityId: string,
        productBackendKey: string
    ): Observable<IHttpResponse<INearbyLocationWorkspacesByCity>> {
        const url = `${environment.baseUrl}/locations/city/${cityId}/nearby-city-popular-area/workspace-count?products=${productBackendKey}`;
        return this.http
            .get<IHttpResponse<INearbyLocationWorkspacesByCity>>(url)
            .pipe(catchError(this.errorService.handleError));
    }

    getSpaceVisitSuggestions(workspaceId: string): Observable<any> {
        const url = `${environment.baseUrl}/workspace/dedicated/recommended/${workspaceId}`;
        return this.http.get<any>(url).pipe(
            map((res: IHttpResponse<any>) => {
                if (res && res.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in get visit additional workspace API', workspaceId);
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    getCitiesWithRegionsAndWorkspaceCount(payload) {
        const spaceImgUrl = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.IMAGE_CONFIG_BASE_FQ}/space-images/`;
        const workspaceData = {
            'New Delhi': [
                {
                    imageURL: `${spaceImgUrl}5deb982284f19c3b32134522/5deb982284f19c3b32134522-1.jpg`,
                    region: 'South Delhi',
                    spaceCount: 20,
                },
                {
                    imageURL: `${spaceImgUrl}5adb6a18553904e0d4f6877b/5adb6a18553904e0d4f6877b-1.jpg`,
                    region: 'Central Delhi',
                    spaceCount: 12,
                },
                {
                    imageURL: `${spaceImgUrl}5baf9e0db1c33e46e229be40/5baf9e0db1c33e46e229be40-2.jpg`,
                    region: 'West Delhi',
                    spaceCount: 12,
                },
                {
                    imageURL: `${spaceImgUrl}5deb982284f19c3b32134521/5deb982284f19c3b32134521-1.jpg`,
                    region: 'North Delhi',
                    spaceCount: 10,
                },
                {
                    imageURL: `${spaceImgUrl}5da1ea34e3162489062a6212/5da1ea34e3162489062a6212-1.jpg`,
                    region: 'Dwarka & Janakpuri',
                    spaceCount: 12,
                },
                {
                    imageURL: `${spaceImgUrl}5af70793661101179fca42c0/5af70793661101179fca42c0-1.jpg`,
                    region: 'Nehru Place',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5e0772596a1e0befd2245df4/5e0772596a1e0befd2245df4-5.jpg`,
                    region: 'Netaji Subhash Place',
                    spaceCount: 4,
                },
                {
                    imageURL: `${spaceImgUrl}5cd6dc0a388fec86312497e7/5cd6dc0a388fec86312497e7-3.jpg`,
                    region: 'Connaught Place',
                    spaceCount: 4,
                },
            ],
            Gurugram: [
                {
                    imageURL: `${spaceImgUrl}5b9e7074edec9694a4e89e16/5b9e7074edec9694a4e89e16-1.jpg`,
                    region: 'DLF Phase 2 & 3',
                    spaceCount: 8,
                },
                {
                    imageURL: `${spaceImgUrl}5c964ca95fc10915a62b866a/5c964ca95fc10915a62b866a-1.jpg`,
                    region: 'DLF Phase 1 & 4',
                    spaceCount: 4,
                },
                {
                    imageURL: `${spaceImgUrl}5deb982284f19c3b32134526/5deb982284f19c3b32134526-2.jpg`,
                    region: 'Golf Course Road',
                    spaceCount: 8,
                },
                {
                    imageURL: `${spaceImgUrl}5deb982284f19c3b32134525/5deb982284f19c3b32134525-2.jpg`,
                    region: 'Sohna Road',
                    spaceCount: 8,
                },
                {
                    imageURL: `${spaceImgUrl}5d4572c58f86d5eacc513179/5d4572c58f86d5eacc513179-1.jpg`,
                    region: 'Cyber City',
                    spaceCount: 4,
                },
                {
                    imageURL: `${spaceImgUrl}5dc41b33fcb6586c88c39174/5dc41b33fcb6586c88c39174-1.jpg`,
                    region: 'Udyog Vihar',
                    spaceCount: 4,
                },
                {
                    imageURL: `${spaceImgUrl}5dd9262eda7c216c6ce44828/5dd9262eda7c216c6ce44828-5.jpg`,
                    region: 'Golf Course Extension',
                    spaceCount: 4,
                },
                {
                    imageURL: `${spaceImgUrl}5bc0d7bb26d246e8e3fad15b/5bc0d7bb26d246e8e3fad15b-1.jpg`,
                    region: 'MG Road',
                    spaceCount: 4,
                },
            ],
            Noida: [
                {
                    imageURL: `${spaceImgUrl}5ca8cd9f860c8883ea2f64e9/5ca8cd9f860c8883ea2f64e9-1.jpg`,
                    region: 'Sec 1-18',
                    spaceCount: 10,
                },
                {
                    imageURL: `${spaceImgUrl}5bcb50b173fd442e19f6b6ac/5bcb50b173fd442e19f6b6ac-5.jpg`,
                    region: 'Sec 58-67',
                    spaceCount: 8,
                },
                {
                    imageURL: `${spaceImgUrl}5c02e671d5adff6caa685a99/5c02e671d5adff6caa685a99-1.jpg`,
                    region: 'Sec 125-132',
                    spaceCount: 4,
                },
                {
                    imageURL: `${spaceImgUrl}5bcb50b173fd442e19f6b6ad/5bcb50b173fd442e19f6b6ad-3.jpg`,
                    region: 'Noida Expressway',
                    spaceCount: 4,
                },
            ],
            Bangalore: [
                {
                    imageURL: `${spaceImgUrl}5b9e7074edec9694a4e89e16/5b9e7074edec9694a4e89e16-1.jpg`,
                    region: 'Koramangala',
                    spaceCount: 15,
                },
                {
                    imageURL: `${spaceImgUrl}5c964ca95fc10915a62b866a/5c964ca95fc10915a62b866a-1.jpg`,
                    region: 'HSR Layout',
                    spaceCount: 25,
                },
                {
                    imageURL: `${spaceImgUrl}5deb982284f19c3b32134526/5deb982284f19c3b32134526-2.jpg`,
                    region: 'M.G. Road',
                    spaceCount: 20,
                },
                {
                    imageURL: `${spaceImgUrl}5deb982284f19c3b32134525/5deb982284f19c3b32134525-2.jpg`,
                    region: 'Indiranagar',
                    spaceCount: 10,
                },
                {
                    imageURL: `${spaceImgUrl}5d4572c58f86d5eacc513179/5d4572c58f86d5eacc513179-1.jpg`,
                    region: 'Whitefield',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5bc0d7bb26d246e8e3fad15b/5bc0d7bb26d246e8e3fad15b-1.jpg`,
                    region: 'Marathahalli',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5dd9262eda7c216c6ce44828/5dd9262eda7c216c6ce44828-5.jpg`,
                    region: 'Electronic City',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5dc41b33fcb6586c88c39174/5dc41b33fcb6586c88c39174-1.jpg`,
                    region: 'Jayanagar',
                    spaceCount: 10,
                },
            ],
            Mumbai: [
                {
                    imageURL: `${spaceImgUrl}5b9e7074edec9694a4e89e16/5b9e7074edec9694a4e89e16-1.jpg`,
                    region: 'Andheri',
                    spaceCount: 15,
                },
                {
                    imageURL: `${spaceImgUrl}5c964ca95fc10915a62b866a/5c964ca95fc10915a62b866a-1.jpg`,
                    region: 'Navi Mumbai',
                    spaceCount: 10,
                },
                {
                    imageURL: `${spaceImgUrl}5deb982284f19c3b32134526/5deb982284f19c3b32134526-2.jpg`,
                    region: 'BKC',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5deb982284f19c3b32134525/5deb982284f19c3b32134525-2.jpg`,
                    region: 'Lower Parel',
                    spaceCount: 10,
                },
                {
                    imageURL: `${spaceImgUrl}5d4572c58f86d5eacc513179/5d4572c58f86d5eacc513179-1.jpg`,
                    region: 'Powai',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5bc0d7bb26d246e8e3fad15b/5bc0d7bb26d246e8e3fad15b-1.jpg`,
                    region: 'Borivali',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5dd9262eda7c216c6ce44828/5dd9262eda7c216c6ce44828-5.jpg`,
                    region: 'Kandivali',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5dc41b33fcb6586c88c39174/5dc41b33fcb6586c88c39174-1.jpg`,
                    region: 'Bandra',
                    spaceCount: 3,
                },
                {
                    imageURL: `${spaceImgUrl}5bcb50b173fd442e19f6b6ad/5bcb50b173fd442e19f6b6ad-3.jpg`,
                    region: 'Malad',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5f59cf670add7b2f9a0f03a8/5f59cf670add7b2f9a0f03a8-5.jpg`,
                    region: 'Goregaon',
                    spaceCount: 7,
                },
                {
                    imageURL: `${spaceImgUrl}5f441677dbc1715077b649aa/5f441677dbc1715077b649aa-6.jpg`,
                    region: 'Thane',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5f441674dbc1715077b6499f/5f441674dbc1715077b6499f-7.jpg`,
                    region: 'Vikhroli',
                    spaceCount: 5,
                },
            ],
            Hyderabad: [
                {
                    imageURL: `${spaceImgUrl}5b9e7074edec9694a4e89e16/5b9e7074edec9694a4e89e16-1.jpg`,
                    region: 'Kondapur',
                    spaceCount: 10,
                },
                {
                    imageURL: `${spaceImgUrl}5c964ca95fc10915a62b866a/5c964ca95fc10915a62b866a-1.jpg`,
                    region: 'Hitech City',
                    spaceCount: 10,
                },
                {
                    imageURL: `${spaceImgUrl}5deb982284f19c3b32134526/5deb982284f19c3b32134526-2.jpg`,
                    region: 'Banjara Hills',
                    spaceCount: 8,
                },
                {
                    imageURL: `${spaceImgUrl}5deb982284f19c3b32134525/5deb982284f19c3b32134525-2.jpg`,
                    region: 'Gachibowli',
                    spaceCount: 8,
                },
                {
                    imageURL: `${spaceImgUrl}5d4572c58f86d5eacc513179/5d4572c58f86d5eacc513179-1.jpg`,
                    region: 'Madhapur',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5bc0d7bb26d246e8e3fad15b/5bc0d7bb26d246e8e3fad15b-1.jpg`,
                    region: 'Jubilee Hills',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5dd9262eda7c216c6ce44828/5dd9262eda7c216c6ce44828-5.jpg`,
                    region: 'Begumpet',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5dc41b33fcb6586c88c39174/5dc41b33fcb6586c88c39174-1.jpg`,
                    region: 'Kukatpally',
                    spaceCount: 3,
                },
            ],
            Pune: [
                {
                    imageURL: `${spaceImgUrl}5fa2bf357ff947ca213b8761/6107df048461df1919868b04.jpg`,
                    region: 'Baner',
                    spaceCount: 25,
                },
                {
                    imageURL: `${spaceImgUrl}5fa2bf357ff947ca213b8761/6107df068461df1919868b0d.jpg`,
                    region: 'Hinjewadi',
                    spaceCount: 10,
                },
                {
                    imageURL: `${spaceImgUrl}5fa2bf357ff947ca213b8761/6107df078461df1919868b13.jpg`,
                    region: 'Kharadi',
                    spaceCount: 10,
                },
                {
                    imageURL: `${spaceImgUrl}5fa2bf357ff947ca213b8761/6107df048461df1919868b02.jpg`,
                    region: 'Shivaji Nagar',
                    spaceCount: 4,
                },
                {
                    imageURL: `${spaceImgUrl}5fa2bf357ff947ca213b8761/63a447b6498b0e7fca016d69.jpg`,
                    region: 'Koregaon Park',
                    spaceCount: 10,
                },
                {
                    imageURL: `${spaceImgUrl}5fa2bf357ff947ca213b8761/6107df048461df1919868b01.jpg`,
                    region: 'Viman Nagar',
                    spaceCount: 10,
                },
                {
                    imageURL: `${spaceImgUrl}5fa2bf357ff947ca213b8761/60b1398e23807b014e504c61.jpg`,
                    region: 'Hadapsar',
                    spaceCount: 5,
                },
                {
                    imageURL: `${spaceImgUrl}5fa2bf357ff947ca213b8761/6107df068461df1919868b0f.jpg`,
                    region: 'Pimpri Chinchwad',
                    spaceCount: 5,
                },
            ],
        };
        let cityArrWithRegion = [];
        switch (payload.citySlug) {
            case citySlug.BANGALORE:
                cityArrWithRegion = [{ city: 'Bangalore', count: 100 }];
                break;
            case citySlug.NEW_DELHI:
                cityArrWithRegion = [
                    { city: 'New Delhi', count: 84 },
                    { city: 'Gurugram', count: 44 },
                    { city: 'Noida', count: 16 },
                ];
                break;
            case citySlug.NOIDA:
                cityArrWithRegion = [
                    { city: 'Noida', count: 16 },
                    { city: 'New Delhi', count: 84 },
                    { city: 'Gurugram', count: 44 },
                ];
                break;
            case citySlug.GURUGRAM:
                cityArrWithRegion = [
                    { city: 'Gurugram', count: 44 },
                    { city: 'New Delhi', count: 84 },
                    { city: 'Noida', count: 16 },
                ];
                break;
            case citySlug.MUMBAI:
                cityArrWithRegion = [{ city: 'Mumbai', count: 80 }];
                break;
            case citySlug.HYDERABAD:
                cityArrWithRegion = [{ city: 'Hyderabad', count: 54 }];
                break;
            case citySlug.PUNE:
                cityArrWithRegion = [{ city: 'Pune', count: 79 }];
                break;
        }
        cityArrWithRegion = cityArrWithRegion.map(cityObj => {
            if (workspaceData[cityObj.city]) {
                cityObj.region = workspaceData[cityObj.city];
                cityObj.workspaceType = payload.workspaceType;
            }
            return cityObj;
        });

        return new Observable(subscriber => {
            subscriber.next(cityArrWithRegion);
            subscriber.complete();
        });
    }

    flexiBasePlanGenerator(): IFlexiInventoryBasePlanGenerator {
        return {
            name: 'Flexi desks',
            idealFor: 'For individuals',
            idealForDescription: 'Pay per use',
            seatType: WorkspaceSeatType.FLEXI_DESKS,
            planCycle: 'per visit',
            description: 'Pay per use — bring your laptop, pick a spot and get working',
        };
    }

    dedicatedBasePlanGenerator(): IInventoryBasePlanGenerator {
        return {
            idealFor: '1-50 members',
            idealForDescription: 'Reserved desk, shared amenities',
            planCycle: 'per desk/month',
            description: 'Dedicated Desks and storage units blocked for you',
            stickyFooterBannerConfig: {
                heading: 'Interested in this option? Let myHQ experts help you get the best deal',
                logo: {
                    url: CommonIconsUrl.CALL,
                    alt: 'call',
                },
                ctaConfig: {
                    label: 'Request Callback',
                },
            },
        };
    }

    cabinsPlanGenerator(): IInventoryBasePlanGenerator {
        return {
            idealFor: '1-50 members',
            idealForDescription: 'Private space, shared amenities',
            planCycle: 'per desk/month',
            description:
                'Reserved cabin for focused productivity — ample storage space & complimentary meeting room hours',
            stickyFooterBannerConfig: {
                heading: 'Interested in this option? Let myHQ experts help you get the best deal',
                logo: {
                    url: CommonIconsUrl.CALL,
                    alt: 'call',
                },
                ctaConfig: {
                    label: 'Request Callback',
                },
            },
        };
    }
    manageOfficePlanGenerator(): IInventoryBasePlanGenerator {
        return {
            idealFor: '20-400 members',
            idealForDescription: 'Private Office, Private Amenities',
            planCycle: 'per desk/month',
            description: 'A demarcated office customised for bigger teams for their office needs',
            stickyFooterBannerConfig: {
                heading: 'Interested in this option? Let myHQ experts help you get the best deal',
                logo: {
                    url: CommonIconsUrl.CALL,
                    alt: 'call',
                },
                ctaConfig: {
                    label: 'Request Callback',
                },
            },
        };
    }

    meetingRoomPlanGenerator(): IInventoryBasePlanGenerator {
        return {
            idealForDescription: 'Private room, Multiple equipments',
            planCycle: '/hour',
            description: 'Book by-the-hour or by the day — Meeting, conference and training rooms for teams.',
            stickyFooterBannerConfig: {
                heading: 'Interested in this option? Book a meeting room with myHQ',
                logo: {
                    url: CommonIconsUrl.SPACE_CLICK,
                    alt: 'call',
                },
                ctaConfig: {
                    label: 'Book Meeting Room',
                },
            },
        };
    }

    getSeatsAvailableForCabins(seatsAvailable: number[]) {
        return seatsAvailable.sort((s1, s2) => (+s1 < +s2 ? -1 : 1)).join(', ');
    }

    getSeatsAvailableForMeetingRooms(seatsAvailable: number[]) {
        return seatsAvailable.sort((s1, s2) => (+s1 < +s2 ? -1 : 1)).join(', ');
    }

    getFlexiPricePlans(
        pricePlans: IFlexiPricePlan[],
        workspaceData: IFlexiWorkspaceDetails & { flexiworkspace: IFlexiWorkspaceDetails }
    ): IWorkspacePlanInventoryCardConfig[] {
        const flexiPricePlansList: IFlexiPricePlan[] = pricePlans || [];
        return flexiPricePlansList.map(plan => {
            const planInfo: IWorkspacePlanInventoryCardConfig = {
                ...plan,
                ...this.flexiBasePlanGenerator(),
                capacity: plan.count,
                rackPricePerSeat: plan.rackPrice,
                pricePerSeat: plan.price,
                pricingList: [
                    {
                        pricePerSeat: plan.price,
                        rackPricePerSeat: plan.rackPrice,
                        images: plan.images || [],
                        offers: plan.offers || [],
                        capacity: plan.count,
                    },
                ],
                allowTrials: workspaceData?.flexiworkspace?.allowTrials,
                cta: { label: 'Book Desk', disable: workspaceData?.flexiworkspace?.blockReserve },
            };

            return planInfo;
        });
    }

    getDedicatedPricePlans(
        pricePlans: IDedicatedPricePlan[],
        workspaceData: IDedicatedWorkspaceDetails,
        productBackendKey?: string
    ): IWorkspacePlanInventoryCardConfig[] {
        const dedicatedPricePlansList: IDedicatedPricePlan[] = pricePlans || [];
        const dedicatedPricePlans: Record<string, IWorkspacePlanInventoryCardConfig> = {};

        dedicatedPricePlansList
            .filter(plan => {
                return plan.active;
            })
            .forEach(plan => {
                dedicatedPricePlans[plan.seatType] = {
                    ...plan,
                    capacity: Math.max(plan.capacity, dedicatedPricePlans[plan.seatType]?.capacity ?? 0),
                    planAmenities: [
                        ...(dedicatedPricePlans[plan.seatType]?.planAmenities ?? []),
                        ...plan.planAmenities,
                    ],
                    pricingList: [
                        ...(dedicatedPricePlans[plan.seatType]?.pricingList ?? []),
                        {
                            capacity: plan.capacity,
                            pricePerSeat: plan.pricePerSeat,
                            rackPricePerSeat: plan.rackPricePerSeat,
                            availableUnits: plan.availableUnits,
                            totalUnits: plan.totalUnits,
                            offers: plan.offers || [],
                            images: plan.images || [],
                        },
                    ],
                };
            });

        return Object.keys(dedicatedPricePlans).map(seatType => {
            let planInfo = {
                ...dedicatedPricePlans[seatType],
                seatsAvailable: this.getSeatsAvailableForCabins(
                    dedicatedPricePlans[seatType].pricingList.map(plan => plan.capacity)
                ),
            };
            if (seatType === WorkspaceSeatType.PRIVATE_CABINS) {
                planInfo = {
                    ...planInfo,
                    ...this.cabinsPlanGenerator(),
                };
            } else if (seatType === WorkspaceSeatType.MANAGED_OFFICE) {
                planInfo = {
                    ...planInfo,
                    ...this.manageOfficePlanGenerator(),
                };
            } else {
                planInfo = {
                    ...planInfo,
                    ...this.dedicatedBasePlanGenerator(),
                };
            }

            planInfo.pricingList.sort((plan1, plan2) => {
                return plan1.capacity > plan2.capacity ? 1 : -1;
            });
            planInfo.planAmenities = Array.from(new Set(dedicatedPricePlans[seatType].planAmenities));

            // Adding configs for generic space details page
            if (!productBackendKey) {
                planInfo.isGenericCard = true;
                planInfo.stickyFooterBannerConfig = {
                    heading: 'Interested in this option? Let myHQ experts help you get the best deal',
                    logo: {
                        url: CommonIconsUrl.CALL,
                        alt: 'call',
                    },
                    ctaConfig: {
                        label: 'Contact Expert',
                    },
                };
            }

            return planInfo;
        });
    }

    getMeetingRoomPricePlans(workspaceData: IMeetingRoomsWorkspaceSpaceDetail): (IWorkspacePlanInventoryCardConfig & {
        subState: MeetingRoomInventorySubState;
        state: MeetingRoomInventoryState;
    })[] {
        const { meetingroominventories: inventoryList, meetingroomworkspace: workspace } = workspaceData;
        const seatsAvailable = this.getSeatsAvailableForMeetingRooms(
            inventoryList.map(item => parseInt(item.capacity, 10))
        );
        const pricePerSeat = Math.min(...inventoryList.map(item => item.pricePerHour));
        const rackPricePerSeat = Math.max(...inventoryList.map(item => item.rackPricePerHour));
        return [
            {
                ...this.meetingRoomPlanGenerator(),
                seatsAvailable,
                name: 'Meeting Room',
                workspaceSlug: workspaceData.slug,
                seatType: WorkspaceSeatType.MEETING_ROOM,
                isGenericCard: true,
                pricePerSeat,
                rackPricePerSeat,
                state: inventoryList?.[0]?.state,
                subState: inventoryList?.[0]?.subState,
                pricingList: inventoryList.map(listItem => {
                    return {
                        capacity: parseInt(listItem.capacity, 10),
                        images: listItem.images,
                        offers: [],
                        pricePerSeat: listItem.pricePerHour,
                        rackPricePerSeat: listItem.rackPricePerHour,
                        redirectUrl: listItem.redirectUrl,
                    };
                }),
            },
        ];
    }
    getFlexiOffers(offers) {
        const offerIcons = {
            menu: `https://res.cloudinary.com/myhq/image/upload/web/ssr/pages/space-details/offers/food-flat.svg`,
            'Free Beverages': `https://res.cloudinary.com/myhq/image/upload/web/ssr/pages/space-details/offers/beverage-flat.svg`,
            'Discount & Cashback': `https://res.cloudinary.com/myhq/image/upload/web/ssr/pages/space-details/offers/discount-flat.svg`,
        };
        const offersObj = offers || {};
        return Object.keys(offersObj).map(key => {
            return {
                label: offersObj[key],
                icon: {
                    url: offerIcons[key],
                    alt: key,
                },
            };
        });
    }

    getProductFromBackendKey(productBackendKey) {
        let product;
        Object.keys(productsMap).forEach(productKey => {
            if (productsMap[productKey].productNameBackend === productBackendKey) {
                product = productsMap[productKey];
            }
        });
        return product;
    }

    isWorkspaceLive(workspace, productBackendKey) {
        if (workspace.status === WorkspaceStatus.LIVE) {
            return this.getWorkspaceStatus(productBackendKey, workspace) === WorkspaceStatus.LIVE;
        }
        return false;
    }

    getWorkspaceStatus(productBackendKey, data): WorkspaceStatus {
        switch (productBackendKey) {
            case productsMap.DEDICATED.productNameBackend:
                return data.dedicatedworkspace.status;
            case productsMap.FLEXI.productNameBackend:
                return data.flexiworkspace.status;
            case productsMap.VIRTUAL_OFFICE.productNameBackend:
                return data.virtualofficeworkspace.status;
            case productsMap.MANAGED_OFFICE.productNameBackend:
                return data.managedofficeworkspace?.status;
            case productsMap.MEETING_ROOM.productNameBackend:
                return data.meetingroomworkspace.status;
        }
    }

    getConfigsForSpaceDetails(productBackendKey, data) {
        // generating different configs for space details page
        ctaLinks = {
            scheduleVisit: {
                url: `${WebsiteBaseUrls.WORKSPACE_SCHEDULE_VISIT}/${data.slug}`,
                queryParams: { showForm: true },
            },
            bookTrial: { url: `${WebsiteBaseUrls.WORKSPACE_FREE_TRIAL}/${data.slug}`, queryParams: { showForm: true } },
        };
        const configs: Record<string, any> = {
            overviewConfig: null,
            nearbySpaceConfig: null,
            moreAboutWorkspaceConfig: null,
            breadcrumbData: null,
            briefDetailsConfig: null,
            reviewSummary: null,
            delistedConfig: null,
            stickyFooterButtonConfig: null,
            ctaLinks,
        };
        configs.briefDetailsConfig = {
            name: data.name,
            spaceType: data.spaceType,
            location: data.location,
            city: data.city,
            buildingName: data.buildingName,
            reviewSummary: data.reviewSummary,
        };
        if (this.isWorkspaceLive(data, productBackendKey)) {
            let timings = data?.timings;
            if (productBackendKey === productsMap.FLEXI.productNameBackend) {
                timings = data?.flexiworkspace?.timings;
            }

            configs.overviewConfig = {
                timings,
                tags: data?.tags,
            };
            configs.moreAboutWorkspaceConfig = {
                about: data.about,
            };
        } else {
            configs.delistedConfig = {
                imageURL: data.spaceImages?.length ? data.spaceImages[0].url : '//:0',
                spaceBriefDetails: {
                    name: data.name,
                    spaceType: data.spaceType,
                    location: data.location,
                    city: data.city,
                    buildingName: data.buildingName,
                },
                breadCrumbs: data.seoData?.breadcrumbs,
            };
        }
        configs.nearbySpaceConfig = {
            workspaceSlug: data.slug,
            workspaceCity: data.city,
            product: this.getProductFromBackendKey(productBackendKey),
        };
        if (!productBackendKey) {
            if (data?.products.indexOf(productsMap.DEDICATED.productNameBackend) !== -1) {
                configs.stickyFooterButtonConfig = {
                    label: `Book space tour at ${data.name}`,
                    link: ctaLinks.scheduleVisit.url,
                    queryParams: ctaLinks.scheduleVisit.queryParams,
                    description: data.promotion?.description,
                };
            } else if (data.products.indexOf(productsMap.FLEXI.productNameBackend) !== -1) {
                configs.stickyFooterButtonConfig = {
                    label: `Book Trial`,
                    link: ctaLinks.bookTrial.url,
                    queryParams: ctaLinks.bookTrial.queryParams,
                };
            }
        } else if (productBackendKey === productsMap.FLEXI.productNameBackend) {
            configs.briefDetailsConfig = {
                ...configs.briefDetailsConfig,
                productTags: data.flexiworkspace.tags?.product,
            };
            if (data?.flexiworkspace?.allowTrials && !data?.flexiworkspace?.disruption) {
                configs.stickyFooterButtonConfig = {
                    label: 'Book Trial',
                };
            } else {
                configs.stickyFooterButtonConfig = {
                    label: `Book Desk`,
                };
            }
        } else if (productBackendKey === productsMap.DEDICATED.productNameBackend) {
            configs.briefDetailsConfig = {
                ...configs.briefDetailsConfig,
                productTags: data.dedicatedworkspace.tags?.product,
            };
        } else if (productBackendKey === productsMap.MANAGED_OFFICE.productNameBackend) {
            configs.briefDetailsConfig = {
                ...configs.briefDetailsConfig,
                spaceType: MOCenterTypeMap?.[data.managedofficeworkspace?.centreType],
                productTags: data.managedofficeworkspace?.tags?.product,
            };
            configs.overviewConfig = {
                timings: data?.managedofficeworkspace?.timings,
                tags: data?.managedofficeworkspace?.tags,
            };
        } else if (productBackendKey === productsMap.VIRTUAL_OFFICE.productNameBackend) {
            configs.moreAboutWorkspaceConfig = {
                about: data.about,
            };
            configs.breadcrumbsConfig = data.breadcrumbs;
            configs.briefDetailsConfig = {
                name: this.getWorkspaceName(data, productBackendKey),
                city: data.city,
                location: data.location,
                spaceType: data.spaceType,
                marketingTags: data.virtualofficeworkspace.marketingTags,
                propertyType: data.virtualofficeworkspace.propertyType,
                productTags: data.virtualofficeworkspace.tags?.product,
                purchaseInsight: data.virtualofficeworkspace?.purchaseInsight,
            };
            configs.nearbySpaceCardConfig = {
                product: productsMap.VIRTUAL_OFFICE,
                workspaceCity: data.city,
                workspaceSlug: data.slug,
                heading: 'Similar Locations',
                nearbyAreaSectionConfig: {
                    heading: 'Explore More Virtual Offices In Nearby Areas',
                    cardPrefixText: 'View Virtual Office in',
                },
            };
        } else if (productBackendKey === productsMap.MEETING_ROOM.productNameBackend) {
            configs.moreAboutWorkspaceConfig = {
                about: data.meetingroomworkspace.about,
            };
            configs.overviewConfig = {
                timings: data.meetingroomworkspace.timings,
                tags: data?.tags,
            };
            configs.briefDetailsConfig = {
                ...configs.briefDetailsConfig,
                name: data.name,
                displayName: this.getFullWorkspaceDisplayName(data),
                seaterLabel: `${data.meetingroominventories?.[0]?.capacity} Seater Meeting Room`,
                address: `${data.location}, ${data.city}`,
                city: data.city,
                location: data.location,
                productTags: data.meetingroomworkspace.tags?.product,
                spaceType: data.spaceType,
            };
            configs.breadcrumbsConfig = data.breadcrumbs;
            configs.nearbySpaceCardConfig = {
                product: productsMap.MEETING_ROOM,
                workspaceCity: data.city,
                workspaceSlug: data.slug,
                capacity: data.meetingroominventories[0].capacity,
                heading: 'Similar Locations',
                nearbyAreaSectionConfig: {
                    heading: 'Explore More Meeting Rooms In Nearby Areas',
                    cardPrefixText: 'View Meeting Room in',
                },
            };
            configs.stickyFooterButtonConfig = {
                label: `Contact Us`,
            };
        }

        return configs;
    }

    getWorkspaceName(data, productBackendKey: string) {
        switch (productBackendKey) {
            case productsMap.VIRTUAL_OFFICE.productNameBackend:
                return data.virtualofficeworkspace.displayName || data.name;
            default:
                return data.name;
        }
    }

    parseCommonDataForWorkspaceDetail(productType: string, data) {
        let spaceImagesList = [];
        let exteriorSpaceImages = [];
        let spaceHighlights = [];
        let amenities: ICommonAmenities;
        let isRecommended = false;
        let placeholderImg: IImage;
        let showExteriorImages = false;

        // Get specific amenities
        switch (productType) {
            case productsMap.FLEXI.productNameBackend: {
                spaceImagesList = data?.flexiworkspace?.spaceImages?.length ? data.flexiworkspace.spaceImages : [];
                spaceHighlights = data?.flexiworkspace?.spaceHighlights?.length
                    ? data.flexiworkspace.spaceHighlights
                    : [];
                amenities = data?.flexiworkspace.amenities;
                isRecommended = data?.flexiworkspace?.popularityScore > WorkspacePopularityScoreThreshold;
                break;
            }
            case productsMap.DEDICATED.productNameBackend: {
                spaceImagesList = data.dedicatedworkspace?.spaceImages?.length
                    ? data.dedicatedworkspace.spaceImages
                    : [];
                exteriorSpaceImages = data?.exteriorSpaceImages?.length ? data?.exteriorSpaceImages : [];
                spaceHighlights = data.dedicatedworkspace?.spaceHighlights?.length
                    ? data.dedicatedworkspace.spaceHighlights
                    : [];
                amenities = data?.dedicatedworkspace?.amenities;
                isRecommended = data?.dedicatedworkspace?.popularityScore > WorkspacePopularityScoreThreshold;
                showExteriorImages = true;
                break;
            }
            case productsMap.MANAGED_OFFICE.productNameBackend: {
                spaceImagesList = data?.managedofficeworkspace?.spaceImages?.length
                    ? data.managedofficeworkspace.spaceImages
                    : [];
                exteriorSpaceImages = data?.exteriorSpaceImages?.length ? data?.exteriorSpaceImages : [];
                spaceHighlights = data?.managedofficeworkspace?.spaceHighlights?.length
                    ? data.managedofficeworkspace.spaceHighlights
                    : [];
                amenities = data?.managedofficeworkspace?.amenities;
                isRecommended = data?.managedofficeworkspace?.popularityScore > WorkspacePopularityScoreThreshold;
                showExteriorImages = true;
                break;
            }
            case productsMap.VIRTUAL_OFFICE.productNameBackend: {
                spaceImagesList = data.virtualofficeworkspace?.spaceImages ?? [];
                spaceHighlights = [];
                amenities = data?.virtualofficeworkspace?.amenities;
                isRecommended = data?.virtualofficeworkspace?.popularityScore > WorkspacePopularityScoreThreshold;
                break;
            }
            case productsMap.MEETING_ROOM.productNameBackend: {
                spaceImagesList = data.meetingroominventories[0].images ?? [];
                spaceHighlights = [];
                amenities = data.meetingroomworkspace?.amenities;
                isRecommended = data?.meetingroomworkspace?.popularityScore > WorkspacePopularityScoreThreshold;
                placeholderImg = {
                    url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/meeting-rooms/meeting-rooms-placeholder.png`,
                    alt: data.name,
                };
                break;
            }
            default: {
                amenities = data?.dedicatedworkspace?.amenities
                    ? data?.dedicatedworkspace?.amenities
                    : data?.flexiworkspace?.amenities;
            }
        }
        // Fallback to workspace level properties, if specific details are not present OR it is generic space detail page
        if (!spaceImagesList.length) {
            spaceImagesList = data.spaceImages || [];
        }

        if (data.spaceHighlights && productType !== productsMap.VIRTUAL_OFFICE.productNameBackend) {
            spaceHighlights.push(...data.spaceHighlights);
        }

        const imageGalleryConfig = {
            spaceImages: spaceImagesList.map(image => {
                return {
                    url: image.url,
                    alt: image.alt,
                };
            }),
            exteriorSpaceImages,
            miniGrid: !!productType,
            show3DButton: !!data?.matterportUrl,
            placeholderImg: null,
            showExteriorImages,
        };

        if (placeholderImg) {
            imageGalleryConfig.placeholderImg = placeholderImg;
        }

        return {
            imageGalleryConfig,
            amenitiesDetailConfig: {
                workspaceName: this.getWorkspaceName(data, productType),
                spaceHighlights,
                amenities,
            },
            isRecommended,
        };
    }

    getVOSpecificData(workspaceDetails) {
        return {
            ...workspaceDetails,
            imageGalleryConfig: {
                ...workspaceDetails.imageGalleryConfig,
                show3DButton: false,
                mobSmall: true,
            },
            briefDetailsConfig: {
                ...workspaceDetails.briefDetailsConfig,
                documentTAT: workspaceDetails.virtualofficeworkspace.documentTAT,
                isKycEnabled: workspaceDetails.virtualofficeworkspace.kycEnabled,
            },
        };
    }

    getMeetingRoomSpecificData(workspaceDetails) {
        return {
            ...workspaceDetails,
            imageGalleryConfig: {
                ...workspaceDetails.imageGalleryConfig,
                show3DButton: false,
            },
            overviewConfig: {
                ...workspaceDetails.overviewConfig,
                timings: workspaceDetails.meetingroomworkspace.timings,
            },
            meetingRoomPlans: this.getMeetingRoomPricePlans(workspaceDetails),
        };
    }

    getFlexiSpecificDetails(data, productBackendKey) {
        const offers = this.getFlexiOffers(data.flexiworkspace?.offersNew);
        return {
            flexiPlans: this.getFlexiPricePlans(data.flexipriceplans, data),
            flexiFeatures: {
                label: 'Why choose flexi pass',
                features: [
                    `Work from any of our Work Cafes or Coworking spaces. ${PromotionConstants.WORKSPACE_COUNT}+ options to choose from.`,
                    'On demand, pay per use plans with no monthly rent',
                ],
            },
            offersComponentConfig: offers,
        };
    }

    getDedicatedSpecificDetails(data, productBackendKey) {
        const dedicatedPlans = this.getDedicatedPricePlans(data.dedicatedpriceplans, data, productBackendKey);
        return {
            dedicatedPlans,
            promotionConfig: data.dedicatedworkspace?.promotion || null,
            testimonials: data.dedicatedworkspace?.testimonial || null,
        };
    }
    getManagedOfficeSpecificDetails(data, productBackendKey) {
        const managedOfficePlans = this.getDedicatedPricePlans(data?.managedofficepriceplans, data, productBackendKey);
        return {
            managedOfficePlans,
        };
    }

    parseSeoData(data) {
        const genericListingPageRegex = new RegExp(UrlPatternRegex.GENERIC_LISTING_PAGE);
        let breadcrumbsConfig;
        if (data.seoData && data.seoData.breadcrumbs) {
            breadcrumbsConfig = data.seoData.breadcrumbs.map(breadcrumbObj => {
                if (genericListingPageRegex.test(breadcrumbObj.redirectUrl)) {
                    // TODO fix this, productsMap should be dynamic?
                    breadcrumbObj.redirectUrl = `/${productsMap.DEDICATED.productBaseUrl}${breadcrumbObj.redirectUrl}`;
                }
                return breadcrumbObj;
            });
        }
        return breadcrumbsConfig;
    }

    parseWorkspaceDetailData(data, productBackendKey?: string) {
        let parsedData = data;
        // Gets space and work amenities for both the flexi and dedicated workspaces
        parsedData = {
            ...parsedData,
            ...this.parseCommonDataForWorkspaceDetail(productBackendKey, data),
            ...this.getConfigsForSpaceDetails(productBackendKey, data),
            reviewSummary: data.reviewSummary,
        };

        // Get specific details based on the product type
        if (data.products) {
            // Workspace contains FLEXI as product and page is either Flexi detail or Generic detail
            if (
                data.products.indexOf(productsMap.FLEXI.productNameBackend) !== -1 &&
                (!productBackendKey || productBackendKey === productsMap.FLEXI.productNameBackend)
            ) {
                parsedData = {
                    ...parsedData,
                    ...this.getFlexiSpecificDetails(parsedData, productBackendKey),
                };
            }
            // Workspace contains DEDICATED as product and page is either Dedicated detail or Generic detail
            if (
                data.products.indexOf(productsMap.DEDICATED.productNameBackend) !== -1 &&
                (!productBackendKey || productBackendKey === productsMap.DEDICATED.productNameBackend)
            ) {
                parsedData = {
                    ...parsedData,
                    ...this.getDedicatedSpecificDetails(parsedData, productBackendKey),
                };
            }
            if (
                data.products.indexOf(productsMap.MANAGED_OFFICE.productNameBackend) !== -1 &&
                (!productBackendKey || productBackendKey === productsMap.MANAGED_OFFICE.productNameBackend)
            ) {
                parsedData = {
                    ...parsedData,
                    ...this.getManagedOfficeSpecificDetails(parsedData, productBackendKey),
                };
            }
            if (
                data.products.indexOf(productsMap.VIRTUAL_OFFICE.productNameBackend) !== -1 &&
                (!productBackendKey || productBackendKey === productsMap.VIRTUAL_OFFICE.productNameBackend)
            ) {
                parsedData = {
                    ...parsedData,
                    ...this.getVOSpecificData(parsedData),
                };
            }
            if (
                data.products.indexOf(productsMap.MEETING_ROOM.productNameBackend) !== -1 &&
                (!productBackendKey || productBackendKey === productsMap.MEETING_ROOM.productNameBackend)
            ) {
                parsedData = {
                    ...parsedData,
                    ...this.getMeetingRoomSpecificData(parsedData),
                };
            }
        }
        // AVOIDING PARSING OF BREADCRUMBS DATA IN SOME PRODUCTS
        const parsedSEODataUnsupportedProducts = [
            productsMap.VIRTUAL_OFFICE.productNameBackend,
            productsMap.MEETING_ROOM.productNameBackend,
        ];

        // @ts-ignore
        if (!productBackendKey || parsedSEODataUnsupportedProducts.includes(productBackendKey)) {
            return parsedData;
        }

        parsedData = {
            ...parsedData,
            breadcrumbsConfig: this.parseSeoData(parsedData),
        };
        return parsedData;
    }

    // TODO: ADD PROPER GENERIC TYPE
    getWorkspaceDetailBySlug<T = any>(workspaceSlug: string, productBackendKey?: string): Observable<T> {
        let apiUrl = `${environment.baseUrl}/workspace/workspacedetails/${workspaceSlug}`;
        if (productBackendKey) {
            apiUrl = `${apiUrl}?productType=${productBackendKey}`;
        }

        return this.http.get<IHttpResponse<T>>(apiUrl).pipe(
            map(res => {
                if (res && res.success) {
                    return res.data;
                } else {
                    console.warn(
                        `getWorkspaceDetailBySlug API failed for ${workspaceSlug} ${productBackendKey}, `,
                        res
                    );
                    throw new Error(res.reason);
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    parseSocialTags(tags?: IWorkspaceSocialTag[]): Record<WorkspaceSocialTagId, IWorkspaceSocialTag['data']> {
        const result = {} as ReturnType<typeof this.parseSocialTags>;

        if (tags?.length > 0) {
            return tags.reduce((acc, tag) => {
                acc[tag.id] = tag.data;
                return acc;
            }, result);
        }

        return result;
    }

    getWorkspaceNeighbourhoodDetails(workspaceSlug: string) {
        const apiUrl = `${environment.baseUrl}/workspace/web/${workspaceSlug}/neighbourhood`;
        return this.http.get<IHttpResponse<INeighbourhoods[]>>(apiUrl).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn(`getWorkspaceNeighbourhoodDetails API failed for ${workspaceSlug}: ${res?.reason}`);
                    throw new Error(res?.reason || 'Unknown error');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    getWorkspaceAmenitiesDetails(
        workspaceId: string,
        productBackendKey?: string
    ): Observable<{
        amenitiesGroup: IAmenitiesGroup[];
    }> {
        let apiUrl = `${environment.baseUrl}/workspace/web/${workspaceId}/amenities`;
        if (productBackendKey) {
            apiUrl = `${apiUrl}?product=${productBackendKey}`;
        }

        return this.http
            .get<
                IHttpResponse<{
                    amenitiesGroup: IAmenitiesGroup[];
                }>
            >(apiUrl)
            .pipe(
                map(res => {
                    if (res && res.success) {
                        return res.data;
                    } else {
                        console.warn(
                            `getWorkspaceAmenitiesDetails API failed for ${workspaceId} ${productBackendKey}, `,
                            res
                        );
                        throw new Error(res.reason);
                    }
                }),
                catchError(this.errorService.handleError)
            );
    }

    getListingFiltersData(payload: IFiltersPayload): Observable<{ filters: IFilterConfig[] }> {
        const url = `${environment.baseUrl}/workspace/web/filters`;
        const geolocationData = this.geolocationService.getGeoLocationDataSync();
        // Update the lat-long values, if payload doesnt have it already and we have users geo-coords
        if (geolocationData.success && !(payload.latitude && payload.longitude)) {
            payload = {
                ...payload,
                latitude: geolocationData.geolocation?.latitude.toString(),
                longitude: geolocationData.geolocation?.longitude.toString(),
            };
        }
        return this.http.post<IHttpResponse<Omit<IFiltersConfig, 'selectedFilters'>>>(url, payload).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn(`Something went wrong in web filters API`);
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    // Get locality filter data
    getLocalityFilterData(payload: ILocalityFilterDataAPIPayload): Observable<ILocalityFilterDataAPIResponse> {
        const url = `${environment.baseUrl}/workspace/web/filters/localities`;
        return this.http.post<IHttpResponse<ILocalityFilterDataAPIResponse>>(url, payload).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn(`Something went wrong in list calendar API`);
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    // Check if we need to add lat long info in here as well
    getPopularWorkspaces(cityslug: string, productBackendKey: string): Observable<IPopularWorkspaces> {
        const url = `${environment.baseUrl}/workspace/popularbyspacetype/${cityslug}?product=${productBackendKey}`;
        return this.http.get<IHttpResponse<IPopularWorkspaces>>(url).pipe(
            map(({ success = true, data }) => {
                if (success) {
                    const parsedData: IPopularWorkspaces = {
                        coworking: [],
                        workcafe: [],
                    };
                    if (data) {
                        Object.keys(data).map(spaceType => {
                            parsedData[spaceType] = data[spaceType].map(workspace => {
                                return {
                                    ...workspace,
                                    offers: workspace.offersNew
                                        ? Object.keys(workspace.offersNew).map(
                                              offerKey => workspace.offersNew[offerKey]
                                          )
                                        : {},
                                    displayTimings: this.getTimingString(workspace),
                                };
                            });
                        });
                    }

                    return parsedData;
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    getPopularWorkspacesByCityAndProduct(
        cityId: string,
        productBackendKey: string
    ): Observable<IPopularWorkspacesByCityAndProductAPIResponse> {
        const url = `${environment.baseUrl}/workspace/web/popular/city/${cityId}?product=${productBackendKey}`;
        return this.http.get<IHttpResponse<IPopularWorkspacesByCityAndProductAPIResponse>>(url).pipe(
            map(res => {
                if (res.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in popular workspaces API');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    getTodaysTimingObj(workHours, date) {
        const dayIndex = new Date(date).getDay();
        if (workHours && workHours[dayIndex]) {
            return workHours[dayIndex];
        }
        return null;
    }

    getTimingString(workspace) {
        const now = new Date();
        const timings = this.getTodaysTimingObj(workspace.workHours, now);
        if (!timings) {
            return `${workspace.timings}`;
        }

        const currentMinutesCount = now.getHours() * 60 + now.getMinutes();
        if (timings.startTime && currentMinutesCount < timings.startTime) {
            return 'Open today';
        } else if (timings.endTime && currentMinutesCount >= timings.endTime) {
            return 'Closed now';
        } else {
            return `Open now, ${workspace.timings.substring(0, 14)}`;
        }
    }

    getNearbySpaceSuggestions(workspaceSlug: string, productBackendKey?: string) {
        let params = '';
        if (productBackendKey) {
            params = `?productType=${productBackendKey}`;
        }
        const apiUrl = `${this.serviceUrl}/nearbyspacesuggestions/${workspaceSlug}${params}`;
        return this.http.get<IHttpResponse<any>>(apiUrl).pipe(catchError(this.errorService.handleError));
    }

    getMeetingRoomsNearbySpaceSuggestions(
        workspaceSlug: string,
        capacity: string
    ): Observable<IHttpResponse<IMeetingRoomNearbySpacesAPIResponse>> {
        const apiUrl = `${environment.baseUrl}/meeting-room/web/nearby/${workspaceSlug}?capacity=${capacity}`;
        return this.http
            .get<IHttpResponse<IMeetingRoomNearbySpacesAPIResponse>>(apiUrl)
            .pipe(catchError(this.errorService.handleError));
    }

    // Get Workspace display name
    getFullWorkspaceDisplayName(workspace) {
        return workspace?.buildingName ? `${workspace.name} - ${workspace.buildingName}` : workspace.name;
    }

    // Return the given object with attribute sorted on the given sort key
    sortObjectAttribute(outerObject, objectAttr, sortKey) {
        return {
            ...outerObject,
            [objectAttr]:
                outerObject[objectAttr] && outerObject[objectAttr].length
                    ? outerObject[objectAttr].sort((a, b) => {
                          if (a[sortKey] === b[sortKey]) {
                              return 0;
                          } else {
                              return a[sortKey] > b[sortKey] ? 1 : -1;
                          }
                      })
                    : outerObject[objectAttr],
        };
    }

    updateTimingString(workspace, productName, selectedDate) {
        let timingValue: string;
        let timingStatus: string;
        let workspaceWorkHours;
        let workspaceTimings;

        switch (productName) {
            case productKeys.FLEXI: {
                workspaceWorkHours = workspace.flexiworkspace?.workHours;
                workspaceTimings = workspace.flexiworkspace?.timings;
                break;
            }
            case productKeys.DEDICATED: {
                workspaceWorkHours = workspace.dedicatedworkspace?.workHours;
                workspaceTimings = workspace.dedicatedworkspace?.timings;
                break;
            }
        }

        if (selectedDate?.date && this.dateTimeService.isToday(selectedDate.date)) {
            const now = new Date();
            const timings = this.getTodaysTimingObj(workspaceWorkHours, selectedDate.date);
            const currentMinutesCount = now.getHours() * 60 + now.getMinutes();
            if (!timings) {
                timingValue = `${workspaceTimings}`;
            } else if (currentMinutesCount < timings.startTime) {
                timingStatus = 'Open Today';
                timingValue = `${workspaceTimings}`;
            } else if (currentMinutesCount >= timings.endTime) {
                timingStatus = 'Closed now';
                timingValue = `${workspaceTimings}`;
            } else {
                timingStatus = 'Open now';
                timingValue = `${workspaceTimings}`;
            }
        } else {
            timingValue = `${workspaceTimings}`;
        }
        return {
            timingStatus,
            timingValue,
        };
    }

    getPrimaryConnectivity(connectivityDetails: IWorkspaceConnectivityDetails[]): IParsedWorkspaceConnectivityDetails {
        if (connectivityDetails?.length) {
            return {
                ...connectivityDetails[0],
                station:
                    connectivityDetails[0].station + connectivityNameSuffix[connectivityDetails[0].connectivityType],
                lineName:
                    connectivityDetails[0].lineName + connectivityLineSuffix[connectivityDetails[0].connectivityType],
                additionalStationCount: connectivityDetails.length - 1,
            };
        }
    }

    parseMapMarkerData(workspace, selectedDate, productName) {
        const parsedConfig: any = {
            _id: workspace._id,
            name: this.getFullWorkspaceDisplayName(workspace),
            spaceImage: workspace.spaceImage,
            spaceType: workspace.spaceType,
            location: workspace.location,
            city: workspace.city,
            date: this.updateTimingString(workspace, productName, selectedDate),
            redirectUrl: workspace.redirectUrl,
            productTags: [],
            offerTags: [],
            reviewSummary: workspace.reviewSummary,
            directions: workspace?.directions,
            propertyLayout: workspace?.propertyLayout,
        };
        switch (productName) {
            case productKeys.FLEXI: {
                parsedConfig.primaryConnectivity = this.getPrimaryConnectivity(
                    workspace.directions?.connectivityDetails
                );
                parsedConfig.productTags = workspace.flexiworkspace?.tags?.product;
                parsedConfig.offerTags = workspace.flexiworkspace?.tags?.offer;
                parsedConfig.displayCreditConfig = {
                    price: workspace?.flexiinventory?.[0]?.price,
                    rackPrice: workspace?.flexiinventory?.[0]?.rackPrice,
                    seats: workspace?.flexiinventory?.[0]?.count,
                    freeTrial: workspace?.flexiworkspace?.allowTrials,
                    isAvailable: !workspace?.flexiworkspace?.blockReserve,
                };
                parsedConfig.buttonConfig = {
                    label: workspace?.flexiworkspace?.allowTrials ? 'Book Trial' : 'Book Desk',
                    type: buttonType.PRIMARY,
                    size: fontSizes.SMALL,
                };
                break;
            }
            case productKeys.DEDICATED: {
                parsedConfig.mostAffordablePlan = this.commonService.getSortedArrayWithUniqueElements(
                    workspace.dedicatedpriceplans || [],
                    'pricePerSeat',
                    'seatType'
                )[0];
                parsedConfig.productTags = workspace.dedicatedworkspace?.tags?.product;
                break;
            }
            case productKeys.MANAGED_OFFICE: {
                parsedConfig.managedofficepriceplans = workspace?.managedofficepriceplans;
                parsedConfig.managedofficeworkspace = {
                    ...workspace?.managedofficeworkspace,
                    totalCapacity:
                        workspace?.propertyLayout?.selectedCapacityUnit === CapacityUnitType.SEAT
                            ? workspace?.propertyLayout?.totalSeating
                            : workspace?.propertyLayout?.superArea,
                    unit: workspace?.propertyLayout?.selectedCapacityUnit ?? CapacityUnitType?.SQFT,
                };
                break;
            }
        }
        return parsedConfig;
    }

    getLocalityListingString(locations = []) {
        const maxLocationCountToShow = 2;
        let localityString = locations
            .filter((_, idx) => idx < maxLocationCountToShow)
            .map(location => location.name)
            .join(', ');
        if (locations.length > maxLocationCountToShow) {
            localityString += ` + ${locations.length - maxLocationCountToShow} More`;
        }
        return localityString;
    }

    getWorkspacesBySlugs(payload: IGetWorkspacesBySlugsPayload): Observable<IGetWorkspacesBySlugsResponse> {
        const url = `${environment.baseUrl}/spacevisit/web/workspace-list/`;
        if (!payload.workspaceSlugs) {
            payload.workspaceSlugs = [];
        }
        return this.http.post<IHttpResponse<IGetWorkspacesBySlugsResponse>>(url, payload).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in get workspaces by slug');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    parseDistanceLabel(distance: number) {
        if (distance) {
            return this.distanceFormatPipe.transform(distance);
        }
    }

    parseWorkspaceAmenities(amenities?: IWorkspaceTag[], visibleAmentiesCount = 2) {
        if (!amenities) {
            return {
                mainAmenities: [],
                hasOtherAmenities: false,
            };
        }
        const hasOtherAmenities = amenities.length > visibleAmentiesCount;
        const otherAmenities = hasOtherAmenities ? amenities.slice(visibleAmentiesCount, amenities.length) : null;
        return {
            mainAmenities: amenities.slice(0, visibleAmentiesCount),
            hasOtherAmenities,
            otherAmenitiesTooltip: hasOtherAmenities ? otherAmenities?.map(amenity => amenity.label)?.join(', ') : null,
            otherAmenitiesTitle: hasOtherAmenities ? `+${otherAmenities?.length}` : null,
        };
    }

    getProductWorkspaceOffersByCity(product: products, citySlug: string) {
        const url = `${environment.baseUrl}/workspace/web/offer-carousel/${product}`;
        const params = { citySlug };

        return this.http.get<IHttpResponse<IGetProductWorkspacesOffersForCity>>(url, { params }).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in other products at workspace API');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    getAvailableProductsAtWorkspace(workspaceSlug: string): Observable<IGetAvailableProductsAtWorkspace> {
        const url = `${environment.baseUrl}/workspace/web/${workspaceSlug}/available-products`;
        return this.http.get<IHttpResponse<IGetAvailableProductsAtWorkspace>>(url).pipe(
            map(res => {
                if (res?.success) {
                    return res?.data;
                } else {
                    console.warn('Something went wrong in other products at workspace API');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    getMostBookedWorkspaces<T extends IProductKeysBackend>(
        product: T,
        citySlug: string
    ): Observable<IGetMostBookedWorkspaces<T>> {
        const url = `${environment.baseUrl}/workspace/web/most-booked-spaces/${product}`;
        const params = { citySlug };
        return this.http.get<IHttpResponse<IGetMostBookedWorkspaces<T>>>(url, { params }).pipe(
            map(res => {
                if (res?.success) {
                    return res?.data;
                } else {
                    console.warn('Something went wrong in other products at workspace API');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }
}
