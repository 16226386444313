import { Injectable, OnInit } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class InterceptorService implements OnInit {
    constructor() {}

    ngOnInit() {}

    getFlattenObjectSortedOnKeys(obj) {
        const flattenedObj = {};
        Object.keys(obj).map((key: string) => {
            if (obj.hasOwnProperty(key)) {
                if (Array.isArray(obj[key])) {
                    flattenedObj[key] = obj[key].sort();
                } else if (obj[key] && typeof obj[key] === 'object') {
                    const flatObject = this.getFlattenObjectSortedOnKeys(obj[key]);
                    Object.keys(flatObject).map((flatKey: string) => {
                        if (flatObject.hasOwnProperty(flatKey)) {
                            flattenedObj[`${key}.${flatKey}`] = flatObject[flatKey];
                        }
                    });
                } else if (obj[key]) {
                    flattenedObj[key] = obj[key];
                }
            }
        });
        return flattenedObj;
    }

    getCacheUrlKey(request) {
        let key = request.urlWithParams;
        if (request.method === 'POST') {
            let stringifiedPayload = '';
            const flatObject = this.getFlattenObjectSortedOnKeys(request.body);
            stringifiedPayload = JSON.stringify(flatObject, Object.keys(flatObject).sort());
            key = `${key}-${stringifiedPayload}`;
        }
        return key;
    }
}
