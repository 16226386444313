import { UtilsService } from '../../utils/utils.service';
import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable({
    providedIn: 'root',
})
export class SentryService {
    init() {
        if (this.utilsService.getDeviceInfo().isBrowser) {
            Sentry.init({
                dsn: environment.sentryDSN,
                environment: environment.env,
                integrations: [
                    // Registers and configures the Tracing integration,
                    // which automatically instruments your application to monitor its
                    // performance, including custom Angular routing instrumentation
                    Sentry.browserTracingIntegration(),
                    // Registers the Replay integration,
                    // which automatically captures Session Replays
                    Sentry.replayIntegration({
                        maskAllText: false,
                        blockAllMedia: false,
                    }),
                ],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for tracing.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,
                // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
                tracePropagationTargets: [environment.baseUrl],

                // Capture Replay for 10% of all sessions,
                // plus for 100% of sessions with an error
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
            });
        }
    }

    constructor(private utilsService: UtilsService) {}

    throwError(errorMessage: string, errorName?: string) {
        const error = new Error(errorMessage);
        if (errorName) {
            error.name = errorName;
        }
        Sentry.captureException(error);
    }
}
