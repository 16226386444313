<div *ngIf="areaMetaDataList?.length > 0" class="area-metadata-container">
    <div class="area-blocks-container">
        <div class="area-block flx-1" *ngFor="let areaMetadata of areaMetaDataList; let i = index" #contentContainer>
            <div class="area-heading" *ngIf="areaMetadata.title">
                <h1>{{ areaMetadata.title }}</h1>
            </div>
            <div class="area-desc" [ngClass]="{ expanded: areaMetadata.isExpanded }">
                <div class="content-container injected-content" [innerHTML]="areaMetadata?.about"></div>
            </div>
            <div
                class="d-flx flx-d-row a-items-c view-content-cta c-pointer"
                (click)="toggleViewMore(i)"
                *ngIf="areaMetadata.ctaConfig.show"
            >
                <p class="view-content-label">{{ areaMetadata.ctaConfig.label }}</p>
                <div class="d-flx flx-d-row a-items-c j-cont-cen view-content-cta-icon-container">
                    <img
                        class="view-icon"
                        appImageShimmerLazyLoad
                        [imageSrc]="areaMetadata.ctaConfig.icon"
                        alt="icon"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
