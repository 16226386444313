<div class="d-flx flx-d-clm snackbar-list" *ngIf="isVisible">
    <div *ngFor="let snackItem of snackBarItems" [ngClass]="snackItem.cssClass">
        <div class="d-flx a-items-c flx-d-row title-container">
            <div class="d-flx snackbar-icon" [innerHTML]="snackItem.icon"></div>
            <p class="snackbar-title">{{ snackItem.title }}</p>
        </div>
        <p class="snackbar-description" *ngIf="snackItem.description">{{ snackItem.description }}</p>
        <div class="cta-container" *ngIf="snackItem.ctaConfig?.show">
            <p class="cta-label c-pointer" (click)="handleCTAClick(snackItem)">{{ snackItem.ctaConfig.label }}</p>
        </div>
        <div
            [innerHTML]="crossIcon"
            class="d-flx a-items-c j-cont-cen close-cta c-pointer"
            (click)="hideSnackBar(snackItem)"
        ></div>
    </div>
</div>
