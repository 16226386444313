import { NgModule } from '@angular/core';
import { SelectCityComponent } from './select-city.component';
import { CommonModule } from '@angular/common';
import { SelectCityModalComponent } from '../select-city-modal/select-city-modal.component';

@NgModule({
    declarations: [SelectCityComponent],
    imports: [CommonModule, SelectCityModalComponent],
    exports: [SelectCityComponent],
})
export class SelectCityModule {}
