import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { ICalendarFormFieldConfig } from './calendar-form-field.interface';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from '../../standalone-components/calendar/calendar.component';
import { ICalendarConfig } from '../../standalone-components/calendar/calendar.interface';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { DirectivesModule } from '../../directives/directives.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { IButtonConfig } from '../../interfaces/button.interface';
import { buttonHTMLType, buttonType, fontSizes, fontWeights } from '../../enums/enums';
import { CalendarFormFieldDropdownPosition } from './calendar-form-field.enum';
import { FormService } from '../../services/form/form.service';
import { Subscription } from 'rxjs';

@Component({
    standalone: true,
    selector: 'app-calendar-form-field',
    templateUrl: './calendar-form-field.component.html',
    styleUrls: ['./calendar-form-field.component.scss'],
    imports: [CommonModule, ReactiveFormsModule, CalendarComponent, forwardRef(() => DirectivesModule), ButtonsModule],
})
export class CalendarFormFieldComponent implements OnInit, AfterViewInit, OnDestroy {
    CommonIconsUrl = CommonIconsUrl;
    deviceInfo = this.utilsService.getDeviceInfo();
    cssClass = '';
    @Input() form: UntypedFormGroup;
    @Input() field: ICalendarFormFieldConfig;
    calendarConfig: ICalendarConfig;
    showDropdown = false;
    uniqueId: number;
    isInputClickedOnce = false;
    confirmDateBtnConfig: IButtonConfig = {
        label: 'Confirm Date',
        type: buttonType.PRIMARY,
        cssClass: 'mob-fixed-btm',
        disabled: true,
        fontWeight: fontWeights.BOLD,
        size: fontSizes.LARGE,
        htmlType: buttonHTMLType.button,
    };
    formFieldTriggerSub: Subscription;

    @ViewChild('dropdownWrapper') dropdownWrapperRef: ElementRef;

    appDropdownConfig = {
        dropdown: {
            topLeft: 'top-left',
            topRight: 'top-right',
            bottomLeft: 'bottom-left',
            bottomRight: 'bottom-right',
        },
    };

    constructor(
        private utilsService: UtilsService,
        private formService: FormService
    ) {}

    ngOnInit() {
        this.uniqueId = this.utilsService.getUniqueId(); // Different Child Components have unique IDs.
        if (this.field) {
            this.calendarConfig = {
                minDate: this.field.minDate,
                maxDate: this.field.maxDate,
                selectedDate: new Date(),
            };
        }

        if (this.field?.value) {
            this.calendarConfig = {
                ...this.calendarConfig,
                selectedDate: this.field.value,
            };
            this.form.get(this.field.name).setValue(this.calendarConfig.selectedDate);
        } else {
            // Setting Initial Value as empty
            this.form.get(this.field.name).setValue('');
        }

        if (this.field.useFormFieldTriggerSubscription) {
            this.formFieldTriggerSub = this.formService.getFormFieldTriggerSub().subscribe(res => {
                if (res?.fieldName === this.field?.name) {
                    this.toggleDropdown(res.show);
                }
            });
        }
    }

    ngAfterViewInit() {}

    ngOnDestroy() {
        if (this.formFieldTriggerSub) {
            this.formFieldTriggerSub.unsubscribe();
        }
    }

    handleDateChange(selectedDate: Date) {
        this.calendarConfig = {
            ...this.calendarConfig,
            selectedDate,
        };
        this.updateDateValueInForm();
        this.toggleDropdown(false);
    }

    updateDateValueInForm() {
        if (this.isInputClickedOnce) {
            this.form.get(this.field.name).setValue(this.calendarConfig.selectedDate);
        }
        this.confirmDateBtnConfig = {
            ...this.confirmDateBtnConfig,
            disabled: false,
        };
    }

    toggleDropdown(show?: boolean) {
        if (show !== undefined) {
            this.showDropdown = show;
        } else {
            this.showDropdown = !this.showDropdown;
        }
        if (this.showDropdown) {
            this.isInputClickedOnce = true;
        }
    }
}
