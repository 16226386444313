import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { InputComponent } from '../../ui-components/input/input.component';
import { DropdownComponent } from '../../ui-components/dropdown/dropdown.component';
import { TextareaComponent } from '../../ui-components/textarea/textarea.component';
import { IFieldConfig } from '../../interfaces/field.interface';
import { formFieldType } from '../../enums/enums';
import { CalendarFormFieldComponent } from '../../ui-components/calendar-form-field/calendar-form-field.component';
import { TimeDurationFormFieldComponent } from '../../ui-components/time-duration-form-field/time-duration-form-field.component';
import { MultiSelectDropdownComponent } from '../../ui-components/multi-select-dropdown/multi-select-dropdown.component';
import { SingleSelectDropdownComponent } from '../../ui-components/single-select-dropdown/single-select-dropdown.component';

@Directive({
    selector: '[formField]',
})
export class FormFieldDirective implements OnInit {
    @Input() field: IFieldConfig;
    @Input() form: UntypedFormGroup;

    componentRef: any;

    constructor(private container: ViewContainerRef) {}

    ngOnInit() {
        if (Object.keys(this.field).length === 0) {
            return;
        }
        // avoid using text input without providing type
        let component;
        switch (this.field.fieldType) {
            case formFieldType.INPUT:
            case formFieldType.PASSWORD:
                component = InputComponent;
                break;
            case formFieldType.DROPDOWN:
                component = DropdownComponent;
                break;
            case formFieldType.MULTI_DROPDOWN:
                component = MultiSelectDropdownComponent;
                break;
            case formFieldType.SINGLE_SELECT_DROPDOWN:
                component = SingleSelectDropdownComponent;
                break;
            case formFieldType.TEXTAREA:
                component = TextareaComponent;
                break;
            case formFieldType.DATE:
                component = CalendarFormFieldComponent;
                break;
            case formFieldType.TIME_DURATION:
                component = TimeDurationFormFieldComponent;
                break;
            default:
                component = InputComponent;
        }
        this.componentRef = this.container.createComponent(component);
        this.componentRef.instance.field = this.field;
        this.componentRef.instance.form = this.field.optional ? this.form.get('optionalFields') : this.form;
    }
}
