import { Component, OnInit, Input } from '@angular/core';
import { CommonIconsUrl } from './../../../core/constants/common-icons-url';

interface ILeadSuccessConfig {
    heading: string;
    description?: string;
}

@Component({
    selector: 'app-lead-success-portrait',
    templateUrl: './lead-success-portrait.component.html',
    styleUrls: ['./lead-success-portrait.component.scss'],
})
export class LeadSuccessPortraitComponent implements OnInit {
    constructor() {}

    @Input() config: ILeadSuccessConfig;
    @Input() alignCenter: boolean;
    commonIconsUrl = CommonIconsUrl;

    ngOnInit() {}
}
