import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-workspace-search-list-shimmer',
    templateUrl: './workspace-search-list-shimmer.component.html',
    styleUrls: ['./workspace-search-list-shimmer.component.scss'],
})
export class WorkspaceSearchListShimmerComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
