import { Injectable } from '@angular/core';
import { dateStatus, nearMeLocationObj } from '../../../shared/enums/enums';
import { ILocation } from '../../interfaces/location.interface';
import { Observable } from 'rxjs';
import { IDateObj } from '../../interfaces/date.interface';
import { SelectCityService } from './../select-city/select-city.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor(private selectCityService: SelectCityService) {}

    getEpochValue(dayNo: number) {
        let epoch = new Date();
        epoch = new Date(epoch.setDate(epoch.getDate() + dayNo));
        epoch.setHours(0, 0, 0, 0);
        return epoch.getTime();
    }

    getEpocFromDate(date) {
        return new Date(date).getTime();
    }

    getEpochDateArray(daysCount: number): number[] {
        const dateArray = [];
        for (let i = 0; i < daysCount; i++) {
            const epocValue = this.getEpochValue(i);
            dateArray.push(epocValue);
        }
        return dateArray;
    }

    getCalendar(daysCount): Observable<IDateObj[]> {
        daysCount = daysCount ? daysCount : 8;
        const calendar: IDateObj[] = [];
        for (let i = 0; i < daysCount; i++) {
            calendar.push({
                date: new Date(this.getEpochValue(i)).toISOString(),
                status: dateStatus.AVAILABLE,
            });
        }
        return new Observable(observer => {
            observer.next(calendar);
        });
    }

    getQueryParamsFromString(urlString: string) {
        const queryParams = {};
        let queryString = '';
        const url = urlString.split('?')[0];
        if (urlString.indexOf('?') > -1) {
            queryString = urlString.split('?')[1];
        }
        if (queryString && queryString.indexOf('=') > 0) {
            // indexOf '=' is > 0 because we were expected atleast 1 key char or string
            const params = queryString.split('&');
            for (const param of params) {
                const [key, value] = param.split('=');
                queryParams[key] = decodeURIComponent(value);
            }
        }
        return { url, queryParams };
    }

    getNearMeLocationObj(): ILocation {
        return {
            place_id: nearMeLocationObj.PLACE_ID,
            name: nearMeLocationObj.DISPLAY,
            isLocation: true,
        };
    }

    getSeatingTextFromSeatSlug(slug, isSingular = false) {
        let seatingText = '';
        switch (slug) {
            case 'coworking-space':
                seatingText = 'coworking spaces';
                break;
            case 'dedicated-desks':
                seatingText = 'dedicated desks';
                break;
            case 'private-cabins':
                seatingText = 'private cabins';
                break;
            case 'office-space':
                seatingText = 'managed offices';
                break;
            case 'work-cafes':
                seatingText = 'work cafes';
                break;
            case 'managed-office':
                seatingText = 'managed offices';
                break;
            default:
                seatingText = 'coworking spaces';
        }
        if (isSingular) {
            seatingText = seatingText.slice(0, -1);
        }
        return seatingText;
    }

    getProductSupportBySelectedCity(productBackendKey: string): Observable<boolean> {
        return this.selectCityService.getSelectedCitySync().pipe(
            map(({ city }) => {
                if (!city) {
                    return true;
                } else if (city && city.products) {
                    return city.products.some(
                        productSupportedByCity => productSupportedByCity.key === productBackendKey
                    );
                }
            })
        );
    }

    getMaskedString(data: string, start: number, end: number) {
        return data.substring(0, start) + data.substring(start, end).replace(/\d/g, '*') + data.substring(end);
    }

    getFormattedCurrency(value: number, fractionalDigitCount: number = 0): string {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: fractionalDigitCount,
        }).format(value);
    }

    // Returns an sorted array on 'sortKey' having only objects with unique 'uniqueKey' key
    getSortedArrayWithUniqueElements(objectArray = [], sortKey, uniqueKey) {
        const compareFunc = (object1, object2) => (object1.price < object2.price ? -1 : 1);
        const uniqueCheck = (object1, index, self) =>
            self.findIndex(obj => obj[uniqueKey] === object1[uniqueKey]) === index;

        return objectArray.sort(compareFunc).filter(uniqueCheck);
    }
}
