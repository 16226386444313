import { TypeaheadModule as BootstrapTypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgModule } from '@angular/core';
import { TypeaheadComponent } from './typeahead.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [TypeaheadComponent],
    imports: [CommonModule, FormsModule, BootstrapTypeaheadModule.forRoot()],
    exports: [TypeaheadComponent],
})
export class TypeaheadModule {}
