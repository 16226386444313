<div class="tab-outer-container" [ngClass]="cssClass" #container>
    <div class="tab-inner-container">
        <tabset #tabset [vertical]="config?.isVertical">
            <tab
                role="tab"
                *ngFor="let tab of config?.tabs; let index = index"
                [active]="index === config?.selectedTabIndex"
                [id]="index"
                (selectTab)="onSelect($event)"
            >
                <ng-template tabHeading>
                    <ng-container
                        *ngIf="config.customTabTemplate"
                        [ngTemplateOutlet]="config.customTabTemplate"
                        [ngTemplateOutletContext]="{ tab: tab }"
                    ></ng-container>
                    <ng-container
                        *ngIf="!config.customTabTemplate"
                        [ngTemplateOutlet]="defaultTabView"
                        [ngTemplateOutletContext]="{ label: tab.label }"
                    ></ng-container>
                </ng-template>
            </tab>
        </tabset>
    </div>
</div>
<div class="tab-contents-container" *ngIf="config?.template">
    <div
        *ngFor="let tab of config?.tabs; let index = index"
        [ngClass]="{ 'hidden-block-element': index !== config?.selectedTabIndex }"
        class="tab-content-container"
        [id]="index"
    >
        <ng-container
            *ngTemplateOutlet="config?.template; context: { data: tab.data }"
            aria-current="page"
        ></ng-container>
    </div>
</div>

<!--templates-->
<ng-template let-label="label" #defaultTabView>
    <p class="tab-label">{{ label }}</p>
</ng-template>
