export enum LoginFormStages {
    ENTER_MOBILE = 'ENTER_MOBILE',
    VERIFY_OTP = 'VERIFY_OTP',
    CREATE_PROFILE = 'CREATE_PROFILE',
}

export enum AuthStatus {
    AUTHENTICATED = 'AUTHENTICATED',
    UNAUTHENTICATED = 'UNAUTHENTICATED',
}
