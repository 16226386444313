import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { LocationService } from '../../services/location/location.service';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { SelectCityService } from '../../services/select-city/select-city.service';
import { flatMap } from 'rxjs/operators';
import { IProductMap } from '../../interfaces/product-interface';
import { DomSanitizer } from '@angular/platform-browser';
// @ts-ignore
import LocationIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/location-flat.svg';
// @ts-ignore
import TrendIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/trend-flat.svg';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives/directives.module';

@Component({
    standalone: true,
    selector: 'app-popular-locations',
    templateUrl: './popular-locations.component.html',
    styleUrls: ['./popular-locations.component.scss'],
    imports: [CommonModule, DirectivesModule],
})
export class PopularLocationsComponent implements OnInit, OnDestroy {
    constructor(
        private locationService: LocationService,
        private selectCityService: SelectCityService,
        private sanitizer: DomSanitizer
    ) {}

    @Input() heading: string;
    @Input() maxLocationCount?: number;
    @Input() selectedLocationId?: string;
    @Input() product?: IProductMap;
    @Output() clickLocation = new EventEmitter();
    selectCitySub;
    showLoader = false;
    popularLocations: any = [];
    selectedLocation: any;
    locationIcon = this.sanitizer.bypassSecurityTrustHtml(LocationIcon);
    trendIcon = this.sanitizer.bypassSecurityTrustHtml(TrendIcon);

    ngOnInit() {
        this.selectCitySub = this.selectCityService
            .getSelectedCitySync()
            .pipe(
                flatMap(({ city }) => {
                    if (city) {
                        this.showLoader = true;
                        return this.locationService.getPopularLocations(city._id, this.product.productNameBackend);
                    } else {
                        return [];
                    }
                })
            )
            .subscribe(popularlocations => {
                this.showLoader = false;
                this.popularLocations = this.maxLocationCount
                    ? popularlocations.slice(0, this.maxLocationCount)
                    : popularlocations;
            });
    }

    ngOnDestroy() {
        this.selectCitySub.unsubscribe();
    }

    onClickPopularLocation(location) {
        this.selectedLocation = location;
        this.clickLocation.emit(location);
    }
}
