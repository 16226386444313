import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UrlUtilsService {
    private queryParams: Record<string, any>;
    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.route.queryParams.subscribe(paramsData => {
            this.queryParams = paramsData;
        });
    }

    updateUrl(url?, queryParamsObj?, options: any = {}) {
        if (url && queryParamsObj) {
            this.router.navigate([url], { queryParams: queryParamsObj, ...options });
        } else if (url) {
            this.router.navigate([url], { ...options });
        } else if (queryParamsObj) {
            this.router.navigate([], { queryParams: queryParamsObj, ...options });
        }
    }

    public getAbsoluteUrl(redirectUrl: string) {
        return `${environment.SERVER_URL}${redirectUrl}`;
    }

    interpolateParams(str, params) {
        return str.replace(/%{(\w+)}/g, (match, paramKey) => {
            if (Object.keys(params).includes(paramKey)) {
                return params[paramKey];
            } else {
                console.warn(`params object ${JSON.stringify(params)} do not contain expected keys for string: ${str}`);
                return `%{${paramKey}}`;
            }
        });
    }

    public getCurrentUrl() {
        return `${environment.SERVER_URL}${this.router.url}`;
    }

    public getCurrentURLQueryParams() {
        return this.queryParams;
    }
}
