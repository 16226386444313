import { Component, Input, OnInit } from '@angular/core';
import { ISearchResultListConfig } from '../../search.interface';
import { LocationSearchListComponent } from '../../../location/location-search-list/location-search-list.component';
import { WorkspaceSearchListComponent } from '../../../workspace/workspace-search-list/workspace-search-list.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-search-typeahead-result-list',
    templateUrl: './search-typeahead-result-list.component.html',
    styleUrls: ['./search-typeahead-result-list.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        WorkspaceSearchListComponent,
        LocationSearchListComponent,
    ],
})
export class SearchTypeaheadResultListComponent implements OnInit {
    @Input() config: ISearchResultListConfig;
    constructor() {}

    ngOnInit(): void {}
}
