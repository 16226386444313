import { Injectable } from '@angular/core';
import { ImageBaseUrl } from './../../../core/constants/constants';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { environment } from '../../../../environments/environment';

enum ConfigPrefix {
    width = 'w_',
    height = 'h_',
}

export const ImageSizes = {
    thumbnail: 64,
    largethumbnail: 128,
    xSmall: 240,
    small: 360,
    medium: 640,
    large: 768,
    hd: 1280,
    hdplus: 1440,
    fhd: 1920,
};

interface IImageAttributes {
    width?: number; // Width and Height Get Preference Over Auto ClientWidth Height
    clientWidth?: number;
    imageWidthSize?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ImageLazyLoadHelperService {
    devicePixelRatio: number;

    constructor(private utilsService: UtilsService) {
        this.devicePixelRatio = this.utilsService.getDevicePixelRatio();
    }

    checkIfImageLinkResponsive(imageUrl: string) {
        return imageUrl && imageUrl.startsWith(ImageBaseUrl.IMAGE_URL_BASE);
    }

    getBestFitImageSize(width: number) {
        const imageSizesArr = Object.values(ImageSizes);
        // initialize the values
        let closestSize = imageSizesArr[0];
        let closestDiff = Math.abs(closestSize - width);
        for (let i = 1; i < imageSizesArr.length; i++) {
            // Calculate the absolute difference between the current value and the given width
            const currDiff = Math.abs(imageSizesArr[i] - width);
            // If the current difference is smaller than the closest difference, Update the closest size and closest difference to the current value and difference, respectively
            if (currDiff < closestDiff) {
                closestSize = imageSizesArr[i];
                closestDiff = currDiff;
            }
        }
        return closestSize;
    }

    checkIfAttributesPresentOnLink(imageUrl: string) {
        if (imageUrl && imageUrl.startsWith(ImageBaseUrl.IMAGE_URL_BASE)) {
            const indexOfBaseUrlEnd = ImageBaseUrl.IMAGE_URL_BASE.length - 1;
            const imageUrlWithoutBase = imageUrl.slice(indexOfBaseUrlEnd);
            if (
                (imageUrlWithoutBase && imageUrlWithoutBase.includes(ConfigPrefix.width)) ||
                imageUrlWithoutBase.includes(ConfigPrefix.width)
            ) {
                return true;
            }
        }
        return false;
    }

    applyAttributesOnLink(imageUrl: string, attributes: IImageAttributes, isATFContent = false) {
        // Apply attributes on given link iff the requested image is stored on our cloudinary and its not a SVG
        if (imageUrl && imageUrl.startsWith(ImageBaseUrl.IMAGE_URL_BASE) && !imageUrl?.endsWith('.svg')) {
            let configUrl =
                imageUrl.indexOf(ImageBaseUrl.IMAGE_CONFIG_BASE_FQ) > -1
                    ? '/'
                    : `/${ImageBaseUrl.IMAGE_CONFIG_BASE_FQ}/`;
            let imageWidth;
            if (attributes.imageWidthSize && ImageSizes.hasOwnProperty(attributes.imageWidthSize)) {
                imageWidth = ImageSizes[attributes.imageWidthSize];
            } else if (attributes.width) {
                imageWidth = this.getBestFitImageSize(attributes.width);
            } else if (attributes.clientWidth) {
                imageWidth = this.getBestFitImageSize(attributes.clientWidth);
            } else {
                return imageUrl;
            }
            // DPR multiplication is only done on prod and that too for non-ATF content images (as they already have imageWidthSize specified)
            if (environment.production && !isATFContent) {
                // if the connection speed is low - no need to multiply the dimension with Pixel Ratio to increase the load on network
                const isLowSpeedNetwork = this.utilsService.isLowSpeedNetwork();
                if (this.devicePixelRatio && !isLowSpeedNetwork) {
                    imageWidth *= this.devicePixelRatio;
                }
            }

            configUrl += `${ConfigPrefix.width}${imageWidth}`;
            const indexOfBaseUrlEnd = ImageBaseUrl.IMAGE_URL_BASE.length;
            return imageUrl.slice(0, indexOfBaseUrlEnd) + configUrl + imageUrl.slice(indexOfBaseUrlEnd);
        }
        return imageUrl;
    }

    addShimmerEffect(element, renderer2) {
        const imageElement = element.nativeElement;
        renderer2.addClass(imageElement, 'shimmer');
    }

    removeShimmerEffect(element, renderer2) {
        const imageElement = element.nativeElement;
        renderer2.removeClass(imageElement, 'shimmer');
    }
}
