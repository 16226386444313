import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges } from '@angular/core';
import { menuEnum } from '../../enums/enums';
import { CommonIconsUrl } from '../../../../../core/constants/common-icons-url';
import { UtilsService } from '../../../../../core/services/utils/utils.service';
import { LocationWorkspaceSearchConfig, LocationWorkspaceSearchComponent } from '../../../location/location-workspace-search/location-workspace-search.component';
import { NgIf, NgTemplateOutlet, NgClass, NgFor } from '@angular/common';
import { DirectivesModule } from '../../../../directives/directives.module';

export interface IHeaderSearchConfig {
    showProductSwitch: boolean;
    menu?: any;
    locationWorkspaceSearchConfig: LocationWorkspaceSearchConfig;
}

@Component({
    selector: 'app-header-search',
    templateUrl: './header-search.component.html',
    styleUrls: ['./header-search.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgTemplateOutlet,
        LocationWorkspaceSearchComponent,
        DirectivesModule,
        NgClass,
        NgFor,
    ],
})
export class HeaderSearchComponent implements OnInit, OnDestroy {
    constructor(private utilsService: UtilsService) {}

    @Input() config: IHeaderSearchConfig;

    @Output() menuChangeEvent = new EventEmitter();
    @Output() toggleSearchModalEvent = new EventEmitter();
    @Output() selectLocationWorkspaceEvent = new EventEmitter();

    menuEnum = menuEnum;
    commonIconsUrl = CommonIconsUrl;
    showOptions = false;

    routerEventSub = this.utilsService.getRouterEvents$().subscribe(event => {
        this.showOptions = false;
    });

    ngOnInit() {}

    toggleOptions(toggle: boolean) {
        this.showOptions = toggle;
    }

    toggleSearchModal(toggle: boolean) {
        this.toggleSearchModalEvent.emit(toggle);
    }

    onSelectDropDownMenu(product) {
        this.menuChangeEvent.emit(product.id);
        this.toggleOptions(false);
    }

    onLocationWorkspaceSelectEvent(payload) {
        this.selectLocationWorkspaceEvent.emit(payload);
    }

    ngOnDestroy() {
        this.routerEventSub.unsubscribe();
    }
}
