import { ImageBaseUrl } from './constants';
// TODO Add Alt Values Also
// TODO:: Follow naming convention like ICON_NAME_COLOR_SIZE
// SIZE will be like LG, MD and SM
export class CommonIconsUrl {
    // arrows
    public static RIGHT_ARROW_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-arrow-primary-flat.svg`;
    public static RIGHT_ARROW_ORANGE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-arrow-orange.svg`;
    public static RIGHT_ARROW_WHITE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-arrow-white.svg`;
    public static RIGHT_ARROW_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-arrow-dark.svg`;
    public static RIGHT_ARROW_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-arrow-grey.svg`;
    public static ARROW_DOWN_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/arrow-down-flat.svg`;
    public static ARROW_DOWN_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/arrow-down-grey.svg`;
    public static ARROW_DOWN_DARK_SECONDARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/arrow-down-dark-secondary.svg`;
    public static ARROW_DOWN_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/arrow-down-dark.svg`;
    public static ARROW_UP_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/arrow-up-flat.svg`;
    public static ARROW_UP_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/arrow-up-dark.svg`;
    public static ARROW_BACK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/arrow-back.svg`;
    // WORKSPACE
    public static WORKSPACE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/workspace-icon.svg`;
    // form related icon
    public static EDIT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/edit.svg`;
    public static EDIT_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/edit-primary.svg`;
    public static EDIT_PRIMARY_FLAT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/edit-primary-flat.svg`;
    // chevrons
    public static RIGHT_CHEVRON_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-chevron-grey.svg`;
    public static RIGHT_CHEVRON_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-chevron-primary-flat.svg`;
    public static RIGHT_CHEVRON_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-chevron-dark.svg`;
    public static DOWN_CHEVRON_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/down-chevron-dark.svg`;
    // locations
    public static LOCATION = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/location-flat.svg`;
    public static LOCATION_MARKER_OUTLINED_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/location-outlined-grey.svg`;
    public static LOCATIONS_MARKER_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/locations-marker.svg`;
    public static LOCATION_MARKER_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/location-marker-primary.svg`;
    public static LOCATIONS_MARKER_LIGHT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/locations-marker-light-flat.svg`;
    public static NEAR_ME = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/near_me.svg`;
    // Calendar
    public static CALENDAR_OUTLINED_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/calender-outlined-grey.svg`;
    public static TENURE_CALENDAR_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tenure-calendar-primary.svg`;
    // Success
    public static SUCCESS_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/success-lg-flat.svg`;
    //   Download
    public static DOWNLOAD_APP = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/download-app-flat.svg`;
    public static PLAY_STORE_BADGE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/play-store-badge.svg`;
    public static APP_STORE_BADGE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/Appstore.svg`;
    public static APP_RATING = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/app-rating.svg`;

    // TODO: add more cateogry
    public static CLOSE_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/cross.svg`;
    public static CLOSE_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/close-grey.svg`;
    public static CLOSE_LIGHT_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/close-light-grey.svg`;
    public static CLOSE_CIRCLE_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/cross-dark-circle.svg`;
    public static CLOSE_WHITE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/close-white.svg`;
    public static PERSON_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/person.svg`;
    public static PERSON_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/person-grey.svg`;
    public static INVALID_WHITE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/invalid-white.svg`;
    // LOGO
    public static LOGO_LARGE = `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/logo/logo-new-flat.svg`;
    public static SEARCH = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/search.svg`;
    public static SEARCH_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/search-grey-new.svg`;
    public static NO_RESULT_FOUND = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/no-result-found.svg`;
    public static ERROR_OUTLINE_WHITE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/error-outline-white.svg`;
    public static ERROR = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/error.svg`;
    // Success Icons
    public static SUCCESS = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/success-flat.svg`;
    public static SUCCESS_OUTLINE_WHITE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/success-outline-white.svg`;
    public static SUCCESS_GREEN = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/success-flat-green.svg`;
    public static CALL = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/call-flat.svg`;
    public static EMAIL = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/email-flat.svg`;
    public static WHATSAPP = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/whatsapp.svg`;
    public static METRO_RED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/metro-red.svg`;
    public static PIN = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/pin.svg`;
    public static BUILDING = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/building.svg`;
    public static OFFER_LG = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/offer.svg`;
    public static OFFER_TAG_GREEN = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/offer-tag-green.svg`;
    public static OFFER_GREEN = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/offer-green.svg`;
    public static OFFER_LIGHT_GREEN = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/offer-light-green.svg`;
    public static OFFER_BLACK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/offer-flat-black.svg`;
    public static OFFER_WHITE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/offer-white.svg`;
    public static OFFER_BLACK_OUTLINED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/offer-black.svg`;
    public static OFFER_TICKET = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/offer-ticket.svg`;
    public static COUPON_BLACK_OUTLINED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/coupon-tag-black-outlined.svg`;
    public static COUPON_WHITE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/coupon-tag-white.svg`;
    public static TICK_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tick-primary-flat.svg`;
    public static TICK_WHITE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tick-white.svg`;
    public static TICK_FILLED_GREEN = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tick-filled-green.svg`;
    public static TICK_FILLED_LIGHT_GREEN = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tick-filled-light-green.svg`;
    public static TICK_FILLED_YELLOW = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tick-filled-yellow.svg`;
    public static RIGHT_TICK_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-tick-primary-flat.svg`;
    public static RIGHT_TICK_FILLED_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-tick-filled-grey`;
    public static TICK_FILLED_ACCENT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tick-filled-accent`;
    public static TICK_CIRCLE_OUTLINE_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tick-circle-outline-primary-flat.svg`;
    public static TICK_CIRCLE_GREEN = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tick-circle-green.svg`;
    public static TICK_CIRCLE_ACCENT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tick-circle-accent.svg`;
    public static TICK_RIGHT_FILLED_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-tick-filled-grey`;
    public static TICK_RIGHT_FILLED_CELLO = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-tick-filled-cello-2`;
    public static RIGHT_TICK_MARK_OUTLINE_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/right-tick-mark-outline-primary`;
    public static CHAT_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/chat-primary.svg`;
    public static PHONE_HAND = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/phone-hand.svg`;
    public static HELP = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/help-flat.svg`;
    public static HELP_CIRCLE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/help-circle-flat.svg`;
    public static CHECK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/check.svg`;
    public static CHECK_BLACK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/check-black.svg`;
    public static CHECK_OUTLINED_GREEN = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/check-outlined-green.svg`;
    public static DOCUMENT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/document.svg`;
    public static DOCUMENT_BLACK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/document-black.svg`;
    public static EXTERNAL_LINK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/external-link.svg`;
    public static BUDGET = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/budget.svg`;
    public static PREMIUM = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/premium.svg`;
    public static TENURE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tenure.svg`;
    public static REFRESH = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/refresh-icon.svg`;
    public static STAR_YELLOW = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/star-yellow.svg`;
    public static STAR_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/star-primary-flat.svg`;
    public static STARS_YELLOW = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/stars-yellow.svg`;
    public static STAR_ORANGE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/star-orange.svg`;
    public static STAR_WHITE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/star-white.svg`;
    public static GEAR = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/gear.svg`;
    public static STAR_SHINE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/star-shine.svg`;
    public static STAR_SHINE_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/star-shine-primary.svg`;
    public static STAR_HAND = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/star-hand.svg`;
    public static STAR_HAND_FILLED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/hand-with-filled-star.svg`;
    public static STAR_HAND_FILLED_BLACK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/star-hand-filled-black.svg`;
    public static TICK_CYCLE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tick-cycle.svg`;

    public static SHORTLIST_FILLED_RED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/shortlist-filled-red`;
    public static SHORTLIST_OUTLINED_TRANSPARENT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/shortlist-outlined-transparent`;
    public static DELETE_OUTLINE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/delete-outline.svg`;
    public static ADDITIONAL_AMENITIES_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/additional-amenities.svg`;
    public static CHAIR = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/chair.svg`;
    public static OFFICE_CHAIR = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/office-chair.svg`;
    public static CLOCK_OUTLINED_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/clock-outlined-primary.svg`;
    public static CLOCK_OUTLINED_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/clock-outlined-grey.svg`;
    public static RUPEE_OUTLINED_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/rupee-outlined-primary.svg`;
    public static RUPEE_OUTLINED_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/rupee-outlined-grey.svg`;
    public static CURRENCY_RUPEE_ACCENT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/currency-rupee-accent.svg`;
    public static ONE_CLICK_ACCENT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/one-click-accent.svg`;
    public static BUILDING_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/building-grey.svg`;
    public static PAYMENTS_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/payments-dark.svg`;
    public static UP_CHEVRON_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/up-chevron-primary.svg`;
    public static DOWN_CHEVRON_PRIMARY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/down-chevron-primary.svg`;
    public static CABINS = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/cabins.svg`;
    public static PRIVATE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/private.svg`;
    public static SPARKLES = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/sparkles.svg`;
    public static DIAMOND = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/diamond.svg`;
    public static FREE_TRIAL_PASS = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/free-trial-pass.svg`;
    public static APP_DOWNLOAD = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/app-download.svg`;
    public static INFO_OUTLINED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/info-outlined.svg`;
    public static INFO_OUTLINED_ACCENT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/info-outlined-accent.svg`;
    public static INFO_WHITE_OUTLINED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/info-white.svg`;
    public static GROWTH = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/growth.svg`;
    public static BIG_BUILDING = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/big-building.svg`;
    public static GLOBE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/globe.svg`;
    public static IDEA = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/idea.svg`;
    public static TRANSPORTATION = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/transportation.svg`;
    public static NETWORK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/network.svg`;
    public static DESK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/desk.svg`;
    public static FLEXI_PASS_OUTLINED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/flexi-pass-outlined.svg`;
    public static VIRTUAL_OFFICE_OUTLINED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/virtual-office-outlined.svg`;
    public static FIXED_DESK_OUTLINED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/fixed-desk-outlined.svg`;
    public static MEETING_ROOM_OUTLINED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/meeting-room-outlined.svg`;
    public static SAD_EMOJI = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/sad-emoji.svg`;
    public static SAD_EMOJI_RED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/sad-emoji-red.svg`;
    public static SAD_EMOJI_YELLOW = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/sad-emoji-yellow.svg`;
    public static LOCATION_OUTLINE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/location-outline.svg`;
    public static LOCATION_OUTLINE_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/location-outlined-grey.svg`;
    public static PERSON_OUTLINED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/person-outlined.svg`;
    public static PERSON_OUTLINED_GREY = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/person-outlined-grey.svg`;
    public static LOGOUT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/logout.svg`;
    public static ARROW_LIST_MARKER = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/arrow-list-marker.svg`;
    public static GST_INVOICE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/gst-invoice.svg`;
    public static MYHQ_HANDPICKED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/myhq-handpicked.svg`;
    public static MEETING_ROOM_SEAT_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/meeting-room-seat-icon.svg`;
    public static MEETING_ROOM_SEAT_ICON_GREEN = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/meeting-room-seat-icon-green.svg`;
    public static DURATION_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/duration.svg`;
    public static INCREMENT_ACTION_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/increment-action-icon.svg`;
    public static DECREMENT_ACTION_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/decrement-action-icon.svg`;
    public static CLOCK_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/clock.svg`;
    public static TEAM_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/team-icon.svg`;
    public static SETTINGS_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/settings-icon.svg`;
    public static SHINY_STAR_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/shiny-star.svg`;
    public static CHECK_ROUNDED_ARROW_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/check-rounded-arrow.svg`;
    public static LEASE_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/lease-icon.svg`;
    public static DOUBLE_SETTINGS_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/double-settings-icon.svg`;
    public static BUILD_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/build-icon.svg`;
    public static CREDITS_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/credits.svg`;
    public static CREDITS_GREY_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/credit-grey.svg`;
    public static TICK_MARK_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tickmark-icon.svg`;
    public static TICK_MARK_WHITE_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/tick-mark-white.svg`;
    public static DOUBLE_TICK_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/double-tick.svg`;
    public static LIGHT_BULB_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/light-bulb.svg`;
    public static CANCEL_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/cancel-icon.svg`;
    public static CALENDAR_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/calendar.svg`;
    public static ERROR_YELLOW = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/error-yellow.svg`;
    public static WALLET = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/wallet.svg`;
    public static WALLET_DARK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/wallet-dark.svg`;
    public static WARNING_TRIANGLE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/warning-triangle.svg`;
    public static WARNING_TRIANGLE_RED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/warning-red.svg`;
    public static WARNING_FILLED_CIRCLE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/warning-filled-circle.svg`;
    public static DIGITAL_KYC = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/digital-kyc.svg`;
    public static HASSLE_FREE = `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/flexible-plan-v2-flat.svg`;
    public static CONTACT_EXPERT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/contact-expert.svg`;
    public static CONTACT_EXPERT_BLACK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/contact-expert-black.svg`;
    public static COMPARE_BLUE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/compare-blue.svg`;
    public static WHATSAPP_LOGO = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/whatsapp-logo-light-green.svg`;
    public static CLIENTS = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/clients.svg`;
    public static SEATS = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/seats.svg`;
    public static COWORKING_SPACE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/coworking-space.svg`;
    public static LOCATION_OUTLINE_BLACK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/location-outline-black.svg`;
    public static VERIFIED_USER = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/verified-user.svg`;
    public static CROSS_RED_FILLED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/cross-red-filled.svg`;
    public static MYHQ_ICON = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/myhq-icon.svg`;
    public static QUESTION_MARK_FILLED_BLUE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/question-mark-filled-blue.svg`;
    public static HOURGLASS_DARK_OUTLINED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/hourglass-outlined.svg`;
    public static ERROR_RED_OUTLINED = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/input-invalid-outlined.svg`;
    public static COMPARE_WORKSPACE_ACCENT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/compare-workspace-accent.svg`;
    public static PLUS_CIRCLE_MULTIPLE_OUTLINE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/plus-circle-multiple-outline.svg`;
    public static VERIFIED_YELLOW = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/verified-yellow.svg`;
    public static SPACE_CLICK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/space-click.svg`;
    // Sentiment Icons
    public static HAPPY_FACE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/face/happy.svg`;
    public static NEUTRAL_FACE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/face/neutral.svg`;
    public static SAD_FACE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/face/sad.svg`;
    //enterprises state-of-the-art
    public static DATA_INFO_ALERT = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/data-info-alert.svg`;
    public static WHERE_TO_VOTE = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/where-to-vote.svg`;
    public static COMPARE_ARROWS = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/compare-arrows.svg`;
    public static ASSIGNMENT_TURNED_IN = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/assignment-turned-in.svg`;
    public static SUPPORT_AGENT_BLACK = `${ImageBaseUrl.IMAGE_URL_BASE}/${ImageBaseUrl.COMMON_ASSETS}/support-black.svg`;
}
