import { Component, DestroyRef, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ITypeaheadConfig, TYPEAHEAD_MIN_LENGTH } from '../../../interfaces/typeahead.interface';
import { RecentSearchesService } from '../../../services/recent-searches/recent-searches.service';
import { UrlUtilsService } from '../../../../core/services/utils/url-utils.service';
import {
    LocationWorkspaceSearchSuggestionType,
    nearMeLocationObj,
    productKeys,
    productsMap,
} from '../../../enums/enums';
import { LocalitiesService } from '../../../services/localities/localities.service';
import { SelectCityService } from '../../../services/select-city/select-city.service';
import { WorkspaceService } from '../../../services/workspace/workspace.service';
import { IProductMap } from '../../../interfaces/product-interface';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import { CommonIconsUrl } from '../../../../core/constants/common-icons-url';
import { map } from 'rxjs/operators';
import { LocationService } from '../../../services/location/location.service';
import { LineBreakType, LinkWithIconComponent } from '../../../ui-components/link-with-icon/link-with-icon.component';
import { CommonModule } from '@angular/common';
import { LocationSearchListComponent } from '../location-search-list/location-search-list.component';
import { StaticComponentsModule } from '../../../static-components/static-components.module';
import { NearMeComponent } from '../../near-me/near-me.component';
import { StepperComponent } from '../../stepper/stepper.component';
import { TypeaheadModule } from '../../../ui-components/typeahead/typeahead.module';
import { WorkspaceSearchListComponent } from '../../workspace/workspace-search-list/workspace-search-list.component';
import { Router } from '@angular/router';
import { SpinnerModule } from '../../../ui-components/spinner/spinner.module';
import { LocationWorkspaceSearchSections } from '../../common-header/enums/enums';
import { ICity } from '../../../interfaces/location.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RecentlySearchedComponent } from '../../recently-searched/recently-searched.component';
import { PopularLocationsComponent } from '../../popular-locations/popular-locations.component';
import { PopularWorkspacesComponent } from '../../popular-workspaces/popular-workspaces.component';
import { TYPEHEAD_WAIT_TIME } from '../../../constants/constants';

export interface LocationWorkspaceSearchConfig {
    typeaheadConfig?: ITypeaheadConfig;
    product: IProductMap;
    showSearchModal: boolean;
    searchSuggestionType: LocationWorkspaceSearchSuggestionType;
    type?: LocationWorkspaceType;
    typeaheadInitValue?: string;
    setFocusOnInit?: boolean;
    showStaticResultByDefault?: boolean;
    typeaheadContainerClass?: string;
    customDefaultSearchResultsTemplate?: TemplateRef<ElementRef>;
    selectedCity?: ICity;
}

export enum LocationWorkspaceType {
    DEFAULT_SEARCH = 'DEFAULT_SEARCH',
    COMMON_HEADER_SEARCH = 'COMMON_HEADER_SEARCH',
    LOCALITY_FILTER_SEARCH = 'LOCALITY_FILTER_SEARCH',
}

@Component({
    standalone: true,
    selector: 'app-location-workspace-search',
    templateUrl: './location-workspace-search.component.html',
    styleUrls: ['./location-workspace-search.component.scss'],
    imports: [
        CommonModule,
        TypeaheadModule,
        StepperComponent,
        LocationSearchListComponent,
        StaticComponentsModule,
        NearMeComponent,
        RecentlySearchedComponent,
        PopularLocationsComponent,
        LinkWithIconComponent,
        WorkspaceSearchListComponent,
        SpinnerModule,
        PopularWorkspacesComponent,
    ],
})
export class LocationWorkspaceSearchComponent implements OnInit {
    searchSuggestionType = LocationWorkspaceSearchSuggestionType;
    LocationWorkspaceSearchSections = LocationWorkspaceSearchSections;
    private _config: LocationWorkspaceSearchConfig;
    isNearMeLocationFetchInProgress = false;
    @Output() locationWorkspaceSelectEvent = new EventEmitter();
    @Output() onToggleModal = new EventEmitter();
    @Output() iconClickEvent = new EventEmitter();

    constructor(
        private workspaceService: WorkspaceService,
        private utilsService: UtilsService,
        private urlUtilsService: UrlUtilsService,
        private locationService: LocationService,
        private recentSearchesService: RecentSearchesService,
        private localitiesService: LocalitiesService,
        private selectCityService: SelectCityService,
        private router: Router,
        private destroyRef: DestroyRef
    ) {}
    nearMeLocationObj = nearMeLocationObj;
    selectedCity;
    showSearchModal: boolean; // displays search modal content based on the condition matched
    popularLocationCount = 8;
    commonIconsUrl = CommonIconsUrl;
    locationWorkspaceSearchCompType = LocationWorkspaceSearchSuggestionType;
    searchItemClass = '';
    deviceInfo = this.utilsService.getDeviceInfo();
    visibleSectionsInDefaultTemplate: LocationWorkspaceSearchSections[] = [];
    typeaheadConfig: ITypeaheadConfig = {
        placeholder: 'Search for location, workspace',
        typeaheadAsync: true,
        typeaheadScrollable: true,
        typeaheadOptionsInScrollableView: 4,
        typeaheadWaitMs: TYPEHEAD_WAIT_TIME,
        typeaheadContainer: '.typeahead-cont',
        typeaheadHideResultsOnBlur: true,
        typeaheadOptionField: 'name',
        showCross: true,
        typeaheadMinLength: 2,
        scrollTypeaheadDropDown: false,
    };
    isSearchStringSmallerThanThreshold = true;
    showSearchResultDropdown = false;
    nearMeComponentConfig = {
        label: 'Show coworking spaces near me',
    };
    lineBreakType = LineBreakType;
    workspaces = {
        link: {
            url: '',
            text: 'Explore all coworking spaces',
        },
    };

    popularLocationHeading = `Popular locations`;
    popularWorkspacesHeading = `Popular workspaces`;
    cssConfig = {
        [LocationWorkspaceType.COMMON_HEADER_SEARCH]: 'common-header-search-container',
        [LocationWorkspaceType.LOCALITY_FILTER_SEARCH]: 'locality-filter-search-container',
    };

    @Input() public set config(updatedConfig) {
        this._config = updatedConfig;
        this.showSearchModal = this._config.showSearchModal;
        this.showSearchResultDropdown = this.getSearchResultDropdownVisibility(this._config);
        this.selectedCity = this.getSelectedCity(updatedConfig);
        this.setSearchItemClass(this._config.type);
        this.getWorkspaceLinkObj(this.selectedCity);
        this.updateTypeaheadConfig();
        this.updateTypeaheadSearchSuggestionsListeners();
        this.setPopularWorkspacesSectionHeading(this._config.product?.productNameFrontend);
        this.setVisibleSectionsInDefaultResultTemplateByProduct(this._config.product?.productNameFrontend);
    }
    public get config() {
        return this._config;
    }

    private getSelectedCity(config: LocationWorkspaceSearchConfig) {
        if (config.selectedCity) {
            return config?.selectedCity;
        } else {
            let selectedCity = {};
            this.selectCityService
                .getSelectedCitySync()
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(({ city }) => {
                    if (city) {
                        selectedCity = city;
                    }
                });

            return selectedCity;
        }
    }

    setSearchItemClass(type: LocationWorkspaceType) {
        switch (type) {
            case LocationWorkspaceType.LOCALITY_FILTER_SEARCH:
                this.searchItemClass = 'locality-filter';
                break;
            default:
                this.searchItemClass = '';
        }
    }

    setPopularWorkspacesSectionHeading(productKey: productKeys, city?: ICity) {
        switch (productKey) {
            case productKeys.MEETING_ROOM:
                if (city) {
                    this.popularWorkspacesHeading = `Popular meeting rooms in ${city.name}`;
                } else {
                    this.popularWorkspacesHeading = 'Popular meeting rooms';
                }
                break;
            default:
                if (city) {
                    this.popularWorkspacesHeading = `Popular workspaces in ${city.name}`;
                } else {
                    this.popularWorkspacesHeading = 'Popular workspaces';
                }
        }
    }

    setVisibleSectionsInDefaultResultTemplateByProduct(productKey: productKeys) {
        switch (productKey) {
            case productKeys.MEETING_ROOM:
                this.visibleSectionsInDefaultTemplate = [
                    LocationWorkspaceSearchSections.NEAR_ME,
                    LocationWorkspaceSearchSections.POPULAR_LOCATIONS,
                    LocationWorkspaceSearchSections.POPULAR_WORKSPACES,
                ];
                break;
            default:
                this.visibleSectionsInDefaultTemplate = [
                    LocationWorkspaceSearchSections.NEAR_ME,
                    LocationWorkspaceSearchSections.RECENTLY_SEARCHED,
                    LocationWorkspaceSearchSections.POPULAR_LOCATIONS,
                    LocationWorkspaceSearchSections.REDIRECTION_CTA,
                ];
        }
    }

    isSectionVisible(section: LocationWorkspaceSearchSections) {
        return this.visibleSectionsInDefaultTemplate.includes(section);
    }

    onLocationWorkspaceSelectEvent(selectedItem, isSearchSuggestion = false) {
        // isSearchSelection is true iff its invoked from the click on a search-card (search result)
        this.locationWorkspaceSelectEvent.emit({
            selectedItem,
            city: this.selectedCity,
            product: this._config.product,
            isSearchSuggestion,
        });
        this.toggleModalAndAdjustFocus(false);
    }

    handleNearMeClick(selectedItem) {
        this.locationService
            .getLocationFromCoordinates(
                {
                    latitude: selectedItem.latitude,
                    longitude: selectedItem.longitude,
                },
                this.config.product.productNameBackend
            )
            .subscribe(
                locationData => {
                    if (locationData?.isSupported) {
                        this.router.navigateByUrl(locationData.location.redirectUrl);
                    } else {
                        this.locationService.setLocationCoordinatesSubData({
                            data: locationData,
                            product: this.config.product,
                        });
                    }
                },
                () => {
                    this.toggleModalAndAdjustFocus(false);
                },
                () => {
                    this.toggleModalAndAdjustFocus(false);
                }
            );
    }

    toggleModalAndAdjustFocus(toggle: boolean) {
        this.showSearchModal = toggle;
        if (this.onToggleModal) {
            this.onToggleModal.emit(toggle);
        }
    }

    setIsSearchStringSmallerThanThreshold(value) {
        this.isSearchStringSmallerThanThreshold = value.trim().length < TYPEAHEAD_MIN_LENGTH;
        this.showSearchResultDropdown = this.getSearchResultDropdownVisibility(this._config);
    }

    getSearchResultDropdownVisibility(config: LocationWorkspaceSearchConfig) {
        switch (config.searchSuggestionType) {
            case LocationWorkspaceSearchSuggestionType.WORKSPACE:
                return this.checkDefaultSearchResultsDropdownVisibility() && this.hasRecentWorkspaceSearches();
            case LocationWorkspaceSearchSuggestionType.LOCATION_AND_WORKSPACE:
            default:
                return this.checkDefaultSearchResultsDropdownVisibility();
        }
    }

    checkDefaultSearchResultsDropdownVisibility() {
        return (
            (this.config.showStaticResultByDefault || this.showSearchModal) && this.isSearchStringSmallerThanThreshold
        );
    }

    hasRecentWorkspaceSearches() {
        const searchHistory = this.recentSearchesService.getRecentSearches().filter(searchItem => {
            return !searchItem.isLocation;
        });
        return searchHistory?.length;
    }

    onIconClickEvent() {
        this.iconClickEvent.emit();
    }

    ngOnInit() {
        this.popularLocationCount = this.deviceInfo.isMobile ? 6 : 8;
        this.updateTypeaheadConfig();
    }

    updateTypeaheadConfig() {
        switch (this._config?.product?.productNameBackend) {
            case productsMap.VIRTUAL_OFFICE.productNameBackend: {
                this.nearMeComponentConfig = {
                    label: 'Show virtual offices near me',
                };
                break;
            }
            case productsMap.MEETING_ROOM.productNameBackend: {
                this.nearMeComponentConfig = {
                    label: 'Show meeting rooms near me',
                };
                break;
            }
            default: {
                this.nearMeComponentConfig = {
                    label: 'Show coworking spaces near me',
                };
            }
        }
        if (this._config.typeaheadConfig) {
            this.typeaheadConfig = {
                ...this.typeaheadConfig,
                ...this._config.typeaheadConfig,
                typeaheadContainer: this._config.typeaheadContainerClass
                    ? `.${this._config.typeaheadContainerClass}`
                    : '.typeahead-cont',
            };
        }
    }

    updateTypeaheadSearchSuggestionsListeners() {
        if (this._config.searchSuggestionType === this.locationWorkspaceSearchCompType.WORKSPACE) {
            this.typeaheadConfig.getDataAsObservable = (searchString: string) => {
                return this.workspaceService
                    .searchWorkspace(this.selectedCity._id, searchString, this._config.product.productNameBackend)
                    .pipe(
                        map(data => {
                            return data.workspaceSuggestions;
                        })
                    );
            };
        } else if (this._config.searchSuggestionType === this.locationWorkspaceSearchCompType.LOCATION) {
            this.typeaheadConfig.getDataAsObservable = (searchString: string) => {
                return this.locationService.getLocationAutocomplete(searchString, this.selectedCity?._id).pipe(
                    map(data => {
                        return data.locationSuggestions
                            ? data.locationSuggestions.map(location => ({
                                  ...location,
                                  isLocation: true,
                                  name: location.display,
                              }))
                            : [];
                    })
                );
            };
        } else {
            this.typeaheadConfig.getDataAsObservable = (query: string) => {
                return this.workspaceService.getWorkspacesAndLocation(
                    query,
                    this.selectedCity?._id,
                    this._config.product.productNameBackend
                );
            };
        }
    }

    getWorkspaceLinkObj(city) {
        if (city && this._config && this._config.product) {
            // Update the labels
            this.popularLocationHeading = `Popular locations in ${city.name}`;
            this.setPopularWorkspacesSectionHeading(this._config.product.productNameFrontend, city);
            // Updates links
            switch (this._config.product.productNameBackend) {
                case productsMap.VIRTUAL_OFFICE.productNameBackend: {
                    this.workspaces = {
                        ...this.workspaces,
                        link: {
                            ...this.workspaces.link,
                            text: `Explore all virtual offices in ${city.name}`,
                            url: `/${city.slug}/${this._config.product.productBaseUrl}/virtual-office-in-${city.slug}`,
                        },
                    };
                    break;
                }
                default: {
                    this.workspaces = {
                        ...this.workspaces,
                        link: {
                            ...this.workspaces.link,
                            text: `Explore all coworking spaces in ${city.name}`,
                            url: `/${city.slug}/${this._config.product.productBaseUrl}/coworking-space-in-${city.slug}`,
                        },
                    };
                }
            }
        }
    }

    updateNearMeFetchStatus(status: 'loading' | 'done') {
        this.isNearMeLocationFetchInProgress = status === 'loading';
    }
}
