<div class="location-cont d-flx a-items-c flx-d-row c-pointer" [ngClass]="cssClass">
    <div class="internal-cont d-flx flx-d-row a-items-c flx-1">
        <div class="icon-cont d-flx j-cont-cen a-items-c">
            <img class="height-100 width-100 object-fit-contain" [src]="commonIconsUrl.LOCATION_OUTLINE" alt="i" />
        </div>
        <div class="desc-cont d-flx flx-1 j-cont-space-bet">
            <div class="name flx-1">
                <h4 class="font-14 font-normal text-ellipsis">{{ searchedLocation.display }}</h4>
            </div>
            <div class="location">
                <p class="font-12 text-color-lighter-grey">Location</p>
            </div>
        </div>
    </div>
</div>
