import { Component, OnDestroy, OnInit } from '@angular/core';
import { IParsedSnackbarConfig, ISnackbarConfig } from './snackbar.interface';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { SnackbarType } from './snackbar.enum';
import { SnackbarService } from './services/snackbar.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
// @ts-ignore
import InfoOutlinedIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/info-outlined.svg';
// @ts-ignore
import SuccessOutlinedIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/success-outlined.svg';
// @ts-ignore
import WarningOutlinedIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/warning-outlined.svg';
// @ts-ignore
import ErrorOutlinedIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/error-outlined.svg';
// @ts-ignore
import CrossIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/cross.svg';

@Component({
    standalone: true,
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    imports: [NgIf, NgClass, NgFor],
    styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit, OnDestroy {
    commonIconsUrl = CommonIconsUrl;
    isVisible = false;
    snackBarItems: IParsedSnackbarConfig[] = [];
    cssClass = 'd-flx flx-d-clm snackbar-container bg-color-white';
    infoIcon = this.sanitizer.bypassSecurityTrustHtml(InfoOutlinedIcon);
    errorIcon = this.sanitizer.bypassSecurityTrustHtml(ErrorOutlinedIcon);
    successIcon = this.sanitizer.bypassSecurityTrustHtml(SuccessOutlinedIcon);
    warningIcon = this.sanitizer.bypassSecurityTrustHtml(WarningOutlinedIcon);
    crossIcon = this.sanitizer.bypassSecurityTrustHtml(CrossIcon);
    snackBarItemsSub: Subscription;
    constructor(
        private snackBarService: SnackbarService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.snackBarItemsSub = this.snackBarService.getSnackBarItemsSub().subscribe(data => {
            const oldData = this.snackBarItems;
            this.snackBarItems = this.parseSnackbarItems(data, oldData);
        });

        this.show();
    }

    ngOnDestroy() {
        if (this.snackBarItemsSub) {
            this.snackBarItemsSub.unsubscribe();
        }
        this.hide();
    }

    isNewItemAdded(snackbarItem: ISnackbarConfig, oldItems: ISnackbarConfig[]) {
        return (
            oldItems.findIndex(item => {
                return item.id === snackbarItem.id;
            }) === -1
        );
    }

    parseSnackbarItems(items: ISnackbarConfig[], oldItems: ISnackbarConfig[]): IParsedSnackbarConfig[] {
        return items.map(item => {
            return {
                ...item,
                cssClass: this.getCssClass(item.type, this.isNewItemAdded(item, oldItems)),
                icon: this.getIcon(item.type),
            };
        });
    }

    show(): void {
        this.isVisible = true;
    }

    hide(): void {
        this.isVisible = false;
    }

    getIcon(type: SnackbarType) {
        switch (type) {
            case SnackbarType.WARNING:
                return this.warningIcon;
            case SnackbarType.ERROR:
                return this.errorIcon;
            case SnackbarType.INFO:
                return this.infoIcon;
            case SnackbarType.SUCCESS:
                return this.successIcon;
            default:
                return this.infoIcon;
        }
    }

    getCssClass(type: SnackbarType, isAnimated?: boolean) {
        let className = this.cssClass;
        switch (type) {
            case SnackbarType.WARNING:
                className = `${className} warn`;
                break;
            case SnackbarType.INFO:
                className = `${className} info`;
                break;
            case SnackbarType.ERROR:
                className = `${className} error`;
                break;
            case SnackbarType.SUCCESS:
                className = `${className} success`;
                break;
            default:
        }

        if (isAnimated) {
            className = `${className} animate`;
        }

        return className;
    }

    hideSnackBar(snackItem: IParsedSnackbarConfig) {
        this.snackBarService.hideSnackbar(snackItem.id);
    }

    handleCTAClick(item: IParsedSnackbarConfig) {
        item.ctaConfig.callback();
        this.hideSnackBar(item);
    }
}
