<div class="header-search-bar-content d-flx a-items-stretch">
    <div class="solutions-dropdown-cont d-flx a-items-c" *ngIf="config.showProductSwitch">
        <ng-container *ngTemplateOutlet="searchDropDownMenu"></ng-container>
    </div>
    <app-location-workspace-search
        [config]="config.locationWorkspaceSearchConfig"
        (onToggleModal)="toggleSearchModal($event)"
        (locationWorkspaceSelectEvent)="onLocationWorkspaceSelectEvent($event)"
    ></app-location-workspace-search>
    <div *ngIf="config.locationWorkspaceSearchConfig.showSearchModal">
        <div class="custom-overlay" (click)="toggleSearchModal(false)"></div>
    </div>
</div>

<ng-template #searchDropDownMenu>
    <div class="search-dropdown" (clickOutside)="toggleOptions(false)" appClickOutside>
        <div class="d-flx menu-cont a-items-c c-pointer" (click)="toggleOptions(true)" #toggleButton>
            <div class="title-desc-cont">
                <div [ngClass]="{ active: showOptions }">
                    <span class="title">{{ config?.menu?.title }}</span>
                </div>
            </div>
            <div class="icon-cont d-flx">
                <span class="icon arrow-down arrow-light arrow-grey"></span>
            </div>
        </div>
        <div class="options-wrapper" *ngIf="showOptions">
            <div class="options-cont">
                <ng-container *ngFor="let subMenu of config.menu.subMenus">
                    <div
                        *ngIf="config.showProductSwitch"
                        (click)="onSelectDropDownMenu(subMenu)"
                        [ngClass]="{ selected: subMenu.isSelected }"
                    >
                        <div class="option d-flx c-pointer">
                            <div class="image-cont">
                                <img class="image" [src]="subMenu.imageUrl" [alt]="subMenu.title" />
                            </div>
                            <div class="title-desc-cont">
                                <div>
                                    <span class="title">{{ subMenu.title }} </span>
                                    <span *ngIf="subMenu.tag" class="tag">{{ subMenu.tag }}</span>
                                </div>
                                <div class="desc">{{ subMenu.description }}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
