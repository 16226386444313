import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/core';
import { InterceptorService } from '../services/interceptor/interceptor.service';
import { InterceptorMetadataKeys } from '../../shared/enums/enums';
import { CustomHttpParams } from './custom-params.interceptor';

@Injectable({
    providedIn: 'root',
})
export class BrowserStateInterceptor implements HttpInterceptor {
    constructor(
        private transferState: TransferState,
        private interceptorService: InterceptorService
    ) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (['GET', 'POST'].indexOf(req.method) === -1) {
            return next.handle(req);
        }
        const params = req.params as CustomHttpParams;

        if (params?.interceptorMetadata?.[InterceptorMetadataKeys.HQ_DISABLE_CACHE]) {
            return next.handle(req);
        }

        const storedResponse: string = this.transferState.get(
            makeStateKey(this.interceptorService.getCacheUrlKey(req)),
            null
        );
        if (storedResponse) {
            const response = new HttpResponse({ body: storedResponse, status: 200 });
            return of(response);
        }
        return next.handle(req);
    }
}
