<div class="city-modal-container" *ngIf="showSelectCityModal">
    <div class="city-modal select-city">
        <div class="city-modal-cont">
            <ng-container *ngTemplateOutlet="selectCity"></ng-container>
        </div>
    </div>
    <div class="custom-overlay" (click)="toggleSelectCityModal(!showCloseButton)"></div>
</div>

<ng-template #selectCity>
    <div class="city-container">
        <span *ngIf="showCloseButton" class="icon-cont c-pointer d-flx" (click)="toggleSelectCityModal(false)">
            <span class="close-icon"></span>
        </span>
        <div class="app-container">
            <div class="modal-heading">
                <h3 role="label">Select City</h3>
            </div>
            <div class="d-flx flx-d-clm popular-city-container width-100" *ngIf="popularCities?.length" role="listbox">
                <h3 class="section-header-text font-bold text-color-success-medium text-align-left">POPULAR CITIES</h3>
                <div class="d-grid popular-city-list width-100" role="listbox">
                    <div
                        class="popular-city c-pointer d-flx flx-d-clm a-items-c border-radius-2"
                        *ngFor="let city of popularCities | orderBy: 'rank'"
                        (click)="onSelectCity(city)"
                        role="option"
                        [ngClass]="{ active: selectedCity?.slug === city.slug }"
                    >
                        <div class="image-cont d-flx a-items-c j-cont-cen" *ngIf="city?.icon?.url">
                            <img
                                class="height-100 width-100 object-fit-contain"
                                appImageShimmerLazyLoad
                                [isATFContent]="true"
                                [imageSrc]="city.icon.url"
                                [alt]="city.icon.alt"
                            />
                        </div>
                        <p class="font-14 font-normal text-ellipsis text-color-dark width-100 popular-city-name">
                            {{ city.name }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="d-flx flx-d-clm other-city-container width-100">
                <h3
                    class="section-header-text font-bold text-color-success-medium text-align-left"
                    *ngIf="popularCities?.length && otherCities?.length"
                >
                    Other cities
                </h3>
                <div class="d-grid other-city-ctr width-100">
                    <div
                        class="other-city c-pointer border-radius-2"
                        *ngFor="let city of otherCities"
                        (click)="onSelectCity(city)"
                        [ngClass]="{ active: selectedCity?.slug === city.slug }"
                    >
                        <p class="font-14 font-normal text-color-dark other-city-name">{{ city.name }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
