<div class="search-loading-container">
    <ng-container *ngIf="!deviceInfo.isMobile">
        <app-workspace-search-list-shimmer *ngFor="let i of [1, 2, 3, 4, 5]"></app-workspace-search-list-shimmer>
    </ng-container>
    <ng-container *ngIf="deviceInfo.isMobile">
        <ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]">
            <div class="shimmer mobile-search-shimmer"></div>
        </ng-container>
    </ng-container>
</div>
