import { Injectable } from '@angular/core';
import { ElementRef } from '@angular/core';
import { StickyContainerComponent } from '../../layouts/sticky-container/sticky-container.component';

@Injectable({
    providedIn: 'root',
})
export class StickyService {
    private stickyContainer: StickyContainerComponent;

    setStickyContainer(container: StickyContainerComponent) {
        this.stickyContainer = container;
    }

    addElementToSticky(element: ElementRef, containerRef: ElementRef, className?: string) {
        if (this.stickyContainer) {
            this.stickyContainer.addElement(element, containerRef, className);
        }
    }

    removeElement(element: ElementRef, containerRef: ElementRef, className?: string) {
        if (this.stickyContainer) {
            this.stickyContainer.removeElement(element, containerRef, className);
        }
    }

    clearStickyElements() {
        if (this.stickyContainer) {
            this.stickyContainer.clearElements();
        }
    }
}
