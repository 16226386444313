export enum SearchExpandedSection {
    DEFAULT = 'DEFAULT',
    CITY_SELECTOR = 'CITY_SELECTOR',
    PRODUCT_SELECTOR = 'PRODUCT_SELECTOR',
}

export enum SearchTabLabels {
    FLEXI = 'Day Pass',
    DEDICATED = 'Coworking Space',
    MEETING_ROOM = 'Meeting Rooms',
    VIRTUAL_OFFICE = 'Virtual Office',
}

export enum SearchTabCtaLabels {
    FLEXI = 'View Workspaces',
    DEDICATED = 'View Workspaces',
    MEETING_ROOM = 'View Meeting Rooms',
    VIRTUAL_OFFICE = 'View Workspaces',
}

export enum SearchOverviewSections {
    NEAR_ME = 'NEAR_ME',
    POPULAR_LOCALITIES = 'POPULAR_LOCALITIES',
    POPULAR_WORKSPACES = 'POPULAR_WORKSPACES',
}
