import { RecentSearchesService } from '../../services/recent-searches/recent-searches.service';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// @ts-ignore
import LocationIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/location-flat.svg';
// @ts-ignore
import WorkspaceIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/workspace-flat.svg';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives/directives.module';

@Component({
    standalone: true,
    selector: 'app-recently-searched',
    templateUrl: './recently-searched.component.html',
    styleUrls: ['./recently-searched.component.scss'],
    imports: [CommonModule, DirectivesModule],
})
export class RecentlySearchedComponent implements OnInit {
    constructor(
        private recentSearchesService: RecentSearchesService,
        private sanitizer: DomSanitizer
    ) {}

    @Input() heading: string;
    @Output() clickRecentSearch = new EventEmitter();
    @Input() showLocations = true;
    @Input() showWorkspaces = true;

    recentlySearchedItems: any[]; // TODO FIX the interface type
    commonIconsUrl = CommonIconsUrl;
    locationIcon = this.sanitizer.bypassSecurityTrustHtml(LocationIcon);
    workspaceIcon = this.sanitizer.bypassSecurityTrustHtml(WorkspaceIcon);
    ngOnInit() {
        this.recentlySearchedItems = this.recentSearchesService.getRecentSearches()
            ? this.recentSearchesService.getRecentSearches()
            : [];
    }

    onClickRecentSearch(recentItem) {
        this.clickRecentSearch.emit(recentItem);
    }
}
