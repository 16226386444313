export class ErrorMsg {
    public static serverError = 'Unexpected error occurred, Please try again after some time';
    public static contactDetailsError = 'Please enter contact details before applying referral code';
    public static emptyCouponCode = 'Please enter a coupon code';
    public static locationPermissionDenied = 'Please Allow Location Access From Settings';
}

export const paymentErrors = {
    payment: 'Error making payment. Please try again',
    initiate: 'Payment initiation failed. Please try again',
    paymentFail: 'Payment Failed. Please try again or request support for help',
    fetchCheckoutDetailsError: 'Could not fetch the payment details. Please try again',
    createCheckoutPaymentError: `Could not create payment checkout. Please try again!`,
    checkoutFail: 'Payment did not proceed due to payment checkout failure. Please try again',
    completeCheckoutPaymentError: 'Something went wrong, during syncing the payment with server. Please try again',
    history: 'Error Loading Payment History',
    reservationPaymentFailure: 'There is a technical issue on our end. Please come back and check after 5 minutes.',
};
