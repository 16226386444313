import { IMenuWithDropdownConfig } from './components/menu-with-dropdown/menu-with-dropdown.interface';
import { ImageBaseUrl } from '../../../core/constants/constants';
import { of } from 'rxjs';
import { menuCalloutType, menuEnum, menuType } from './enums/enums';

export const moreMenuItem: IMenuWithDropdownConfig = {
    id: menuEnum.MORE,
    title: 'More',
    type: menuType.DROPDOWN,
    show: false,
    calloutType: menuCalloutType.DOWNLOAD_APP,
    isCollapsible: true,
    collapsed: false,
    subMenus: [
        {
            heading: 'Guides & Toolkits',
            menuItems: [
                {
                    id: menuEnum.COMPANY_NAME_SEARCH,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/action_key.svg`,
                    title: 'Company Name Search',
                    description: 'Find available company names',
                    redirectUrl: of('guides/company-name-search'),
                    isExternalUrl: true,
                },
                {
                    id: menuEnum.COMPANY_REGISTRATION,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/approval.svg`,
                    title: 'Company Registration Guide',
                    description: 'Get step by step detailed process',
                    redirectUrl: of(`guides/company-registration-in-india`),
                    isExternalUrl: true,
                },
                {
                    id: menuEnum.GST_REGISTRATION,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/gst.svg`,
                    title: 'GST Registration Guide',
                    description: 'Get step by step detailed process',
                    redirectUrl: of('guides/gst-registration-in-india'),
                    isExternalUrl: true,
                },
                {
                    id: menuEnum.VPOB,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/vpob.svg`,
                    title: 'Virtual Place of Business',
                    description: 'Virtual office for e-commerce sellers',
                    redirectUrl: of('guides/virtual-place-of-business-registration'),
                    isExternalUrl: true,
                },
            ],
        },
        {
            heading: 'Insights & Success Stories',
            menuItems: [
                {
                    id: menuEnum.TESTIMONIALS,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/reviews.svg`,
                    title: 'Testimonials',
                    description: 'Hear from our clients',
                    redirectUrl: of(`/testimonials`),
                    isExternalUrl: false,
                },
                {
                    id: menuEnum.CASE_STUDIES,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/case_studies.svg`,
                    title: 'Case Studies',
                    description: 'Client success stories of real impact',
                    redirectUrl: of(`/case-studies`),
                    isExternalUrl: false,
                },
                {
                    id: menuEnum.INDUSTRY_REPORTS,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/monitoring.svg`,
                    title: 'Industry Reports',
                    description: 'Discover insights, analysis, and trends',
                    redirectUrl: of('/industry-reports'),
                    isExternalUrl: false,
                },
                {
                    id: menuEnum.BLOG,
                    imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/menu/contract_edit.svg`,
                    title: 'myHQ Digest',
                    description: 'Visit our blog',
                    redirectUrl: of(`https://digest.myhq.in/`),
                    isExternalUrl: true,
                },
            ],
        },
    ],
};
