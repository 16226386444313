import { map } from 'rxjs/operators';
import { ObserverQueue } from '../../../classes/observerQueue.class';
import { Injectable, NgZone } from '@angular/core';
import { zip, interval } from 'rxjs';
import { toPromiseAndEnqueueTask } from '../../../decorators/toPromiseAndEnqueueTask.decorator';
import {
    SpecificPageViewedEvents,
    FlexiTrialFunnelStages,
    DLFormFunnelStages,
    TeamFlexiLFormFunnelStages,
    DVisitFormFunnelStages,
    VirtualOfficeLFormFunnelStages,
} from '../enums';
import { productsMap } from '../../../../shared/enums/enums';
declare const fbq: any;

const queue = new ObserverQueue();

@Injectable({
    providedIn: 'root',
})
export class FacebookAnalyticsService {
    // Doc details this https://developers.facebook.com/docs/facebook-pixel/reference#utilizing_params
    init() {
        window['facebookAnalyticsService'] = {
            component: this,
            zone: this.ngZone,
            onScriptLoaded: () => this.onScriptLoaded(),
        };
    }

    onScriptLoaded() {
        zip(queue, interval(10))
            .pipe(map(([queueItem, d]) => queueItem))
            .subscribe(({ event, resolve }) => {
                resolve(event());
            });
    }

    @toPromiseAndEnqueueTask(queue)
    trackPageView(): any {
        if (typeof fbq !== 'undefined') {
            fbq('track', 'PageView');
        }
    }

    @toPromiseAndEnqueueTask(queue)
    trackEvent(action, properties) {
        if (Object.values(SpecificPageViewedEvents).includes(action)) {
            this.trackSpecificPageViewEvents(action, properties);
        } else if (Object.values(TeamFlexiLFormFunnelStages).includes(action)) {
            this.trackLeadEvents(action, properties);
        } else if (Object.values(DLFormFunnelStages).includes(action)) {
            this.trackLeadEvents(action, properties);
        } else if (Object.values(VirtualOfficeLFormFunnelStages).includes(action)) {
            this.trackLeadEvents(action, properties);
        } else if (Object.values(FlexiTrialFunnelStages).includes(action)) {
            this.trackTrialEvent(action, properties);
        } else if (Object.values(DVisitFormFunnelStages).includes(action)) {
            this.trackVisitEvent(action, properties);
        }
    }

    constructor(private ngZone: NgZone) {}

    private trackContentView(properties) {
        if (typeof fbq !== 'undefined') {
            fbq('track', 'ViewContent', properties);
        }
    }

    private trackLead(properties) {
        if (typeof fbq !== 'undefined') {
            fbq('track', 'Lead', properties);
        }
    }

    private trackCustomEvents(action, properties) {
        if (typeof fbq !== 'undefined') {
            fbq('trackCustom', action, properties);
        }
    }

    private trackSpecificPageViewEvents(action, properties) {
        switch (action) {
            case SpecificPageViewedEvents.WORKSPACE_VIEWED:
                return this.trackContentView({
                    content_name: action,
                    content_category: properties.product,
                    content_ids: this.getWorkspaceFBProductId([properties.workspaceId]),
                });
            // TODO: Setupremarketing based on Search & Listing
            case SpecificPageViewedEvents.LISTING_PAGE_VIEWED:
                return this.trackContentView({
                    content_name: action,
                    content_category: properties.product,
                });
            case SpecificPageViewedEvents.FLEXI_PRODUCT_VIEWED:
            case SpecificPageViewedEvents.DEDICATED_PRODUCT_VIEWED:
            case SpecificPageViewedEvents.TEAMFLEXI_PRODUCT_VIEWED:
            case SpecificPageViewedEvents.VO_PRODUCT_VIEWED:
            case SpecificPageViewedEvents.DEDICATED_LANDING_VIEWED:
            case SpecificPageViewedEvents.VO_LANDING_VIEWED:
                return this.trackContentView({
                    content_name: action,
                });
            default:
                return;
        }
    }

    private trackLeadEvents(action, properties) {
        switch (action) {
            case DLFormFunnelStages.FORM_STEP1_SUBMIT:
                return this.trackLead({
                    content_name: action,
                    content_category: productsMap.DEDICATED.productNameBackend,
                });
            case TeamFlexiLFormFunnelStages.FORM_STEP1_SUBMIT:
                return this.trackLead({
                    content_name: action,
                    content_category: productsMap.CORPORATE_FLEXI.productNameBackend,
                });
            case VirtualOfficeLFormFunnelStages.FORM_STEP1_SUBMIT:
                return this.trackLead({
                    content_name: action,
                    content_category: productsMap.VIRTUAL_OFFICE.productNameBackend,
                });
            default:
                return;
        }
    }

    private trackTrialEvent(action, properties) {
        switch (action) {
            case FlexiTrialFunnelStages.TRIAL_DETAIL_FORM_SUBMIT:
            case FlexiTrialFunnelStages.SUCCESS:
                return this.trackCustomEvents(action, {});
            default:
                return;
        }
    }

    private trackVisitEvent(action, properties) {
        switch (action) {
            case DVisitFormFunnelStages.STEP1_SUBMIT:
            case DVisitFormFunnelStages.SUCCESS:
                return this.trackCustomEvents(action, {});
            default:
                return;
        }
    }

    private getWorkspaceFBProductId(workspaceIds: [string]) {
        return workspaceIds.map(id => `${id.slice(0, 4)}${id.slice(-4)}`);
    }
}
