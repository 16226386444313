import { UtilsService } from '../../../core/services/utils/utils.service';
import { environment } from '../../../../environments/environment';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { ImageLazyLoadHelperService, ImageSizes } from './image-lazy-load-helper.service';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnInit,
} from '@angular/core';

enum imageStages {
    BLUR = 'BLUR',
    FULL_RES = 'FULL_RES',
}

@Directive({
    selector: 'img[appImageProgressiveLazyLoad]',
})
export class ImageProgressiveLazyLoadDirective implements OnInit, AfterViewInit {
    defaultLowResWidth = 64;
    imageLinkSubject = new ReplaySubject<any>(null);
    imageStageSubject = new BehaviorSubject(null);
    imageInSecondaryIntersectionViewSubject = new BehaviorSubject(false);
    imageInPrimaryIntersectionViewSubject = new BehaviorSubject(false);

    lowResIntersectionConfig = {
        root: null,
        rootMargin: '300px',
        threshold: 0,
    };

    highResIntersectionConfig = {
        root: null,
        rootMargin: '800px',
        threshold: 0,
    };

    @HostBinding('attr.src') srcAttr = '//:0';
    @HostBinding('attr.loading') loadingAttr = 'lazy';

    @Input() imageWidthSize?: keyof typeof ImageSizes;
    @Input('lowResIntersectionMargin') set setLowResIntersectionMargin(rootMargin: string) {
        this.lowResIntersectionConfig = {
            ...this.lowResIntersectionConfig,
            rootMargin,
        };
    }
    @Input('highResIntersectionMargin') set setHighResIntersectionMargin(rootMargin: string) {
        this.highResIntersectionConfig = {
            ...this.highResIntersectionConfig,
            rootMargin,
        };
    }

    @Input('src') set src(value: string) {
        if (this.isATFContent) {
            this.imageStageSubject.next(imageStages.BLUR);
        } else {
            // set the stage to blur, to load low resolution image
            this.imageInSecondaryIntersectionViewSubject
                .pipe(
                    filter(val => val === true),
                    take(1)
                )
                .subscribe(() => {
                    this.imageStageSubject.next(imageStages.BLUR);
                });
        }
        this.imageLinkSubject.next(value);
    }

    @Input() isATFContent = false;
    @Input() autoWidth = true;

    @HostListener('load')
    onLoad() {
        if (this.imageStageSubject.value === imageStages.BLUR) {
            this.imageInPrimaryIntersectionViewSubject
                .pipe(
                    filter(val => val),
                    take(1)
                )
                .subscribe(() => {
                    this.imageStageSubject.next(imageStages.FULL_RES);
                });
            return;
        } else {
            this.focusImage();
        }
    }

    focusImage() {
        this.el.nativeElement.style.filter = '';
    }

    blurImageAndSetTransitionTime() {
        this.el.nativeElement.style.filter = 'blur(5px)';
        this.el.nativeElement.style.transition = 'filter 0.25s ease 0s';
    }

    constructor(
        private el: ElementRef,
        private cdRef: ChangeDetectorRef,
        private imageLazyService: ImageLazyLoadHelperService,
        private utilsService: UtilsService
    ) {
        this.blurImageAndSetTransitionTime();
        if (this.utilsService.getDeviceInfo().isBrowser) {
            if (this.utilsService.isIntersectionObserverSupported()) {
                this.attachSecondaryObserver();
            } else {
                this.imageInPrimaryIntersectionViewSubject.next(true);
                this.imageInSecondaryIntersectionViewSubject.next(true);
            }
        }
    }

    ngOnInit() {}

    ngAfterViewInit() {
        combineLatest([this.imageLinkSubject, this.imageStageSubject])
            .pipe(filter(([imageLink, imageStage]) => imageLink && imageStage))
            .subscribe(([imageLink, imageStage]) => {
                if (imageStage === imageStages.BLUR) {
                    // pass the function to observer to call when loaded??
                    if (this.imageLazyService.checkIfImageLinkResponsive(imageLink)) {
                        this.srcAttr = this.imageLazyService.applyAttributesOnLink(imageLink, {
                            width: this.defaultLowResWidth,
                        });
                        this.blurImageAndSetTransitionTime();
                    } else if (this.utilsService.isBrowser) {
                        // Get the full resolution image
                        this.imageStageSubject.next(imageStages.FULL_RES);
                    }
                } else {
                    let highResLink;
                    if (this.autoWidth && !this.imageLazyService.checkIfAttributesPresentOnLink(imageLink)) {
                        const imageAttributesObj: any = {};
                        if (this.imageWidthSize) {
                            imageAttributesObj.imageWidthSize = this.imageWidthSize;
                        } else if (
                            this.el.nativeElement.clientWidth !== this.defaultLowResWidth ||
                            this.imageWidthSize === 'thumbnail'
                        ) {
                            // TODO whats this condition !!!
                            imageAttributesObj.clientWidth = this.el.nativeElement.clientWidth;
                        } else if (!environment.production && imageLink) {
                            console.warn(
                                `Image With Link ${imageLink} seems to be dependent on image size for Its Box`
                            );
                        }
                        highResLink = this.imageLazyService.applyAttributesOnLink(imageLink, imageAttributesObj);
                    } else {
                        highResLink = imageLink;
                    }
                    if (this.srcAttr === highResLink) {
                        this.focusImage();
                    } else {
                        this.srcAttr = highResLink;
                    }
                }
            });
        this.cdRef.detectChanges();
    }

    // Primary observer is being used to check when should we fetch the high-res version of image.
    private attachPrimaryObserver() {
        const primaryObserver = new IntersectionObserver(entries => {
            entries.forEach(({ isIntersecting }) => {
                if (isIntersecting) {
                    this.imageInPrimaryIntersectionViewSubject.next(true);
                    primaryObserver.unobserve(this.el.nativeElement);
                }
            });
        }, this.lowResIntersectionConfig);
        primaryObserver.observe(this.el.nativeElement);
    }

    // Secondary observer is being used to check when the low-res version of image should fetched
    private attachSecondaryObserver() {
        const secondaryObserver = new IntersectionObserver(entries => {
            entries.forEach(({ isIntersecting }) => {
                if (isIntersecting) {
                    // Remove the secondary intersection and attach primary intersection
                    this.imageInSecondaryIntersectionViewSubject.next(true);
                    secondaryObserver.unobserve(this.el.nativeElement);
                    this.attachPrimaryObserver();
                }
            });
        }, this.highResIntersectionConfig);
        secondaryObserver.observe(this.el.nativeElement);
    }
}
