import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectDropdownComponent } from './multi-select-dropdown.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { CheckModule } from '../check/check.module';
import {ButtonsModule} from '../buttons/buttons.module';

@NgModule({
    declarations: [MultiSelectDropdownComponent],
    imports: [CommonModule, ReactiveFormsModule, CheckboxModule, CheckModule, ButtonsModule],
    exports: [MultiSelectDropdownComponent],
})
export class MultiDropdownComponentModule {}
