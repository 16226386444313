<ng-container *ngIf="showLoader">
    <div *ngIf="heading" class="heading-cont">
        <div class="heading"><span [innerHTML]="trendIcon"></span> {{ heading }}</div>
    </div>
    <div class="popular-locations-cont d-flx flx-wrp">
        <ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6]">
            <div class="shimmer location-shimmer"></div>
        </ng-container>
    </div>
</ng-container>
<div *ngIf="!showLoader && popularLocations.length">
    <div *ngIf="heading" class="heading-cont">
        <div class="heading"><span [innerHTML]="trendIcon"></span> {{ heading }}</div>
    </div>
    <div class="popular-locations-cont d-flex flx-wrp">
        <ng-container *ngFor="let location of popularLocations">
            <div
                class="location-cont d-flx a-items-b c-pointer"
                (click)="onClickPopularLocation(location)"
                [ngClass]="{ selected: selectedLocation?._id === location._id }"
            >
                <div class="svg-cont d-flx" [innerHTML]="locationIcon"></div>
                <div class="location-detail d-flx a-items-c">
                    <h3 class="name text-ellipsis">{{ location.name }}</h3>
                </div>
            </div>
        </ng-container>
    </div>
</div>
