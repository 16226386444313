import { ICustomHttpParamsConfig, IInterceptorMetadata } from '../../shared/interfaces/custom-http-params.interface';
import { HttpParams } from '@angular/common/http';

export class CustomHttpParams extends HttpParams {
    public readonly interceptorMetadata: Partial<IInterceptorMetadata>;

    constructor({ params, interceptorMetadata }: Partial<ICustomHttpParamsConfig> = {}) {
        if (typeof params === 'string') {
            super({ fromString: params });
        } else if (typeof params === 'object') {
            super({ fromObject: params });
        } else {
            super();
        }

        this.interceptorMetadata = interceptorMetadata;
    }

    // overrides HttpParams.append
    append(param: string, value: string): CustomHttpParams {
        const updatedHttpParams = super.append(param, value);
        return new CustomHttpParams({
            interceptorMetadata: this.interceptorMetadata,
            params: updatedHttpParams.toString(),
        });
    }

    // overrides HttpParams.set
    set(param: string, value: string): CustomHttpParams {
        const updatedHttpParams = super.set(param, value);
        return new CustomHttpParams({
            interceptorMetadata: this.interceptorMetadata,
            params: updatedHttpParams.toString(),
        });
    }

    // overrides HttpParams.delete
    delete(param: string, value?: string): CustomHttpParams {
        const updatedHttpParams = super.delete(param, value);
        return new CustomHttpParams({
            interceptorMetadata: this.interceptorMetadata,
            params: updatedHttpParams.toString(),
        });
    }
}
