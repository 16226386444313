import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../../core/services/utils/utils.service';
import { StaticComponentsModule } from '../../../../static-components/static-components.module';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-search-typeahead-result-loading',
    templateUrl: './search-typeahead-result-loading.component.html',
    styleUrls: ['./search-typeahead-result-loading.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        StaticComponentsModule,
    ],
})
export class SearchTypeaheadResultLoadingComponent implements OnInit {
    deviceInfo = this.utilsService.getDeviceInfo();
    constructor(private utilsService: UtilsService) {}

    ngOnInit(): void {}
}
