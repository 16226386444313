import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleSelectDropdownComponent } from './single-select-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [SingleSelectDropdownComponent],
    imports: [CommonModule, ReactiveFormsModule, FormsModule],
    exports: [SingleSelectDropdownComponent],
})
export class SingleSelectDropdownModule {}
