import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadSuccessPortraitComponent } from './lead-success-portrait.component';

@NgModule({
    declarations: [LeadSuccessPortraitComponent],
    imports: [CommonModule],
    exports: [LeadSuccessPortraitComponent],
})
export class LeadSuccessPortraitModule {}
