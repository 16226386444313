<div class="d-flx flx-d-clm header-search-container" *ngIf="config.useAsHeaderComponent; else searchContent">
    <div
        class="show-desk-flx a-items-c dummy-search-box bg-color-white"
        (click)="handleHeaderSearchBoxClick()"
        #headerSearchContainer
    >
        <div class="d-flx a-items-c j-cont-cen search-icon-container" [innerHTML]="searchIcon"></div>
        <p class="font-14 text-color-lighter-grey text-ellipsis">{{ headerSearchLabel }}</p>
    </div>
    <div
        class="show-mob-tab-flx a-items-c j-cont-cen search-icon-container"
        [innerHTML]="searchIcon"
        (click)="handleHeaderSearchBoxClick()"
    ></div>
    <div class="detailed-search-container">
        <ng-container [ngTemplateOutlet]="searchContent" *ngIf="expandedSection"></ng-container>
    </div>
</div>

<div class="custom-overlay" *ngIf="expandedSection" (click)="handleBlur()"></div>

<!--templates-->
<ng-template #searchContent>
    <div class="search-wrapper width-100" [ngClass]="wrapperCssClass" #searchWrapper>
        <div class="search-wrapper-top-bar"></div>
        <div class="d-flx flx-d-clm search-container width-100 bg-color-white">
            <app-search-header
                [config]="searchHeaderConfig"
                (onFocus)="handleSearchHeaderFocus($event)"
                (onProductChange)="handleProductChange($event)"
                (onSearchInput)="handleSearchInputChange($event)"
                (onOrdinaryLocationSelect)="handleOrdinaryLocationSelect($event)"
                [hidden]="deviceInfo.isMobile && config.useAsHeaderComponent"
                (onRemoveSelectedItem)="handleRemoveSelectedItem($event)"
            >
            </app-search-header>
            <div class="height-100 width-100 header-end-marker">
                <ng-container *ngIf="expandedSection">
                    <div class="bg-color-white search-overview-container width-100" *ngIf="showSearchOverview">
                        @defer {
                            <app-search-overview
                                [config]="searchOverviewConfig"
                                (onLocationSelect)="handleLocationSelect($event)"
                                (onCloseExpandedSection)="closeExpandedSection()"
                                (onOrdinaryLocationSelect)="handleOrdinaryLocationSelect($event)"
                                (onSearchInput)="handleSearchInputChange($event)"
                                (onOpenExpandedSection)="openExpandedSection($event)"
                                (onProductChange)="handleProductChange($event)"
                                (onClearOrdinaryLocations)="handleClearOrdinaryLocations()"
                            ></app-search-overview>
                        }
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
