import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeFormatFromMins',
})
export class TimeFormatFromMinsPipe implements PipeTransform {
    transform(totalMinutes: number): any {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours <= 12 ? hours : hours - 12}:${minutes < 10 ? '0' + minutes : minutes} ${
            hours < 12 ? 'AM' : 'PM'
        }`;
    }
}
