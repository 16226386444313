import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ISingleOption, ISingleSelectDropdownFieldConfig } from '../../interfaces/field.interface';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../../../core/services/utils/utils.service';

// @ts-ignore
import SearchIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/search-outlined.svg';

@Component({
    selector: 'app-single-select-dropdown',
    templateUrl: './single-select-dropdown.component.html',
    styleUrls: ['./single-select-dropdown.component.scss'],
})
export class SingleSelectDropdownComponent implements OnInit, AfterViewChecked {
    @Input() form: UntypedFormGroup;
    @Input() field: ISingleSelectDropdownFieldConfig;

    @ViewChild('container') container: ElementRef;
    @ViewChild('dropdownList') dropdownList: ElementRef;
    @ViewChild('dropdownButton') dropdownButton: ElementRef;
    @ViewChild('inputElement') inputElement: ElementRef;

    deviceInfo = this.utilsService.getDeviceInfo();
    commonIconsUrl = CommonIconsUrl;
    isDropdownOpen = false;
    selectedOption: ISingleOption;
    selectedLabel = '';
    searchString = '';

    // icons
    searchIcon = this.sanitizer.bypassSecurityTrustHtml(SearchIcon);

    constructor(
        private sanitizer: DomSanitizer,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
        this.form.controls[this.field.name].setValue('');
        if (this.field.default) {
            this.setOptionDefault(this.field.default);
        }
    }

    ngAfterViewChecked() {
        this.getPositionOfDropdown();
    }

    getPositionOfDropdown() {
        if (this.deviceInfo.isBrowser && !this.deviceInfo.isMobile) {
            const dropdownHeight = this.dropdownList.nativeElement.clientHeight;
            const windowHeight = window.innerHeight;
            const dropdownTop = this.dropdownButton.nativeElement.getBoundingClientRect().top;

            if (windowHeight - dropdownTop < dropdownHeight) {
                this.dropdownList.nativeElement.style.bottom = '100%';
                this.dropdownList.nativeElement.style.top = 'auto';
            } else {
                this.dropdownList.nativeElement.style.bottom = 'auto';
                this.dropdownList.nativeElement.style.top = 'calc(100% + 4px)';
            }
        }
    }

    onFocusInDropdown(): void {
        this.isDropdownOpen = !this.isDropdownOpen;
        if (this.isDropdownOpen) {
            this.handleDropdownOpen();
        } else {
            this.handleDropdownClose();
        }
    }

    onFocusOutDropdown(event): void {
        if (!this.dropdownList?.nativeElement?.contains(event.relatedTarget)) {
            this.isDropdownOpen = false;
            this.handleDropdownClose();
        }
    }

    handleDropdownOpen() {
        setTimeout(() => {
            this.inputElement?.nativeElement?.focus();
        }, 0);
    }
    handleDropdownClose() {
        this.searchString = '';
        setTimeout(() => {
            this.container.nativeElement.blur();
        }, 0);
    }

    selectOption(option: ISingleOption): void {
        this.selectedOption = option;
        this.selectedLabel = option.label;
        this.form.controls[this.field.name].setValue(option.value);
        this.isDropdownOpen = false;
        this.handleDropdownClose();
    }

    isOptionSelected(option: ISingleOption): boolean {
        return this.selectedOption && this.selectedOption.value === option.value;
    }

    setOptionDefault(defaultValue: string) {
        const defaultOption = this.field.options.find(option => option.value === defaultValue);
        if (defaultOption) {
            this.selectedOption = defaultOption;
            this.selectedLabel = defaultOption.label;
            this.form.controls[this.field.name].setValue(defaultOption.value);
        }
    }

    getDataList(options: ISingleOption[]) {
        if (this.searchString) {
            return options.filter(option => {
                return option.label.toLowerCase().includes(this.searchString.toLowerCase());
            });
        }

        return options;
    }

    handleFilterOptions(searchString: string) {
        this.searchString = searchString;
    }
}
