@defer (when deviceInfo.isMobile) {
    <ng-container *ngIf="bannerInstance$ | async as bannerInstance">
        <ng-container
            *ngIf="bannerInstance?.visible && deviceInfo.isMobile"
            [ngSwitch]="bannerInstance?.bannerConfig?.type"
        >
            <div class="banner-cont" *ngSwitchCase="productKeys.FLEXI">
                <div class="close-icon-cont c-pointer" role="button" (click)="hideBanner()">
                    <img class="close-image" [src]="closeIcon.url" [alt]="closeIcon.alt" />
                </div>
                <div class="d-flx j-cont-space-bet a-items-c">
                    <div class="left-sec-cont">
                        <div class="heading-cont">
                            <h5>Download the myHQ app</h5>
                        </div>
                        <div class="desc-cont">
                            <p>Get a free trial at workspace of your choice</p>
                        </div>
                    </div>
                    <div class="right-sec-cont c-pointer" (click)="redirectToDownloadApp()">
                        <div class="button-cont">Get App</div>
                    </div>
                </div>
            </div>

            <app-virtual-office-banner
                *ngSwitchCase="productKeys.VIRTUAL_OFFICE"
                (onHideBanner)="hideBanner()"
            ></app-virtual-office-banner>
        </ng-container>
    </ng-container>
}
