<div class="d-flx width-100 calendar-component" *ngIf="isBrowser; else loadingTemplate">
    <bs-datepicker-inline
        class="width-100"
        [bsValue]="config.selectedDate"
        [maxDate]="config.maxDate"
        [minDate]="config.minDate"
        [bsConfig]="datepickerConfig"
        (bsValueChange)="handleSelectedDateChange($event)"
    ></bs-datepicker-inline>
</div>

<ng-template #loadingTemplate>
    <div class="shimmer border-radius-4 width-100 shimmer-calendar"></div>
</ng-template>
