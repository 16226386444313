import { ChatBtnPositionEnum } from './enums';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from './../utils/utils.service';
import { filter, take } from 'rxjs/operators';
import { ReplaySubject, BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Inject, Injectable, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
    brandUrlRegex,
    dedicatedUrlRegex,
    flexiUrlRegex,
    landingPagesRegex,
    managedOfficeUrlRegex,
    meetingRoomsRegex,
    virtualOfficeUrlRegex,
} from '../../constants/urls-regex';

declare var $zoho: any;

const hideChatUrlRegex = {
    WORKSPACEDETAILSPAGE: '^(?:/|/flexi/|/dedicated/)coworking-space/([^/]+?)/?$',
    landingPagesRegex: landingPagesRegex.allUrls,
    flexiListingPage: flexiUrlRegex.listingPage,
    meetingRoomsListingPage: meetingRoomsRegex.listingPage,
    dedicatedListingPage: dedicatedUrlRegex.listingPage,
    managedOfficeListingPage: managedOfficeUrlRegex.listingPage,
    virtualOfficeListingPage: virtualOfficeUrlRegex.listingPage,
    brandListingPage: brandUrlRegex.listingPage,
    meetingRoomsPaymentPages: meetingRoomsRegex.paymentsPage,
    flexiPaymentPages: flexiUrlRegex.bookingPages,
    virtualOfficePaymentPages: virtualOfficeUrlRegex.paymentsPage,
};

const chatPositionConfig = {
    LANDING_PAGE: {
        bottom: '108px',
    },
    RESET: {
        bottom: '10px',
    },
};

enum ChatButtonState {
    SHOW = 'show',
    HIDE = 'hide',
}

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    isScriptLoadedSubject = new ReplaySubject<boolean>(1);
    chatOnlineStatusSubject = new BehaviorSubject<boolean>(false);
    chatVisibilityStatusSubject = new BehaviorSubject<boolean>(false);
    chatBtnPositionSubject = new BehaviorSubject<string>(ChatBtnPositionEnum.DEFAULT);

    constructor(
        private ngZone: NgZone,
        private utilsService: UtilsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) document
    ) {}

    ZohoButtonSelector = '.siq_bL';
    chatButtonLoaded$ = new BehaviorSubject<boolean>(false);

    init() {
        window['chatService'] = {
            component: this,
            zone: this.ngZone,
            onScriptLoaded: () => this.onScriptLoad(),
        };
    }

    subscribeToChatStatusCallbacks() {
        $zoho.salesiq.chat.online(() => {
            this.chatOnlineStatusSubject.next(true);
        });
        $zoho.salesiq.chat.offline(() => {
            this.chatOnlineStatusSubject.next(false);
        });
    }

    onScriptLoad() {
        this.isScriptLoadedSubject.next(true);
        this.subscribeToChatBtnPosition();
        this.checkChatVisibleOnPage(this.router.url);
        this.subscribeToChatStatusCallbacks();
        this.subscribeToChatBtnPositionManually();
        this.setChatBtnPosition(ChatBtnPositionEnum.DEFAULT);
        combineLatest([this.chatVisibilityStatusSubject, this.chatOnlineStatusSubject]).subscribe(
            ([chatVisibilityStatus, chatOnlineStatus]) => {
                if (chatVisibilityStatus && chatOnlineStatus) {
                    this.showChatButton();
                    this.chatButtonLoaded$.next(true);
                    return;
                }
                this.hideChatButton();
            }
        );
    }

    subscribeToChatBtnPosition() {
        this.chatBtnPositionSubject.subscribe(chatBtnPosition => {
            $zoho.salesiq.floatbutton.position(chatBtnPosition);
        });
    }

    subscribeToChatBtnPositionManually() {
        // Update the position whenever chat button is loaded or route is changed
        combineLatest([this.chatButtonLoaded$, this.activatedRoute.url]).subscribe(([isChatButtonLoaded, url]) => {
            // Update chat button position only when its loaded
            if (isChatButtonLoaded) {
                const currentRoute = this.router.url; // TODO either use activatedRoute only or router observable ?
                // Add Regex pattern for other pages where custom positioning is required
                if (currentRoute.match(landingPagesRegex.managedOffice)) {
                    this.setChatBtnPositionManually(chatPositionConfig.RESET);
                } else if (currentRoute.startsWith('/lp')) {
                    this.setChatBtnPositionManually(chatPositionConfig.LANDING_PAGE);
                } else {
                    this.setChatBtnPositionManually(chatPositionConfig.RESET);
                }
            }
        });
    }

    getChatOnlineStatusObservable(): Observable<any> {
        return this.chatOnlineStatusSubject.asObservable();
    }

    getIsScriptLoadedObservable() {
        return this.isScriptLoadedSubject.asObservable();
    }

    setChatVisibilityStatus(value: boolean) {
        this.chatVisibilityStatusSubject.next(value);
    }

    setChatBtnPosition(position: string) {
        this.chatBtnPositionSubject.next(position);
    }

    setChatBtnPositionManually(position) {
        const zohoChatButton = document.querySelector(this.ZohoButtonSelector);
        // Currently we only need to update bottom position value, add more update statement if needed
        if (zohoChatButton && position.bottom) {
            // @ts-ignore
            zohoChatButton.style.setProperty('bottom', position.bottom);
        }
    }

    private hideChatButton() {
        $zoho.salesiq.floatbutton.visible(ChatButtonState.HIDE);
    }

    private showChatButton() {
        $zoho.salesiq.floatbutton.visible(ChatButtonState.SHOW);
    }

    openChatWindow() {
        if (this.chatOnlineStatusSubject.value) {
            $zoho.salesiq.chat.start();
        }
    }

    closeChatWindow() {
        $zoho.salesiq.chat.complete();
    }

    checkChatVisibleOnPage(url: string) {
        let showChatOnPage = true;

        const hideChatRegexMatch = Object.values(hideChatUrlRegex).find((regex: string) => RegExp(regex).test(url));
        if (hideChatRegexMatch) {
            showChatOnPage = false;
        }
        if (showChatOnPage) {
            this.setChatVisibilityStatus(true);
        } else {
            this.setChatVisibilityStatus(false);
        }
    }

    subscribeToRouterEvents() {
        this.getIsScriptLoadedObservable()
            .pipe(
                filter(value => value === true),
                take(1)
            )
            .subscribe(() => {
                this.utilsService.getRouterEvents$().subscribe((data: any) => {
                    this.checkChatVisibleOnPage(data.urlAfterRedirects);
                });
            });
    }
}
