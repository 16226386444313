import { TabsModule as BootstrapTabsModule } from 'ngx-bootstrap/tabs';
import { NgModule } from '@angular/core';
import { TabsComponent } from './tabs.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [TabsComponent],
    imports: [CommonModule, BootstrapTabsModule.forRoot()],
    exports: [TabsComponent],
})
export class TabsModule {}
