<app-modals [template]="unsupportedProductModalContent"></app-modals>

<!--templates-->
<ng-template #unsupportedProductModalContent>
    <div class="d-flx flx-d-clm bg-color-white unsupported-product-modal-content">
        <div class="d-flx width-100 a-items-c j-cont-space-bet modal-header-container">
            <h3 class="modal-heading-text">Explore or connect with myHQ Experts</h3>
            <div class="d-flx a-items-c j-cont-cen c-pointer close-icon-container" (click)="closeModal()">
                <img
                    class="height-100 width-100 object-fit-contain"
                    appImageShimmerLazyLoad
                    [imageSrc]="CommonIconsUrl.CLOSE_DARK"
                />
            </div>
        </div>
        <div class="d-flx flx-d-clm flx-1 a-items-c modal-content-container width-100">
            <h4 class="info-text">{{ modalContentConfig?.heading }}</h4>
            <p class="info-description">{{ modalContentConfig?.description }}</p>
            <app-button
                class="requirement-cta"
                [field]="requirementCtaConfig"
                (click)="handleShareRequirementCtaClick()"
            ></app-button>
        </div>
    </div>
</ng-template>
