import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ITextareaFieldConfig } from './../../interfaces/field.interface';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() field: ITextareaFieldConfig;
    constructor() {}

    ngOnInit(): void {
        const field = this.form.controls[this.field.name];
        field.setValue('');
    }
}
