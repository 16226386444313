import { Injectable, ElementRef } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PhoneNumber, SocialMediaLink } from '../../constants/constants';
import { CommonIconsUrl } from '../../constants/common-icons-url';
import { IFAQContent } from '../../../shared/interfaces/faq.interface';
import {
    IArticleDataPayload,
    IPricingDataPayload,
} from '../../../shared/standalone-components/listing-rich-content/listing-rich-content.interface';
interface IBreadcrumbRichData<T> {
    '@type': string;
    position: number;
    item: T;
}
interface IItem {
    '@id': string;
    name: string;
}

export type TWeekDays = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

export interface ILocalBusinessData {
    businessUrl: string;
    businessName: string;
    address: {
        street: string;
        locality: string;
        region: string;
        postalCode: string;
    };
    coordinates: [number, number];
    image: string[];
    openingHours: Array<{
        weekDays: Array<TWeekDays>;
        opens: string;
        closes: string;
    }>;
    priceRange: string;
}

@Injectable({
    providedIn: 'root',
})
export class RichContentService {
    contextUrl = 'https://schema.org';
    constructor() {}

    getBreadcrumbsData(listData) {
        const itemList: IBreadcrumbRichData<IItem>[] = [];
        listData.map((item, index) => {
            itemList.push({
                '@type': 'ListItem',
                position: index + 1,
                item: {
                    '@id': `${environment.SERVER_URL}${item.redirectUrl}`,
                    name: item.name,
                },
            });
        });

        return `{
      "@context": ${JSON.stringify(this.contextUrl)},
      "@type": "BreadcrumbList",
      "itemListElement": ${JSON.stringify(itemList)}
    }`;
    }

    getFaqStructuredData(faqData) {
        // Creating the structured Data (Json-LD) for FAQs
        const mainEntity = faqData
            ? faqData.map(({ question, answer }: IFAQContent) => {
                  return {
                      '@type': 'Question',
                      name: question,
                      acceptedAnswer: {
                          '@type': 'Answer',
                          text: answer,
                      },
                  };
              })
            : [];
        return `{
      "@context": ${JSON.stringify(this.contextUrl)},
      "@type": "FAQPage",
      "mainEntity": ${JSON.stringify(mainEntity)}
    }`;
    }

    getLocalBusinessStructuredData(data: ILocalBusinessData) {
        const openingHoursData = data?.openingHours.map(openingHourObj => {
            return {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: JSON.stringify(openingHourObj.weekDays),
                opens: openingHourObj.opens,
                closes: openingHourObj.closes,
            };
        });

        return `{
      "@context": ${JSON.stringify(this.contextUrl)},
      "@id": ${JSON.stringify(data.businessUrl)},
      "name": ${JSON.stringify(data.businessName)},
      "@type": "LocalBusiness",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": ${JSON.stringify(data.address.street || '')},
        "addressLocality": ${JSON.stringify(data.address.locality || '')},
        "addressRegion": ${JSON.stringify(data.address.region || '')},
        "postalCode": "${data.address.postalCode}",
        "addressCountry": "IN"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "${data.coordinates[0]}",
        "longitude": "${data.coordinates[1]}"
      },
      "image": ${JSON.stringify(data.image)},
      "OpeningHoursSpecification": ${JSON.stringify(openingHoursData)},
      "priceRange": ${JSON.stringify(data.priceRange || '')},
      "telephone": "${PhoneNumber.PHONE_NUMBER}",
      "url": ${JSON.stringify(data.businessUrl)}
    }`;
    }

    getOrganisationData() {
        return `{
    "@context": ${JSON.stringify(this.contextUrl)},
      "@type": "Organization",
      "name": "myHQ",
     "description": "myHQ is a network of co-working spaces in various cities across India, providing flexible workspace solutions for businesses of all sizes. From startups, freelancers to established businesses, myHQ provides office spaces tailored to suit diverse needs and requirements. These office spaces provide State-Of-Art amenities like high-speed internet, meeting rooms, collaborative zones for professionals to connect, and a dynamic work environment to boost productivity and innovation.",
      "url": "https://${environment.domain}/",
      "logo": "https://res.cloudinary.com/myhq/image/upload/web/ssr/common/logo/logo-new-flat.svg",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "${PhoneNumber.PHONE_NUMBER}",
        "contactType": "sales",
        "areaServed": "IN",
        "availableLanguage": "en"
      },
      "sameAs": [${JSON.stringify(SocialMediaLink.FACEBOOK)},
        ${JSON.stringify(SocialMediaLink.INSTAGRAM)},
        ${JSON.stringify(SocialMediaLink.TWITTER)},
        ${JSON.stringify(SocialMediaLink.LINKEDIN)},
        ${JSON.stringify(`https://${environment.domain}/`)}]
      }
      `;
    }

    getWebsiteData() {
        return `{
      "@context": ${JSON.stringify(this.contextUrl)},
      "@type": "WebSite",
      "name": "myHQ",
      "alternateName": "myHQ - Creating Work Friendly Spaces",
      "url": ${JSON.stringify(environment.SERVER_URL)}
    }`;
    }

    getSocialMediaData() {
        return `{
          "@context": ${JSON.stringify(this.contextUrl)},
          "@type": "Organization",
          "name": "myHQ",
          "url": ${JSON.stringify(environment.SERVER_URL)},
          "logo": ${JSON.stringify(CommonIconsUrl.LOGO_LARGE)},
          "sameAs": [${JSON.stringify(SocialMediaLink.FACEBOOK)},
            ${JSON.stringify(SocialMediaLink.TWITTER)},
            ${JSON.stringify(SocialMediaLink.LINKEDIN)},
            ${JSON.stringify(SocialMediaLink.GOOGLE_PLUS)},
            ${JSON.stringify(SocialMediaLink.INSTAGRAM)}]
        }`;
    }

    getSearchData() {
      return `{
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://myhq.in/",
        "potentialAction": {
          "@type": "SearchAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://query.myhq.in/search?q={search_term_string}"
          },
          "query-input": "required name=search_term_string"
        }
      }`;
    }

    getArticleData(payload: IArticleDataPayload) {
        const { city, headline, description, imageUrl, product, id } = payload;
        return `{
      "@context": ${JSON.stringify(this.contextUrl)},
        "@type": "Article",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": ${JSON.stringify(
              id ||
                  `https://${environment.domain}/${city.slug}/${product.productBaseUrl}/${product.listingUrlSlug}-in-${city.slug}`
          )}
        },
        "headline": ${JSON.stringify(headline)},
        "description": ${JSON.stringify(description)},
        "image": ${JSON.stringify(imageUrl)},
        "author": {
          "@type": "Organization",
          "name": "myHQ",
          "url": ${JSON.stringify(`https://${environment.domain}/`)}
        },
        "publisher": {
          "@type": "Organization",
          "name": "myHQ",
          "logo": {
            "@type": "ImageObject",
            "url": "https://res.cloudinary.com/myhq/image/upload/web/ssr/common/logo/logo-new-flat.svg"
          }
        },
        "datePublished": ""
    }`;
    }

    getPricingData(payload: IPricingDataPayload) {
        const { mpn, reviewBody, name, description, sku, city, imageUrl, product } = payload;
        return `{
      "@context": ${JSON.stringify(this.contextUrl)},
      "@type": "Product",
      "name": ${JSON.stringify(name)},
      "image": ${JSON.stringify(imageUrl)},
      "description": ${JSON.stringify(description)},
      "brand": "myHQ",
      "sku": ${JSON.stringify(sku)},
      "mpn": ${JSON.stringify(mpn)},
      "offers": {
      "@type": "Offer",
      "url": ${JSON.stringify(
          `https://${environment.domain}/${city.slug}/${product.productBaseUrl}/${product.listingUrlSlug}-in-${city.slug}`
      )},
      "priceCurrency": "INR",
      "price": "1000",
      "priceValidUntil": "2024-12-31",
      "availability": "https://schema.org/InStock",
      "itemCondition": "https://schema.org/NewCondition"
      },
      "review": {
      "@type": "Review",
      "name": ${JSON.stringify(name)},
      "reviewBody": ${JSON.stringify(reviewBody)},
      "author": { "@type": "Person", "name": "Varsha" },
      "publisher": { "@type": "Organization", "name": "myHQ" }
      }
      }`;
    }

    addRichData(renderer2, commonRef: ElementRef) {
      // getSearchData
        const functionNames = ['getOrganisationData', 'getWebsiteData', 'getSocialMediaData'];
        functionNames.map(functionName => {
            const script = renderer2.createElement('script');
            script.type = `application/ld+json`;
            script.text = this[functionName]();
            renderer2.appendChild(commonRef.nativeElement, script);
        });
    }
}
