import { Injectable } from '@angular/core';
import { RecentSearchesService } from './recent-searches/recent-searches.service';
import { LocalitiesService } from './localities/localities.service';
import { UrlUtilsService } from '../../core/services/utils/url-utils.service';
import { nearMeLocationObj, productKeys } from '../enums/enums';
import { MeetingRoomsService } from './meeting-rooms/meeting-rooms.service';

@Injectable({
    providedIn: 'root',
})
export class LocationWorkspaceSearchService {
    constructor(
        private recentSearchesService: RecentSearchesService,
        private localitiesService: LocalitiesService,
        private urlUtilsService: UrlUtilsService,
        private meetingRoomsService: MeetingRoomsService
    ) {}

    onSelectionEvent(payload) {
        const { selectedItem, city, product, isSearchSuggestion } = payload;

        if (isSearchSuggestion) {
            this.recentSearchesService.setRecentSearch(selectedItem, city);
        }
        const { redirectUrl, queryParams, options } = this.localitiesService.generateLocalitiesRedirectUrl(
            selectedItem,
            product
        );

        if (product.productNameFrontend === productKeys.MEETING_ROOM) {
            if (this.meetingRoomsService.isMeetingRoomsUrl(redirectUrl)) {
                const parsedUrl = this.meetingRoomsService.parseMeetingRoomsSpaceDetailUrl(redirectUrl);
                if (parsedUrl.isValidUrl && !parsedUrl.capacity) {
                    this.meetingRoomsService.toggleCapacityModal(true, parsedUrl.workspaceSlug);
                } else {
                    this.urlUtilsService.updateUrl(redirectUrl, queryParams, options);
                }
            }
        } else {
            this.urlUtilsService.updateUrl(redirectUrl, queryParams, options);
        }
    }
}
