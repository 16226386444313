export enum WorkspaceSeatType {
    PRIVATE_CABINS = 'Private Cabins',
    DEDICATED_DESKS = 'Dedicated Desks',
    MANAGED_OFFICE = 'Managed Office',
    FLEXI_DESKS = 'Flexi Desks',
    MEETING_ROOM = 'Meeting Room',
}

export enum InventoryCardSections {
    ADDITIONAL_AMENITIES = 'ADDITIONAL_AMENITIES',
    SEATING_OPTIONS = 'SEATING_OPTIONS',
    MANAGED_OFFICE_AMENITIES = 'MANAGED_OFFICE_AMENITIES',
    MEETING_ROOM_EQUIPMENTS = 'MEETING_ROOM_EQUIPMENTS',
}
