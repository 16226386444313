import { WebengageService } from './../analytics-api/webengage.service';
import { Injectable } from '@angular/core';
import { FacebookAnalyticsService } from '../analytics-api/facebook-analytics.service';
import { SegmentAnalyticsService } from '../analytics-api/segment-analytics.service';
import { MixPanelService } from '../analytics-api/mix-panel.service';
import { UtilsService } from '../../utils/utils.service';
import { AnalyticsBaseInterface } from './analytics-base.interface';
import { GoogleTagAnalyticsService } from '../analytics-api/google-tag-analytics.service';
import { IPlatforms } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsBaseService implements AnalyticsBaseInterface {
    constructor(
        private mixPanelService: MixPanelService,
        private utilsService: UtilsService,
        private facebookAnalyticsService: FacebookAnalyticsService,
        private googleTagManager: GoogleTagAnalyticsService,
        private segmentAnalytics: SegmentAnalyticsService,
        private webengageService: WebengageService,
    ) {}

    trackEvent(action: string, properties: any = {}, platforms?: IPlatforms) {
        if (this.utilsService.isBrowser) {
            this.facebookAnalyticsService.trackEvent(action, properties);
            this.mixPanelService.track(action, properties);
            this.segmentAnalytics.trackEvent(action, properties);
            this.googleTagManager.trackEvent(action, properties);
            if(platforms?.webengage) {
                this.webengageService.trackEvent(action, properties);
            }
        } else {
            console.log('Not Calling TrackEvent on Non Browser Devices');
        }
    }

    trackPageNavigationEvent(properties: object, platform?: IPlatforms) {
        if (this.utilsService.isBrowser) {
            // TODO: Error in any downstream function unsubscribes from observable
            // Figure out a way to do this w/o using try / catch
            try {
                this.facebookAnalyticsService.trackPageView();
                this.segmentAnalytics.trackPageNavigation(properties);
                this.googleTagManager.trackPageNavigation(properties);
            } catch (err) {
                console.log('Error in page navigation', err);
            }
        }
    }

    identifyUser(uniqId: string, user: object) {
        // unique id is phone number
        if (this.utilsService.isBrowser) {
            this.mixPanelService.identify(uniqId, user);
            this.segmentAnalytics.identifyUser(uniqId, user);
            this.webengageService.identifyUser(uniqId, user);
        } else {
            console.log('Not Calling Identify User on Non Browser Devices');
        }
    }

    resetOnLogout() {
        if (this.utilsService.isBrowser) {
            this.mixPanelService.reset();
            this.webengageService.reset();
        } else {
            console.log('Not Calling Reset On Logout on Non Browser Devices');
        }
    }

    trackGenericProperties(properties: object) {
        if (this.utilsService.isBrowser) {
            this.mixPanelService.register(properties);
            this.segmentAnalytics.identifyUser(null, properties);
            this.webengageService.identifyUser(null, properties);
        } else {
            console.log('Not Calling Track Generic Properties on Non Browser Devices');
        }
    }
}
