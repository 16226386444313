type ExtractKeys<T extends Record<any, any>> = T[keyof T];

export const ElementType = {
    TEXT: 'TEXT',
    PARAGRAPH: 'PARAGRAPH',
    HEADING: 'HEADING',
    ITALIC: 'ITALIC',
    BOLD: 'BOLD',
    LIST: 'LIST',
    LINK: 'LINK',
    IMAGE: 'IMAGE',
    SECTION: 'SECTION',
    SPACE: 'SPACE',
    ICON: 'ICON',
} as const;

export type ElementType = ExtractKeys<typeof ElementType>;

export enum ListElementStyle {
    ORDERED = 'ORDERED',
    UNORDERED = 'UNORDERED',
}

export type TextElement = { type: typeof ElementType.TEXT; data: { text: string } };

export type ParagraphElement = {
    type: typeof ElementType.PARAGRAPH;
    data: {
        children: (BoldElement | ItalicElement | LinkElement | TextElement | HeadingElement)[];
    };
};

export type HeadingElement = {
    type: typeof ElementType.HEADING;
    data: { text: string };
};

export type ItalicElement = {
    type: typeof ElementType.ITALIC;
    data: { text: string };
};

export type BoldElement = {
    type: typeof ElementType.BOLD;
    data: { text: string };
};

export type ListElement = {
    type: typeof ElementType.LIST;
    data: {
        style: ListElementStyle;
        items: (ParagraphElement | BoldElement | ItalicElement | LinkElement | TextElement)[];
    };
};

export type LinkElement = {
    type: typeof ElementType.LINK;
    data:
        | {
              text: string;
              url: string;
              isExternal?: boolean;
          }
        | { text: string; id: string };
};

export type ImageElement = {
    type: typeof ElementType.IMAGE;
    data: {
        src: string;
        alt: string;
    };
};

export type SectionElement = {
    type: typeof ElementType.SECTION;
    data: {
        children: Element[];
    };
};

export type SpaceElement = {
    type: typeof ElementType.SPACE;
    data: {
        align: 'center' | 'start' | 'end';
        direction: 'horizontal' | 'vertical';
        size: number;
        children: Element[];
    };
};

export type IconElement = {
    type: typeof ElementType.ICON;
    data: {
        size?: number;
        src: string;
    };
};

export type Element =
    | ParagraphElement
    | HeadingElement
    | ItalicElement
    | BoldElement
    | ListElement
    | LinkElement
    | ImageElement
    | SectionElement
    | TextElement
    | SpaceElement
    | IconElement;

export type IStructuredContent = Element[];
