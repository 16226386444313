<div *ngIf="recentlySearchedItems.length" class="recent-search-cont">
    <div *ngIf="heading" class="heading-cont">
        <div class="heading">{{ heading }}</div>
    </div>
    <div class="recently-searched-items-cont d-flx flx-wrp">
        <ng-container *ngFor="let item of recentlySearchedItems">
            <div class="recently-searched-item" *ngIf="!item.isLocation && showWorkspaces">
                <ng-container *ngTemplateOutlet="workspace; context: { item: item }"></ng-container>
            </div>
            <div class="recently-searched-item" *ngIf="item.isLocation && showLocations">
                <ng-container *ngTemplateOutlet="location; context: { item: item }"></ng-container>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #workspace let-item="item">
    <div class="recent-search-card workspace-cont d-flx a-items-start c-pointer" (click)="onClickRecentSearch(item)">
        <div class="image-cont d-flx j-cont-cen a-items-c">
            <div class="svg-cont d-flx" [innerHTML]="workspaceIcon"></div>
        </div>
        <div class="details-cont">
            <div class="name-cont">
                <h3 class="name text-ellipsis">{{ item.name }}</h3>
            </div>
            <div class="type-location-cont d-flx">
                <p class="font-10 text-color-lighter-grey">{{ item.location }}</p>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #location let-item="item">
    <div class="location-cont recent-search-card d-flx a-items-c c-pointer" (click)="onClickRecentSearch(item)">
        <div class="svg-cont d-flx" [innerHTML]="locationIcon"></div>
        <div class="details-cont">
            <div class="name-cont d-flx a-items-c">
                <h3 class="name text-ellipsis">{{ item.name }}</h3>
            </div>
        </div>
    </div>
</ng-template>
