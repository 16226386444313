<img
    [ngSrc]="image?.url"
    [alt]="image?.alt"
    [fill]="fill"
    [priority]="priority"
    [ngStyle]="styles"
    [sizes]="sizes"
    [height]="height"
    [width]="width"
    [ngClass]="cssClass"
    [placeholder]="placeholder"
/>
