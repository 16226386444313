import { ObserverQueue } from './../classes/observerQueue.class';
// Converts Function to Promise and queues up orignal Call

export function toPromiseAndEnqueueTask(queue: { push: (e) => any }) {
    return function (classRef: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;
        descriptor.value = (...args): Promise<any> => {
            return new Promise(resolve => {
                queue.push({ event: () => originalMethod.call(classRef, ...args), resolve });
            });
        };
        return descriptor;
    };
}
