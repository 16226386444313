import { productsMap } from './../../enums/enums';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SelectCityService } from './../select-city/select-city.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UrlGeneratorService {
    productsMap = productsMap;
    homepage: BehaviorSubject<string> = new BehaviorSubject('/');
    flexiProductUrl: BehaviorSubject<string> = new BehaviorSubject(this.productsMap.FLEXI.productBaseUrl);
    managedOfficeUrl: BehaviorSubject<string> = new BehaviorSubject(this.productsMap.MANAGED_OFFICE.productBaseUrl);
    corporateFlexiProductUrl: BehaviorSubject<string> = new BehaviorSubject(
        this.productsMap.CORPORATE_FLEXI.productBaseUrl
    );
    teamDashboardUrl: BehaviorSubject<string> = new BehaviorSubject(environment.teamDashboardUrl);
    teamDashboardLoginUrl: BehaviorSubject<string> = new BehaviorSubject(`${environment.teamDashboardUrl}/login`);
    teamDashboardSignUpUrl: BehaviorSubject<string> = new BehaviorSubject(`${environment.teamDashboardUrl}/signup`);
    dedicatedProductUrl: BehaviorSubject<string> = new BehaviorSubject(`/${this.productsMap.DEDICATED.productBaseUrl}`);
    enterprisesProductUrl: BehaviorSubject<string> = new BehaviorSubject(
        `/${this.productsMap.ENTERPRISES.productBaseUrl}`
    );
    dedicatedListingUrl: BehaviorSubject<string> = new BehaviorSubject(
        `/${this.productsMap.DEDICATED.productBaseUrl}/coworking-space-in-`
    );
    managedOfficeListingUrl: BehaviorSubject<string> = new BehaviorSubject(
        `/${this.productsMap.MANAGED_OFFICE.productBaseUrl}`
    );
    dedicatedListingUrlWithManagedOffice: BehaviorSubject<string> = new BehaviorSubject(
        `/${this.productsMap.DEDICATED.productBaseUrl}/coworking-space-in-?filters=%7B"PRODUCT":"DEDICATED","LOCALITIES":%5B%5D,"DEDICATED_SPACE_TYPE":"OFFICE_SPACE"%7D`
    );
    privateCabinListingUrl: BehaviorSubject<string> = new BehaviorSubject(
        `/${this.productsMap.DEDICATED.productBaseUrl}/private-cabins-in-`
    );
    meetingRoomUrl: BehaviorSubject<string> = new BehaviorSubject(this.productsMap.MEETING_ROOM.productBaseUrl);
    meetingRoomShopifyUrl: BehaviorSubject<string> = new BehaviorSubject('//meetingrooms.myhq.in/');
    // TODO: CHECK MEETING ROOMS LISTING URL; ASK MUKUL
    meetingRoomListingUrl: BehaviorSubject<string> = new BehaviorSubject(
        `/${this.productsMap.MEETING_ROOM.productBaseUrl}`
    );
    virtualOfficeUrl: BehaviorSubject<string> = new BehaviorSubject(this.productsMap.VIRTUAL_OFFICE.productBaseUrl);
    virtualOfficeListingUrl: BehaviorSubject<string> = new BehaviorSubject(
        `/${this.productsMap.VIRTUAL_OFFICE.productBaseUrl}/virtual-office-in-`
    );
    virtualOfficeListingUrlWithNewCompanyRegistration: BehaviorSubject<string> = new BehaviorSubject(
        `/${this.productsMap.VIRTUAL_OFFICE.productBaseUrl}/virtual-office-in-?filters=%7B"PRODUCT":"VIRTUAL_OFFICE","SOLUTION":"company-registration"%7D`
    );
    virtualOfficeListingUrlWithNewGSTRegistration: BehaviorSubject<string> = new BehaviorSubject(
        `/${this.productsMap.VIRTUAL_OFFICE.productBaseUrl}/virtual-office-in-?filters=%7B"PRODUCT":"VIRTUAL_OFFICE","SOLUTION":"gst-registration"%7D`
    );
    flexiPlansUrl: BehaviorSubject<string> = new BehaviorSubject(`/${this.productsMap.FLEXI.productBaseUrl}/plans`);
    flexiListingUrl: BehaviorSubject<string> = new BehaviorSubject(
        `/${this.productsMap.FLEXI.productBaseUrl}/coworking-space-in-`
    );
    myShortlistsUrl: BehaviorSubject<string> = new BehaviorSubject(`/shortlisted-workspaces`);
    dedicatedScheduleVisitUrl: BehaviorSubject<string> = new BehaviorSubject('/dedicated/schedule-visit');
    selectedCity$: Subscription;

    // TODO:: check and sort update urls
    updateUrlCity(citySlug) {
        this.homepage.next(`/${citySlug}`);
        this.flexiProductUrl.next(`/${citySlug}/${this.productsMap.FLEXI.productBaseUrl}`);
        this.corporateFlexiProductUrl.next(`/${citySlug}/${this.productsMap.CORPORATE_FLEXI.productBaseUrl}`);
        this.managedOfficeUrl.next(`/lp/${citySlug}/${this.productsMap.MANAGED_OFFICE.productBaseUrl}`);
        this.dedicatedProductUrl.next(`/${citySlug}/${this.productsMap.DEDICATED.productBaseUrl}`);
        this.meetingRoomUrl.next(`/${citySlug}/${this.productsMap.MEETING_ROOM.productBaseUrl}`);
        this.virtualOfficeUrl.next(`/${citySlug}/${this.productsMap.VIRTUAL_OFFICE.productBaseUrl}`);
        this.flexiPlansUrl.next(`${citySlug}/flexi/plans`);
        this.dedicatedListingUrl.next(
            `/${citySlug}/${this.productsMap.DEDICATED.productBaseUrl}/coworking-space-in-${citySlug}`
        );
        this.managedOfficeListingUrl.next(`/${citySlug}/managed-office/${citySlug}`);
        this.privateCabinListingUrl.next(
            `/${citySlug}/${this.productsMap.DEDICATED.productBaseUrl}/private-cabins-in-${citySlug}`
        );
        this.flexiListingUrl.next(
            `/${citySlug}/${this.productsMap.FLEXI.productBaseUrl}/coworking-space-in-${citySlug}`
        );
        this.virtualOfficeListingUrl.next(
            `/${citySlug}/${this.productsMap.VIRTUAL_OFFICE.productBaseUrl}/virtual-office-in-${citySlug}`
        );
        this.virtualOfficeListingUrlWithNewCompanyRegistration.next(
            `/${citySlug}/${this.productsMap.VIRTUAL_OFFICE.productBaseUrl}/virtual-office-in-${citySlug}?filters=%7B"PRODUCT":"VIRTUAL_OFFICE","SOLUTION":"company-registration"%7D`
        );
        this.virtualOfficeListingUrlWithNewGSTRegistration.next(
            `/${citySlug}/${this.productsMap.VIRTUAL_OFFICE.productBaseUrl}/virtual-office-in-${citySlug}?filters=%7B"PRODUCT":"VIRTUAL_OFFICE","SOLUTION":"gst-registration"%7D`
        );
        this.dedicatedListingUrlWithManagedOffice.next(
            `/${citySlug}/${this.productsMap.DEDICATED.productBaseUrl}/coworking-space-in-${citySlug}?filters=%7B"PRODUCT":"DEDICATED","DEDICATED_SPACE_TYPE":"OFFICE_SPACE"%7D`
        );
        this.meetingRoomListingUrl.next(`/${citySlug}/${this.productsMap.MEETING_ROOM.productBaseUrl}/${citySlug}`);
    }

    constructor(private selectCityService: SelectCityService) {
        this.selectedCity$ = this.selectCityService.getSelectedCitySync().subscribe(({ city }) => {
            if (city) {
                this.updateUrlCity(city.slug);
            }
        });
    }
}
