<div
    *ngIf="field"
    [attr.formGroup]="form"
    [className]="additionalClass ? additionalClass + ' line-height-18' : 'line-height-18'"
>
    <button
        [type]="field.htmlType || buttonHTMLType.submit"
        [class]="config.cssClass"
        (click)="onClickEmitEvent($event)"
        [disabled]="form?.invalid || field.showLoader || field.disabled"
        [ngClass]="{
            'gap-4': field?.type == buttonType.LINK,
            'gap-8': field?.type != buttonType.LINK,
            'disable-btn': form?.invalid || field.showLoader || field.disabled,
        }"
    >
        <ng-container *ngIf="!field.showLoader">
            <div *ngIf="field.subLabel" class="sub-label">
                {{ field.subLabel }}
            </div>
            <div
                class="d-flx a-items-c"
                [ngClass]="{
                    'gap-4': field?.type == buttonType.LINK,
                    'gap-8 j-cont-cen': field?.type != buttonType.LINK,
                }"
            >
                <ng-container *ngIf="iconPosition === 'left'" [ngTemplateOutlet]="buttonIcon"></ng-container>
                <div
                    *ngIf="field.label"
                    [ngClass]="{
                        'text-ellipsis': showEllipsis,
                        'next-line-wrap': !showEllipsis,
                    }"
                    #label
                >
                    {{ field.label }}
                </div>
                <ng-container *ngIf="iconPosition === 'right'" [ngTemplateOutlet]="buttonIcon"></ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="field.showLoader">
            <app-loaders class="d-flx" [config]="field.loaderConfig"></app-loaders>
        </ng-container>
    </button>
</div>

<ng-template #buttonIcon>
    <div class="d-flx j-cont-cen a-items-c icon-container" *ngIf="field?.icon || field?.iconUrl">
        <img
            class="height-100 width-100"
            [src]="
                field?.iconUrl ? field?.iconUrl : ImageBaseUrl.IMAGE_URL_BASE + '/web/ssr/common/assets/' + field.icon
            "
            alt="i"
            loading="lazy"
        />
    </div>
</ng-template>
