import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IFAQContent } from '../interfaces/faq.interface';

@Injectable({
    providedIn: 'root',
})
export class FaqService {
    constructor() {}

    faqData = new BehaviorSubject<IFAQContent[]>([]);

    public getFaqData(): Observable<IFAQContent[]> {
        return this.faqData.asObservable();
    }

    public setFaqData(data: IFAQContent[]) {
        if (data) {
            this.faqData.next(data);
        }
    }
}
