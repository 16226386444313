import { ImageBaseUrl } from '../../../core/constants/constants';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { IFieldConfig } from '../../interfaces/field.interface';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { formFieldType } from '../../enums/enums';
import { CommonModule } from '@angular/common';

// @ts-ignore
import PasswordHiddenIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/password-hidden.svg';
// @ts-ignore
import PasswordVisibleIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/password-visible.svg';

@Component({
    standalone: true,
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    imports: [CommonModule, ReactiveFormsModule],
})
export class InputComponent implements OnInit {
    ImageBaseUrl = ImageBaseUrl;
    FormFieldType = formFieldType;
    uniqueId: number;
    deviceInfo = this.utilsService.getDeviceInfo();
    passwordHiddenIcon = this.sanitizer.bypassSecurityTrustHtml(PasswordHiddenIcon);
    passwordVisibleIcon = this.sanitizer.bypassSecurityTrustHtml(PasswordVisibleIcon);
    passwordIcon: SafeHtml = this.passwordHiddenIcon;
    inputTextData: any;
    isInvalid = false;
    isValid = false;
    showValidationErrors: boolean[] = [];
    isPasswordVisible = false;

    @Input() form: UntypedFormGroup;
    @Input() field: IFieldConfig;

    constructor(
        private utilsService: UtilsService,
        private sanitizer: DomSanitizer
    ) {}

    @ViewChild('inputElement') inputElement: ElementRef;

    ngOnInit() {
        this.uniqueId = this.utilsService.getUniqueId(); // Different Child Components have unique IDs.
        this.deviceInfo = this.utilsService.getDeviceInfo();
        const field = this.form.controls[this.field.name];
        if (this.field.restrictedCharacters) {
            field.valueChanges.pipe(debounceTime(100), distinctUntilChanged()).subscribe(() => {
                if (this.inputTextData) {
                    this.field.restrictedCharacters.forEach(
                        str => (this.inputTextData = String(this.inputTextData).replace(new RegExp(str, 'g'), ''))
                    );
                }
            });
        }

        field.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(res => {
            if (field.dirty || field.touched) {
                if (field.invalid) {
                    this.isValid = false;
                    this.isInvalid = true;
                } else {
                    this.isValid = true;
                    this.isInvalid = false;
                }
                this.showValidationErrors = [];
                this.field.validations?.forEach((validationObj, index) => {
                    if (field.hasError(validationObj.name)) {
                        this.showValidationErrors[index] = true;
                    }
                });
            }
        });

        if (this.field.focus && this.deviceInfo.isMobile) {
            setTimeout(() => {
                this.inputElement.nativeElement.focus();
            });
        }
    }

    togglePasswordView(event) {
        event?.stopPropagation();
        this.isPasswordVisible = !this.isPasswordVisible;
        this.field.type = this.isPasswordVisible ? 'text' : 'password';
        this.passwordIcon = this.isPasswordVisible ? this.passwordVisibleIcon : this.passwordHiddenIcon;
    }
}
