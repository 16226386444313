import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { DirectivesModule } from '../../../directives/directives.module';
import { CommonIconsUrl } from '../../../../core/constants/common-icons-url';

// TODO should be part of the location workspace module, as its tightly coupled with it
@Component({
    standalone: true,
    selector: 'app-location-search-list',
    templateUrl: './location-search-list.component.html',
    styleUrls: ['./location-search-list.component.scss'],
    imports: [CommonModule, DirectivesModule],
})
export class LocationSearchListComponent implements OnInit {
    constructor() {}

    commonIconsUrl = CommonIconsUrl;

    @Input() searchedLocation: any; // TODO define the interface type
    @Input() cssClass = '';
    ngOnInit() {}
}
