import {
    AfterViewInit,
    ApplicationRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ITabsConfig } from '../../../../interfaces/tabs.interface';
import {
    buttonType,
    CommonFilters,
    fontSizes,
    PrefixIcon,
    productKeys,
    productsMap,
    TabVariant,
} from '../../../../enums/enums';
import { ITypeaheadConfig } from '../../../../interfaces/typeahead.interface';
import { IButtonConfig } from '../../../../interfaces/button.interface';
import { SearchExpandedSection, SearchTabCtaLabels, SearchTabLabels } from '../../search.enum';
import { SelectCityService } from '../../../../services/select-city/select-city.service';
import { ICity } from '../../../../interfaces/location.interface';
import {
    ISearchHeaderConfig,
    ISearchHeaderOnFocusPayload,
    ISearchLocation,
    ISearchTabItem,
} from '../../search.interface';
import { IProductMap } from '../../../../interfaces/product-interface';
import { UtilsService } from '../../../../../core/services/utils/utils.service';
// @ts-ignore
import FlexiPassIcon from '!!raw-loader!svgo-loader!../../../../../../assets/web/ssr/common/assets/flexi-pass-outlined.svg';
// @ts-ignore
import VirtualOfficeIcon from '!!raw-loader!svgo-loader!../../../../../../assets/web/ssr/common/assets/virtual-office-outlined.svg';
// @ts-ignore
import FixedDeskIcon from '!!raw-loader!svgo-loader!../../../../../../assets/web/ssr/common/assets/fixed-desk-outlined.svg';
// @ts-ignore
import MeetingRoomIcon from '!!raw-loader!svgo-loader!../../../../../../assets/web/ssr/common/assets/meeting-room-outlined.svg';
import { DomSanitizer } from '@angular/platform-browser';
import { WorkspaceService } from '../../../../services/workspace/workspace.service';
import { TypeaheadComponent } from '../../../../ui-components/typeahead/typeahead.component';
import { Router } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LocationService } from '../../../../services/location/location.service';
import { MeetingRoomsService } from '../../../../services/meeting-rooms/meeting-rooms.service';
import {
    IMeetingRoomCapacityModalConfig,
    IMeetingRoomWorkspaceCardData,
} from '../../../../../modules/meeting-rooms/meeting-rooms.interface';
import { MeetingRoomCapacityModalComponent } from '../../../../standalone-components/meeting-room-capacity-modal/meeting-room-capacity-modal.component';
import { SearchTypeaheadNoResultComponent } from '../search-typeahead-no-result/search-typeahead-no-result.component';
import { SearchTypeaheadResultLoadingComponent } from '../search-typeahead-result-loading/search-typeahead-result-loading.component';
import { SearchTypeaheadResultListComponent } from '../search-typeahead-result-list/search-typeahead-result-list.component';
import { SearchUnsupportedProductBannerComponent } from '../search-unsupported-product-banner/search-unsupported-product-banner.component';
import { ButtonsModule } from '../../../../ui-components/buttons/buttons.module';
import { TypeaheadModule } from '../../../../ui-components/typeahead/typeahead.module';
import { TabsModule } from '../../../../ui-components/tabs/tabs.module';
import { NgIf, NgTemplateOutlet, NgClass } from '@angular/common';
import { TYPEHEAD_WAIT_TIME } from '../../../../constants/constants';

@Component({
    selector: 'app-search-header',
    templateUrl: './search-header.component.html',
    styleUrls: ['./search-header.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TabsModule,
        NgTemplateOutlet,
        NgClass,
        TypeaheadModule,
        ButtonsModule,
        SearchUnsupportedProductBannerComponent,
        SearchTypeaheadResultListComponent,
        SearchTypeaheadResultLoadingComponent,
        SearchTypeaheadNoResultComponent,
        MeetingRoomCapacityModalComponent,
    ],
})
export class SearchHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
    deviceInfo = this.utilsService.getDeviceInfo();
    config: ISearchHeaderConfig;
    isProductSupported = true;
    @Input('config') set updateConfig(data: ISearchHeaderConfig) {
        if (data) {
            const prevConfig = this.config;
            this.config = data;

            if (!this.utilsService.isArraysOfObjectsEqual(prevConfig?.products, this.config.products)) {
                this.updateProductTabs();
            }

            if (this.config.tabVariant && this.config.tabVariant !== this.headerTabsConfig.variant) {
                this.headerTabsConfig = {
                    ...this.headerTabsConfig,
                    variant: this.config.tabVariant,
                };
            }

            // checking if the product has changed
            if (prevConfig?.selectedProduct !== this.config.selectedProduct) {
                this.handleProductUpdate(data.selectedProduct);
            }
            if (this.config.popularLocalities) {
                this.generateLocalitiesTypeaheadLabel();
            }
            if (this.config.selectedOrdinaryLocalities) {
                this.searchTypeaheadConfig = {
                    ...this.searchTypeaheadConfig,
                    selectedItems: this.config.selectedOrdinaryLocalities.map(item => {
                        return {
                            _id: item.place_id,
                            title: item.display,
                        };
                    }),
                };
            }
        }
    }
    flexiPassIcon = this.sanitizer.bypassSecurityTrustHtml(FlexiPassIcon);
    fixedDeskIcon = this.sanitizer.bypassSecurityTrustHtml(FixedDeskIcon);
    virtualOfficeIcon = this.sanitizer.bypassSecurityTrustHtml(VirtualOfficeIcon);
    meetingRoomIcon = this.sanitizer.bypassSecurityTrustHtml(MeetingRoomIcon);

    selectedCity: ICity;
    localitiesTypeaheadPlaceholder = '';
    showLocalityPlaceholder = false;
    headerTabsConfig: ITabsConfig = {
        tabs: [],
        variant: TabVariant.CONTAINED,
        selectedTabIndex: -1,
        template: null,
    };
    tabLabelDataMap: Record<SearchTabLabels, ISearchTabItem> = {
        [SearchTabLabels.FLEXI]: {
            label: SearchTabLabels.FLEXI,
            icon: {
                url: this.flexiPassIcon,
                alt: SearchTabLabels.FLEXI,
            },
            product: productsMap[productKeys.FLEXI],
            ctaText: SearchTabCtaLabels.FLEXI,
        },
        [SearchTabLabels.DEDICATED]: {
            label: SearchTabLabels.DEDICATED,
            icon: {
                url: this.fixedDeskIcon,
                alt: SearchTabLabels.DEDICATED,
            },
            product: productsMap[productKeys.DEDICATED],
            ctaText: SearchTabCtaLabels.DEDICATED,
        },
        [SearchTabLabels.MEETING_ROOM]: {
            label: SearchTabLabels.MEETING_ROOM,
            icon: {
                url: this.meetingRoomIcon,
                alt: SearchTabLabels.MEETING_ROOM,
            },
            product: productsMap[productKeys.MEETING_ROOM],
            ctaText: SearchTabCtaLabels.MEETING_ROOM,
        },
        [SearchTabLabels.VIRTUAL_OFFICE]: {
            label: SearchTabLabels.VIRTUAL_OFFICE,
            icon: {
                url: this.virtualOfficeIcon,
                alt: SearchTabLabels.VIRTUAL_OFFICE,
            },
            product: productsMap[productKeys.VIRTUAL_OFFICE],
            ctaText: SearchTabCtaLabels.VIRTUAL_OFFICE,
        },
    };
    searchTypeaheadConfig: ITypeaheadConfig = {
        placeholder: 'Search location or workspace',
        typeaheadAsync: true,
        typeaheadScrollable: true,
        prefixIcon: PrefixIcon.SEARCH_ICON,
        typeaheadHideResultsOnBlur: true,
        typeaheadMinLength: 2,
        typeaheadWaitMs: TYPEHEAD_WAIT_TIME,
        typeaheadOptionField: 'name',
        visibleItemCountLimit: 2,
    };
    viewWorkspacesBtnConfig: IButtonConfig = {
        label: 'View Workspaces',
        type: buttonType.PRIMARY,
        size: fontSizes.LARGE,
    };
    typeaheadInitValue = '';
    typeaheadPlaceholderInterval;
    selectCitySub: Subscription;
    showMeetingRoomCapacityModal = false;
    meetingRoomCapacityModalConfig: IMeetingRoomCapacityModalConfig;
    meetingRoomCapacityModalViewStateSub: Subscription;
    @Output() onFocus: EventEmitter<ISearchHeaderOnFocusPayload> = new EventEmitter();
    @Output() onProductChange: EventEmitter<IProductMap> = new EventEmitter();
    @Output() onSearchInput: EventEmitter<string> = new EventEmitter();
    @Output() onOrdinaryLocationSelect: EventEmitter<ISearchLocation> = new EventEmitter();
    @Output() onRemoveSelectedItem? = new EventEmitter();
    @ViewChild('tabItem', { static: true }) tabItem: TemplateRef<any>;
    @ViewChild(TypeaheadComponent) typeaheadComponent: TypeaheadComponent;
    constructor(
        private selectCityService: SelectCityService,
        private utilsService: UtilsService,
        private sanitizer: DomSanitizer,
        private workspaceService: WorkspaceService,
        private router: Router,
        private locationService: LocationService,
        private meetingRoomsService: MeetingRoomsService,
        private applicationRef: ApplicationRef
    ) {}

    ngOnInit(): void {
        this.selectCitySub = this.selectCityService.getSelectedCitySync().subscribe(cityData => {
            if (cityData.city) {
                this.selectedCity = cityData.city;
                this.updateProductStatus(this.selectedCity);
                this.checkProductSupport(this.config.selectedProduct);
            }
        });
        this.meetingRoomCapacityModalViewStateSub = this.meetingRoomsService
            .getCapacityModalViewStateSub()
            .subscribe(data => {
                if (data) {
                    this.showMeetingRoomCapacityModal = data.show;

                    if (data.workspaceSlug) {
                        this.meetingRoomCapacityModalConfig = {
                            ...this.meetingRoomCapacityModalConfig,
                            workspaceSlug: data.workspaceSlug,
                        };
                    }
                }
            });
        this.updateSearchSuggestionListeners();
    }

    ngAfterViewInit() {
        this.headerTabsConfig = {
            ...this.headerTabsConfig,
            customTabTemplate: this.tabItem,
        };

        this.applicationRef.isStable.pipe(first(isStable => isStable)).subscribe(() => {
            if (this.deviceInfo.isBrowser) {
                setTimeout(() => {
                    this.typeaheadPlaceholderInterval = setInterval(() => {
                        this.showLocalityPlaceholder = !this.showLocalityPlaceholder;
                        this.updateTypeaheadPlaceholder();
                    }, 3000);
                }, 3000);
            }
        });
    }

    ngOnDestroy() {
        if (this.typeaheadPlaceholderInterval) {
            clearInterval(this.typeaheadPlaceholderInterval);
        }
        if (this.selectCitySub) {
            this.selectCitySub.unsubscribe();
        }
        this.meetingRoomCapacityModalViewStateSub?.unsubscribe();
    }

    handleTypeaheadFocus() {
        this.onFocus.emit({ expandedSection: SearchExpandedSection.DEFAULT, isSearchTypeaheadInFocus: true });
    }

    handleCitySelectorClick(event) {
        event.stopPropagation();
        this.onFocus.emit({ expandedSection: SearchExpandedSection.CITY_SELECTOR, isSearchTypeaheadInFocus: false });
    }

    getDefaultTypeaheadPlaceholder() {
        if (!this.isProductSupported) {
            return 'We are coming soon to your city...';
        } else {
            if (this.config.selectedProduct.productNameFrontend === productKeys.VIRTUAL_OFFICE) {
                if (this.selectedCity) {
                    return `Search workspaces in ${this.selectedCity.name}`;
                } else {
                    return 'Search workspaces';
                }
            } else if (this.config.selectedProduct.productNameFrontend === productKeys.MEETING_ROOM) {
                if (this.selectedCity) {
                    return `Search location in ${this.selectedCity.name}`;
                } else {
                    return 'Search location';
                }
            } else {
                if (this.selectedCity) {
                    return `Search location or workspaces in ${this.selectedCity.name}`;
                } else {
                    return 'Search location or workspaces';
                }
            }
        }
    }

    updateTypeaheadPlaceholder() {
        if (this.config.selectedOrdinaryLocalities && this.config.selectedOrdinaryLocalities.length > 0) {
            this.searchTypeaheadConfig = {
                ...this.searchTypeaheadConfig,
                placeholder: `Search more location or workspaces in ${this.selectedCity.name}`,
            };
        } else if (this.showLocalityPlaceholder && this.localitiesTypeaheadPlaceholder && this.isProductSupported) {
            this.searchTypeaheadConfig = {
                ...this.searchTypeaheadConfig,
                placeholder: this.localitiesTypeaheadPlaceholder,
            };
        } else {
            this.searchTypeaheadConfig = {
                ...this.searchTypeaheadConfig,
                placeholder: this.getDefaultTypeaheadPlaceholder(),
            };
        }
    }

    updateSearchSuggestionListeners() {
        switch (this.config.selectedProduct.productNameFrontend) {
            case productKeys.VIRTUAL_OFFICE:
                this.searchTypeaheadConfig.getDataAsObservable = (searchString: string) => {
                    return this.workspaceService
                        .searchWorkspace(
                            this.selectedCity._id,
                            searchString,
                            this.config.selectedProduct.productNameBackend
                        )
                        .pipe(
                            map(data => {
                                return data.workspaceSuggestions;
                            })
                        );
                };
                this.viewWorkspacesBtnConfig = {
                    ...this.viewWorkspacesBtnConfig,
                    label: 'View Workspaces',
                };
                break;
            case productKeys.MEETING_ROOM:
                this.searchTypeaheadConfig.getDataAsObservable = (searchString: string) => {
                    return this.locationService.getLocationAutocomplete(searchString, this.selectedCity?._id).pipe(
                        map(data => {
                            return data.locationSuggestions
                                ? data.locationSuggestions.map(location => ({
                                      ...location,
                                      isLocation: true,
                                      name: location.display,
                                  }))
                                : [];
                        })
                    );
                };
                this.viewWorkspacesBtnConfig = {
                    ...this.viewWorkspacesBtnConfig,
                    label: 'View Meeting Rooms',
                };
                break;
            default:
                this.searchTypeaheadConfig.getDataAsObservable = (searchString: string) => {
                    return this.workspaceService.getWorkspacesAndLocation(
                        searchString,
                        this.selectedCity?._id,
                        this.config.selectedProduct.productNameBackend
                    );
                };
        }
    }

    updateProductStatus(city: ICity) {
        const supportedProducts = city.products.map(product => {
            return product.key;
        });

        this.headerTabsConfig.tabs.forEach(tabItem => {
            const tabData: ISearchTabItem = this.tabLabelDataMap[tabItem.label];
            this.tabLabelDataMap[tabItem.label].comingSoon = !supportedProducts.includes(
                tabData.product.productNameBackend
            );
        });
    }

    updateProductTabs() {
        const productLabels: string[] = [];
        this.config.products.forEach(product => {
            const label = this.tabLabelDataMap[SearchTabLabels[product.productNameFrontend]].label;
            productLabels.push(label);
        });
        this.headerTabsConfig = {
            ...this.headerTabsConfig,
            tabs: productLabels.map(item => {
                return {
                    label: item,
                };
            }),
        };
    }

    generateLocalitiesTypeaheadLabel() {
        if (this.config.selectedProduct.productNameFrontend === productKeys.VIRTUAL_OFFICE) {
            this.localitiesTypeaheadPlaceholder = '';
        } else {
            if (this.config.popularLocalities.length) {
                this.localitiesTypeaheadPlaceholder = `Try: ${this.config.popularLocalities
                    .map(item => {
                        return item.display;
                    })
                    .slice(0, 3)
                    .join(', ')}...`;
            }
        }
    }

    handleProductUpdate(product: IProductMap) {
        const idx = this.getProductTabIndexByProduct(product.productNameFrontend);
        if (idx !== this.headerTabsConfig.selectedTabIndex) {
            this.updateTabIndex(idx);
        }
        this.checkProductSupport(product);

        // Reset to default placeholder when the product is changed
        this.searchTypeaheadConfig = {
            ...this.searchTypeaheadConfig,
            placeholder: this.getDefaultTypeaheadPlaceholder(),
        };
    }

    checkProductSupport(product: IProductMap) {
        if (this.selectedCity) {
            const supportedProducts = this.selectedCity.products.map(item => {
                return item.key;
            });
            this.isProductSupported = supportedProducts.includes(product.productNameBackend);

            this.viewWorkspacesBtnConfig = {
                ...this.viewWorkspacesBtnConfig,
                disabled: !this.isProductSupported,
            };
        }
    }

    updateTabIndex(index: number) {
        setTimeout(() => {
            this.headerTabsConfig = {
                ...this.headerTabsConfig,
                selectedTabIndex: Math.max(index, 0),
            };
        });
    }

    getProductTabIndexByProduct(productName: productKeys) {
        const label = this.tabLabelDataMap[SearchTabLabels[productName]].label;

        return this.headerTabsConfig.tabs.findIndex(tab => {
            return tab.label === label;
        });
    }

    getProductByIndex(index: number) {
        return this.headerTabsConfig.tabs[index];
    }

    handleTabUpdate(index: number) {
        const product = this.getProductByIndex(index);
        const productMap = this.tabLabelDataMap[product.label].product;
        this.updateTabIndex(index);
        this.viewWorkspacesBtnConfig = {
            ...this.viewWorkspacesBtnConfig,
            label: this.tabLabelDataMap[product.label].ctaText,
        };
        // Only emit product change event if the product in the config is different from what user has selected
        if (this.config.selectedProduct.productNameFrontend !== productMap.productNameFrontend) {
            this.onProductChange.emit(productMap);
        }
    }

    handleSearchStringChange(value) {
        this.onSearchInput.emit(value);
    }

    handleSearchItemSelect(item) {
        this.typeaheadComponent.clearValue();
        if (item.isLocation) {
            this.onOrdinaryLocationSelect.emit(item);
        } else {
            if (item.product === productKeys.MEETING_ROOM) {
                this.meetingRoomRedirect(item.redirectUrl);
            } else {
                window.open(item.redirectUrl);
            }
        }
    }

    toggleCapacityModal(show: boolean, workspaceData?: IMeetingRoomWorkspaceCardData) {
        this.meetingRoomsService.toggleCapacityModal(show, workspaceData?.slug);
    }

    meetingRoomRedirect(redirectUrl: string) {
        const parsedUrl = this.meetingRoomsService.parseMeetingRoomsSpaceDetailUrl(redirectUrl);
        if (parsedUrl.isValidUrl && !parsedUrl.capacity) {
            this.meetingRoomsService.toggleCapacityModal(true, parsedUrl.workspaceSlug);
        }
    }

    getListingUrl() {
        if (this.config.selectedProduct.listingUrlSlug) {
            return `${this.selectedCity.slug}/${this.config.selectedProduct.productBaseUrl}/${this.config.selectedProduct.listingUrlSlug}-in-${this.selectedCity.slug}`;
        } else {
            return `${this.selectedCity.slug}/${this.config.selectedProduct.productBaseUrl}/${this.selectedCity.slug}`;
        }
    }

    handleViewWorkspacesCTA() {
        const listingUrl = this.getListingUrl();
        const filters = {
            [CommonFilters.LOCALITIES]: [
                ...this.config.selectedPopularLocalities.map(item => {
                    return item.place_id;
                }),
                ...this.config.selectedOrdinaryLocalities.map(item => {
                    return item.place_id;
                }),
            ],
        };
        if (filters[CommonFilters.LOCALITIES].length) {
            this.router.navigate([listingUrl], {
                queryParams: {
                    filters: JSON.stringify(filters),
                },
            });
        } else {
            this.router.navigate([listingUrl]);
        }
    }

    handleRemoveSelectedItem(id: string) {
        this.onRemoveSelectedItem.emit(id);
    }
}
