<div class="d-flx flx-d-clm width-100 unsupported-product-banner-container">
    <div class="d-flx a-items-c width-100 banner-title-container">
        <div class="d-flx a-items-c j-cont-cen banner-icon-container">
            <img
                class="height-100 width-100 object-fit-contain"
                appImageShimmerLazyLoad
                [imageSrc]="CommonIconsUrl.SAD_EMOJI"
                alt="sad-emoji"
            />
        </div>
        <h3 class="banner-title text-color-dark font-bold">
            {{ title }}
        </h3>
    </div>
    <p class="banner-description text-color-dark-grey">
        Please select <span class="font-bold">another solution</span> or <span class="font-bold">change the city</span>.
        Or, get in touch with our experts to know more
    </p>
</div>
