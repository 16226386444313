import { UtilsService } from './../../../core/services/utils/utils.service';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { ImageLazyLoadHelperService, ImageSizes } from './image-lazy-load-helper.service';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Renderer2,
} from '@angular/core';
import { filter } from 'rxjs/operators';

@Directive({
    selector: 'img[appImageShimmerLazyLoad]',
})
export class ImageShimmerLazyLoadDirective implements OnInit, AfterViewInit {
    defaultLowResWidth = 64;
    imageLinkSubject = new ReplaySubject<any>(1);
    imageInViewSubject = new BehaviorSubject(false);

    defaultIntersectionOptions: IntersectionObserverInit = {
        root: null,
        rootMargin: '300px',
        threshold: 0,
    };

    private intersectionOptions = this.defaultIntersectionOptions;
    @Input('options') set _options(value: typeof this.defaultIntersectionOptions) {
        this.intersectionOptions = { ...this.defaultIntersectionOptions, ...value };
    }
    imageShimmerAdded = false;

    @HostBinding('attr.src') srcAttr = '//:0';
    @HostBinding('attr.loading') loadingAttr;

    @Input() isATFContent = false;
    @Input() imageWidthSize?: keyof typeof ImageSizes;

    @Input('imageSrc') set setImageSrc(value: string) {
        this.imageLinkSubject.next(value);
    }

    @HostListener('load')
    onLoad() {
        if (this.imageShimmerAdded) {
            this.imageLazyService.removeShimmerEffect(this.el, this.renderer2);
            this.imageShimmerAdded = false;
        }
    }

    constructor(
        private el: ElementRef,
        private cdRef: ChangeDetectorRef,
        private imageLazyService: ImageLazyLoadHelperService,
        private utilsService: UtilsService,
        private renderer2: Renderer2
    ) {}

    ngOnInit() {
        if (!this.isATFContent) {
            this.loadingAttr = 'lazy';
        } else {
            this.imageInViewSubject.next(true);
        }
    }

    ngAfterViewInit() {
        combineLatest([this.imageLinkSubject, this.imageInViewSubject])
            .pipe(filter(([imageLink, imageInView]) => imageLink))
            .subscribe(([imageLink, imageInView]) => {
                if (!imageInView) {
                    // Update src value with default image src and adding a container with shimmer effect
                    // this.srcAttr = '//:0';
                    this.imageLazyService.addShimmerEffect(this.el, this.renderer2);
                    this.imageShimmerAdded = true;
                } else {
                    // No chek for best fit images for vector images and images that are not on cloudinary... ie. google map images
                    if (imageLink?.endsWith('.svg') || !this.imageLazyService.checkIfImageLinkResponsive(imageLink)) {
                        this.srcAttr = imageLink;
                    } else {
                        // Get the first fit image in very first shot (i.e non progressively)
                        const imageAttributesObj: any = {};
                        if (this.imageWidthSize) {
                            imageAttributesObj.imageWidthSize = this.imageWidthSize;
                        } else if (this.el.nativeElement.clientWidth !== this.defaultLowResWidth) {
                            imageAttributesObj.clientWidth = this.el.nativeElement.clientWidth;
                        }
                        if (this.isATFContent && !this.imageWidthSize) {
                            console.warn(
                                `Missing required field 'imageWidthSize' for the 'above the fold image' ${imageLink}!`
                            );
                        }
                        const srcAttr = this.imageLazyService.applyAttributesOnLink(
                            imageLink,
                            imageAttributesObj,
                            this.isATFContent
                        );
                        if (srcAttr !== this.srcAttr) {
                            this.srcAttr = srcAttr;
                        }
                    }
                }
            });
        // load the image ASAP if the image is set to be in above the fold region
        if (this.isATFContent) {
            this.imageInViewSubject.next(true);
        } else if (this.utilsService.getDeviceInfo().isBrowser) {
            this.utilsService.isIntersectionObserverSupported()
                ? this.lazyLoadImage()
                : this.imageInViewSubject.next(true);
        }
        this.cdRef.detectChanges();
    }

    private lazyLoadImage() {
        const obs = new IntersectionObserver(entries => {
            entries.forEach(({ isIntersecting }) => {
                if (isIntersecting) {
                    this.imageInViewSubject.next(true);
                    obs.unobserve(this.el.nativeElement);
                }
            });
        }, this.intersectionOptions);
        obs.observe(this.el.nativeElement);
    }
}
