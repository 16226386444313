import { UtilsService } from './../../utils/utils.service';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class WebengageService {
    constructor(
        private utilsService: UtilsService,
    ) {}

    trackEvent(event: string, properties: object): any {
        // TODO :: we have to build queue on this 
        // We have to remove empty keys from object to pass in webengage else webengage will send an warning in console
        if(window.webengage) {
            window.webengage.track(event, this.utilsService.removeBlankAttributes(properties));
        }
    }

    identifyUser(uniqId, user): any {
        // here uniqueId is phone number
        if (user?.phone) {
            if(window.webengage) {
                window.webengage.user.login(uniqId);
            }
            if(window.webengage) {
                window.webengage.user.setAttribute('we_phone', JSON.stringify(user?.phone));
            }
        } if (user?.email) {
            if(window.webengage) {
                window.webengage.user.setAttribute('we_email', user?.email);
            }
        } if (user?.name) {
            if(window.webengage) {
                window.webengage.user.setAttribute('we_name', user?.name);
            }
        }
    }

    reset() {
        if(window.webengage) {
            window.webengage.user.logout();
        }
    }
}