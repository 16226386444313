<div [hidden]="!showLoader && !popularWorkspaces.length">
    <div *ngIf="heading" class="heading-cont">
        <div class="heading"><span [innerHTML]="trendIcon"></span> {{ heading }}</div>
    </div>
    <div class="popular-workspaces-cont d-flex flx-wrp">
        <ng-container
            [ngTemplateOutlet]="popularWorkspaceContent"
            *ngIf="!showLoader; else workspacePillShimmers"
        ></ng-container>
    </div>
</div>

<!--templates-->
<ng-template #workspacePillShimmers>
    <ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6]">
        <div class="shimmer workspace-pill-shimmer"></div>
    </ng-container>
</ng-template>

<ng-template #popularWorkspaceContent>
    <div
        class="d-flx a-items-start j-cont-cen workspace-pill c-pointer"
        *ngFor="let workspace of popularWorkspaces"
        (click)="handleWorkspacePillClick(workspace)"
    >
        <div class="d-flx a-items-c j-cont-cen workspace-pill-icon">
            <img
                class="height-100 width-100 object-fit-contain"
                appImageShimmerLazyLoad
                [imageSrc]="CommonIconsUrl.WORKSPACE"
                alt="workspace-icon"
            />
        </div>
        <div class="d-flx flx-d-clm workspace-pill-content">
            <div class="d-flx width-100 workspace-pill-name-container">
                <p class="font-12 text-color-dark-grey workspace-pill-name">{{ workspace.name }}</p>
                <p class="font-12 text-color-dark-grey workspace-pill-name" *ngIf="workspace.buildingName">
                    - {{ workspace.buildingName }}
                </p>
            </div>
            <p class="font-10 text-color-lighter-grey workspace-pill-location">
                {{ workspace.location }}, {{ workspace.city }}
            </p>
        </div>
    </div>
</ng-template>
