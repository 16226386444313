import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipeModule } from '../../../pipe/pipe.module';
import { ISearchWorkspace } from '../../../interfaces/workspace.interface';
import { CommonIconsUrl } from '../../../../core/constants/common-icons-url';

@Component({
    standalone: true,
    selector: 'app-workspace-search-list',
    templateUrl: './workspace-search-list.component.html',
    styleUrls: ['./workspace-search-list.component.scss'],
    imports: [CommonModule, DirectivesModule, PipeModule],
})
export class WorkspaceSearchListComponent implements OnInit {
    commonIconsUrl = CommonIconsUrl;

    @Input() searchedWorkspace: ISearchWorkspace;
    @Input() cssClass = '';
    constructor() {}

    ngOnInit() {}
}
