import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonIconsUrl } from '../../../../core/constants/common-icons-url';
import {
    LocationWorkspaceSearchComponent,
    LocationWorkspaceSearchConfig,
    LocationWorkspaceType,
} from '../location-workspace-search/location-workspace-search.component';
import {
    LocationWorkspaceSearchSuggestionType,
    PrefixIcon,
    productKeys,
    productsMap,
    TypeaheadTypes,
} from '../../../enums/enums';
import { ImageBaseUrl } from '../../../../core/constants/constants';
import { SelectCityService } from '../../../services/select-city/select-city.service';
import { LineBreakType, LinkWithIconComponent } from '../../../ui-components/link-with-icon/link-with-icon.component';
import { DomSanitizer } from '@angular/platform-browser';
// @ts-ignore
import SearchIcon from '!!raw-loader!svgo-loader!../../../../../assets/web/ssr/common/assets/search.svg';
import { ModalsModule } from '../../../ui-components/modals/modals.module';
import { CommonModule } from '@angular/common';
import { SelectCityModule } from '../../select-city/select-city.module';
import { NearMeComponent } from '../../near-me/near-me.component';
import { RecentSearchesService } from '../../../services/recent-searches/recent-searches.service';
import { LocationService } from '../../../services/location/location.service';
import { Router } from '@angular/router';
import { IProductMap } from '../../../interfaces/product-interface';
import { RecentlySearchedComponent } from '../../recently-searched/recently-searched.component';
import { PopularLocationsComponent } from '../../popular-locations/popular-locations.component';
import { PopularWorkspacesComponent } from '../../popular-workspaces/popular-workspaces.component';

export interface ILocationWorkspaceHeaderSearchMob {
    showMobileHeaderSearch: boolean;
    showProductSwitch: boolean;
}

@Component({
    standalone: true,
    selector: 'app-location-workspace-mobile-modal-trigger',
    templateUrl: './location-workspace-mobile-modal-trigger.component.html',
    styleUrls: ['./location-workspace-mobile-modal-trigger.component.scss'],
    imports: [
        ModalsModule,
        CommonModule,
        SelectCityModule,
        LocationWorkspaceSearchComponent,
        NearMeComponent,
        RecentlySearchedComponent,
        PopularLocationsComponent,
        PopularWorkspacesComponent,
        LinkWithIconComponent,
    ],
})
export class LocationWorkspaceMobileModalTriggerComponent implements OnInit, OnDestroy {
    showModal: boolean;
    isSearchStateOn: boolean;
    LocationWorkspaceSearchSuggestionType = LocationWorkspaceSearchSuggestionType;
    commonIconsUrl = CommonIconsUrl;
    selectedCity;
    selectCitySub;
    selectedProduct: IProductMap = productsMap.FLEXI;
    popularLocationCount = 6;
    lineBreakType = LineBreakType;
    popularLocationLabel = `Popular locations`;
    popularWorkspacesLabel = `Popular workspaces`;
    workspaces = {
        link: {
            url: '',
            text: 'Explore all coworking spaces',
        },
    };
    nearMeComponentConfig = {
        label: 'Show coworking spaces near me',
    };
    searchIcon = this.sanitizer.bypassSecurityTrustHtml(SearchIcon);
    showSearchResultTemplate = true;
    @Output() locationWorkspaceSelectEvent = new EventEmitter();

    @Input() config?: ILocationWorkspaceHeaderSearchMob;
    private _locationWorkspaceSearchConfig: LocationWorkspaceSearchConfig;
    @Input() public set locationWorkspaceSearchConfig(updatedValue: LocationWorkspaceSearchConfig) {
        const updatedTypeaheadConfig = updatedValue.typeaheadConfig || {
            placeholder: '',
        };
        this._locationWorkspaceSearchConfig = {
            ...updatedValue,
            showStaticResultByDefault: true,
            setFocusOnInit: true,
            type: LocationWorkspaceType.DEFAULT_SEARCH,
            typeaheadConfig: {
                ...updatedTypeaheadConfig,
                type: TypeaheadTypes.DEFAULT,
                prefixIcon: PrefixIcon.LEFT_ICON,
            },
            typeaheadContainerClass: 'mobile-modal-search-typeahead-cont',
        };
        if (updatedValue.product) {
            this.selectedProduct = updatedValue.product;
        }
        this.productButtons = this.productButtons.map(productBtn => {
            productBtn.selected = productBtn.id === updatedValue.product?.productBaseUrl;
            return productBtn;
        });
        this.updateComponentConfigs(this.selectedCity);
        this.checkRecentWorkspaceSearches();
    }

    public get locationWorkspaceSearchConfig() {
        return this._locationWorkspaceSearchConfig;
    }

    constructor(
        private selectCityService: SelectCityService,
        private sanitizer: DomSanitizer,
        private recentSearchesService: RecentSearchesService,
        private locationService: LocationService,
        private router: Router
    ) {}

    productButtons = [
        {
            id: productsMap.FLEXI.productBaseUrl,
            product: productsMap.FLEXI,
            text: 'Flexi Desk',
            selected: false,
            imageDarkUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/home/hero/flexi-black.svg`,
            imageWhiteUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/home/hero/flexi-white.svg`,
        },
        {
            id: productsMap.DEDICATED.productBaseUrl,
            product: productsMap.DEDICATED,
            text: 'Fixed Desk',
            selected: true,
            imageDarkUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/home/hero/dedicated-black.svg`,
            imageWhiteUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/home/hero/dedicated-white.svg`,
        },
    ];
    productTitles = {
        FLEXI: 'Flexi Desk',
        DEDICATED: 'Fixed Desk',
    };

    toggleModal(value: boolean) {
        this.showModal = value;
    }

    toggleSearchState(value: boolean) {
        this.isSearchStateOn = value;
    }

    onLocationWorkspaceSelectEvent(payload) {
        this.locationWorkspaceSelectEvent.emit(payload);
        this.toggleModal(false);
    }

    defaultLocationWorkspaceSelectEvent(selectedItem, isSearchSuggestion = false) {
        this.locationWorkspaceSelectEvent.emit({
            selectedItem,
            city: this.selectedCity,
            product: this._locationWorkspaceSearchConfig.product,
            isSearchSuggestion,
        });
        this.toggleModal(false);
    }

    handleNearMeClick(selectedItem) {
        this.locationService
            .getLocationFromCoordinates(
                {
                    latitude: selectedItem.latitude,
                    longitude: selectedItem.longitude,
                },
                this.locationWorkspaceSearchConfig.product.productNameBackend
            )
            .subscribe(
                locationData => {
                    if (locationData?.isSupported) {
                        this.router.navigateByUrl(locationData.location.redirectUrl);
                    } else {
                        this.locationService.setLocationCoordinatesSubData({
                            data: locationData,
                            product: this.locationWorkspaceSearchConfig.product,
                        });
                    }
                },
                () => {
                    this.toggleModal(false);
                },
                () => {
                    this.toggleModal(false);
                }
            );
    }

    switchProduct(selectedProductObj: any) {
        this.selectedProduct = selectedProductObj;
        this.productButtons = this.productButtons.map(productBtn => {
            productBtn.selected = productBtn.id === selectedProductObj.id;
            return productBtn;
        });
        this.locationWorkspaceSearchConfig = {
            ...this.locationWorkspaceSearchConfig,
            product: selectedProductObj.product,
            showStaticResultByDefault: true,
            setFocusOnInit: true,
        };
    }

    ngOnInit() {
        this.selectCitySub = this.selectCityService.getSelectedCitySync().subscribe(({ city }) => {
            if (city) {
                this.selectedCity = city;
                this.updateComponentConfigs(this.selectedCity);
            }
        });
    }

    updateComponentConfigs(city) {
        // Updating the workspaces component config
        if (city && this._locationWorkspaceSearchConfig && this._locationWorkspaceSearchConfig.product) {
            // Update labels
            this.popularLocationLabel = `Popular locations in ${city.name}`;
            // Update links
            switch (this._locationWorkspaceSearchConfig.product.productNameBackend) {
                case productsMap.VIRTUAL_OFFICE.productNameBackend: {
                    this.workspaces = {
                        ...this.workspaces,
                        link: {
                            ...this.workspaces.link,
                            text: `Explore all virtual offices in ${city.name}`,
                            url: `/${city.slug}/${this._locationWorkspaceSearchConfig.product.productBaseUrl}/virtual-office-in-${city.slug}`,
                        },
                    };
                    break;
                }
                default: {
                    this.workspaces = {
                        ...this.workspaces,
                        link: {
                            ...this.workspaces.link,
                            text: `Explore all coworking spaces in ${city.name}`,
                            url: `/${city.slug}/${this._locationWorkspaceSearchConfig.product.productBaseUrl}/coworking-space-in-${city.slug}`,
                        },
                    };
                }
            }
        }
        // Updating the NearMeComponent Config
        switch (this.selectedProduct?.productNameBackend) {
            case productsMap.VIRTUAL_OFFICE.productNameBackend: {
                this.nearMeComponentConfig = {
                    label: 'Show virtual offices near me',
                };
                break;
            }
            case productsMap.MEETING_ROOM.productNameBackend: {
                this.nearMeComponentConfig = {
                    label: 'Show meeting rooms near me',
                };
                break;
            }
            default: {
                this.nearMeComponentConfig = {
                    label: 'Show coworking spaces near me',
                };
            }
        }
    }

    iconClickEvent() {
        this.toggleSearchState(false);
    }

    checkRecentWorkspaceSearches() {
        const searchHistory = this.recentSearchesService.getRecentSearches().filter(searchItem => {
            return !searchItem.isLocation;
        });

        this.showSearchResultTemplate = !!searchHistory?.length;
    }

    ngOnDestroy() {
        this.selectCitySub.unsubscribe();
    }
}
