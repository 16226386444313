import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth/auth.service';
import { AuthStatus } from '../../modules/auth/auth.enum';
import { Router } from '@angular/router';
import { flexiUrlRegex, meetingRoomsRegex, virtualOfficeUrlRegex } from '../constants/urls-regex';
import { UtilsService } from '../services/utils/utils.service';
import { MobileOtpAuthService } from '../../shared/services/auth/mobile-otp-auth.service';
import { SnackbarService } from '../../shared/standalone-components/snackbar/services/snackbar.service';
import { SnackbarType } from '../../shared/standalone-components/snackbar/snackbar.enum';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private utilsService: UtilsService,
        private authService: AuthService,
        private snackbarService: SnackbarService,
        private mobileOtpAuthService: MobileOtpAuthService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authReq = req.clone({
            withCredentials: true,
        });

        return next.handle(authReq).pipe(
            catchError(response => {
                if (response.status === 401) {
                    const url = this.router.routerState.snapshot.url;
                    if (this.utilsService.getRegexPatternMatch([virtualOfficeUrlRegex.allUrls], url) >= 0) {
                        this.authService.getAuthStatusSub().pipe(
                            tap(status => {
                                if (status === AuthStatus.AUTHENTICATED) {
                                    this.authService.logout();
                                    this.authService.setAuthStatus(AuthStatus.UNAUTHENTICATED);
                                    this.authService.setUserData(null);
                                    this.showErrorToast();
                                }
                            })
                        );
                    } else if (
                        this.utilsService.getRegexPatternMatch([flexiUrlRegex.allUrls], url) >= 0 ||
                        this.utilsService.getRegexPatternMatch([meetingRoomsRegex.allUrls], url) >= 0
                    ) {
                        this.mobileOtpAuthService.getAuthStatusSub().pipe(
                            tap(status => {
                                if (status === AuthStatus.AUTHENTICATED) {
                                    this.mobileOtpAuthService.logout();
                                    this.mobileOtpAuthService.setAuthStatus(AuthStatus.UNAUTHENTICATED);
                                    this.mobileOtpAuthService.setUserData(null);
                                    this.showErrorToast();
                                }
                            })
                        );
                    }
                }

                return throwError(() => response);
            })
        );
    }

    showErrorToast() {
        this.snackbarService.open({
            type: SnackbarType.WARNING,
            title: 'Session Expired',
            description: 'Your current session has been expired, please login again',
        });
    }
}
