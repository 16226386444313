import { Injectable, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

@Injectable({
    providedIn: 'root',
})
export class DateTimeService implements OnInit {
    constructor() {}

    ngOnInit() {}

    getDateTimeFormat(date, format) {
        return dayjs(date).format(format);
    }

    isToday(date) {
        const dayDiff = dayjs(date).diff(dayjs().startOf('day'), 'days');
        return dayDiff === 0;
    }

    getDayNameFromDayIndex(weekDayIndex) {
        return dayjs()
            .day(+weekDayIndex % 7)
            .format('dddd');
    }

    getDateISOString(date: Date | string) {
        return dayjs(date).toISOString();
    }

    getTodayISOString() {
        return dayjs(dayjs(), 'en-IN').startOf('day').toISOString();
    }

    getStartOfISOString(date?: Date) {
        if (date) {
            return dayjs(date, 'en-IN').startOf('day').toISOString();
        }
        return dayjs('en-IN').startOf('day').toISOString();
    }
}
