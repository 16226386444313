import { ModalModule as BootstrapModalsModule } from 'ngx-bootstrap/modal';
import { NgModule } from '@angular/core';
import { ModalsComponent } from './modals.component';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ModalFooterComponent } from './components/modal-footer/modal-footer.component';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
    declarations: [ModalsComponent, ModalHeaderComponent, ModalFooterComponent],
    imports: [CommonModule, BootstrapModalsModule.forRoot(), DirectivesModule],
    exports: [ModalsComponent, ModalHeaderComponent, ModalFooterComponent],
})
export class ModalsModule {}
