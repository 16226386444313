import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface IFullscreenConfig {
    hideCommonHeader?: boolean;
    hideCommonFooter?: boolean;
    hideTopNotification?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class FullscreenService {
    private isFullscreen: BehaviorSubject<IFullscreenConfig> = new BehaviorSubject({});

    setFullscreenConfig(config: IFullscreenConfig): void {
        this.isFullscreen.next(config);
    }

    get fullscreenDetail(): Observable<IFullscreenConfig> {
        return this.isFullscreen.asObservable();
    }

    disableFullscreen(): void {
        this.isFullscreen.next({});
    }
}
