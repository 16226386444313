import { UtilsService } from '../utils/utils.service';
import { Injectable } from '@angular/core';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { UrlUtilsService } from '../utils/url-utils.service';
import { environment } from '../../../../environments/environment';

const UTMTrackingKeys = {
    SOURCE: 'utm_source',
    CAMPAIGN: 'utm_campaign',
    MEDIUM: 'utm_medium',
    CHANNEL: 'utm_channel',
    KEYWORD: 'utm_keyword',
    CREATIVE_ID: 'utm_creative',
    GCLID: 'gclId',
    ADGROUP: 'utm_adgroup',
    LEAD_ACTIVATION: 'utm_lead_activation',
};

@Injectable({
    providedIn: 'root',
})
export class CampaignTrackingService {
    constructor(
        private utilsService: UtilsService,
        private storageService: StorageService,
        private urlUtilsService: UrlUtilsService
    ) {}

    private getNormalizedQueryParams() {
        const queryParams = this.utilsService.getQueryParams().value;
        const parsedQueryParams = {};
        Object.keys(queryParams).forEach(key => {
            parsedQueryParams[key.toLowerCase()] = queryParams[key];
        });
        return parsedQueryParams;
    }

    setCampaignCookies() {
        const shouldUpdateCampaignCookies = this.shouldUpdateCampaignCookies();
        if (shouldUpdateCampaignCookies) {
            const queryParams = this.getNormalizedQueryParams();
            Object.values(UTMTrackingKeys).forEach((cookieUtmKey: string) => {
                const normalizedCookieKey = cookieUtmKey.toLowerCase();
                if (this.storageService.checkCookie(normalizedCookieKey)) {
                    this.storageService.removeCookie(normalizedCookieKey, '/', `.${environment.domain}`);
                }
                if (queryParams && queryParams[normalizedCookieKey]) {
                    let finalValue = queryParams[normalizedCookieKey];
                    if (Array.isArray(finalValue)) {
                        finalValue = finalValue[finalValue.length - 1];
                    }
                    this.storageService.setCookie(normalizedCookieKey, finalValue, 1, '/');
                }
            });
        }
    }

    shouldUpdateCampaignCookies(): boolean {
        let shouldUpdateCampaignCookies = false;
        const queryParams = this.getNormalizedQueryParams();
        for (const utmKey of Object.values(UTMTrackingKeys)) {
            const normalizedCookieKey = utmKey.toLowerCase();
            if (queryParams && queryParams[normalizedCookieKey]) {
                shouldUpdateCampaignCookies = true;
                break;
            }
        }
        return shouldUpdateCampaignCookies;
    }

    updatePayloadForTracking(payload: any) {
        return {
            ...payload,
            trackingData: {
                landingUrl: this.urlUtilsService.getCurrentUrl(),
            },
        };
    }
}
