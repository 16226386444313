import { Injectable } from '@angular/core';
import { citySlug } from '../enums/enums';
import { ImageBaseUrl } from '../../core/constants/constants';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { IHttpResponse } from '../interfaces/HTTPResponse.interface';
import { ErrorService } from '../../core/services/error/error.service';
import {
    IVOAutocompleteWorkspaceDetails,
    IVOCompareWorkspaceDetails,
    IVODocumentsProvided,
    IVOPlansAndTenureFilers,
    IVOPlansAPIResponse,
} from '../../modules/virtual-office/virtual-office.interface';

@Injectable({
    providedIn: 'root',
})
export class VirtualOfficeService {
    constructor(
        private http: HttpClient,
        private errorService: ErrorService
    ) {}

    whyMyhq = {
        heading: 'What does myHQ guarantee in your Virtual Office?',
        description: `We make your office-search, a hassle-less experience.
    With an in depth knowledge, let our workspace solution experts find what you’re looking for.`,
        benefits: [
            {
                image: {
                    url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/lowest-price-flat.svg`,
                    alt: `Lowest Price Guarantee`,
                },
                heading: 'Lowest Price Guarantee',
                description:
                    'Get an additional 5% discount if you can find a cheaper solution at the same partner space',
            },
            {
                image: {
                    url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/turnaround-v2-flat.svg`,
                    alt: `72-hour Document TAT`,
                },
                heading: 'Fastest Document Turnaround Time',
                description: 'Our average document delivery time is just 5 to 7 working days.',
            },
            {
                image: {
                    url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/largest-coverage-flat.svg`,
                    alt: `Largest Coverage`,
                },
                heading: 'Largest Coverage',
                description: `Strong network of partner spaces pan-India. Own a virtual office in any major city.`,
            },
            {
                image: {
                    url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/flexible-plan-v2-flat.svg`,
                    alt: `hassle free`,
                },
                heading: 'Hassle free experience. No follow-ups',
                description: '100% digital process with no running around for compliances or unnecessary calls',
            },
        ],
    };

    getWhyMyhqConfig() {
        return this.whyMyhq;
    }

    getPlanCardData(payload?: { citySlug?: string }) {
        let url = `${environment.baseUrl}/virtual-office/web/plans`;
        if (payload && payload.citySlug) {
            url += `?citySlug=${payload.citySlug}`;
        }
        return this.http.get<IHttpResponse<IVOPlansAPIResponse>>(url).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in VO Listing plans API');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    getVirtualOfficeCityLink(city) {
        let linkUrl = `https://virtualoffice.myhq.in/collections/virtual-office-in-india`;
        switch (city) {
            case citySlug.BANGALORE:
                linkUrl = `https://virtualoffice.myhq.in/collections/virtual-office-in-bangalore`;
                break;
            case citySlug.NEW_DELHI:
                linkUrl = `https://virtualoffice.myhq.in/collections/virtual-office-in-delhi`;
                break;
            case citySlug.NOIDA:
                linkUrl = `https://virtualoffice.myhq.in/collections/virtual-office-in-noida`;
                break;
            case citySlug.GURUGRAM:
                linkUrl = `https://virtualoffice.myhq.in/collections/virtual-office-in-gurgaon`;
                break;
            case citySlug.MUMBAI:
                linkUrl = `https://virtualoffice.myhq.in/collections/virtual-office-in-mumbai`;
                break;
            case citySlug.HYDERABAD:
                linkUrl = `https://virtualoffice.myhq.in/collections/virtual-office-in-hyderabad`;
                break;
            case citySlug.CHENNAI:
                linkUrl = `https://virtualoffice.myhq.in/collections/virtual-office-in-chennai`;
                break;
            case citySlug.AHMEDABAD:
                linkUrl = `https://virtualoffice.myhq.in/collections/virtual-office-in-ahmedabad`;
                break;
            case citySlug.KOLKATA:
                linkUrl = `https://virtualoffice.myhq.in/collections/virtual-office-in-kolkata`;
                break;
            case citySlug.PUNE:
                linkUrl = `https://virtualoffice.myhq.in/collections/virtual-office-in-pune`;
                break;
        }
        return linkUrl;
    }

    getCompareWorkspaceDetails(workspaceSlug: string, voPlan: string, paymentCycle: string) {
        const url = `${environment.baseUrl}/virtual-office/web/comparison/workspacedetails/${workspaceSlug}?paymentCycle=${paymentCycle}&voPlan=${voPlan}`;
        return this.http.get<IHttpResponse<IVOCompareWorkspaceDetails>>(url).pipe(
            map(res => {
                if (res.success) {
                    return res.data;
                } else {
                    return null;
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    getVOPlansAndTenureFilters() {
        const url = `${environment.baseUrl}/virtual-office/web/comparison/filters`;
        return this.http.get<IHttpResponse<IVOPlansAndTenureFilers>>(url).pipe(
            map(res => {
                return res;
            }),
            catchError(this.errorService.handleError)
        );
    }

    getDocumentsProvidedList() {
        const url = `${environment.baseUrl}/virtual-office/web/info/documentsProvided`;
        return this.http.get<IHttpResponse<IVODocumentsProvided>>(url).pipe(
            map(res => {
                return res;
            }),
            catchError(this.errorService.handleError)
        );
    }

    getWorkspaceAutocomplete(payload: {
        voPlan: string;
        paymentCycle: string;
        citySlug: string;
        searchString?: string;
    }) {
        const url = `${environment.baseUrl}/virtual-office/web/workspaces/autocomplete`;
        return this.http
            .post<IHttpResponse<IVOAutocompleteWorkspaceDetails>>(url, {
                selectedFilters: {
                    SOLUTION: payload.voPlan,
                    paymentCycle: payload.paymentCycle,
                },
                citySlug: payload.citySlug,
                searchString: payload.searchString ?? '',
            })
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.errorService.handleError)
            );
    }
}
