<div class="d-flx typeahead-field" [ngClass]="cssConfig[config?.type]">
    <div
        class="search-icon-container d-flx a-items-c svg-cont c-pointer"
        *ngIf="config?.prefixIcon"
        (click)="onIconClickEvent()"
        [innerHTML]="prefixIconMap[config?.prefixIcon]"
    ></div>
    <ng-container *ngTemplateOutlet="selectedValuesTemplate" />
    <input
        [(ngModel)]="searchString"
        (ngModelChange)="typeaheadChangeHandler($event)"
        [placeholder]="config?.placeholder"
        [typeahead]="suggestions$"
        [typeaheadMinLength]="config?.typeaheadMinLength"
        [typeaheadAsync]="config?.typeaheadAsync"
        [typeaheadItemTemplate]="listTemplate"
        [typeaheadOptionField]="config?.typeaheadOptionField"
        [typeaheadScrollable]="config?.typeaheadScrollable"
        [typeaheadOptionsInScrollableView]="config?.typeaheadOptionsInScrollableView"
        [typeaheadHideResultsOnBlur]="config?.typeaheadHideResultsOnBlur"
        (typeaheadOnSelect)="select($event)"
        (focus)="toggleFocusAndInitializeResults()"
        [container]="config.typeaheadContainer"
        [isAnimated]="!this.isIOSSafari()"
        (typeaheadLoading)="changeTypeaheadLoading($event)"
        (typeaheadNoResults)="typeaheadNoResults($event)"
        [typeaheadWaitMs]="config?.typeaheadWaitMs"
        class="form-control"
        #typeaheadInput
        role="combobox"
    />
    <div class="c-pointer d-flx clear" *ngIf="searchString.length && config?.showCross" (click)="clearValue()">
        <img [src]="CommonIconsUrl.CLOSE_CIRCLE_DARK" alt="clear" />
    </div>
</div>
<div *ngIf="typeaheadLoading && isSearchModalInFocus">
    <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
</div>
<ng-container *ngIf="noResult && searchString.length && !typeaheadLoading && isSearchModalInFocus">
    <ng-container *ngTemplateOutlet="noResultTemplate"></ng-container>
</ng-container>

<ng-template #selectedValuesTemplate>
    <div class="d-flx a-items-c selected-items-container margin-right-8" *ngIf="config.selectedItems?.length">
        <div *ngIf="config.selectedItems.length <= config?.visibleItemCountLimit; else elseBlock" class="d-flx gap-4">
            <div *ngFor="let item of this.config.selectedItems">
                <ng-container
                    [ngTemplateOutlet]="selectedValueTemplate"
                    [ngTemplateOutletContext]="{ title: item.title, id: item._id, showCrossIcon: true }"
                ></ng-container>
            </div>
        </div>
        <ng-template #elseBlock>
            <div class="d-flx gap-4">
                <ng-container
                    [ngTemplateOutlet]="selectedValueTemplate"
                    [ngTemplateOutletContext]="{
                        title: this.config.selectedItems[0].title,
                        id: this.config.selectedItems[0]._id,
                        showCrossIcon: true,
                    }"
                ></ng-container>
                <ng-container
                    [ngTemplateOutlet]="selectedValueTemplate"
                    [ngTemplateOutletContext]="{
                        title: selectedMoreTitle,
                        id: '',
                        showCrossIcon: false,
                    }"
                ></ng-container>
            </div>
        </ng-template>
    </div>
</ng-template>

<ng-template #selectedValueTemplate let-title="title" let-id="id" let-showCrossIcon="showCrossIcon">
    <div class="d-flx a-items-c j-cont-cen pill selected">
        <p class="font-12 font-normal text-color-dark-grey text-ellipsis selected-title">{{ title }}</p>
        <div
            *ngIf="showCrossIcon"
            class="selected-cross-icon d-flx a-items-c j-cont-cen c-pointer"
            [innerHTML]="crossIcon"
            (click)="handleRemoveSelectedItem(id)"
            role="button"
        ></div>
    </div>
</ng-template>
