import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ICalendarConfig } from './calendar.interface';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { NgIf } from '@angular/common';

@Component({
    standalone: true,
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    imports: [BsDatepickerModule, NgIf],
    styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
    isBrowser = this.utilsService.getDeviceInfo().isBrowser;
    config: ICalendarConfig;
    datepickerConfig: Partial<BsDatepickerConfig> = {
        containerClass: 'theme-dark-blue custom-datepicker',
        showWeekNumbers: false,
        selectFromOtherMonth: true,
    };
    @Input('config') set updateCalendarConfig(data: ICalendarConfig) {
        if (data) {
            this.config = data;
            this.datepickerConfig = {
                ...this.datepickerConfig,
                ...this.config,
            };
        }
    }
    @Output() dateSelectedEvent: EventEmitter<Date> = new EventEmitter();

    isFirstValue = true;

    constructor(private utilsService: UtilsService) {}

    ngOnInit() {}

    handleSelectedDateChange(event) {
        if (this.isFirstValue) {
            // NOTE: Ignoring the first value produced by the calendar component
            // Reason: The change event is also triggered on the first render, which is unecessary and can cause bugs
            // when we want to run additional logic when the calendar value changes
            // (example - close date-filter popup on change)
            this.isFirstValue = false;
            return;
        }

        this.dateSelectedEvent.emit(event);
    }
}
