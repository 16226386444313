import { PrefixIcon, TypeaheadTypes } from '../enums/enums';

export interface ITypeaheadConfig {
    type?: TypeaheadTypes;
    prefixIcon?: PrefixIcon;
    placeholder: string;
    dataSource?: any;
    typeaheadAsync?: boolean;
    typeaheadScrollable?: boolean;
    typeaheadOptionsInScrollableView?: number;
    typeaheadWaitMs?: number;
    getDataAsObservable?;
    typeaheadOptionField?: string;
    typeaheadContainer?: any;
    typeaheadHideResultsOnBlur?: boolean;
    showCross?: boolean;
    typeaheadMinLength?: number;
    scrollTypeaheadDropDown?: boolean;
    selectedItems?: ITypeaheadItem[];
    visibleItemCountLimit?: number;
}

export const TYPEAHEAD_MIN_LENGTH = 2;
export interface ITypeaheadItem {
    _id: string;
    title: string;
}
