import { Injectable } from '@angular/core';
import { UtilsService } from '../utils/utils.service';
import { AnalyticsBaseService } from './base/analytics-base.service';
import { MockAnalyticsBaseService } from './base/mock-analytics-base.service';
import {
    AnalyticsAPIs,
    AppDownloadFunnelStages,
    AutosuggestEvent,
    CompanyNameSearchEvents,
    CompanyRegistrationEvents,
    ContactUsFormFunnelStages,
    DedicatedSpaceVisitEvents,
    DLFormFunnelStages,
    DVisitFormFunnelStages,
    ExitIntentFormEvents,
    FlexiAppDownloadEvents,
    FlexiBookingEvents,
    FlexiSubFunnelStages,
    FlexiTrialFunnelStages,
    GstRegistrationEvents,
    HeaderFunnelStages,
    HomeFormFunnelStages,
    ManagedOfficeEvents,
    MeetingRoomFormFunnelStages,
    MeetingRoomLFormFunnelStages,
    MobileOtpFormFunnelStages,
    MOFormFunnelStages,
    SpaceDetailPageEvents,
    SpecificPageViewedEvents,
    TeamFlexiLFormFunnelStages,
    UserReviewsEvents,
    VirtualOfficeEvents,
    VOCompareWorkspaceEvents,
    VOPaymentEvents,
    VOSpaceDetailEvents,
    VOSpaceListingEvents,
    VPOBEvents,
} from './enums';
import { IPageViewPayload, ITrackEventProperties } from './interfaces';
import { environment } from '../../../../environments/environment';
import { AnalyticsBaseInterface } from './base/analytics-base.interface';
import { DedicatedFormPlanType } from '../../../shared/app-components/form/dedicated-form/dedicated-form.interface';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private analyticsBase: AnalyticsBaseInterface;

    set useMockService(value: boolean) {
        this.analyticsBase = value ? this.mockAnalyticsBaseService : this.analyticsBaseService;
    }

    constructor(
        private analyticsBaseService: AnalyticsBaseService,
        private mockAnalyticsBaseService: MockAnalyticsBaseService,
        private utilsService: UtilsService
    ) {
        this.useMockService = !environment.production || environment.env === 'qa';
    }

    subscribeToRouterEvents() {
        this.utilsService.getRouterEvents$().subscribe(val => {
            this.analyticsBase.trackPageNavigationEvent(val, { [AnalyticsAPIs.WEBENGAGE]: false });
        });
    }

    identifyUser(uniqId, data) {
        this.analyticsBase.identifyUser(uniqId, data);
    }

    resetOnLogout() {
        this.analyticsBase.resetOnLogout();
    }

    trackCityOfUser(data) {
        this.analyticsBase.trackGenericProperties({ cityName: data.name });
    }

    trackAutoSuggestSearch(data) {
        this.analyticsBase.trackEvent(AutosuggestEvent.SEARCH, data, { [AnalyticsAPIs.WEBENGAGE]: true });
    }

    trackAutoSuggestWorkspaceSearch(data) {
        this.analyticsBase.trackEvent(
            AutosuggestEvent.SEARCH,
            { ...data, type: 'WORKSPACE' },
            { [AnalyticsAPIs.WEBENGAGE]: true }
        );
    }

    trackAutoSuggestLocationSearch(data) {
        this.analyticsBase.trackEvent(
            AutosuggestEvent.SEARCH,
            { ...data, type: 'LOCATION' },
            { [AnalyticsAPIs.WEBENGAGE]: true }
        );
    }

    trackWorkspacePageView(data) {
        this.analyticsBase.trackEvent(SpecificPageViewedEvents.WORKSPACE_VIEWED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackListingPagePageView(data) {
        // TODO: Set up Location Facebook Remarketing
        this.analyticsBase.trackEvent(SpecificPageViewedEvents.LISTING_PAGE_VIEWED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackFlexiProductPageView(data) {
        this.analyticsBase.trackEvent(SpecificPageViewedEvents.FLEXI_PRODUCT_VIEWED, data);
    }

    trackDedicatedProductPageView(data) {
        this.analyticsBase.trackEvent(SpecificPageViewedEvents.DEDICATED_PRODUCT_VIEWED, data);
    }

    trackTeamFlexiProductPageView(data) {
        this.analyticsBase.trackEvent(SpecificPageViewedEvents.TEAMFLEXI_PRODUCT_VIEWED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackVirtualOfficeProductPageView(data) {
        this.analyticsBase.trackEvent(SpecificPageViewedEvents.VO_PRODUCT_VIEWED, data);
    }

    trackManagedOfficeLandingPageView(data) {
        this.analyticsBase.trackEvent(SpecificPageViewedEvents.MO_LANDING_VIEWED, data);
    }

    trackTestimonialsPageView(data) {
        this.analyticsBase.trackEvent(SpecificPageViewedEvents.TESTIMONIALS_PAGE_VIEWED, data);
    }

    trackCaseStudiesPageView(data) {
        this.analyticsBase.trackEvent(SpecificPageViewedEvents.CASE_STUDIES_PAGE_VIEWED, data);
    }

    // VO SPACE DETAILS
    trackVirtualOfficeSpaceDetailGetQuoteFormOpen(data) {
        this.analyticsBase.trackEvent(VOSpaceDetailEvents.VO_SD_GET_QUOTE_FORM_OPEN, data);
    }

    trackVirtualOfficeSpaceDetailGetQuoteFormClose(data) {
        this.analyticsBase.trackEvent(VOSpaceDetailEvents.VO_SD_GET_QUOTE_FORM_CLOSE, data);
    }

    trackVirtualOfficeSpaceDetailGetQuoteFormSubmit(data) {
        this.analyticsBase.trackEvent(VOSpaceDetailEvents.VO_SD_GET_QUOTE_FORM_SUBMIT, data);
    }

    // Compare Workspaces Events
    trackVirtualOfficeCompareWorkspaceGetQuoteFormOpen(data) {
        this.analyticsBase.trackEvent(VOCompareWorkspaceEvents.VO_CW_GET_QUOTE_FORM_OPEN, data);
    }

    trackVirtualOfficeCompareWorkspaceGetQuoteFormClose(data) {
        this.analyticsBase.trackEvent(VOCompareWorkspaceEvents.VO_CW_GET_QUOTE_FORM_CLOSE, data);
    }

    trackVirtualOfficeCompareWorkspaceGetQuoteFormSubmit(data) {
        this.analyticsBase.trackEvent(VOCompareWorkspaceEvents.VO_CW_GET_QUOTE_FORM_SUBMIT, data);
    }

    trackVirtualOfficeCompareWorkspacePageViewed(data) {
        this.analyticsBase.trackEvent(VOCompareWorkspaceEvents.CW_PAGE_VIEWED, data);
    }

    trackVirtualOfficeCompareWorkspaceInit(data) {
        this.analyticsBase.trackEvent(VOCompareWorkspaceEvents.CW_INIT, data);
    }

    trackVirtualOfficeCompareWorkspaceAddWorkspace(data) {
        this.analyticsBase.trackEvent(VOCompareWorkspaceEvents.ADD_TO_COMPARE, data);
    }

    trackVirtualOfficeCompareWorkspaceRemoveWorkspace(data) {
        this.analyticsBase.trackEvent(VOCompareWorkspaceEvents.REMOVE_FROM_COMPARE, data);
    }

    // Flexi Lead Form Events
    trackTeamFlexiFormOpen(data) {
        this.analyticsBase.trackEvent(TeamFlexiLFormFunnelStages.FORM_OPEN, data, { [AnalyticsAPIs.WEBENGAGE]: true });
    }

    trackTeamFlexiFormClose(data) {
        this.analyticsBase.trackEvent(TeamFlexiLFormFunnelStages.FORM_CLOSE, data, { [AnalyticsAPIs.WEBENGAGE]: true });
    }

    trackTeamFlexiFormSubmit(data) {
        this.identifyUser(data.phone, { name: data.name, email: data.email, phone: data.phone });
        this.analyticsBase.trackEvent(TeamFlexiLFormFunnelStages.FORM_STEP1_SUBMIT, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackTeamFlexiFormUpdate(data) {
        this.analyticsBase.trackEvent(TeamFlexiLFormFunnelStages.FORM_STEP2_SUBMIT, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackTeamFlexiFormStage2Skip() {
        this.analyticsBase.trackEvent(
            TeamFlexiLFormFunnelStages.FORM_STEP2_SKIP,
            {},
            { [AnalyticsAPIs.WEBENGAGE]: true }
        );
    }

    // Meeting Room Lead Form Events
    trackMeetingRoomFormOpen(data) {
        this.analyticsBase.trackEvent(MeetingRoomLFormFunnelStages.MR_LFORM_OPEN, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackMeetingRoomFormClose(data) {
        this.analyticsBase.trackEvent(MeetingRoomLFormFunnelStages.MR_LFORM_CLOSE, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackMeetingRoomFormSubmit() {
        this.analyticsBase.trackEvent(MeetingRoomLFormFunnelStages.MR_LFORM_SUBMIT, null, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    // Dedicated Lead Form Events
    trackDedicatedFormOpen(data) {
        if (data.planType === DedicatedFormPlanType.MANAGED_OFFICE) {
            this.analyticsBase.trackEvent(MOFormFunnelStages.FORM_OPEN, data);
        } else {
            this.analyticsBase.trackEvent(DLFormFunnelStages.FORM_OPEN, data);
        }
    }

    trackDedicatedFormClose(data) {
        if (data.planType === DedicatedFormPlanType.MANAGED_OFFICE) {
            this.analyticsBase.trackEvent(MOFormFunnelStages.FORM_CLOSE, data);
        } else {
            this.analyticsBase.trackEvent(DLFormFunnelStages.FORM_CLOSE, data);
        }
    }

    trackDedicatedFormStage1Submit(data) {
        this.identifyUser(data.phone, { name: data.name, email: data.email, phone: data.phone });
        if (data.planType === DedicatedFormPlanType.MANAGED_OFFICE) {
            this.analyticsBase.trackEvent(MOFormFunnelStages.FORM_STEP1_SUBMIT, data);
        } else {
            this.analyticsBase.trackEvent(DLFormFunnelStages.FORM_STEP1_SUBMIT, data);
        }
    }

    trackDedicatedFormStage2Submit(data) {
        if (data.planType === DedicatedFormPlanType.MANAGED_OFFICE) {
            this.analyticsBase.trackEvent(MOFormFunnelStages.FORM_STEP2_SUBMIT, data);
        } else {
            this.analyticsBase.trackEvent(DLFormFunnelStages.FORM_STEP2_SUBMIT, data);
        }
    }

    trackDedicatedFormStage2Skip(data) {
        if (data.planType === DedicatedFormPlanType.MANAGED_OFFICE) {
            this.analyticsBase.trackEvent(MOFormFunnelStages.FORM_STEP2_SKIP, data);
        } else {
            this.analyticsBase.trackEvent(DLFormFunnelStages.FORM_STEP2_SKIP, data);
        }
    }

    trackDedicatedFormSuccessAction(data) {
        if (data.planType === DedicatedFormPlanType.MANAGED_OFFICE) {
            this.analyticsBase.trackEvent(MOFormFunnelStages.FORM_SUCCESS_ACTION, data);
        } else {
            this.analyticsBase.trackEvent(DLFormFunnelStages.FORM_SUCCESS_ACTION, data);
        }
    }

    trackDedicatedFormRedirectToListingPage(data) {
        this.analyticsBase.trackEvent(MOFormFunnelStages.FORM_REDIRECT, data);
    }

    trackDedicatedFormCall(data) {
        this.analyticsBase.trackEvent(HeaderFunnelStages.FORM_CALL, data);
    }

    trackManagedOfficeListingBanner(data?: any) {
        this.analyticsBase.trackEvent(ManagedOfficeEvents.MO_LISTING_BANNER_OPEN, data);
    }

    // Home page lead form events

    trackHomePageFormOpen(data) {
        this.analyticsBase.trackEvent(HomeFormFunnelStages.FORM_OPEN, data);
    }

    trackHomePageStage1Submit(data) {
        this.analyticsBase.trackEvent(HomeFormFunnelStages.FORM_STEP1_SUBMIT, data);
    }

    trackHomePageStage2Submit(data) {
        this.analyticsBase.trackEvent(HomeFormFunnelStages.FORM_STEP2_SUBMIT, data);
    }

    trackHomePageFormStage2Skip() {
        this.analyticsBase.trackEvent(HomeFormFunnelStages.FORM_STEP2_SKIP, {});
    }

    // VO Listing Events
    trackVOListingCompareCTAClick(data) {
        this.analyticsBase.trackEvent(VOSpaceListingEvents.SL_COMPARE_PLANS, data);
    }

    trackVOListingQuoteFormOpen(data) {
        this.analyticsBase.trackEvent(VOSpaceListingEvents.SL_QUOTE_FORM_OPEN, data);
    }

    trackVOListingQuoteFormSubmit(data) {
        this.analyticsBase.trackEvent(VOSpaceListingEvents.SL_QUOTE_FORM_SUBMIT, data);
    }

    trackVOListingQuoteFormClose(data) {
        this.analyticsBase.trackEvent(VOSpaceListingEvents.SL_QUOTE_FORM_CLOSE, data);
    }

    // Flexi Subscription Events
    trackFlexiSubscriptionSelect(data) {
        this.analyticsBase.trackEvent(FlexiSubFunnelStages.FLEXI_SUB_SELECTED, data);
    }

    trackFlexiSubscriptionDetails(data) {
        this.identifyUser(data.phone, { name: data.name, email: data.email, phone: data.phone });
        this.analyticsBase.trackEvent(FlexiSubFunnelStages.FLEXI_SUB_DETAILS, data);
    }

    trackFlexiSubscriptionCheckout(data) {
        this.analyticsBase.trackEvent(FlexiSubFunnelStages.FLEXI_SUB_CHECKOUT, data);
    }

    trackFlexiSubscriptionPaymentSuccess(data) {
        // TODO: Add DB userId to tracking once api is set up
        this.analyticsBase.trackEvent(FlexiSubFunnelStages.FLEXI_SUB_PAY_SUCCESS, data);
    }

    trackFlexiSubscriptionPaymentFailure(data) {
        this.analyticsBase.trackEvent(FlexiSubFunnelStages.FLEXI_SUB_PAY_FAILURE, data);
    }

    trackFlexiAppDownloadSuccess(data) {
        this.analyticsBase.trackEvent(FlexiAppDownloadEvents.APP_DOWNLOAD_BANNER_CLICKED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    // Dedicated Space Visit Events
    trackSpaceVisitFormOpen(data) {
        this.analyticsBase.trackEvent(DVisitFormFunnelStages.FORM_OPEN, data);
    }

    trackSpaceVisitFormClose(data) {
        this.analyticsBase.trackEvent(DVisitFormFunnelStages.FORM_CLOSE, data);
    }

    trackSpaceVisitAboutOpen(data) {
        this.analyticsBase.trackEvent(DVisitFormFunnelStages.ABOUT_OPEN, data);
    }

    trackSpaceVisitAboutClose(data) {
        this.analyticsBase.trackEvent(DVisitFormFunnelStages.ABOUT_CLOSE, data);
    }

    trackSpaceVisitUserDetailsSubmit(data) {
        this.identifyUser(data.phone, { name: data.name, email: data.email, phone: data.phone });
        this.analyticsBase.trackEvent(DVisitFormFunnelStages.STEP1_SUBMIT, data);
    }

    trackSpaceVisitRequirementSubmit(data) {
        this.analyticsBase.trackEvent(DVisitFormFunnelStages.STEP2_SUBMIT, data);
    }

    trackSpaceVisitSuccessPageLoad(data) {
        this.analyticsBase.trackEvent(DVisitFormFunnelStages.SUCCESS, data);
    }

    trackSpaceVisitRequirementSkip(data) {
        this.analyticsBase.trackEvent(DVisitFormFunnelStages.STEP2_SKIP, data);
    }

    trackSpaceVisitAddSpaces(data) {
        this.analyticsBase.trackEvent(DVisitFormFunnelStages.SPACES_ADDED, data);
    }

    trackWorkspaceShortlist(data) {
        this.analyticsBase.trackEvent(DedicatedSpaceVisitEvents.SHORTLISTED, data);
    }

    trackMyShortlistPageView(data) {
        this.analyticsBase.trackEvent(DedicatedSpaceVisitEvents.MY_SHORTLISTS_VIEWED, data);
    }

    trackScheduleVisitCTAClicked(data) {
        this.analyticsBase.trackEvent(DedicatedSpaceVisitEvents.SCHEDULE_VISIT_CLICKED, data);
    }

    // Free Trial Funnel Events
    trackFreeTrialPageView(data) {
        this.analyticsBase.trackEvent(FlexiTrialFunnelStages.FTRIAL_PAGE_VIEWED, data);
    }

    trackFreeTrialWorkspaceView(data) {
        this.analyticsBase.trackEvent(FlexiTrialFunnelStages.WORKSPACE_VIEWED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackTrialFormOpen(data) {
        this.analyticsBase.trackEvent(FlexiTrialFunnelStages.FORM_OPEN, data);
    }

    trackTrialFormClose(data) {
        this.analyticsBase.trackEvent(FlexiTrialFunnelStages.FORM_CLOSE, data);
    }

    trackTrialDetailFormSubmit(data) {
        this.identifyUser(data.phone, { name: data.name, email: data.email, phone: data.phone });
        this.analyticsBase.trackEvent(FlexiTrialFunnelStages.TRIAL_DETAIL_FORM_SUBMIT, data);
    }

    trackTrialOtpResend(data) {
        this.analyticsBase.trackEvent(FlexiTrialFunnelStages.TRIAL_OTP_RESEND, data);
    }

    trackTrialModifyPhone(data) {
        this.analyticsBase.trackEvent(FlexiTrialFunnelStages.MODIFY_PHONE, data);
    }

    trackTrialVerifyFormSubmit(data) {
        this.analyticsBase.trackEvent(FlexiTrialFunnelStages.TRIAL_VERIFY_FORM_SUBMIT, data);
    }

    trackExitIntentFormShow(data) {
        this.analyticsBase.trackEvent(ExitIntentFormEvents.LFORM_SHOW, data);
    }

    trackExitIntentFormClose(data) {
        this.analyticsBase.trackEvent(ExitIntentFormEvents.LFORM_CLOSE, data);
    }

    trackExitIntentFormSubmit(data) {
        this.identifyUser(data.phone, { name: data.name, email: data.email, phone: data.phone });
        this.analyticsBase.trackEvent(ExitIntentFormEvents.LFORM_SUBMIT, data);
    }

    // Virtual Office Lead Form Events
    trackVirtualOfficeFormOpen(event, data) {
        this.analyticsBase.trackEvent(event, data);
    }

    trackVirtualOfficeFormClose(event, data) {
        this.analyticsBase.trackEvent(event, data);
    }

    trackVirtualOfficeFormSubmit(event, data) {
        this.identifyUser(data.phone, { name: data.name, email: data.email, phone: data.phone, cityId: data.cityId });
        this.analyticsBase.trackEvent(event, data);
    }

    trackVirtualOfficeSeeAllLocationsClick(data) {
        this.analyticsBase.trackEvent(VirtualOfficeEvents.VO_PD_SEE_ALL_LOCATIONS_REDIRECT, data);
    }

    trackVirtualOfficeMoneyBackTandCClick(data) {
        this.analyticsBase.trackEvent(VirtualOfficeEvents.VO_PD_MONEY_BACK_TandC, data);
    }

    trackVirtualOfficeCityExploreNowClick(data) {
        this.analyticsBase.trackEvent(VirtualOfficeEvents.VO_PD_EXPLORE_NOW_CTA_REDIRECT, data);
    }

    trackVirtualOfficeExploreAllCitiesClick(data) {
        this.analyticsBase.trackEvent(VirtualOfficeEvents.VO_PD_ALL_CITIES, data);
    }

    trackVirtualOfficeGetInTouchClick(data) {
        this.analyticsBase.trackEvent(VirtualOfficeEvents.VO_PD_FORM, data);
    }

    trackVirtualOfficeFormRedirectToListingPage(event, data) {
        this.analyticsBase.trackEvent(event, data);
    }

    trackVirtualOfficePlanExploreLocationsRedirect(data) {
        this.analyticsBase.trackEvent(VirtualOfficeEvents.VO_PD_EXPLORE_LOCATIONS_CTA_REDIRECT, data);
    }

    trackVirtualOfficeCommunityCTAClick(data) {
        this.analyticsBase.trackEvent(VirtualOfficeEvents.VO_PD_COMMUNITY_CTA_REDIRECT, data);
    }

    // Space Detail Page Events
    trackWorkspace3dViewOpen(data) {
        this.analyticsBase.trackEvent(SpaceDetailPageEvents.WORKSPACE_3D_VIEW_OPEN, data);
    }

    // Landing page events
    trackLandingPageView(payload: IPageViewPayload) {
        if (payload.eventName === SpecificPageViewedEvents.MR_LANDING_VIEWED) {
            this.analyticsBase.trackEvent(payload.eventName, payload.data, { [AnalyticsAPIs.WEBENGAGE]: true });
        } else {
            this.analyticsBase.trackEvent(payload.eventName, payload.data);
        }
    }

    // Contact Us Form Event
    trackContactUsFormSubmit(data) {
        this.identifyUser(data.phone, {
            name: data.name,
            email: data.email,
            phone: data.phone,
            company: data.company,
            product: data.product,
            teamsize: data.teamsize,
        });
        this.analyticsBase.trackEvent(ContactUsFormFunnelStages.FORM_SUBMIT, data);
    }

    // App Download Events
    trackAppDownloadRedirectAction(data) {
        this.analyticsBase.trackEvent(AppDownloadFunnelStages.APP_DOWNLOAD_REDIRECT, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    // VO Payments Events
    trackVOPaymentPageView(data) {
        this.analyticsBase.trackEvent(VOPaymentEvents.VO_PLAN_SELECTED, data);
    }

    trackVOPaymentsCheckoutInitiated(data) {
        this.analyticsBase.trackEvent(VOPaymentEvents.VO_PLAN_CHECKOUT, data);
    }

    trackVOPaymentsSuccess(data) {
        this.analyticsBase.trackEvent(VOPaymentEvents.VO_PLAN_SUCCESS, data);
    }

    trackVOPaymentsFailed(data) {
        this.analyticsBase.trackEvent(VOPaymentEvents.VO_PLAN_FAILURE, data);
    }

    trackVOStartKyc(data) {
        this.analyticsBase.trackEvent(VirtualOfficeEvents.VO_BEGIN_KYC, data);
    }

    trackUserReviewBookingRatingInputClicked(data) {
        this.analyticsBase.trackEvent(UserReviewsEvents.BOOKING_RATING_INPUT_CLICKED, data);
    }

    trackUserReviewSubmitted(data) {
        this.analyticsBase.trackEvent(UserReviewsEvents.BOOKING_REVIEW_SUBMITTED, data);
    }

    trackMobileOtpLoginPopViewed(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(MobileOtpFormFunnelStages.LOGIN_POPUP_VIEWED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackMobileOtpSubmitted(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(MobileOtpFormFunnelStages.OTP_SUBMITTED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackMobileOtpLoginSuccess(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(MobileOtpFormFunnelStages.LOGIN_SUCCESS, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackMobileOtpLoginFailed(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(MobileOtpFormFunnelStages.LOGIN_FAILURE, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackBookingPageViewed(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.BOOKING_PAGE_VIEWED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackFlexiBookingInitiated(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.BOOKING_INITIATED, data, { [AnalyticsAPIs.WEBENGAGE]: true });
    }

    trackFlexiBookingConfirmPageViewed(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.BOOKING_CONFIRM_PAGE_VIEWED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackFlexiBookingProceedClicked(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.BOOKING_PROCEED_CLICKED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackFlexiPaymentModeSelectionPageViewed(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.PMODE_SELECTION_PAGE_VIEWED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackFlexiPaymentModeSelected(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.PMODE_SELECTED, data, { [AnalyticsAPIs.WEBENGAGE]: true });
    }

    trackFlexiRechargeScreenViewed(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.FLEXI_RECHARGE_PAGE_VIEWED, data);
    }

    trackFlexiOrderSummaryViewed(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.ORDER_SUMMARY_PAGE_VIEWED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackFlexiMyHQPaymentInitiated(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.MYHQ_PAYMENT_INITIATED, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackFlexiMyHQPaymentSuccess(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.MYHQ_PAYMENT_SUCCESS, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackFlexiMyHQPaymentFailed(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.MYHQ_PAYMENT_FAILURE, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackBookingFailed(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.BOOKING_FAILED, data, { [AnalyticsAPIs.WEBENGAGE]: true });
    }

    trackBookingSuccess(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.BOOKING_SUCCESS, data, { [AnalyticsAPIs.WEBENGAGE]: true });
    }

    trackMrBookingSuccess(data: ITrackEventProperties) {
        this.analyticsBase.trackEvent(FlexiBookingEvents.BOOKING_SUCCESS_MR, data, { [AnalyticsAPIs.WEBENGAGE]: true });
    }

    // MR Lead From Events
    trackMrLeadFormOpen(data) {
        this.analyticsBase.trackEvent(MeetingRoomFormFunnelStages.FORM_OPEN, data);
    }

    trackMrLeadFormClose(data) {
        this.analyticsBase.trackEvent(MeetingRoomFormFunnelStages.FORM_CLOSE, data);
    }

    trackMrLeadFormCreateLead(data) {
        this.analyticsBase.trackEvent(MeetingRoomFormFunnelStages.FORM_STEP1_SUBMIT, data, {
            [AnalyticsAPIs.WEBENGAGE]: true,
        });
    }

    trackMrLeadFormUpdateLead(data) {
        this.analyticsBase.trackEvent(MeetingRoomFormFunnelStages.FORM_SUBMIT, data);
    }

    trackMrFormStage2Skip() {
        this.analyticsBase.trackEvent(
            MeetingRoomFormFunnelStages.FORM_STEP2_SKIP,
            {},
            { [AnalyticsAPIs.WEBENGAGE]: true }
        );
    }

    // VO Company name search
    trackCNSPageViewed(data: { citySlug: string }) {
        this.analyticsBase.trackEvent(CompanyNameSearchEvents.VO_COMPANY_NAME_SEARCH_PAGE_VIEWED, data);
    }

    trackCNSLeadFormOpen(data) {
        this.analyticsBase.trackEvent(CompanyNameSearchEvents.VO_COMPANY_NAME_LEAD_FORM_OPEN, data);
    }

    trackCNSLeadFormSubmitted(data) {
        this.analyticsBase.trackEvent(CompanyNameSearchEvents.VO_COMPANY_NAME_LEAD_FORM_SUBMIT, data);
    }

    trackCNSSearch(data) {
        this.analyticsBase.trackEvent(CompanyNameSearchEvents.VO_COMPANY_NAME_SEARCH, data);
    }

    trackCNSSearchSuccess(data) {
        this.analyticsBase.trackEvent(CompanyNameSearchEvents.VO_COMPANY_NAME_SEARCH_SUCCESS, data);
    }

    trackCNSSearchFailure(data) {
        this.analyticsBase.trackEvent(CompanyNameSearchEvents.VO_COMPANY_NAME_SEARCH_FAILURE, data);
    }

    trackCRPageViewed() {
        this.analyticsBase.trackEvent(CompanyRegistrationEvents.VO_COMPANY_REGISTRATION_SEARCH_PAGE_VIEWED, {});
    }

    trackGSTPageViewed() {
        this.analyticsBase.trackEvent(GstRegistrationEvents.VO_GST_REGISTRATION_SEARCH_PAGE_VIEWED, {});
    }

    trackVPOVPageViewed() {
        this.analyticsBase.trackEvent(VPOBEvents.VO_VPOB_PAGE_VIEWED, {});
    }
}
