<div *ngIf="isAnimated" class="header-container">
    <div
        appReactiveStickyHeader
        [classLogic]="navbarClassLogic"
        class="animated-header"
        [ngClass]="{ 'search-active': headerSearchConfig?.locationWorkspaceSearchConfig?.showSearchModal }"
    >
        <ng-container *ngTemplateOutlet="header"></ng-container>
    </div>
</div>
<div *ngIf="!isAnimated" class="header-container">
    <ng-container *ngTemplateOutlet="header"></ng-container>
</div>

<!-- Auth Modals -->
@defer (when renderVoAuthModal) {
    <app-login-modal *ngIf="renderVoAuthModal"></app-login-modal>
}

@defer (when renderMeetingRoomModal) {
    <app-mobile-otp-login-modal *ngIf="renderMeetingRoomModal"></app-mobile-otp-login-modal>
}

<ng-template #header>
    <header class="app-container position-relative">
        <div *ngIf="!device.isMobile">
            <div class="header-cont d-flx j-cont-space-bet">
                <div class="logo-city-cont d-flx a-items-c">
                    <a *ngIf="isLogoClickable" [routerLink]="homepageUrl | async" aria-label="myhq"
                        ><ng-container *ngTemplateOutlet="logo"></ng-container
                    ></a>
                    <a *ngIf="!isLogoClickable" aria-label="myhq"
                        ><ng-container *ngTemplateOutlet="logo"></ng-container
                    ></a>
                    <div *ngIf="showCityChange" class="city-cont">
                        <app-select-city></app-select-city>
                    </div>
                    <div
                        class="header-search-bar-cont show-tab-desk"
                        *ngIf="showHeaderSearch"
                        [ngClass]="{ 'show-search-in-sticky': showHeaderSearchInSticky }"
                    >
                        <ng-container *ngIf="headerSearchVariant === HeaderSearchVariant.DETAILED; else defaultSearch">
                            <app-search [config]="detailedHeaderSearchConfig"></app-search>
                        </ng-container>
                        <ng-template #defaultSearch>
                            <app-header-search
                                [config]="headerSearchConfig"
                                (menuChangeEvent)="searchSolutionChangeHandler($event)"
                                (toggleSearchModalEvent)="toggleModal($event)"
                                (selectLocationWorkspaceEvent)="onLocationWorkspaceSelectEvent($event)"
                            ></app-header-search>
                        </ng-template>
                    </div>
                </div>
                <div class="menu-cont d-flx a-items-c">
                    <ng-container *ngFor="let menu of menus; trackBy: menuItemTrackBy">
                        <ng-container *ngIf="menu.show">
                            <ng-container [ngSwitch]="menu.type">
                                <div class="menu-list-item">
                                    <app-menu-with-dropdown
                                        *ngSwitchCase="menuType.DROPDOWN"
                                        [menu]="menu"
                                    ></app-menu-with-dropdown>
                                    <app-location-dropdown
                                        *ngSwitchCase="menuType.LOCATION_DROPDOWN"
                                        [title]="'Locations'"
                                        [selectedCity]="selectedCity"
                                        [product]="selectedProduct"
                                    ></app-location-dropdown>
                                    <ng-container *ngSwitchCase="menuType.LINK">
                                        <ng-container
                                            *ngTemplateOutlet="menuWithLink; context: { menu: menu }"
                                        ></ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="menuType.LINK_BTN">
                                        <ng-container
                                            *ngTemplateOutlet="menuWithLinkBtn; context: { menu: menu }"
                                        ></ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="menu.type === menuType.LOGIN_SIGNUP">
                                        <ng-container *ngTemplateOutlet="loginSignup"></ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="menu.type === menuType.LOGIN_SIGNUP_FLEXI">
                                        <ng-container *ngTemplateOutlet="loginSignupFlexi"></ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="menu.type === menuType.LOGOUT_BTN">
                                        <ng-container *ngTemplateOutlet="logoutBtn"></ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="menu.type === menuType.LOGOUT_BTN_FLEXI">
                                        <ng-container *ngTemplateOutlet="logoutBtnFlexi"></ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="device.isMobile">
            <ng-container *ngTemplateOutlet="mobileHeader"></ng-container>
        </div>
    </header>
</ng-template>
<app-modals
    *ngIf="menuModalMobile.isOpen"
    [template]="mobileMenuTemplate"
    (hideModal)="toggleMenuModal(false)"
></app-modals>

<ng-template #mobileMenuTemplate>
    <div class="header-modal-cont">
        <ng-container *ngTemplateOutlet="mobileHeader"></ng-container>
        <div>
            <ng-container *ngFor="let menu of menus">
                <ng-container *ngIf="menu.show">
                    <ng-container *ngIf="menu.type === menuType.DROPDOWN">
                        <ng-container
                            *ngTemplateOutlet="mobileDropdownTemplate; context: { menu: menu }"
                        ></ng-container>
                    </ng-container>
                    <ng-container *ngIf="menu.type === menuType.LINK">
                        <ng-container *ngTemplateOutlet="menuWithLink; context: { menu: menu }"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="menu.type === menuType.LINK_BTN">
                        <ng-container *ngTemplateOutlet="menuWithLinkBtn; context: { menu: menu }"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="menu.type === menuType.CLICKABLE_LINK_BTN">
                        <ng-container
                            *ngTemplateOutlet="menuWithClickableLinkBtnMobile; context: { menu: menu }"
                        ></ng-container>
                    </ng-container>
                    <ng-container *ngIf="menu.type === menuType.LOGIN_SIGNUP">
                        <ng-container *ngTemplateOutlet="loginSignup"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="menu.type === menuType.LOGIN_SIGNUP_FLEXI">
                        <ng-container *ngTemplateOutlet="loginSignupFlexi"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="menu.type === menuType.LOGOUT_BTN">
                        <ng-container *ngTemplateOutlet="logoutBtn"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="menu.type === menuType.LOGOUT_BTN_FLEXI">
                        <ng-container *ngTemplateOutlet="logoutBtnFlexi"></ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>
<ng-template #menuWithLink let-menu="menu">
    <!-- TODO:: remove duplicate code for routing internal and external -->
    <a
        *ngIf="!menu.isExternalUrl"
        [routerLink]="menu.redirectUrl | async"
        routerLinkActive="selected"
        [routerLinkActiveOptions]="{ exact: true }"
        #rla="routerLinkActive"
        (click)="onMenuClick()"
    >
        <div class="d-flx a-items-c menu-with-link-cont">
            <div class="menu-icon-cont" *ngIf="device.isMobile">
                <img class="menu-icon" [src]="rla.isActive ? menu.iconUrlSelected : menu.iconUrl" alt="i" />
            </div>
            <div class="title-desc-cont">
                <div class="title">{{ menu.title }}</div>
                <div *ngIf="menu.description" class="desc">{{ menu.description }}</div>
            </div>
        </div>
    </a>
    <a
        *ngIf="menu.isExternalUrl"
        [href]="menu.redirectUrl | async"
        routerLinkActive="selected"
        [routerLinkActiveOptions]="{ exact: true }"
        target="_blank"
        #rla="routerLinkActive"
        (click)="onMenuClick()"
    >
        <div class="d-flx a-items-c menu-with-link-cont">
            <div class="menu-icon-cont" *ngIf="device.isMobile">
                <img class="menu-icon" [src]="rla.isActive ? menu.iconUrlSelected : menu.iconUrl" alt="i" />
            </div>
            <div class="title-desc-cont">
                <div class="title">{{ menu.title }}</div>
                <div *ngIf="menu.description" class="desc">{{ menu.description }}</div>
            </div>
        </div>
    </a>
</ng-template>
<ng-template #menuWithLinkBtn let-menu="menu">
    <a
        *ngIf="!menu.isExternalUrl"
        [routerLink]="menu.redirectUrl | async"
        routerLinkActive="selected"
        [routerLinkActiveOptions]="{ exact: true }"
        #rla="routerLinkActive"
        (click)="onMenuClick()"
    >
        <div class="d-flx a-items-c menu-with-link-btn-cont" [ngClass]="cssConfig[menu.btnType]">
            <div class="menu-icon-cont">
                <img class="menu-icon" [src]="rla.isActive ? menu.iconUrlSelected : menu.iconUrl" alt="i" />
            </div>
            <div class="title-cont">
                <div class="title">{{ menu.title }}</div>
            </div>
        </div>
    </a>
    <a
        *ngIf="menu.isExternalUrl"
        [href]="menu.redirectUrl | async"
        routerLinkActive="selected"
        target="_blank"
        [routerLinkActiveOptions]="{ exact: true }"
        #rla="routerLinkActive"
        (click)="onMenuClick()"
    >
        <div class="d-flx a-items-c menu-with-link-btn-cont" [ngClass]="cssConfig[menu.btnType]">
            <div class="menu-icon-cont">
                <img class="menu-icon" [src]="rla.isActive ? menu.iconUrlSelected : menu.iconUrl" alt="i" />
            </div>
            <div class="title-cont">
                <div class="title">{{ menu.title }}</div>
            </div>
        </div>
    </a>
</ng-template>
<ng-template #menuWithClickableLinkBtnMobile let-menu="menu">
    <div class="menu-link-btn-cont d-flx" (click)="onMenuBtnClick(menu.id)">
        <div class="d-flx a-items-c menu-with-link-btn-clickable-cont">
            <div class="menu-icon"><img class="icon" [src]="menu.iconUrl" alt="i" /></div>
            <div class="title-cont">
                <div class="title">{{ menu.title }}</div>
            </div>
        </div>
        <div *ngIf="menu.showArrow" class="icon-cont">
            <img class="icon" [src]="commonIconsUrl.RIGHT_CHEVRON_GREY" alt="i" />
        </div>
    </div>
</ng-template>

<ng-template #mobileDropdownTemplate let-menu="menu">
    <div class="dropdown-cont">
        <div
            class="label-cont d-flx a-items-c j-cont-space-bet"
            [ngClass]="{ 'c-pointer': menu.isCollapsible }"
            (click)="handleCollapse(menu)"
        >
            <div class="label">{{ menu.title }}</div>

            <div
                class="arrow margin-right-8"
                [class.arrow-down]="menu.collapsed"
                [class.arrow-up]="!menu.collapsed"
                *ngIf="menu.isCollapsible"
            ></div>
        </div>

        <ng-container *ngIf="!menu.isCollapsible || (menu.isCollapsible && !menu.collapsed)">
            <!-- TODO :: remove duplicate code in href and router link -->
            <ng-container *ngFor="let subMenu of menu.subMenus">
                <ng-container *ngFor="let menuItem of subMenu?.menuItems ?? []">
                    <div *ngIf="!menuItem.isExternalUrl">
                        <a [routerLink]="menuItem.redirectUrl | async" (click)="onMenuClick()">
                            <div class="dropdown d-flx a-items-start c-pointer gap-8">
                                <div class="image-cont margin-top-4">
                                    <img class="image-contain d-flx" [src]="menuItem.imageUrl" [alt]="menuItem.title" />
                                </div>
                                <div class="title-desc-cont">
                                    <div>
                                        <span class="title">{{ menuItem.title }} </span>
                                        <span *ngIf="menuItem.tag" class="tag">{{ menuItem.tag }}</span>
                                        <span *ngIf="menuItem.comingSoon" class="tag-grey"><i>- Coming soon</i></span>
                                    </div>
                                    <div class="desc">{{ menuItem.description }}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div *ngIf="menuItem.isExternalUrl">
                        <a [href]="menuItem.redirectUrl | async" (click)="onMenuClick()">
                            <div class="dropdown d-flx a-items-start c-pointer gap-8">
                                <div class="image-cont margin-top-4">
                                    <img class="image-contain d-flx" [src]="menuItem.imageUrl" [alt]="menuItem.title" />
                                </div>
                                <div class="title-desc-cont">
                                    <div class="title">{{ menuItem.title }}</div>
                                    <div class="desc">{{ menuItem.description }}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
<ng-template #mobileHeader let-subMenus="subMenus">
    <div class="header-cont d-flx j-cont-space-bet a-items-c">
        <div class="d-flx">
            <div *ngIf="showMenuIconOnMobile" class="icon-cont d-flx a-items-c">
                <div
                    class="svg-cont"
                    [ngClass]="{ 'close-icon-cont': menuModalMobile.isOpen }"
                    (click)="toggleMenuModal(!this.menuModalMobile.isOpen)"
                    [innerHTML]="menuModalMobile.isOpen ? crossIcon : menuIcon"
                ></div>
            </div>
            <a *ngIf="isLogoClickable" [routerLink]="homepageUrl | async" aria-label="myhq"
                ><ng-container *ngTemplateOutlet="logo"></ng-container
            ></a>
            <a *ngIf="!isLogoClickable" aria-label="myhq"><ng-container *ngTemplateOutlet="logo"></ng-container></a>
        </div>
        <div class="d-flx a-items-c">
            <div *ngIf="showCityChange" class="city-cont">
                <app-select-city></app-select-city>
            </div>
            <div class="d-flx search-icon" *ngIf="showHeaderSearch">
                <ng-container *ngIf="headerSearchVariant === HeaderSearchVariant.DETAILED; else defaultMobSearch">
                    <app-search [config]="detailedHeaderSearchConfig"></app-search>
                </ng-container>
                <ng-template #defaultMobSearch>
                    <app-location-workspace-mobile-modal-trigger
                        [locationWorkspaceSearchConfig]="locationWorkspaceSearchConfig"
                        [config]="locationWorkspaceHeaderSearchMob"
                        (locationWorkspaceSelectEvent)="onLocationWorkspaceSelectEvent($event)"
                    ></app-location-workspace-mobile-modal-trigger>
                </ng-template>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #loginSignup>
    <app-login-signup-menu-item></app-login-signup-menu-item>
</ng-template>

<ng-template #loginSignupFlexi>
    <app-login-signup-flexi-menu-item></app-login-signup-flexi-menu-item>
</ng-template>

<ng-template #logoutBtn>
    <app-logout-button [product]="productKeys.VIRTUAL_OFFICE"></app-logout-button>
</ng-template>
<ng-template #logoutBtnFlexi>
    <app-logout-button [product]="productKeys.MEETING_ROOM"></app-logout-button>
</ng-template>

<ng-template #logo>
    <div class="logo-cont d-flx a-items-c svg-cont" [innerHTML]="logoIcon"></div>
</ng-template>
