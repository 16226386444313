<div
    class="dropdown-cont"
    [ngClass]="{ 'no-val': form.value[field.name] == '' }"
    tabindex="0"
    (focusout)="closeDropdown($event)"
    [formGroup]="form"
    #container
>
    <div (click)="toggleDropdown()">
        <input [id]="field.name" [formControlName]="field.name" [hidden]="true" />
        <div class="dropdown text-ellipsis text-color-dark" >{{selectedLabels}}</div>
        <label [for]="field.name" [ngClass]="{ floating: form.value[field.name].length }" class="label">
            {{ field.label }}
        </label>
        <div class="icon"><img [src]="commonIconsUrl.DOWN_CHEVRON_DARK" alt="i" /></div>
    </div>
    <div
        *ngIf="isDropdownOpen"
        class="width-100 bg-color-white box-shadow-card-box-shadow padding-horizontal-16 padding-top-8 border-radius-2 options-cont"
        #dropdown
    >
        <div
            *ngFor="let option of field.options; let isLast = last"
            class="d-flx gap-8 c-pointer padding-top-8"
            [ngClass]="{'padding-bottom-8': !isLast,}"
            (mousedown)="toggleOption(option)"
            tabindex="0"
            [attr.aria-selected]="isSelected(option)"
        >
            <app-check [config]="{ value: option.value, checked: isSelected(option) }"></app-check>
            <div class="d-flx flx-d-clm gap-4">
                <h6 class="font-14">{{ option.label }}</h6>
                <p class="font-12 text-color-medium-grey" *ngIf="option.description">{{ option.description }}</p>
            </div>
        </div>
        <div class="margin-vertical-16 d-flx j-cont-end">
            <app-button [field]="updateBtnConfig" (onClick)="updateSelection()"></app-button>
        </div>
    </div>
</div>
