<!-- Template for common Location and workspace search  -->
<div [ngClass]="cssConfig[config?.type]">
    <div class="position-relative">
        <div class="search-box">
            <div class="input-container" [ngClass]="{ active: showSearchModal }">
                <ng-container *ngTemplateOutlet="typeaheadTemplate"></ng-container>
            </div>
            <div
                class="default-typeahead-cont"
                [ngClass]="config?.typeaheadContainerClass ? config?.typeaheadContainerClass : 'typeahead-cont'"
            >
                @defer {
                    <ng-container *ngIf="showSearchResultDropdown">
                        <ng-container
                            *ngIf="config?.customDefaultSearchResultsTemplate; else defaultSearchResultsTemplate"
                        >
                            <ng-container *ngTemplateOutlet="config?.customDefaultSearchResultsTemplate"></ng-container>
                        </ng-container>
                    </ng-container>
                }
            </div>
        </div>
    </div>
</div>

<ng-template #typeaheadTemplate>
    <app-typeahead
        [config]="typeaheadConfig"
        [initValue]="config.typeaheadInitValue || ''"
        (onSelect)="onLocationWorkspaceSelectEvent($event, true)"
        (onFocus)="toggleModalAndAdjustFocus(true)"
        [listTemplate]="listTemplate"
        [loadingTemplate]="loadingTemplate"
        [noResultTemplate]="noResultTemplate"
        [focusOnInput]="config?.setFocusOnInit"
        [isSearchModalInFocus]="showSearchModal"
        (iconClickEvent)="onIconClickEvent()"
        (onSearchStringChangeEvent)="setIsSearchStringSmallerThanThreshold($event)"
    ></app-typeahead>
</ng-template>

<ng-template #listTemplate let-model="item" let-index="index">
    <div class="result-cont">
        @defer (on immediate) {
            <div *ngIf="!model.isLocation">
                <app-workspace-search-list
                    [searchedWorkspace]="model"
                    [cssClass]="searchItemClass"
                ></app-workspace-search-list>
            </div>
            <div *ngIf="model.isLocation">
                <app-location-search-list
                    [searchedLocation]="model"
                    [cssClass]="searchItemClass"
                ></app-location-search-list>
            </div>
        }
    </div>
</ng-template>

<ng-template #loadingTemplate>
    <div class="loading-container">
        <ng-container *ngIf="!deviceInfo.isMobile">
            <app-workspace-search-list-shimmer *ngFor="let i of [1, 2, 3, 4, 5]"></app-workspace-search-list-shimmer>
        </ng-container>
        <ng-container *ngIf="deviceInfo.isMobile">
            <ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]">
                <div class="shimmer mobile-search-shimmer"></div>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #noResultTemplate>
    <div class="no-result-container">
        <p>No result found</p>
    </div>
</ng-template>

<ng-template #locationAndWorkspaceSearchResultTemplate>
    <div class="default-result-container" *ngIf="isSearchStringSmallerThanThreshold">
        <div class="result-card" *ngIf="isSectionVisible(LocationWorkspaceSearchSections.NEAR_ME)">
            <app-near-me
                [config]="nearMeComponentConfig"
                (callback)="handleNearMeClick($event)"
                (fetchStatus)="updateNearMeFetchStatus($event)"
            ></app-near-me>
        </div>
        <ng-container [ngTemplateOutlet]="spinnerTemplate" *ngIf="isNearMeLocationFetchInProgress"> </ng-container>
        <ng-container *ngIf="!isNearMeLocationFetchInProgress">
            <div class="result-card" *ngIf="isSectionVisible(LocationWorkspaceSearchSections.RECENTLY_SEARCHED)">
                <app-recently-searched
                    [heading]="'Recent searches'"
                    (clickRecentSearch)="onLocationWorkspaceSelectEvent($event)"
                >
                </app-recently-searched>
            </div>
            <div class="result-card" *ngIf="isSectionVisible(LocationWorkspaceSearchSections.POPULAR_LOCATIONS)">
                <app-popular-locations
                    [heading]="popularLocationHeading"
                    (clickLocation)="onLocationWorkspaceSelectEvent($event)"
                    [maxLocationCount]="popularLocationCount"
                    [product]="config?.product"
                >
                </app-popular-locations>
            </div>
            <div class="result-card" *ngIf="isSectionVisible(LocationWorkspaceSearchSections.POPULAR_WORKSPACES)">
                <app-popular-workspaces
                    [heading]="popularWorkspacesHeading"
                    (clickWorkspace)="onLocationWorkspaceSelectEvent($event)"
                    [product]="config?.product"
                >
                </app-popular-workspaces>
            </div>
            <div
                class="d-flx explore-link"
                *ngIf="isSectionVisible(LocationWorkspaceSearchSections.REDIRECTION_CTA)"
                (click)="toggleModalAndAdjustFocus(false)"
            >
                <app-link-with-icon
                    [LinkText]="workspaces.link.text"
                    [url]="workspaces.link.url"
                    [size]="'large'"
                    [isExternalUrl]="false"
                    [linkColor]="'primary'"
                    [lineBreakType]="lineBreakType.BREAK_LINE"
                ></app-link-with-icon>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #workspaceSearchResultTemplate>
    <div class="default-result-container" *ngIf="isSearchStringSmallerThanThreshold">
        <div class="result-card">
            <app-recently-searched
                [heading]="'Recent searches'"
                (clickRecentSearch)="onLocationWorkspaceSelectEvent($event)"
                [showLocations]="false"
            />
        </div>
        <div class="d-flx explore-link" (click)="toggleModalAndAdjustFocus(false)">
            <app-link-with-icon
                [LinkText]="workspaces.link.text"
                [url]="workspaces.link.url"
                [size]="'large'"
                [isExternalUrl]="false"
                [linkColor]="'primary'"
                [lineBreakType]="lineBreakType.BREAK_LINE"
            ></app-link-with-icon>
        </div>
    </div>
</ng-template>

<ng-template #defaultSearchResultsTemplate>
    <ng-container [ngSwitch]="config.searchSuggestionType">
        <ng-container
            *ngSwitchCase="searchSuggestionType.LOCATION_AND_WORKSPACE"
            [ngTemplateOutlet]="locationAndWorkspaceSearchResultTemplate"
        ></ng-container>
        <ng-container
            *ngSwitchCase="searchSuggestionType.WORKSPACE"
            [ngTemplateOutlet]="workspaceSearchResultTemplate"
        ></ng-container>
        <ng-container *ngSwitchDefault [ngTemplateOutlet]="locationAndWorkspaceSearchResultTemplate"></ng-container>
    </ng-container>
</ng-template>

<ng-template #spinnerTemplate>
    <div class="d-flx flx-d-clm width-100 a-items-c j-cont-cen bg-color-white spinner-container">
        <app-spinner class="spinner-content"></app-spinner>
        <p class="spinner-text">Finding workspaces near you...</p>
    </div>
</ng-template>
