import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { catchError } from 'rxjs/operators';
import { SentryService } from '../services/performance-error-tracking/apis/sentry.service';

@Injectable({
    providedIn: 'root',
})
export class SentryInterceptor implements HttpInterceptor {
    constructor(private sentryService: SentryService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const containsUndefinedString = (value: string, includesValue?: string): boolean => {
            if (typeof value !== 'string') {
                return false;
            }
            if (includesValue) {
                return value.includes(includesValue);
            }
            return value.includes('undefined') || value.includes('null') || value.includes('[object Object]');
        };

        const hasNullishValues = (obj: any) => {
            if (!obj) {
                return false;
            }
            return Object.values(obj).some(value => value === null || value === undefined || value === 'undefined');
        };

        const logErrorToSentry = (message: string, details: any) => {
            Sentry.setExtra('url', req?.url);
            Sentry.setExtra('method', req?.method);
            Sentry.setExtra('payload', req?.body);

            this.sentryService.throwError(details, message);
        };

        const checkAndLog = (type: string, data: any, message?: string) => {
            logErrorToSentry(message || `Nullish value found in ${type}`, data);
        };

        const pathname = req.url.split('?')[0];
        // Check Path Params
        if (containsUndefinedString(pathname)) {
            checkAndLog('Path parameters', pathname);
        }

        // Check Path Params (included - runtime)
        if (containsUndefinedString(pathname.toLowerCase(), 'runtime')) {
            checkAndLog('Path parameters', pathname, 'URL includes runtime in path parameters');
        }

        // Check Query Params
        // if (hasNullishValues(req.params)) {
        //     checkAndLog('Query parameters', req.params);
        // }

        // Check Body
        // if (hasNullishValues(req.body)) {
        //     checkAndLog('body', req.body);
        // }

        // Check Response
        return next.handle(req).pipe(
            // Uncomment if we want success false to be logged
            // tap((res: HttpResponse<any>) => {
            //     Sentry.withScope(async scope => {
            //         scope.setExtra('url', req?.url);
            //         scope.setExtra('method', req?.method);
            //         scope.setExtra('payload', req?.body);
            //
            //         if (res?.body && !res?.body?.success) {
            //             scope.setExtra('success', false);
            //             Sentry.captureException(new Error(`${req?.url} [success - false]`));
            //         }
            //     });
            // }),
            catchError((response: HttpErrorResponse) => {
                Sentry.withScope(async scope => {
                    scope.setExtra('url', req?.url);
                    scope.setExtra('method', req?.method);
                    scope.setExtra('payload', req?.body);
                    scope.setTag('status', response.status);

                    if (!(response.status === 401 || response.status >= 500)) {
                        this.sentryService.throwError(
                            `[${req?.method}] [${response?.status}] ${req?.url}`,
                            response.error
                        );
                    }
                });
                return throwError(() => response);
            })
        );
    }
}
