import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IFieldConfig } from '../../interfaces/field.interface';
import { IButtonConfig } from '../../interfaces/button.interface';

@Component({
    selector: 'app-input-with-button',
    templateUrl: './input-with-button.component.html',
    styleUrls: ['./input-with-button.component.scss'],
})
export class InputWithButtonComponent implements OnInit {
    constructor(private formBuilder: UntypedFormBuilder) {}
    @Input() fieldObj: {
        field: IFieldConfig;
        cta: IButtonConfig;
    };
    @Output() submit: EventEmitter<any> = new EventEmitter<any>();
    form: UntypedFormGroup;
    ngOnInit() {
        this.form = this.createControl();
    }
    onSubmit() {
        if (this.form.valid) {
            this.submit.emit({ formData: this.form.value });
        }
    }
    createControl() {
        const group = this.formBuilder.group({});
        const control = this.formBuilder.control(
            this.fieldObj.field.value,
            this.bindValidations(this.fieldObj.field.validations || [])
        );
        group.addControl(this.fieldObj.field.name, control);
        return group;
    }
    bindValidations(validations: any) {
        if (!validations.length) {
            return null;
        }
        const validatorList = [];
        validations.forEach(valid => {
            validatorList.push(valid.validator);
        });
        return Validators.compose(validatorList);
    }
}
