import { ICarouselConfig } from '../interfaces/carousel-config.interface';

export enum buttonType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    LINK = 'link',
    LIGHT = 'light',
    OUTLINE = 'outline',
    DARK = 'dark',
    MEDIUM_GREY = 'medium-grey',
    GREY = 'grey',
    GREEN = 'green',
}

export enum buttonHTMLType {
    submit = 'submit',
    reset = 'reset',
    button = 'button',
}

export enum fontSizes {
    X_LARGE = 'X-LARGE',
    LARGE = 'LARGE',
    MEDIUM = 'MEDIUM',
    SMALL = 'SMALL',
    MEDIUM_MOB_DESK = 'MEDIUM_MOB_DESK',
}

export enum fontWeights {
    BOLD = 'BOLD',
    BOLDER = 'BOLDER',
    NORMAL = 'NORMAL',
}

export enum nearMeLocationObj {
    PLACE_ID = 'NEAR_ME',
    DISPLAY = 'Near me',
}

export enum dateLabel {
    YESTERDAY = 'Yesterday',
    TODAY = 'Today',
    TOMORROW = 'Tomorrow',
}

export enum formStages {
    STAGE_1 = 'PRIMARY',
    STAGE_2 = 'SECONDARY',
    STAGE_3 = 'SUCCESS',
}

export enum dateStatus {
    AVAILABLE = 'Available',
    UNAVAILABLE = 'Unavailable',
}

export enum dateSubStatus {
    SOLDOUT = 'Sold Out',
    CLOSED = 'Closed',
    CLOSEDNOW = 'Closed Now',
    DISRUPTION = 'Disruption',
    MODIFIED = 'Modified',
    FILLINGFAST = 'Filling Fast',
    CLOSING_SOON = 'CLOSING_SOON',
    BOOKING_CLOSED = 'BOOKING_CLOSED',
}

export enum productKeys {
    FLEXI = 'FLEXI',
    CORPORATE_FLEXI = 'CORPORATE_FLEXI',
    DEDICATED = 'DEDICATED',
    MEETING_ROOM = 'MEETING_ROOM',
    VIRTUAL_OFFICE = 'VIRTUAL_OFFICE',
    MANAGED_OFFICE = 'MANAGED_OFFICE',
    ENTERPRISES = 'ENTERPRISES',
}

// use this productsMap to get better control over frontend and backend naming convention
export const productsMap = {
    FLEXI: {
        productNameFrontend: productKeys.FLEXI as const,
        productNameBackend: 'INDIVIDUAL',
        productBaseUrl: 'flexi',
        listingUrlSlug: 'coworking-space',
    } as const,
    CORPORATE_FLEXI: {
        productNameFrontend: productKeys.CORPORATE_FLEXI as const,
        productNameBackend: 'CORPORATE',
        productBaseUrl: 'corporate-flexi',
        listingUrlSlug: 'coworking-space',
    } as const,
    DEDICATED: {
        productNameFrontend: productKeys.DEDICATED as const,
        productNameBackend: 'DEDICATED',
        productBaseUrl: 'dedicated',
        listingUrlSlug: 'coworking-space',
    } as const,
    MEETING_ROOM: {
        productNameFrontend: productKeys.MEETING_ROOM as const,
        productNameBackend: 'MEETING_ROOM',
        productBaseUrl: 'meeting-room',
        listingUrlSlug: '',
    } as const,
    VIRTUAL_OFFICE: {
        productNameFrontend: productKeys.VIRTUAL_OFFICE as const,
        productNameBackend: 'VIRTUAL_OFFICE',
        productBaseUrl: 'virtual-office',
        listingUrlSlug: 'virtual-office',
    } as const,
    MANAGED_OFFICE: {
        productNameFrontend: productKeys.MANAGED_OFFICE as const,
        productNameBackend: 'MANAGED_OFFICE',
        productBaseUrl: 'office-space',
        listingUrlSlug: 'office-space',
    } as const,
    ENTERPRISES: {
        productNameFrontend: productKeys.DEDICATED as const,
        productNameBackend: '',
        productBaseUrl: 'office-space-for-enterprises',
        listingUrlSlug: '',
    } as const,
} as const;

export const paymentCycleString = {
    MONTHLY: 'month',
    YEARLY: 'year',
};

export const planTypeMap = {
    flexi: 'Flexi Desks',
    'Dedicated Desks': 'Dedicated Desks',
    'Private Cabins': 'Private Cabins',
    'Managed Office': 'Managed Office',
};

export enum planType {
    FLEXI = 'Flexi Desks',
    DEDICATED = 'Dedicated Desks',
    CABIN = 'Private Cabins',
    MANAGED_OFFICE = 'Managed Office',
}

export enum spaceType {
    WORKCAFE = 'workcafe',
    COWORKING = 'coworking',
}

export enum ChatPromptType {
    CHAT = 'CHAT',
    CALL = 'CALL',
    CALLOUT = 'CALLOUT',
}

export enum ReservationDetailType {
    SIMPLE = 'SIMPLE',
    DETAILED = 'DETAILED',
}

export enum CardsLayoutType {
    HORIZONTAL = 'HORIZONTAL',
    VERTICAL = 'VERTICAL',
    FLEXIBLE = 'FLEXIBLE',
}

export enum CardSizes {
    MOB_LARGE_DESK_SMALL = 'MOB_LARGE_DESK_SMALL',
}

export enum InfoMessageType {
    IMPORTANT = 'IMPORTANT',
} // TODO add other types as well

export enum LocationWorkspaceSearchSuggestionType {
    LOCATION = 'LOCATION',
    WORKSPACE = 'WORKSPACE',
    LOCATION_AND_WORKSPACE = 'LOCATION_AND_WORKSPACE',
}

export enum TypeaheadTypes {
    DEFAULT = 'DEFAULT',
    SMALL = 'SMALL',
    LOCALITY_FILTER = 'LOCALITY_FILTER',
}

export enum PrefixIcon {
    SEARCH_ICON = 'SEARCH_ICON',
    LEFT_ICON = 'LEFT_ICON',
}

export enum geoLocationPermissionStatus {
    GRANTED = 'granted',
    PROMPT = 'prompt',
    DENIED = 'denied',
    UNABLE_TO_FETCH = 'unable',
}

export enum cityChangeSource {
    COOKIE = 'cookie',
    URL_CHANGE = 'urlchange',
    USER_ACTION = 'useraction',
}

export enum LocationCoordinatesErrorCodes {
    PRODUCT_NOT_SUPPORTED = 'PRODUCT_NOT_SUPPORTED',
    CITY_NOT_SUPPORTED = 'CITY_NOT_SUPPORTED',
}
export enum UrlPatternRegex {
    GENERIC_LISTING_PAGE = '^/(?:([^/]+?))(/coworking-space-in-|/dedicated-desks-in-|/private-cabins-in-)(?:([^/]+?))/?$',
}

export enum staticPageEventTypeEnums {
    PAGE_LOADED = 'page-loaded',
    REDIRECTION = 'redirection',
}

// space in start of below values is bcz they are suffix
export enum connectivityNameSuffix {
    MONORAIL = ' Monorail Station',
    LOCAL = ' Local Train Station',
    METRO = ' Metro Station',
}

export enum connectivityLineSuffix {
    MONORAIL = ' Line',
    LOCAL = ' Line',
    METRO = ' Line',
}

export enum formFieldType {
    INPUT = 'INPUT',
    DROPDOWN = 'DROPDOWN',
    MULTI_DROPDOWN = 'MULTI_DROPDOWN',
    SINGLE_SELECT_DROPDOWN = 'SINGLE_SELECT_DROPDOWN',
    TEXTAREA = 'TEXTAREA',
    OTP = 'OTP',
    PASSWORD = 'PASSWORD',
    DATE = 'DATE',
    TIME_DURATION = 'TIME_DURATION',
}

export enum citySlug {
    NEW_DELHI = 'new-delhi',
    BANGALORE = 'bangalore',
    NOIDA = 'noida',
    GURUGRAM = 'gurugram',
    HYDERABAD = 'hyderabad',
    FARIDABAD = 'faridabad',
    MUMBAI = 'mumbai',
    KOLKATA = 'kolkata',
    PUNE = 'pune',
    AHMEDABAD = 'ahmedabad',
    JAIPUR = 'jaipur',
    CHENNAI = 'chennai',
    GHAZIABAD = 'ghaziabad',
    VISHAKHAPATNAM = 'vishakhapatnam',
    BHOPAL = 'bhopal',
    INDORE = 'indore',
}

export enum formSuccessActionType {
    CALL = 'CALL',
    USER_REDIRECT = 'USER_REDIRECT',
    AUTO_REDIRECT = 'AUTO_REDIRECT',
    EMAIL = 'EMAIL',
    CLOSE = 'CLOSE',
}

export enum formPosition {
    HERO = 'HERO',
    MIDDLE = 'MIDDLE',
    BOTTOM = 'BOTTOM',
    STICKY = 'STICKY',
    POPUP = 'POPUP',
    LOCALITY_CARD = 'LOCALITY_CARD',
    CONTACT_CARD = 'CONTACT_CARD',
    LISTING_CARD = 'LISTING_CARD',
    SCHEDULE_VISIT_SCREEN = 'SCHEDULE_VISIT_SCREEN',
    SHARE_COMPARISON = 'SHARE_COMPARISON',
    BANNER = 'BANNER',
    WORKSPACE_CARD = 'WORKSPACE_CARD',
    HEADER = 'HEADER',
}

export enum filterType {
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    BOOLEAN = 'BOOLEAN',
    GROUP = 'GROUP',
    RANGE_INPUT = 'RANGE_INPUT',
    SINGLE_PILL_SELECT = 'SINGLE_PILL_SELECT',
}

export enum filterSubType {
    SEARCH = 'SEARCH',
    DATE_UI = 'DATE_UI',
    CITY_UI = 'CITY_UI',
    LOCALITY_UI = 'LOCALITY_UI',
    DATE_DURATION_TIME = 'DATE_DURATION_TIME',
    MR_CAPACITY_UI = 'MR_CAPACITY_UI',
    RANGE_UI = 'RANGE_UI',
}

// Filters that are fixed to be going to sent to BE from FE (as they are based on page URL), OR filters that coupled / shared between FE and BE
export enum CommonFilters {
    PRODUCT = 'PRODUCT', // Shared
    CITY = 'CITY', // Shared
    LOCALITIES = 'LOCALITIES', // Shared
    SORT_BY = 'SORT_BY', // Shared
    BOOKING_DATE = 'BOOKING_DATE', // Flexi listing page
    SEAT_TYPE = 'SEAT_TYPE', // Dedicated Lising
    FLEXI_SPACE_TYPE = 'FLEXI_SPACE_TYPE', // Flexi listing page
    BRANDS = 'BRANDS', // Brand listing page
    SOLUTION = 'SOLUTION', // Virtual Office listing page
    DATE_DURATION_TIME = 'DATE_DURATION_TIME', // Meeting Rooms listing page
    CAPACITY = 'CAPACITY', // Meeting Rooms listing page
}

export enum appDownloadRedirectAction {
    SMS = 'SMS',
    APP_STORE = 'APP_STORE',
    PLAY_STORE = 'PLAY_STORE',
}

export enum MapViewGestureEnum {
    COOPERATIVE = 'cooperative',
    GREEDY = 'greedy',
    NONE = 'none',
    AUTO = 'auto',
}

export enum staticTemplateKey {
    SUBSCRIPTION_WHATSINCLUDED = 'subscription_whatsincluded',
}

export enum formOpenSource {
    MOBILE_CTA = 'mobileCTA',
    LOCALITY_CARD = 'localityCard',
    MIDDLE_CTA = 'middleCTA',
}

export enum listingPageCard {
    BASIC_CARD = 'basicCard',
    MULTI_FEATURE = 'multiFeature',
    MULTI_FEATURE_FLEXI = 'multiFeatureFlexi',
    EXPERT_DETAIL = 'contactExpert',
    MY_HQ_ASSURED = 'myhqAssured',
    WORKSPACE_CARD = 'workspaceCard',
    MO_CALLOUT = 'moCallout',
    PROMO_CAROUSEL = 'PROMO_CAROUSEL',
    COMPARE_WORKSPACE = 'COMPARE_WORKSPACE',
    AVERAGE_PRICING_BANNER = 'AVERAGE_PRICING_BANNER',
    MO_COMPARISON = 'MO_COMPARISON',
    AMENITY_BANNER = 'AMENITY_BANNER',
    COWORKING_CALLOUT = 'COWORKING_CALLOUT',
    CLIENT_BANNER = 'CLIENT_BANNER',
    MARKET_PLACE = 'MARKET_PLACE',
}

export enum MarketingTags {
    BudgetFriendly = 'BudgetFriendly',
    PremiumAddress = 'PremiumAddress',
}

export enum DedicatedSocialTags {
    Popular = 'Popular',
}

export enum FlexiSocialTags {
    Popular = 'Popular',
}

export enum FlexiMarketingTags {
    Premier = 'Premier',
}

export enum FlexiOfferTags {
    FREE_TRIAL = 'FREE_TRIAL',
}

export enum FormType {
    DEFAULT = 'DEFAULT',
    MODAL = 'MODAL',
}

export const WorkspaceInventoryCardCarouselConfig: ICarouselConfig = {
    slidesToShow: 1.1,
    autoplay: false,
    dots: true,
    infinite: false,
    arrows: false,
    lazyLoad: 'ondemand',
};

export enum TabVariant {
    UNDERLINED = 'UNDERLINED',
    CONTAINED = 'CONTAINED',
    CONTAINED_DESK_SMALL = 'CONTAINED_DESK_SMALL',
    CONTAINED_DARK = 'CONTAINED_DARK',
    FILLED = 'FILLED',
}

export enum HeaderSearchVariant {
    DEFAULT = 'DEFAULT',
    DETAILED = 'DETAILED',
}

export enum InterceptorMetadataKeys {
    HQ_DISABLE_CACHE = 'HQ_DISABLE_CACHE',
}

export enum WorkspaceStatus {
    LIVE = 'LIVE',
    DRAFT = 'DRAFT',
    DECOMMISSIONED = 'DECOMMISSIONED',
}

export enum ScriptLoadStrategy {
    ASYNC = 'ASYNC',
    DEFER = 'DEFER',
}

export enum CalendarWorkspaceWorkHourStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
}

export enum products {
    INDIVIDUAL = 'INDIVIDUAL',
    CORPORATE = 'CORPORATE',
    DEDICATED = 'DEDICATED',
    MEETING_ROOM = 'MEETING_ROOM',
    VIRTUAL_OFFICE = 'VIRTUAL_OFFICE',
}

export const sizes = {
    X_SMALL: 'x-small',
    SMALL: 'small',
    DEFAULT: 'default',
    LARGE: 'large',
} as const;

export type sizes = (typeof sizes)[keyof typeof sizes];

export enum KeyboardKey {
    BACKSPACE = 'Backspace',
    DELETE = 'Delete',
}

export enum ListingPageStartingUrl {
    VIRTUAL_OFFICE = 'virtual-office-in',
    MEETING_ROOM = 'meeting-room-in',
    COWORKING_SPACE = 'coworking-space-in-',
    DEDICATED_DESK = 'dedicated-desk-in',
    PRIVATE_CABINS = 'private-cabins-in',
    MANAGED_OFFICE = '',
}

export enum WorkspaceSocialTagId {
    WORKSPACE_ACTIVITY = 'WORKSPACE_ACTIVITY',
    POPULAR_WORKSPACE = 'POPULAR_WORKSPACE',
}

export enum TestimonialKeys {
    ALL = 'all',
    DEDICATED_PRODUCT_PAGE = 'dedicatedProductPage',
    DEDICATED_LISTING_PAGE = 'dedicatedListingPage',
    CORPORATE_FLEXI_PRODUCT_PAGE = 'corporateFlexiProductPage',
    MANGED_OFFICE_AND_DEDICATED_PAGE = 'managedOfficeAndDedicatedPage',
    FLEXI_PRODUCT_PAGE = 'flexiProductPage',
    HOME_PAGE = 'homePage',
    VIRTUAL_OFFICE = 'virtualOffice',
    MEETING_ROOM = 'meetingRoom',
}
