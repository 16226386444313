<app-modals *ngIf="showModal" [template]="searchWrapper" (hideModal)="toggleModal(false)"></app-modals>

<div>
    <div class="search-box-cont d-flx" *ngIf="!config?.showMobileHeaderSearch" (click)="toggleModal(true)">
        <ng-container *ngTemplateOutlet="inputBoxTemplate"></ng-container>
    </div>
    <div
        *ngIf="config?.showMobileHeaderSearch"
        (click)="toggleModal(true)"
        class="search-icon-trigger svg-cont d-flx a-items-c"
        [innerHTML]="searchIcon"
    ></div>
</div>

<ng-template #searchWrapper>
    <div class="modal-search-container mobile-search-modal">
        <ng-container *ngIf="showModal && !isSearchStateOn">
            <div class="close-cont d-flx j-cont-space-bet a-items-b">
                <div (click)="toggleModal(false)" class="d-flx back-arrow-container">
                    <span class="icon arrow-with-tail left-arrow-with-tail primary">
                        <span></span>
                    </span>
                </div>
                <div class="select-city-cont">
                    <app-select-city></app-select-city>
                </div>
            </div>
            <div class="mobile-modal-search-heading" *ngIf="selectedCity">
                <h5 *ngIf="config?.showProductSwitch">Search in {{ selectedCity?.name }}</h5>
                <h5 *ngIf="!config?.showProductSwitch">
                    Search {{ productTitles[locationWorkspaceSearchConfig?.product?.productNameFrontend] }} in
                    {{ selectedCity?.name }}
                </h5>
            </div>
            <ng-container *ngIf="config?.showProductSwitch">
                <ng-container *ngTemplateOutlet="productTabsTemplate"></ng-container>
            </ng-container>
            <div class="search-box-cont mb-0 d-flex" (click)="toggleSearchState(true)">
                <ng-container *ngTemplateOutlet="inputBoxTemplate"></ng-container>
            </div>
            @defer {
                <ng-container
                    [ngTemplateOutlet]="defaultSearchResultsTemplate"
                    *ngIf="
                        locationWorkspaceSearchConfig.searchSuggestionType !==
                        LocationWorkspaceSearchSuggestionType.WORKSPACE
                    "
                ></ng-container>
                <ng-container
                    [ngTemplateOutlet]="workspaceSearchResultTemplate"
                    *ngIf="
                        locationWorkspaceSearchConfig.searchSuggestionType ===
                        LocationWorkspaceSearchSuggestionType.WORKSPACE
                    "
                ></ng-container>
            }
        </ng-container>
        <app-location-workspace-search
            *ngIf="isSearchStateOn"
            [config]="locationWorkspaceSearchConfig"
            (iconClickEvent)="iconClickEvent()"
            (locationWorkspaceSelectEvent)="onLocationWorkspaceSelectEvent($event)"
        />
    </div>
</ng-template>

<ng-template #productTabsTemplate>
    <div>
        <div class="search-cont">
            <div class="search-bar-cont d-flx active">
                <div class="btn-cont d-flx j-cont-space-bet">
                    <ng-container *ngFor="let productButton of productButtons; last as isLast">
                        <div
                            class="product-btn d-flx a-items-c j-cont-cen c-pointer"
                            [ngClass]="{
                                selected: productButton.selected,
                                last: isLast,
                            }"
                            (click)="switchProduct(productButton)"
                        >
                            <div class="image-cont">
                                <img
                                    [src]="
                                        productButton.selected
                                            ? productButton.imageWhiteUrl
                                            : productButton.imageDarkUrl
                                    "
                                    alt="icon"
                                />
                            </div>
                            {{ productButton.text }}
                            <div class="down-arrow"></div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #inputBoxTemplate>
    <div class="search-icon-container d-flex a-items-c svg-cont" [innerHTML]="searchIcon"></div>
    <input
        type="text"
        class="search-box text-ellipsis"
        [placeholder]="locationWorkspaceSearchConfig.typeaheadConfig?.placeholder || 'Search location or a workspace'"
        [value]="locationWorkspaceSearchConfig.typeaheadInitValue || ''"
    />
</ng-template>

<ng-template #defaultSearchResultsTemplate>
    <div class="default-result-container">
        <app-near-me [config]="nearMeComponentConfig" (callback)="handleNearMeClick($event)"></app-near-me>
        <div class="result-card">
            <app-recently-searched
                [heading]="'Recent searches'"
                (clickRecentSearch)="defaultLocationWorkspaceSelectEvent($event)"
            >
            </app-recently-searched>
        </div>
        <div class="result-card">
            <app-popular-locations
                [heading]="popularLocationLabel"
                (clickLocation)="defaultLocationWorkspaceSelectEvent($event)"
                [maxLocationCount]="popularLocationCount"
                [product]="locationWorkspaceSearchConfig?.product"
            >
            </app-popular-locations>
            <app-popular-workspaces
                [heading]="popularWorkspacesLabel"
                (clickWorkspace)="defaultLocationWorkspaceSelectEvent($event)"
                [product]="locationWorkspaceSearchConfig?.product"
            >
            </app-popular-workspaces>
            <div class="d-flx explore-link" (click)="toggleModal(false)">
                <app-link-with-icon
                    [LinkText]="workspaces.link.text"
                    [url]="workspaces.link.url"
                    [size]="'large'"
                    [isExternalUrl]="false"
                    [linkColor]="'primary'"
                    [lineBreakType]="lineBreakType.BREAK_LINE"
                ></app-link-with-icon>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #workspaceSearchResultTemplate>
    <div class="default-result-container" *ngIf="showSearchResultTemplate">
        <div class="result-card">
            <app-recently-searched
                [heading]="'Recent searches'"
                (clickRecentSearch)="defaultLocationWorkspaceSelectEvent($event)"
                [showLocations]="false"
            >
            </app-recently-searched>
        </div>
        <div class="result-card">
            <app-popular-workspaces
                [heading]="popularWorkspacesLabel"
                (clickWorkspace)="defaultLocationWorkspaceSelectEvent($event)"
                [product]="locationWorkspaceSearchConfig?.product"
            >
            </app-popular-workspaces>
            <div class="d-flx explore-link" (click)="toggleModal(false)">
                <app-link-with-icon
                    [LinkText]="workspaces.link.text"
                    [url]="workspaces.link.url"
                    [size]="'large'"
                    [isExternalUrl]="false"
                    [linkColor]="'primary'"
                    [lineBreakType]="lineBreakType.BREAK_LINE"
                ></app-link-with-icon>
            </div>
        </div>
    </div>
</ng-template>
