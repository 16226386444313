<form [formGroup]="form" [ngClass]="className" (submit)="onSubmit($event)">
    <div class="d-flx height-100" [ngClass]="cssConfig[config.formType || IFormsType.DEFAULT]">
        <div *ngFor="let fieldGroup of config.fields" class="form-item-container">
            <p *ngIf="fieldGroup?.title" class="font-14 font-bold margin-bottom-8">{{ fieldGroup.title }}</p>
            <ng-container *ngIf="fieldGroup?.length > 0">
                <div class="d-flx fields-cont">
                    <ng-container *ngFor="let field of fieldGroup">
                        <div class="field-cont">
                            <div formField [field]="field" [form]="form"></div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="!fieldGroup?.length > 0">
                <div formField [field]="fieldGroup" [form]="form"></div>
            </ng-container>
        </div>
        <ng-container *ngIf="config.optionalFields">
            <div *ngIf="!config.optionalFields.show" class="heading-container">
                <div
                    class="close-text c-pointer"
                    (click)="openOptionalFields()"
                    *ngIf="config.optionalFields?.showOptionalFieldsCTAConfig?.show"
                >
                    {{ config.optionalFields.showOptionalFieldsCTAConfig.label }}
                </div>
            </div>
            <div *ngIf="config.optionalFields.show">
                <div class="d-flx j-cont-space-bet width-100">
                    <div class="heading-container">
                        <div class="open-text">{{ config.optionalFields.headingOpen }}</div>
                    </div>
                    <p
                        (click)="hideOptionalFields()"
                        class="hide-optional-fields-cta c-pointer"
                        *ngIf="config.optionalFields?.hideOptionalFieldsCTAConfig?.show"
                    >
                        {{ config.optionalFields.hideOptionalFieldsCTAConfig?.label }}
                    </p>
                </div>
            </div>
            <ng-container *ngIf="config.optionalFields.show">
                <div formGroupName="optionalFields">
                    <div *ngFor="let field of config.optionalFields.fields">
                        <div formField [field]="field" [form]="form"></div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-content select="[postFormFields]"></ng-content>
        <ng-content></ng-content>
        <ng-container
            *ngIf="mobCtaTemplate && deviceInfo.isMobile"
            [ngTemplateOutlet]="mobCtaTemplate"
            [ngTemplateOutletContext]="{ buttonsTemplate }"
        ></ng-container>
        <ng-container
            *ngIf="!mobCtaTemplate || !deviceInfo.isMobile"
            [ngTemplateOutlet]="buttonsTemplate"
        ></ng-container>
    </div>
</form>

<ng-template #buttonsTemplate>
    <div
        class="inline-form-button"
        [ngClass]="config.ctaContainerClass"
        class="flx-1"
        *ngFor="let button of config.ctas; let i = index"
    >
        <div *ngIf="!button.hidden" formButton [button]="button" [form]="form"></div>
    </div>
</ng-template>
