import { Component, Input, OnInit } from '@angular/core';
import { CommonIconsUrl } from '../../../../../core/constants/common-icons-url';
import { ISearchUnsupportedProductBannerConfig } from '../../search.interface';
import { productKeys } from '../../../../enums/enums';
import { DirectivesModule } from '../../../../directives/directives.module';

@Component({
    selector: 'app-search-unsupported-product-banner',
    templateUrl: './search-unsupported-product-banner.component.html',
    styleUrls: ['./search-unsupported-product-banner.component.scss'],
    standalone: true,
    imports: [DirectivesModule],
})
export class SearchUnsupportedProductBannerComponent implements OnInit {
    title = '';
    config: ISearchUnsupportedProductBannerConfig;
    @Input('config') set updateConfig(data: ISearchUnsupportedProductBannerConfig) {
        if (data) {
            this.config = data;
            this.setBannerTitle();
        }
    }
    CommonIconsUrl = CommonIconsUrl;
    constructor() {}

    ngOnInit(): void {}

    setBannerTitle() {
        switch (this.config.product.productNameFrontend) {
            case productKeys.FLEXI:
                this.title = `Sorry, Day Pass is not available in ${this.config.city.name} yet!`;
                break;
            case productKeys.DEDICATED:
                this.title = `Sorry, Coworking space are not available in ${this.config.city.name} yet!`;
                break;
            case productKeys.VIRTUAL_OFFICE:
                this.title = `Sorry, Virtual Offices are not available in ${this.config.city.name} yet!`;
                break;
            case productKeys.MEETING_ROOM:
                this.title = `Sorry, Meeting Rooms are not available in ${this.config.city.name} yet!`;
                break;
        }
    }
}
