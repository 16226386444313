import { NgModule } from '@angular/core';
import { WorkspaceSearchListShimmerComponent } from './shimmers/workspace-search-list-shimmer/workspace-search-list-shimmer.component';
import { WorkspaceCardDetailedShimmerComponent } from './shimmers/workspace-card-detailed-shimmer/workspace-card-detailed-shimmer.component';
import { VoPlanCardShimmerComponent } from './shimmers/vo-plan-card-shimmer/vo-plan-card-shimmer.component';
import { FiltersShimmerComponent } from './shimmers/filters-shimmer/filters-shimmer.component';
import { BreadcrumbShimmerComponent } from './shimmers/breadcrumb-shimmer/breadcrumb-shimmer.component';
import { CommonModule } from '@angular/common';
import { ImageGalleryShimmerComponent } from './shimmers/image-gallery-shimmer/image-gallery-shimmer.component';
import { AcceptInviteShimmerComponent } from './shimmers/accept-invite-shimmer/accept-invite-shimmer.component';

@NgModule({
    declarations: [
        VoPlanCardShimmerComponent,
        FiltersShimmerComponent,
        BreadcrumbShimmerComponent,
        WorkspaceSearchListShimmerComponent,
        WorkspaceCardDetailedShimmerComponent,
        ImageGalleryShimmerComponent,
        AcceptInviteShimmerComponent,
    ],
    imports: [CommonModule],
    exports: [
        WorkspaceSearchListShimmerComponent,
        WorkspaceCardDetailedShimmerComponent,
        FiltersShimmerComponent,
        BreadcrumbShimmerComponent,
        VoPlanCardShimmerComponent,
        ImageGalleryShimmerComponent,
        AcceptInviteShimmerComponent,
    ],
    providers: [],
})
export class StaticComponentsModule {}
