import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { DirectivesModule } from '../../directives/directives.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { ITimeDurationFormFieldConfig } from './time-duration-form-field.interface';
import { ButtonsModule } from '../buttons/buttons.module';
import { IFilterConfig } from '../../standalone-components/filters/interfaces/filters.interface';
import { buttonType, filterType, fontSizes, fontWeights } from '../../enums/enums';
import { TimeSlotsValue } from '../../component-modules/date-duration-time-filter/date-duration-time-filter.enum';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { IButtonConfig } from '../../interfaces/button.interface';
import { DurationFilterComponent } from '../../standalone-components/duration-filter/duration-filter.component';
import { TimeFilterComponent } from '../../standalone-components/time-filter/time-filter.component';

@Component({
    standalone: true,
    selector: 'app-time-duration-form-field',
    templateUrl: './time-duration-form-field.component.html',
    styleUrls: ['./time-duration-form-field.component.scss'],
    imports: [
        CommonModule,
        forwardRef(() => DirectivesModule),
        ReactiveFormsModule,
        ButtonsModule,
        DurationFilterComponent,
        TimeFilterComponent,
    ],
})
export class TimeDurationFormFieldComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() field: ITimeDurationFormFieldConfig;
    CommonIconsUrl = CommonIconsUrl;
    showDropdown = false;
    uniqueId: number;
    isInputClickedOnce = false;
    selectedDuration = 1;
    selectedSlots: TimeSlotsValue[];
    durationLabel = '';
    timeSlotsLabel = '';
    durationFilterConfig: IFilterConfig = this.getDefaultDurationConfig();
    timeSlotFilterConfig: IFilterConfig = this.getDefaultTimeSlotConfig();
    cancelBtnConfig: IButtonConfig = {
        type: buttonType.LINK,
        label: 'Cancel',
        size: fontSizes.MEDIUM_MOB_DESK,
        fontWeight: fontWeights.BOLD,
        cssClass: 'btn-medium-grey',
    };
    applyBtnConfig: IButtonConfig = {
        type: buttonType.LINK,
        label: 'Apply',
        size: fontSizes.MEDIUM_MOB_DESK,
        fontWeight: fontWeights.BOLD,
    };
    constructor(private utilsService: UtilsService) {}

    ngOnInit() {
        this.uniqueId = this.utilsService.getUniqueId(); // Different Child Components have unique IDs.
        if (this.field?.value) {
            const { time, duration } = this.field.value;
            if (time) {
                this.timeSlotFilterConfig = {
                    ...this.timeSlotFilterConfig,
                    selected: time,
                };
            }

            if (duration) {
                this.durationFilterConfig = {
                    ...this.durationFilterConfig,
                    selected: duration.toString(),
                };
            }
            this.updateFieldValue();
        } else {
            // Setting Initial Value as empty
            this.form.get(this.field.name).setValue('');
        }
    }

    getDefaultDurationConfig(): IFilterConfig {
        return {
            type: filterType.GROUP,
            id: 'DURATION',
            title: 'Duration',
            default: 1,
            selected: '1',
            config: {
                minLimit: 1,
                maxLimit: 10,
            },
            data: [],
        };
    }

    getDefaultTimeSlotConfig(): IFilterConfig {
        return {
            type: filterType.MULTI_SELECT,
            title: 'Time Slot',
            id: 'TIME_SLOT',
            defaultValue: '',
            default: [],
            selected: [],
            config: {},
            data: this.getTimeSlotsData(),
        };
    }

    getTimeSlotsData() {
        return [
            {
                id: TimeSlotsValue.FULL_DAY,
                title: 'Full Day',
            },
            {
                id: TimeSlotsValue.MORNING,
                title: 'Morning',
                description: 'before 12 noon',
            },
            {
                id: TimeSlotsValue.AFTERNOON,
                title: 'Afternoon',
                description: '12 noon to 4 pm',
            },
            {
                id: TimeSlotsValue.EVENING,
                title: 'Evening',
                description: 'after 4 pm',
            },
        ];
    }

    getSelectedSlotConfigById(id: TimeSlotsValue) {
        const timeSlots = this.getTimeSlotsData();
        return timeSlots.find(slot => {
            return slot.id === id;
        });
    }

    handleDurationUpdate(duration: number) {
        this.selectedDuration = duration;
    }

    handleSlotSelection(slots: TimeSlotsValue[]) {
        this.selectedSlots = slots;
    }

    toggleDropdown(show: boolean) {
        if (show) {
            this.isInputClickedOnce = true;
        }
        this.showDropdown = show;
    }

    updateDisplayLabels() {
        if (this.selectedDuration) {
            this.durationLabel = `${this.selectedDuration} ${this.utilsService.pluralise(
                'hour',
                this.selectedDuration
            )}`;
        }
        if (this.selectedSlots?.length) {
            const selectedSlotConfig = this.getSelectedSlotConfigById(this.selectedSlots[0]);
            this.timeSlotsLabel = selectedSlotConfig.title;
        }
    }

    updateFieldValue() {
        // TODO: FINALISE THE FORMAT, ONCE BE API IS DONE
        const parsedFieldValue = this.getParsedFieldValue();
        this.form.get(this.field.name).setValue(parsedFieldValue);
        this.updateDisplayLabels();
        this.toggleDropdown(false);
    }

    getParsedFieldValue() {
        // TODO: UPDATE LOGIC AFTER BE
        let finalValue = '';

        if (this.selectedDuration) {
            finalValue = `${this.selectedDuration} hour(s)`;
        }

        if (this.selectedSlots?.length) {
            const selectedSlotConfig = this.getSelectedSlotConfigById(this.selectedSlots[0]);
            finalValue = `${finalValue} . ${selectedSlotConfig.title}`;
        }
        if (this.selectedSlots?.length > 1) {
            finalValue = `${finalValue} (+${this.selectedSlots.length - 1})`;
        }

        return finalValue;
    }
}
