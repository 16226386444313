<div class="d-flx flx-d-clm width-100 time-filter-container">
    <div class="d-flx a-items-c label-container">
        <div class="d-flx a-items-c j-cont-cen time-icon-container">
            <img
                class="height-100 width-100 object-fit-contain"
                appImageShimmerLazyLoad
                [isATFContent]="true"
                [imageSrc]="CommonIconsUrl.CLOCK_ICON"
                alt="clock"
            />
        </div>
        <p class="font-14 font-normal text-color-dark">Time</p>
        <p class="font-10 font-normal text-color-lighter-grey">(optional)</p>
    </div>
    <div class="d-flx flx-d-clm slot-list-container">
        <ng-container
            [ngTemplateOutlet]="slotItem"
            *ngFor="let slot of timeSlots"
            [ngTemplateOutletContext]="slot"
        ></ng-container>
    </div>
</div>

<!--templates-->
<ng-template #slotItem let-label="label" let-value="value" let-description="description">
    <div class="d-flx a-items-c slot-item-container" (click)="handleSelectSlot(value)">
        <app-check [config]="{ checked: isSlotSelected(value), value }"></app-check>
        <p class="font-14 font-normal text-color-dark">{{ label }}</p>
        <p class="font-12 font-normal text-color-lighter-grey" *ngIf="description">{{ description }}</p>
    </div>
</ng-template>
