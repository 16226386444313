<div class="near-me-cont">
    <div class="near-me">
        <app-button
            [field]="{
                type: buttonType.LINK,
                label: config?.label || 'Show space near me',
                icon: 'current-location-flat.svg',
                cssClass: 'current-location',
                iconPosition: 'left',
                disabled: disableNearMeBtn
            }"
            [showLoader]="showLoader"
            (onClick)="onClickSelectLocation()"
        ></app-button>
    </div>
    <div class="error-info" *ngIf="showError">
        <p>Location access blocked. Please manually search a location or allow location access</p>
    </div>
</div>
