import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { ICheckConfig } from './check.interface';

@Component({
    selector: 'app-check',
    templateUrl: './check.component.html',
    styleUrls: ['./check.component.scss'],
})
export class CheckComponent implements OnInit {
    commonIconsUrl = CommonIconsUrl;
    @Input() config: ICheckConfig;
    @Output() onClick: EventEmitter<any> = new EventEmitter();
    ngOnInit() {}
    handleClick(event) {
        this.onClick.emit(event);
    }
}
