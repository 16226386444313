import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    IMeetingRoomBookingDataAPIPayload,
    IMeetingRoomBookingDataResponse,
    IMeetingRoomCapacityModalViewStateSubPayload,
    IMeetingRoomCheckoutBreadcrumbsPayload,
    IMeetingRoomInitiateBookingAPIPayload,
    IMeetingRoomPaymentOptionsData,
    IMeetingRoomReservationData,
    IMeetingRoomReservationShareDetailsData,
    IMeetingRoomWorkspaceCardData,
    IMeetingRoomWorkspaceSpaceDetailAPIResponse,
    IParsedMeetingRoomsWorkspaceDetail,
    IParsedMeetingRoomWorkspaceCardData,
} from '../../../modules/meeting-rooms/meeting-rooms.interface';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { meetingRoomsRegex } from '../../../core/constants/urls-regex';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from '../../../core/services/error/error.service';
import { environment } from '../../../../environments/environment';
import { IHttpResponse } from '../../interfaces/HTTPResponse.interface';
import { catchError, map } from 'rxjs/operators';
import { WorkspaceService } from '../workspace/workspace.service';
import { IExpertData } from '../../component-modules/space-details/brief-space-contact-expert-card/brief-space-contact-expert-card.interface';
import { ImageBaseUrl } from '../../../core/constants/constants';
import {
    MeetingRoomReservationErrorSubTypes,
    MeetingRoomReservationErrorTypes,
} from '../../../modules/meeting-rooms/meeting-rooms.enums';
import { IWorkspaceInventoryPlanAmenity } from '../../standalone-components/workspace-plan-inventory-card/workspace-plan-inventory-card.interface';
import { CommonService } from '../common/common.service';
import { IWorkHours, IWorkspaceContentTag } from '../../interfaces/workspace.interface';
import { InterceptorMetadataKeys, productsMap } from '../../enums/enums';
import { formatDate } from '@angular/common';
import { CustomHttpParams } from '../../../core/interceptors/custom-params.interceptor';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { ElementType } from '../../interfaces/structured-content.interface';
import { IOfferCarouselComponentConfig } from '../../standalone-components/offer-carousel/offer-carousel.interface';
import { ICouponCode } from '../../component-modules/payments/payments.interface';
import { catchAPIError } from '../../operators';
import { ApiError, APIErrorKind } from '../../interfaces/common.interface';
import { SnackbarService } from '../../standalone-components/snackbar/services/snackbar.service';
import { SnackbarType } from '../../standalone-components/snackbar/snackbar.enum';

@Injectable({
    providedIn: 'root',
})
export class MeetingRoomsService {
    private capacityModalViewState: BehaviorSubject<IMeetingRoomCapacityModalViewStateSubPayload> = new BehaviorSubject(
        null
    );

    constructor(
        private http: HttpClient,
        private errorService: ErrorService,
        private utilsService: UtilsService,
        private workspaceService: WorkspaceService,
        private commonService: CommonService,
        private snackbarService: SnackbarService
    ) {}

    toggleCapacityModal(show: boolean, workspaceSlug?: string) {
        this.capacityModalViewState.next({ show, workspaceSlug });
    }

    getCapacityModalViewStateSub(): Observable<IMeetingRoomCapacityModalViewStateSubPayload> {
        return this.capacityModalViewState;
    }

    isMeetingRoomsUrl(url: string): boolean {
        return this.utilsService.getRegexPatternMatch([meetingRoomsRegex.allUrls], url) >= 0;
    }

    parseMeetingRoomsSpaceDetailUrl(url: string): {
        isValidUrl: boolean;
        url: string;
        workspaceSlug?: string;
        capacity?: string;
    } {
        if (this.utilsService.getRegexPatternMatch([meetingRoomsRegex.spaceDetailPage], url) < 0) {
            return {
                isValidUrl: false,
                url,
            };
        } else {
            const urlParts = url.split('/');

            const workspaceSlug = urlParts[2];

            const capacityUrlParts = url.split('?capacity=');
            let capacity: string;

            if (capacityUrlParts.length > 1) {
                capacity = capacityUrlParts[1];
            }

            return {
                isValidUrl: true,
                url,
                workspaceSlug,
                capacity,
            };
        }
    }

    getWorkspaceDetails(
        workspaceSlug: string,
        capacity?: string
    ): Observable<IMeetingRoomWorkspaceSpaceDetailAPIResponse> {
        let url = `${environment.baseUrl}/meeting-room/web/${workspaceSlug}`;
        if (capacity) {
            url += `?capacity=${capacity}`;
        }
        return this.http.get<IHttpResponse<IMeetingRoomWorkspaceSpaceDetailAPIResponse>>(url).pipe(
            map(res => {
                if (res?.success) {
                    return res?.data;
                } else {
                    console.warn('Something went wrong in meeting room space detail API');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }
    getWorkspaceOfferDetails(workspaceSlug: string): Observable<any> {
        const url = `${environment.baseUrl}/meeting-room/web/${workspaceSlug}/workspace-offer-details`;
        const customHttpParams = new CustomHttpParams({
            interceptorMetadata: {
                [InterceptorMetadataKeys.HQ_DISABLE_CACHE]: true,
            },
        });
        const httpOptions = {
            params: customHttpParams,
        };
        return this.http.get<IHttpResponse<any>>(url, httpOptions).pipe(
            map(res => {
                if (res?.success) {
                    return res?.data;
                } else {
                    console.warn('Something went wrong in meeting room offer detail API');
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    parseWorkspaceCardData(data: IMeetingRoomWorkspaceCardData): IParsedMeetingRoomWorkspaceCardData {
        return {
            ...data,
            seaterLabel: `${data.meetingroominventories?.[0]?.capacity} Seater Meeting Room`,
            displayName: this.workspaceService.getFullWorkspaceDisplayName(data),
            address: `${data.location}, ${data.city}`,
            distanceLabel: this.workspaceService.parseDistanceLabel(data.distance),
            connectivityDetails: this.workspaceService.getPrimaryConnectivity(data.directions?.connectivityDetails),
            meetingroominventories: data.meetingroominventories?.map(inventoryItem => {
                return {
                    ...inventoryItem,
                    redirectUrl: decodeURIComponent(inventoryItem.redirectUrl),
                    bookingRedirectUrl: inventoryItem?.bookingRedirectUrl
                        ? decodeURIComponent(inventoryItem.bookingRedirectUrl)
                        : undefined,
                };
            }),
        };
    }

    getSpaceExpertDetail(workspace: IParsedMeetingRoomsWorkspaceDetail): IExpertData {
        return {
            name: 'Anuj Verma',
            firstName: `Anuj`,
            image: {
                url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/myhq-experts/meeting-rooms/meeting-rooms-expert-anuj-verma.png`,
                alt: `expert photo`,
            },
            heading: 'Looking for an expert help to book meeting room?',
            subheading: `Share your requirements and Anuj will get back to you with personalised recommendations.`,
            phone: this.commonService.getMaskedString(`+919595830467`, 6, 11),
            introduction: `All coworking spaces are handled by myHQ experts. Connect with our expert to know more about this workspace.`,
            experience: `Anuj and his team have helped 200+ clients find meeting rooms in ${workspace.region}.`,
            description: `Our expert handles this workspace and other spaces in same region.`,
            services: [
                `Shortlisting meeting room exactly as per your requirement`,
                `Quick & guaranteed booking confirmation`,
                `Payment negotiation and flexible options`,
                `Catering to customised requirements`,
            ],
        };
    }

    getBookingConfirmExpertDetail(): IExpertData {
        return {
            name: 'Anuj Verma',
            firstName: `Anuj`,
            image: {
                url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/myhq-experts/meeting-rooms/meeting-rooms-expert-anuj-verma.png`,
                alt: `expert photo`,
            },
            phone: this.commonService.getMaskedString(`+919595830467`, 6, 11),
            introduction: 'Prefer to plan for your meetings in advance? Contact us to reserve for multiple dates',
            experience: '',
            description: 'Anuj and their team will help you with team booking & multi-date bookings',
            services: [`Booking Confirmation`, `Payment Queries`],
        };
    }

    getListingExpertDetail(region: string, dataVersion: string): IExpertData {
        return {
            name: 'Anuj Verma',
            firstName: `Anuj`,
            image: {
                url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/myhq-experts/meeting-rooms/meeting-rooms-expert-anuj-verma.png`,
                alt: `expert photo`,
            },
            phone: this.commonService.getMaskedString(`+918655830460`, 3, 8),
            heading: 'Looking for an expert help to book meeting room?',
            subheading: `Share your requirements and Anuj will get back to you with personalised recommendations.`,
            introduction: `All coworking spaces are handled by myHQ experts. Connect with our expert to know more about this workspace.`,
            experience: `Anuj and his team have helped 200+ companies find meeting rooms in ${region}.`,
            description: `Our expert handles this workspace and other spaces in same region.`,
            services: [
                `Shortlisting meeting room exactly as per your requirement`,
                `Quick & guaranteed booking confirmation`,
                `Payment negotiation and flexible options`,
                `Catering to customised requirements`,
            ],
        };
    }

    getGenericMeetingRoomEquipments(): IWorkspaceInventoryPlanAmenity[] {
        const equipmentsBaseUrl = `${ImageBaseUrl.IMAGE_URL_BASE}/product/meeting-room/equipments`;
        return [
            {
                label: 'Projector',
                icon: {
                    url: `${equipmentsBaseUrl}/projector.svg`,
                    alt: 'Projector',
                },
            },
            {
                label: 'Monitor',
                icon: {
                    url: `${equipmentsBaseUrl}/monitor_tv.svg`,
                    alt: 'Monitor',
                },
            },
            {
                label: 'Whiteboard',
                icon: {
                    url: `${equipmentsBaseUrl}/whiteboard.svg`,
                    alt: 'Whiteboard',
                },
            },
            {
                label: 'Video Conference',
                icon: {
                    url: `${equipmentsBaseUrl}/video_conference.svg`,
                    alt: 'Video Conference',
                },
            },
            {
                label: 'Wifi',
                icon: {
                    url: `${equipmentsBaseUrl}/wifi.svg`,
                    alt: 'Wifi',
                },
            },
            {
                label: 'Speaker',
                icon: {
                    url: `${equipmentsBaseUrl}/speakers.svg`,
                    alt: 'Speaker',
                },
            },
        ];
    }

    parseMeetingRoomSlot({
        startTimeInMinutes,
        endTimeInMinutes,
    }: {
        startTimeInMinutes: number;
        endTimeInMinutes: number;
    }) {
        const transformMinutesToHoursString = (minutes: number) => {
            const date = new Date();
            date.setHours(0, minutes, 0);
            return formatDate(date, 'shortTime', 'en-US');
        };
        return {
            startTimeLabel: transformMinutesToHoursString(startTimeInMinutes),
            endTimeLabel: transformMinutesToHoursString(endTimeInMinutes),
            duration: (endTimeInMinutes - startTimeInMinutes) / 60,
        };
    }

    getMeetingRoomBookingData(payload: IMeetingRoomBookingDataAPIPayload): Observable<IMeetingRoomBookingDataResponse> {
        const url = `${environment.baseUrl}/reservations/meeting-room/web/room-list/${payload.workspaceSlug}/${payload.capacity}?date=${payload.selectedDate}`;
        const customHttpParams = new CustomHttpParams({
            interceptorMetadata: {
                [InterceptorMetadataKeys.HQ_DISABLE_CACHE]: true,
            },
        });
        const httpOptions = {
            params: customHttpParams,
        };
        return this.http.get<IHttpResponse<IMeetingRoomBookingDataResponse>>(url, httpOptions).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in Meeting Room Booking Data API');
                }
            }, catchError(this.errorService.handleError))
        );
    }

    getTodaysTimingObj(workHours: IWorkHours, selectedDate: Date) {
        const dayIndex = new Date(selectedDate).getDay();
        return workHours[dayIndex];
    }

    // Meeting Room Reservations
    initiateMeetingRoomReservation(payload: IMeetingRoomInitiateBookingAPIPayload): Observable<any> {
        const url = `${environment.baseUrl}/reservations/meeting-room/web/initiate`;
        return this.http.post<IHttpResponse<any>>(url, payload);
    }

    getMeetingRoomReservationWithValidation(reservationId: string): Observable<IMeetingRoomReservationData> {
        const url = `${environment.baseUrl}/reservations/meeting-room/web/${reservationId}`;
        return this.http.get<IHttpResponse<IMeetingRoomReservationData>>(url).pipe(
            catchAPIError(this.errorService, {
                onError: (err: ApiError<IHttpResponse<IMeetingRoomReservationData>>) => {
                    if (err.kind === APIErrorKind.HTTP_ERROR) {
                        this.snackbarService.open({
                            title: 'Server Error',
                            description: 'Failed to get reservation summary, Please try again later.',
                            type: SnackbarType.ERROR,
                        });
                    } else if (err.kind === APIErrorKind.SUCCESS_FALSE) {
                        const errorMessage = err.res?.reason || err.res?.errors?.[0]?.errorMessage;
                        this.snackbarService.open({
                            title: 'Reservation Service Failed!',
                            description: errorMessage,
                            type: SnackbarType.ERROR,
                        });
                    }
                },
            }),
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in getMeetingRoomReservation API');
                    return res.data;
                }
            }, catchError(this.errorService.handleError))
        );
    }

    getMeetingRoomReservationStatus(reservationId: string): Observable<IHttpResponse<IMeetingRoomReservationData>> {
        const url = `${environment.baseUrl}/reservations/meeting-room/web/${reservationId}/status`;
        return this.http.get<IHttpResponse<IMeetingRoomReservationData>>(url).pipe(
            map(res => {
                return res;
            }, catchError(this.errorService.handleError))
        );
    }

    getMeetingRoomPaymentSummary(reservationId: string): Observable<IMeetingRoomPaymentOptionsData> {
        const url = `${environment.baseUrl}/reservations/meeting-room/web/${reservationId}/paymentsummary`;
        return this.http.get<IHttpResponse<IMeetingRoomPaymentOptionsData>>(url).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in getMeetingRoomPaymentOptions API');
                    return res.data;
                }
            }, catchError(this.errorService.handleError))
        );
    }

    confirmReservation(reservationId: string): Observable<IHttpResponse<IMeetingRoomReservationData>> {
        const url = `${environment.baseUrl}/reservations/meeting-room/web/${reservationId}/confirm`;
        return this.http.put<IHttpResponse<IMeetingRoomReservationData>>(url, undefined).pipe(
            map(res => {
                return res;
            }, catchError(this.errorService.handleError))
        );
    }

    shareInfoReservation(reservationId: string): Observable<IMeetingRoomReservationShareDetailsData> {
        const url = `${environment.baseUrl}/reservations/meeting-room/web/${reservationId}/share`;
        return this.http.get<IHttpResponse<IMeetingRoomReservationShareDetailsData>>(url).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in shareInfoReservation API');
                }
            }, catchError(this.errorService.handleError))
        );
    }

    getWorkspacePromos(reservationId: string): Observable<{ coupons: ICouponCode[] }> {
        const url = `${environment.baseUrl}/reservations/meeting-room/web/${reservationId}/campaign-promo`;
        return this.http.get<IHttpResponse<any>>(url).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in getWorkspacePromos API');
                }
            }, catchError(this.errorService.handleError))
        );
    }

    applyWorkspacePromos(reservationId: string, body: { code: string }): Observable<any> {
        const url = `${environment.baseUrl}/reservations/meeting-room/web/${reservationId}/campaign-promo`;
        return this.http.put<IHttpResponse<any>>(url, body).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in applyWorkspacePromos API');
                }
            }, catchError(this.errorService.handleError))
        );
    }

    removeWorkspacePromos(reservationId: string): Observable<any> {
        const url = `${environment.baseUrl}/reservations/meeting-room/web/${reservationId}/campaign-promo`;
        return this.http.delete<IHttpResponse<any>>(url).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                } else {
                    console.warn('Something went wrong in removeWorkspacePromos API');
                }
            }, catchError(this.errorService.handleError))
        );
    }

    meetingRoomCheckoutBreadcrumbs(payload: IMeetingRoomCheckoutBreadcrumbsPayload): Observable<any> {
        const url = `${environment.baseUrl}/reservations/meeting-room/web/breadcrumbs`;
        return this.http
            .get<IHttpResponse<any>>(url, {
                params: { ...payload },
            })
            .pipe(
                map(res => {
                    if (res?.success) {
                        return res.data;
                    } else {
                        console.warn('Something went wrong in meetingRoomCheckoutBreadcrumbs API');
                    }
                }, catchError(this.errorService.handleError))
            );
    }

    isInventorySoldOut(error?: {
        type: MeetingRoomReservationErrorTypes;
        subType: MeetingRoomReservationErrorSubTypes;
    }): boolean {
        return error?.type === MeetingRoomReservationErrorTypes.INVENTORY;
    }

    getCancellationPolicy(workspaceId: string) {
        const url = `${environment.baseUrl}/statictemplate/web/cancellationpolicy/${workspaceId}?product=${productsMap.MEETING_ROOM.productNameBackend}`;
        return this.http.get<any>(url).pipe(
            map((res: IHttpResponse<any>) => {
                if (res && res.success) {
                    return res.data;
                } else {
                    console.warn('Error :: in getting plan getCancellationPolicy', url);
                    return null;
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    parseOfferTags(offerTags: IWorkspaceContentTag[]): IOfferCarouselComponentConfig {
        return {
            items: offerTags?.map(offer => [
                {
                    type: ElementType.SPACE,
                    data: {
                        align: 'start',
                        size: 8,
                        direction: 'horizontal',
                        children: [
                            { type: ElementType.ICON, data: { size: 14, src: CommonIconsUrl.OFFER_GREEN } },
                            {
                                type: ElementType.SECTION,
                                data: {
                                    children: offer.content,
                                },
                            },
                        ],
                    },
                },
            ]),
        };
    }
}
