import { Observable, catchError, tap } from 'rxjs';
import { ErrorService } from '../../core/services/error/error.service';
import { IHandleHttpResponseErrorOptions } from '../interfaces/common.interface';
import { IHttpResponse } from '../interfaces/HTTPResponse.interface';

/**
 * rxjs operator to handle API errors.
 * This operator handles 2 types of errors, HTTP errors and SuccessFalse responses.
 *
 * SuccessFalse responses will be passed through as it is to the consumer (to handle expected errors).
 * The consumer will have to explicitly handle what logic should run in case of success: true/false
 *
 * It provides convenient interface to handle errors using `onError` option
 *
 * @example
 * observable$.pipe(
 *     // fetchData
 *     catchAPIError(this.errorService)
 *     // OR
 *     catchAPIError(this.errorService, { message: "Custom error message", closable: false })
 *     // OR
 *      catchAPIError(this.errorService, {
 *           onError: err => {
 *               switch (err.kind) {
 *                   case APIErrorKind.SUCCESS_FALSE:
 *                       this.globalService.presentToast(err.res.reason, false, false);
 *                       break;
 *                   case APIErrorKind.HTTP_ERROR:
 *                       this.globalService.presentToast(deskReservationErrors.ppgInitiate, false, false);
 *                       break;
 *               }
 *           },
 *       });
 * )
 */
export function catchAPIError<T extends IHttpResponse<unknown>>(
    errorService: ErrorService,
    options?: IHandleHttpResponseErrorOptions<T>
): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> =>
        source.pipe(
            tap(res => errorService.handleHTTPResponseSuccessFalse(res, options)),
            catchError(err => errorService.handleHTTPResponseError(err, options))
        );
}
