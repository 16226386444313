import { ElementRef } from '@angular/core';
import { FormHeaderListType, IFormListItem } from '../../../../shared/ui-components/form-header/form-header.interface';
import { IFormsConfig as IFormFieldsConfig } from '../../../../shared/ui-components/forms/forms.component';

export enum DedicatedFormType {
    STICKY_BUTTON = 'STICKY_BUTTON',
    FLOATING_BUTTON = 'FLOATING_BUTTON',
    MODAL = 'MODAL',
    STICKY_FORM = 'STICKY_FORM',
    DEFAULT = 'DEFAULT',
}

export enum DedicatedFormPlanType {
    MANAGED_OFFICE = 'Managed Office',
    DEDICATED_DESKS = 'Dedicated Desks',
}

export interface IDedicatedBaseFormConfig {
    heading: string;
    subHeading?: string;
    benefits?: IFormListItem[];
    ctaText?: string;
    listType?: FormHeaderListType;
}

export interface IDedicatedFormConfig {
    mwebClose: IDedicatedBaseFormConfig;
    mwebOpen: IDedicatedBaseFormConfig;
    desk: IDedicatedBaseFormConfig;
    defaultCampaign?: string;
    city?: {
        slug?: string;
        name?: string;
    };
    brand?: {
        name: string;
    };
    formType: DedicatedFormType;
    stickyFormConfig?: {
        showAfterTopRef: ElementRef;
        topSpacing: number;
        hideAfterBottomRef: ElementRef;
        bottomSpacing: number;
    };
    secondaryFormConfig?: {
        heading: string;
        description: string;
    };
    submitButtonLabel?: string;
    showSuccessWithCTA?: boolean;
    formSource: string;
    planType: DedicatedFormPlanType;
    formFieldsConfig?: IFormFieldsConfig;
    skipFormStep2?: boolean;
    showFormOnMobile?: boolean;
    successRedirectUrl?: string;
    isWhatsappForm?: boolean;
    initialWhatsappText?: string;
}
