<app-sticky-container></app-sticky-container>

<ng-container *ngIf="isMobileAppStaticPage || isLandingPage">
    <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="!isMobileAppStaticPage && !isLandingPage">
    <div class="width-100" #appContainerElement>
        <div appStickySection [stickySectionConfig]="stickySectionConfig" #appPrefixBanner>
            <app-prefix-banner></app-prefix-banner>
        </div>
    </div>

    <app-common-header [hidden]="(isFullscreen$ | async)?.hideCommonHeader"></app-common-header>
    <router-outlet></router-outlet>
    <app-common-footer [hidden]="(isFullscreen$ | async)?.hideCommonFooter"></app-common-footer>
    <div id="commonRichData" #commonRichData></div>
    <div *ngIf="showUnsupportedCityProductModal">
        <app-unsupported-city-product-modal
            [config]="unsupportedCityProductModalConfig"
            (hideModal)="closeUnsupportedCityProductModal()"
        ></app-unsupported-city-product-modal>
    </div>
</ng-container>
