import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ICity } from '../../interfaces/location.interface';
import { LocationService } from '../../services/location/location.service';
import { SelectCityService } from '../../services/select-city/select-city.service';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { CommonModule, DOCUMENT } from '@angular/common';
import { cityChangeSource } from '../../enums/enums';
import { ISelectCityModalConfig } from './select-city-modal.interface';
import { Subscription } from 'rxjs';
import { DirectivesModule } from '../../directives/directives.module';
import { PipeModule } from '../../pipe/pipe.module';

@Component({
    standalone: true,
    selector: 'app-select-city-modal',
    imports: [CommonModule, DirectivesModule, PipeModule],
    templateUrl: './select-city-modal.component.html',
    styleUrls: ['./select-city-modal.component.scss'],
})
export class SelectCityModalComponent implements OnInit, AfterViewInit, OnDestroy {
    popularCities: ICity[] = [];
    otherCities: ICity[] = [];
    selectedCity: ICity;
    showCloseButton = false;
    showSelectCityModal = false;
    selectCitySub: Subscription;
    @Input('config') set updateConfig(data: ISelectCityModalConfig) {
        if (data) {
            this.showSelectCityModal = data.showModal;
        }
    }
    constructor(
        private locationService: LocationService,
        private selectCityService: SelectCityService,
        private utilsService: UtilsService,
        @Inject(DOCUMENT) private document: Document
    ) {}
    ngOnInit() {
        if (
            !this.selectedCity ||
            !this.selectedCity._id ||
            !this.selectedCity.name ||
            !this.selectedCity.slug ||
            !this.selectedCity.products
        ) {
            this.toggleSelectCityModal(true);
        }
        this.locationService.getAvailableCity().subscribe(data => {
            data?.cities?.map(city => {
                const parsedCity = {
                    ...city,
                    icon: {
                        ...city.icon,
                        alt: `coworking space in ${city.name}`,
                    },
                };
                if (city.isPopular) {
                    this.popularCities.push(parsedCity);
                } else {
                    this.otherCities.push(parsedCity);
                }
            });
        });
        this.selectCitySub = this.selectCityService.getSelectedCitySync().subscribe(({ city }) => {
            if (city) {
                this.selectedCity = city;
                this.showCloseButton = true;
                this.toggleSelectCityModal(false);
            }
        });
    }

    onSelectCity(city: ICity) {
        this.selectCityService.setSelectedCity(city, cityChangeSource.USER_ACTION);
        this.toggleSelectCityModal(false);
    }

    toggleSelectCityModal(flag: boolean) {
        this.showSelectCityModal = flag;
        if (this.document?.body?.classList) {
            if (this.showSelectCityModal) {
                const scrollBarWidth = this.utilsService.getScrollBarWidth();
                this.document.body.classList.add('modal-open');
                this.document.body.style.paddingRight = `${scrollBarWidth}px`;
            } else {
                this.document.body.classList.remove('modal-open');
                this.document.body.style.paddingRight = '0';
            }
        }
    }

    ngAfterViewInit(): void {}

    ngOnDestroy() {
        if (this.selectCitySub) {
            this.selectCitySub.unsubscribe();
        }
    }
}
