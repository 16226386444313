import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {
    ILocationSelectEventPayload,
    ISearchConfig,
    ISearchHeaderConfig,
    ISearchHeaderOnFocusPayload,
    ISearchLocation,
    ISearchOverviewConfig,
} from './search.interface';
import { SearchExpandedSection } from './search.enum';
import { IProductMap } from '../../interfaces/product-interface';
import { SelectCityService } from '../../services/select-city/select-city.service';
import { ICity } from '../../interfaces/location.interface';
import {
    ILocalityFilterDataAPIPayload,
    ILocalityFilterDataAPIResponse,
    WorkspaceService,
} from '../../services/workspace/workspace.service';
import { CommonFilters, productKeys } from '../../enums/enums';
import { UtilsService } from '../../../core/services/utils/utils.service';
// @ts-ignore
import SearchIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/search.svg';
import { DomSanitizer } from '@angular/platform-browser';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { HomeProductsDetails } from '../../../modules/home/home/home.enum';
import { HeaderService } from '../common-header/services/header.service';
import { Subscription } from 'rxjs';
import { menuEnum } from '../common-header/enums/enums';
import { SearchOverviewComponent } from './components/search-overview/search-overview.component';
import { SearchHeaderComponent } from './components/search-header/search-header.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    standalone: true,
    imports: [CommonModule, SearchHeaderComponent, SearchOverviewComponent],
})
export class SearchComponent implements OnInit, OnDestroy {
    config: ISearchConfig;
    selectedCity: ICity;
    deviceInfo = this.utilsService.getDeviceInfo();
    searchIcon = this.sanitizer.bypassSecurityTrustHtml(SearchIcon);
    popularLocations: ISearchLocation[] = [];
    previousLocalityPayload: ILocalityFilterDataAPIPayload;
    isScrollLocked = false;
    showSearchOverview = false;
    isSearchTypeaheadInFocus = false;
    scrollPosition = 0;
    wrapperCssClass = '';

    @Input('config') set updateConfig(data: ISearchConfig) {
        if (data) {
            this.config = data;
            this.searchHeaderConfig = {
                ...this.searchHeaderConfig,
                products: this.config.products || [],
                selectedProduct: this.config.selectedProduct,
                hideViewWorkspacesButtonInCollapsedView:
                    this.config.headerConfig?.hideViewWorkspacesButtonInCollapsedView,
                focusOnInit: this.config.headerConfig?.focusOnInit,
            };

            if (this.config.headerConfig?.bannerConfig) {
                this.searchHeaderConfig = {
                    ...this.searchHeaderConfig,
                    bannerConfig: {
                        ...this.config.headerConfig.bannerConfig,
                    },
                };
            }

            if (this.config.searchWrapperCssClass) {
                this.wrapperCssClass = `${this.wrapperCssClass} ${this.config.searchWrapperCssClass}`;
            }

            if (this.config.tabVariant) {
                this.searchHeaderConfig = {
                    ...this.searchHeaderConfig,
                    tabVariant: this.config.tabVariant,
                };
            }

            this.searchOverviewConfig = {
                ...this.searchOverviewConfig,
                products: this.config.products || [],
                selectedProduct: this.config.selectedProduct,
                showProductSpecificCities: this.config.showProductSpecificCities,
            };
            this.setHeaderSearchLabel(this.config.selectedProduct, this.selectedCity);
        }
    }

    expandedSection: SearchExpandedSection;
    searchHeaderConfig: ISearchHeaderConfig = {
        products: [],
        selectedPopularLocalities: [],
        selectedOrdinaryLocalities: [],
    };
    searchOverviewConfig: ISearchOverviewConfig = {
        products: [],
        selectedPopularLocalities: [],
        selectedOrdinaryLocalities: [],
        popularLocalities: [],
    };
    headerSearchLabel = 'Search location or workspaces';
    @ViewChild('searchWrapper', { static: false }) searchWrapper: ElementRef;
    @ViewChild('headerSearchContainer', { static: false }) headerSearchContainer: ElementRef;
    @Output() onExpandSection: EventEmitter<void> = new EventEmitter();
    @Output() onCollapseSection: EventEmitter<void> = new EventEmitter();
    @Output() onProductChange: EventEmitter<IProductMap> = new EventEmitter<IProductMap>();

    private menuSubscription: Subscription;

    constructor(
        private selectCityService: SelectCityService,
        private workspaceService: WorkspaceService,
        private utilsService: UtilsService,
        private renderer2: Renderer2,
        private sanitizer: DomSanitizer,
        private headerService: HeaderService
    ) {}

    ngOnInit(): void {
        this.selectCityService
            .getSelectedCitySync()
            .pipe(
                distinctUntilChanged((previous, current) => {
                    return previous?.city?.slug === current?.city?.slug;
                })
            )
            .subscribe(data => {
                if (data.city) {
                    const previousCity = this.selectedCity;
                    this.selectedCity = data.city;
                    if (this.selectedCity.slug !== previousCity?.slug) {
                        // Only update if city changed
                        this.getSearchDataByCityAndProduct(this.selectedCity, this.config.selectedProduct);
                        this.setHeaderSearchLabel(this.config.selectedProduct, this.selectedCity);
                    }
                }
            });

        this.menuSubscription = this.headerService
            .getMenuData()
            .pipe(
                filter(id => id === menuEnum.SEARCH),
                tap(() => this.handleHeaderSearchBoxClick())
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.toggleScrollLockAndFocus(false);
        this.menuSubscription?.unsubscribe();
    }

    getSearchDataByCityAndProduct(city: ICity, product: IProductMap) {
        this.searchOverviewConfig = {
            ...this.searchOverviewConfig,
            loaderConfig: {
                show: true,
            },
        };
        this.setLocalityData(city.slug, product.productNameBackend);
    }

    setLocalityData(citySlug: string, productNameBackend: string) {
        if (
            this.previousLocalityPayload?.selectedFilters[CommonFilters.CITY] !== citySlug ||
            this.previousLocalityPayload?.selectedFilters[CommonFilters.PRODUCT] !== productNameBackend
        ) {
            const payload: ILocalityFilterDataAPIPayload = {
                selectedFilters: {
                    [CommonFilters.CITY]: citySlug,
                    [CommonFilters.PRODUCT]: productNameBackend,
                    [CommonFilters.LOCALITIES]: [],
                },
            };
            this.previousLocalityPayload = payload;
            this.workspaceService.getLocalityFilterData(payload).subscribe(
                popularLocationsData => {
                    if (popularLocationsData) {
                        this.popularLocations = this.parseLocalityFilterPopularLocations(
                            popularLocationsData.popularLocations
                        );
                        this.searchHeaderConfig = {
                            ...this.searchHeaderConfig,
                            popularLocalities: this.popularLocations,
                        };
                        this.searchOverviewConfig = {
                            ...this.searchOverviewConfig,
                            popularLocalities: this.popularLocations,
                        };
                    }
                },
                () => {
                    this.searchOverviewConfig = {
                        ...this.searchOverviewConfig,
                        loaderConfig: {
                            show: false,
                        },
                    };
                },
                () => {
                    this.searchOverviewConfig = {
                        ...this.searchOverviewConfig,
                        loaderConfig: {
                            show: false,
                        },
                    };
                }
            );
        }
    }

    parseLocalityFilterPopularLocations(items: ILocalityFilterDataAPIResponse['popularLocations']): ISearchLocation[] {
        return items.map(item => {
            return {
                place_id: item.googlePlaceId,
                display: item.name,
            };
        });
    }

    handleSearchHeaderFocus(payload: ISearchHeaderOnFocusPayload) {
        this.openExpandedSection(payload.expandedSection);
        this.isSearchTypeaheadInFocus = payload.isSearchTypeaheadInFocus;
        if (this.deviceInfo.isMobile) {
            this.showSearchOverview = true;
        } else {
            this.showSearchOverview = !(!!this.searchHeaderConfig.searchInputText && this.isSearchTypeaheadInFocus);
        }
    }

    toggleScrollLockAndFocus(lock: boolean, isHeaderSearch?: boolean) {
        if (this.deviceInfo.isBrowser) {
            const targetElement = isHeaderSearch
                ? this.headerSearchContainer?.nativeElement
                : this.searchWrapper?.nativeElement;
            this.isScrollLocked = lock;
            if (this.isScrollLocked) {
                this.scrollPosition = window.pageYOffset;
                if (targetElement) {
                    this.utilsService.scrollToTargetAdjusted(targetElement, 100);
                }
                this.renderer2.setStyle(document.body, 'overflow', 'hidden');
            } else {
                this.renderer2.setStyle(document.body, 'overflow', 'auto');
            }
        }
    }

    handleBlur() {
        this.closeExpandedSection();
    }

    handleProductChange(product: IProductMap) {
        // Only update if the product actually changed
        if (this.config.selectedProduct.productNameFrontend !== product.productNameFrontend) {
            this.config = {
                ...this.config,
                selectedProduct: product,
            };
            this.searchHeaderConfig = {
                ...this.searchHeaderConfig,
                selectedProduct: product,
                bannerConfig: {
                    ...this.config.headerConfig.bannerConfig,
                    label: HomeProductsDetails[product.productNameFrontend].searchBannerLabel,
                    labelMob: HomeProductsDetails[product.productNameFrontend].searchBannerLabelMob,
                },
            };
            this.searchOverviewConfig = {
                ...this.searchOverviewConfig,
                selectedProduct: product,
            };
            this.getSearchDataByCityAndProduct(this.selectedCity, product);
        }
        this.onProductChange.emit(product);
    }

    handleLocationSelect(payload: ILocationSelectEventPayload) {
        const { isPopular, location } = payload;
        if (isPopular) {
            if (location) {
                if (!payload.isRemoved) {
                    this.searchOverviewConfig = {
                        ...this.searchOverviewConfig,
                        selectedPopularLocalities: [...this.searchOverviewConfig.selectedPopularLocalities, location],
                    };
                } else {
                    this.searchOverviewConfig = {
                        ...this.searchOverviewConfig,
                        selectedPopularLocalities: this.searchOverviewConfig.selectedPopularLocalities.filter(item => {
                            return item.place_id !== location.place_id;
                        }),
                    };
                }

                this.searchHeaderConfig = {
                    ...this.searchHeaderConfig,
                    selectedPopularLocalities: this.searchOverviewConfig.selectedPopularLocalities,
                };
            } else {
                this.searchOverviewConfig = {
                    ...this.searchOverviewConfig,
                    selectedPopularLocalities: [],
                };
                this.searchHeaderConfig = {
                    ...this.searchHeaderConfig,
                    selectedPopularLocalities: this.searchHeaderConfig.selectedPopularLocalities,
                };
            }
        } else {
            this.handleOrdinaryLocationSelect(location, payload.isRemoved);
        }
    }

    closeExpandedSection() {
        this.expandedSection = null;
        if (this.wrapperCssClass.includes('expanded')) {
            this.wrapperCssClass = this.wrapperCssClass.replace('expanded', '');
        }
        this.searchHeaderConfig = {
            ...this.searchHeaderConfig,
            isExpanded: false,
        };
        this.searchOverviewConfig = {
            ...this.searchOverviewConfig,
            selectedSection: null,
        };
        if (!this.deviceInfo.isMobile) {
            this.toggleScrollLockAndFocus(false);
        }
        this.onCollapseSection.emit();
    }

    openExpandedSection(section: SearchExpandedSection) {
        this.expandedSection = section;
        if (!this.wrapperCssClass.includes('expanded')) {
            this.wrapperCssClass = `${this.wrapperCssClass} expanded`;
        }
        this.searchOverviewConfig = {
            ...this.searchOverviewConfig,
            selectedSection: section,
        };
        this.searchHeaderConfig = {
            ...this.searchHeaderConfig,
            isExpanded: true,
        };
        if (!this.deviceInfo.isMobile && !this.config.useAsHeaderComponent) {
            this.toggleScrollLockAndFocus(true);
        }
        this.onExpandSection.emit();
    }

    handleSearchInputChange(value: string) {
        this.searchOverviewConfig = {
            ...this.searchOverviewConfig,
            searchInputText: value,
        };
        this.searchHeaderConfig = {
            ...this.searchHeaderConfig,
            searchInputText: value,
        };
        if (this.deviceInfo.isMobile) {
            this.showSearchOverview = true;
        } else {
            this.showSearchOverview = !(!!this.searchHeaderConfig.searchInputText && this.isSearchTypeaheadInFocus);
        }
    }

    handleOrdinaryLocationSelect(item: ISearchLocation, isRemoved?: boolean) {
        if (!isRemoved) {
            // ignore adding action if already selected
            const isLocationExisting =
                this.searchOverviewConfig.selectedOrdinaryLocalities.findIndex(
                    searchItem => searchItem.place_id === item.place_id
                ) !== -1;
            if (!isLocationExisting) {
                this.searchOverviewConfig = {
                    ...this.searchOverviewConfig,
                    selectedOrdinaryLocalities: [...this.searchOverviewConfig.selectedOrdinaryLocalities, item],
                };
                this.searchHeaderConfig = {
                    ...this.searchHeaderConfig,
                    selectedOrdinaryLocalities: this.searchOverviewConfig.selectedOrdinaryLocalities,
                };
            }
        } else {
            this.searchOverviewConfig = {
                ...this.searchOverviewConfig,
                selectedOrdinaryLocalities: this.searchOverviewConfig.selectedOrdinaryLocalities.filter(
                    locationItem => {
                        return locationItem.place_id !== item.place_id;
                    }
                ),
            };
            this.searchHeaderConfig = {
                ...this.searchHeaderConfig,
                selectedOrdinaryLocalities: this.searchOverviewConfig.selectedOrdinaryLocalities,
            };
        }
    }

    handleRemoveSelectedItem(id: string) {
        const selectedItem = this.searchOverviewConfig.selectedOrdinaryLocalities.find(item => item.place_id === id);
        if (selectedItem) {
            this.handleOrdinaryLocationSelect(selectedItem, true);
        }
    }
    handleClearOrdinaryLocations() {
        this.searchOverviewConfig = {
            ...this.searchOverviewConfig,
            selectedOrdinaryLocalities: [],
        };
        this.searchHeaderConfig = {
            ...this.searchHeaderConfig,
            selectedOrdinaryLocalities: [],
        };
    }

    handleHeaderSearchBoxClick() {
        if (this.deviceInfo.isMobile) {
            this.showSearchOverview = true;
        }
        this.openExpandedSection(SearchExpandedSection.DEFAULT);
        this.renderer2.setStyle(document.body, 'overflow', 'hidden');
    }

    setHeaderSearchLabel(product: IProductMap, city?: ICity) {
        switch (product.productNameFrontend) {
            case productKeys.VIRTUAL_OFFICE:
                if (city) {
                    this.headerSearchLabel = `Search workspaces in ${city.name}`;
                } else {
                    this.headerSearchLabel = `Search workspaces`;
                }
                break;
            default:
                if (city) {
                    this.headerSearchLabel = `Search location or workspaces in ${city.name}`;
                } else {
                    this.headerSearchLabel = `Search location or workspaces`;
                }
        }
    }
}
