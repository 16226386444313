import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(
        private utilsService: UtilsService,
        private cookieService: CookieService
    ) {}

    setCookie(key: string, value, expiryTime?: number | Date, path?: string) {
        // expiry time is in days if the type is number
        expiryTime = expiryTime ? expiryTime : 365;
        this.cookieService.set(key, JSON.stringify(value), {
            expires: expiryTime,
            domain: `.${environment.domain}`,
            sameSite: 'Lax',
            path,
        });
    }

    getCookie(key: string, allCookies?) {
        return this.cookieService.get(key) ? JSON.parse(this.cookieService.get(key)) : null;
    }

    removeCookie(key: string, path?: string, domain?: string) {
        this.cookieService.delete(key, path, domain);
    }

    checkCookie(key: string): boolean {
        return this.cookieService.check(key);
    }

    getSession(key: string) {
        return this.utilsService.isBrowser ? JSON.parse(sessionStorage.getItem(key)) : null;
    }

    setSession(key: string, value) {
        if (this.utilsService.isBrowser) {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    removeSession(key: string) {
        if (this.utilsService.isBrowser) {
            sessionStorage.removeItem(key);
        }
    }

    getLocalStorage(key: string) {
        try {
            return this.utilsService.isBrowser ? JSON.parse(localStorage.getItem(key)) : null;
        } catch {
            return null;
        }
    }

    setLocalStorage(key: string, value) {
        if (this.utilsService.isBrowser) {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }

    removeLocalStorage(key: string) {
        if (this.utilsService.isBrowser) {
            localStorage.removeItem(key);
        }
    }
}
