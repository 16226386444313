import {
    footerDisabledUrls,
    primaryFooterDisabledUrls,
    secondaryFooterProductCategoryDisabledLinks,
} from '../../../core/constants/urls-regex';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImageBaseUrl, AppDownloadLink, SocialMediaLink } from '../../../core/constants/constants';
import { Validators } from '@angular/forms';
import { RegexMatcherService } from '../../../core/services/regexMatcher/regex-matcher.service';
import { CommonXhrService } from '../../services/common-xhr/common-xhr.service';
import { FooterService } from './services/footer.service';
import { buttonType, appDownloadRedirectAction } from '../../enums/enums';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { ContactDetails } from '../../../core/constants/constants';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { DomSanitizer } from '@angular/platform-browser';
// @ts-ignore
import FacebookIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/facebook.svg';
// @ts-ignore
import InstaIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/instagram.svg';
// @ts-ignore
import LinkedInIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/linkedin.svg';
// @ts-ignore
import TwitterIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/twitter.svg';
// @ts-ignore
import PhoneIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/call-flat.svg';
// @ts-ignore
import EmailIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/email-flat.svg';
// @ts-ignore
import BoltIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/bolt.svg';
import { IFooterCmsMetaDataConfig } from '../footer-cms-metadata/footer-cms-metadata.interfaces';
import { Subscription } from 'rxjs';
import { UrlGeneratorService } from '../../services/url-generator/url-generator.service';
@Component({
    selector: 'app-common-footer',
    templateUrl: './common-footer.component.html',
    styleUrls: ['./common-footer.component.scss'],
})
export class CommonFooterComponent implements OnInit, OnDestroy {
    constructor(
        private regexMatcherService: RegexMatcherService,
        private commonXhrService: CommonXhrService,
        private footerService: FooterService,
        private utilsService: UtilsService,
        private analyticsService: AnalyticsService,
        private sanitizer: DomSanitizer,
        private urlGeneratorService: UrlGeneratorService,
    ) {}
    ImageBaseUrl = ImageBaseUrl;
    commonIconsUrl = CommonIconsUrl;
    AppDownloadLink = AppDownloadLink;
    SocialMediaLink = SocialMediaLink;
    contactDetails = ContactDetails;
    appDownloadRedirectAction = appDownloadRedirectAction;
    showFooter: boolean;
    showPrimaryFooter = true;
    showProductCategoryLinks = true;
    homepageUrl = this.urlGeneratorService.homepage;
    formConfig = {
        field: {
            label: 'Enter your email',
            type: 'text',
            name: 'email',
            validations: [
                {
                    name: 'pattern',
                    validator: Validators.pattern(this.regexMatcherService.getRegexEmail()),
                    message: 'Enter a valid email.',
                },
                {
                    name: 'required',
                    validator: Validators.required,
                    message: 'Email is required.',
                },
            ],
        },
        cta: {
            type: buttonType.PRIMARY,
            label: 'Subscribe',
            cssClass: 't-bolder',
            showLoader: false,
        },
    };
    externalLinks = {
        socialMedia: [
            {
                name: 'fb',
                url: `${SocialMediaLink.FACEBOOK}`,
                icon: this.sanitizer.bypassSecurityTrustHtml(FacebookIcon),
            },
            {
                name: 'insta',
                url: `${SocialMediaLink.INSTAGRAM}`,
                icon: this.sanitizer.bypassSecurityTrustHtml(InstaIcon),
            },
            {
                name: 'twitter',
                url: `${SocialMediaLink.TWITTER}`,
                icon: this.sanitizer.bypassSecurityTrustHtml(TwitterIcon),
            },
            {
                name: 'linkedin',
                url: `${SocialMediaLink.LINKEDIN}`,
                icon: this.sanitizer.bypassSecurityTrustHtml(LinkedInIcon),
            },
        ],
        appDowload: [
            {
                name: 'android',
                url: `${AppDownloadLink.ANDROID}`,
                imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/assets/play-store-badge.svg`,
                redirectType: this.appDownloadRedirectAction.PLAY_STORE,
            },
            {
                name: 'ios',
                url: `${AppDownloadLink.IOS}`,
                imageUrl: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/assets/Appstore.svg`,
                redirectType: this.appDownloadRedirectAction.APP_STORE,
            },
        ],
    };
    boltIcon = this.sanitizer.bypassSecurityTrustHtml(BoltIcon);
    anarockLogo = `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/logo/anarock-logo.png`;
    cmsMetaDataConfig: IFooterCmsMetaDataConfig[];

    contactButtons = [
        {
            label: this.contactDetails.PHONE_NUMBER.label,
            link: this.contactDetails.PHONE_NUMBER.href,
            icon: this.sanitizer.bypassSecurityTrustHtml(PhoneIcon),
        },
        {
            label: this.contactDetails.EMAIL.label,
            link: this.contactDetails.EMAIL.href,
            icon: this.sanitizer.bypassSecurityTrustHtml(EmailIcon),
        },
    ];

    successResponse = {
        success: false,
        successConfig: {
            heading: 'Thanks for subscribing to our newsletter. Please check your inbox for regular updates.',
        },
    };
    errorResponse = {
        visible: false,
        content: 'An Error has Occured. Please Try Again',
    };

    // secondary footer
    currentYear = new Date().getFullYear();
    secondaryFooterLinks$;

    routerEventSub: Subscription;

    primaryFooterLinks$ = this.footerService.getPrimaryFooterLinks();

    ngOnInit() {
        this.routerEventSub = this.utilsService.getRouterEvents$().subscribe(event => {
            this.updateFooter(event.url);
            // Fetching the cms metadata on route change
            this.footerService
                .getCMSMetaData({
                    slug: event.url.split('?')[0],
                })
                .subscribe(data => {
                    if (data) {
                        this.cmsMetaDataConfig = data?.content;
                    }
                });
        });
    }

    ngOnDestroy() {
        if (this.routerEventSub) {
            this.routerEventSub.unsubscribe();
        }
    }

    subscribeNewsletter(data) {
        if (!data.formData) {
            return;
        }
        this.formConfig.cta.showLoader = true;
        this.commonXhrService.subscribeNewsletter(data.formData).subscribe(
            res => {
                this.formConfig.cta.showLoader = false;
                this.successResponse.success = true;
            },
            error => {
                this.formConfig.cta.showLoader = false;
                this.errorResponse.visible = true;
            }
        );
    }

    updateFooter(url) {
        if (this.utilsService.getRegexPatternMatch(footerDisabledUrls, url) >= 0) {
            this.showFooter = false;
            return;
        } else {
            this.showFooter = true;
        }
        this.showProductCategoryLinks =
            this.utilsService.getRegexPatternMatch(secondaryFooterProductCategoryDisabledLinks, url) < 0;
        this.showPrimaryFooter = this.utilsService.getRegexPatternMatch(primaryFooterDisabledUrls, url) < 0;
        this.secondaryFooterLinks$ = this.footerService.getSecondaryFooterLinks();
    }

    trackAppDownloadAnalytics(redirectType) {
        this.analyticsService.trackAppDownloadRedirectAction({ RedirectType: redirectType });
    }

    handleAnarockLogoClick() {
        window.open('https://anarock.com/');
    }
}
