<div
    [formGroup]="form"
    class="form-field"
    [ngClass]="(!form.value[field.name] ? 'no-val ' : '') + cssClass"
    appClickOutside
    (clickOutside)="toggleDropdown(false)"
>
    <div class="d-flx width-100 j-cont-space-bet" (click)="toggleDropdown()" appDropdown [config]="appDropdownConfig" [dropdownMenu]="dropdownWrapperRef" [toggleDropdown]="showDropdown">
        <input
            [type]="field.type"
            [id]="field.name + uniqueId"
            [formControlName]="field.name"
            autocomplete="off"
            readonly
        />
        <div class="d-flx a-items-c width-100 field-value-display-container bg-color-white" [ngClass]='{focus: showDropdown}'>
            <p class="font-14 font-normal text-color-dark" *ngIf="form.value[field.name]">
                {{ form.value[field.name] | date: field?.dateDisplayFormat || 'dd-MM-yyyy' }}
            </p>
        </div>
        <label [for]="field.name + uniqueId" [ngClass]="{ floating: form.value[field.name] }" class="label text-ellipsis">{{
            field.label
        }}</label>
        <div class="icon" [ngClass]="{ inverted: showDropdown }">
            <img appImageShimmerLazyLoad [imageSrc]="CommonIconsUrl.DOWN_CHEVRON_DARK" alt="i" />
        </div>
    </div>
    <div class="dropdown-wrapper" #dropdownWrapper>
        <div class="arrow"></div>
        <ng-container [ngTemplateOutlet]="dropdownContent"></ng-container>
    </div>
</div>

<!--templates-->
<ng-template #dropdownContent>
    <div class="dropdown bg-color-white">
        <div class="d-flx a-items-c width-100 j-cont-space-bet dropdown-heading-container">
            <p class="dropdown-heading font-bold text-color-dark-grey">Select date</p>
            <div class="d-flx a-items-c j-cont-cen close-icon-container c-pointer" (click)="toggleDropdown(false)">
                <img
                    class="height-100 width-100 object-fit-contain"
                    appImageShimmerLazyLoad
                    [imageSrc]="CommonIconsUrl.CLOSE_DARK"
                    alt="close"
                />
            </div>
        </div>
        <div class="calendar">
            <app-calendar [config]="calendarConfig" (dateSelectedEvent)="handleDateChange($event)"></app-calendar>
        </div>
        <app-button
            *ngIf="deviceInfo.isMobile"
            [field]="confirmDateBtnConfig"
            (onClick)="toggleDropdown(false)"
        ></app-button>
    </div>
</ng-template>
