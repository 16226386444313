<div [formGroup]="form" class="dropdown-cont" [ngClass]="{ 'no-val': form.value[field.name] == '' }">
  <select class="dropdown" [ngClass]="{ floating: form.value[field.name] }" [id]="field.name" [formControlName]="field.name" [disabled]="field.readonly">
    <option value="" disabled selected>{{ field.label }}</option>
    <option *ngFor="let option of field.options" [value]="option.value">{{ option.label }}</option>
  </select>
  <label
    [for]="field.name"
    [ngClass]="{ floating: form.value[field.name], 'd-none': form.value[field.name] === '' }"
    class="label"
  >{{ field.label }}</label
  >
  <div class="icon" [ngClass]="{ floating: form.value[field.name] }"><img [src]="commonIconsUrl.DOWN_CHEVRON_DARK" alt="i" /></div>
</div>
