import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { WithLoadingPipe } from './with-loading.pipe';
import { DayFormatPipe } from './day-format.pipe';
import { DistanceFormatPipe } from './distance-format.pipe';
import { TimeFormatFromMinsPipe } from './time-format.pipe';
import { OrderByPipe } from './order-by.pipe';
import { FilterByPipe } from './filter-by.pipe';
import { LineBreaksToHtmlPipe } from './line-breaks-to-html.pipe';

@NgModule({
    declarations: [
        WithLoadingPipe,
        DayFormatPipe,
        DistanceFormatPipe,
        TimeFormatFromMinsPipe,
        OrderByPipe,
        FilterByPipe,
        LineBreaksToHtmlPipe,
    ],
    imports: [CommonModule],
    exports: [
        WithLoadingPipe,
        DayFormatPipe,
        DistanceFormatPipe,
        TimeFormatFromMinsPipe,
        OrderByPipe,
        FilterByPipe,
        LineBreaksToHtmlPipe,
    ],
    providers: [
        DecimalPipe,
        DatePipe,
        WithLoadingPipe,
        DayFormatPipe,
        DistanceFormatPipe,
        TimeFormatFromMinsPipe,
        OrderByPipe,
        LineBreaksToHtmlPipe,
    ],
})
export class PipeModule {}
