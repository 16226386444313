<div class="d-flx width-100 j-cont-space-bet duration-filter-container">
    <div class="label-container d-flx a-items-c text-color-dark">
        <div class="d-flx a-items-c j-cont-cen duration-icon-container">
            <img
                class="height-100 width-100 object-fit-contain"
                appImageShimmerLazyLoad
                [imageSrc]="CommonIconsUrl.DURATION_ICON"
                [isATFContent]="true"
                alt="duration"
            />
        </div>
        <p class="font-14 font-normal text-color-dark">Duration (hr)</p>
    </div>
    <div class="d-flx a-items-c action-container">
        <div
            class="d-flx a-items-c j-cont-cen action-icon-container c-pointer"
            (click)="decrement()"
            [ngClass]="{ disabled: duration === filterConfig.config.minLimit }"
        >
            <img
                appImageShimmerLazyLoad
                class="height-100 width-100 object-fit-contain action-icon"
                [imageSrc]="CommonIconsUrl.DECREMENT_ACTION_ICON"
                [isATFContent]="true"
                alt="decrement"
            />
        </div>
        <div class="input-container d-flx j-cont-cen">
            <p class="font-14 font-normal text-color-dark">{{ duration < 10 ? '0' + duration : duration }}</p>
        </div>
        <div
            class="d-flx a-items-c j-cont-cen action-icon-container c-pointer"
            (click)="increment()"
            [ngClass]="{ disabled: duration === filterConfig.config.maxLimit }"
        >
            <img
                appImageShimmerLazyLoad
                class="height-100 width-100 object-fit-contain action-icon"
                [imageSrc]="CommonIconsUrl.INCREMENT_ACTION_ICON"
                [isATFContent]="true"
                alt="increment"
            />
        </div>
    </div>
</div>
