<div
    [formGroup]="form"
    class="form-field"
    [ngClass]="{ 'no-val': !form.value[field.name] }"
    appClickOutside
    (clickOutside)="toggleDropdown(false)"
>
    <div class="d-flx width-100 j-cont-space-bet" (click)="toggleDropdown(true)">
        <input
            [type]="field.type"
            [id]="field.name + uniqueId"
            [formControlName]="field.name"
            autocomplete="off"
            readonly
        />
        <div class="d-flx a-items-c width-100 field-value-display-container bg-color-white" [ngClass]='{focus: showDropdown}'>
            <p class="font-14 font-normal text-color-dark" *ngIf="durationLabel">{{ durationLabel }}</p>
            <div class="label-separator" *ngIf="timeSlotsLabel"></div>
            <p class="font-14 font-normal text-color-dark" *ngIf="timeSlotsLabel">{{ timeSlotsLabel }}</p>
            <p class="font-12 font-normal text-color-lighter-grey" *ngIf="timeSlotsLabel && selectedSlots?.length > 1">
                (+{{ selectedSlots.length - 1 }})
            </p>
        </div>
        <label [for]="field.name + uniqueId" [ngClass]="{ floating: form.value[field.name] }" class="label">{{
            field.label
        }}</label>
        <div class="icon" [ngClass]="{ inverted: showDropdown }">
            <img appImageShimmerLazyLoad [imageSrc]="CommonIconsUrl.DOWN_CHEVRON_DARK" alt="i" />
        </div>
    </div>
    <div class="dropdown-wrapper">
        <div class="arrow" [hidden]="!showDropdown"></div>
        <ng-container [ngTemplateOutlet]="dropdownContent"></ng-container>
    </div>
</div>

<!--templates-->
<ng-template #dropdownContent>
    <div class="dropdown bg-color-white" [hidden]="!showDropdown">
        <div class="d-flx a-items-c width-100 j-cont-space-bet dropdown-heading-container">
            <p class="font-14 font-bold text-color-dark-grey">Select Duration</p>
        </div>
        <div class="d-flx flx-d-clm width-100 time-duration-container">
            <app-duration-filter [config]="durationFilterConfig" (onChangeDuration)="handleDurationUpdate($event)">
            </app-duration-filter>
            <app-time-filter [config]="timeSlotFilterConfig" (onSlotChange)="handleSlotSelection($event)">
            </app-time-filter>
        </div>
        <div class="d-flx j-cont-end width-100 action-buttons-container">
            <app-button [field]="cancelBtnConfig" (onClick)="toggleDropdown(false)"></app-button>
            <app-button [field]="applyBtnConfig" (onClick)="updateFieldValue()"></app-button>
        </div>
    </div>
</ng-template>
