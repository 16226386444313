<div (clickOutside)="toggleOptions(false)" appClickOutside>
    <div class="d-flx gap-4 menu-cont padding-horizontal-16 a-items-c c-pointer" (click)="toggleOptions(true)">
        <div *ngIf="menu.imageUrl" class="bg-color-blue padding-4 d-flx a-items-c j-cont-cen border-radius-2">
            <div class="menu-icon-cont d-flx a-items-c j-cont-cen">
                <img [src]="menu.imageUrl" alt="i" class="image-contain" />
            </div>
        </div>
        <div class="title-desc-cont d-flx a-items-c">
            <div>
                <div class="font-12 font-bold">{{ menu.title }}</div>
                <div *ngIf="menu.comingSoon" class="font-12 text-color-light-grey margin-left-2">
                    <i>- Coming soon</i>
                </div>
            </div>
            <div class="icon-cont d-flx a-items-c">
                <span class="d-flx caret-down"></span>
            </div>
        </div>

        <div class="dropdown-cont d-flx gap-24 bg-color-white padding-24 border-radius-4" *ngIf="showOptions">
            <div class="sub-menus d-flx">
                <div class="sub-menu d-flx flx-d-clm gap-12 margin-right-24" *ngFor="let subMenu of menu.subMenus">
                    <p class="font-16 font-bold padding-horizontal-12">{{ subMenu.heading }}</p>

                    <div class="d-flx flx-d-clm">
                        <ng-container *ngFor="let menuItem of subMenu.menuItems">
                            <a
                                *ngIf="!menuItem.isExternalUrl"
                                [routerLink]="menuItem.redirectUrl | async"
                                routerLinkActive="selected"
                                #rla="routerLinkActive"
                            >
                                <ng-container
                                    [ngTemplateOutlet]="menuItemTemplate"
                                    [ngTemplateOutletContext]="{ menuItem }"
                                />
                            </a>
                            <a *ngIf="menuItem.isExternalUrl" [href]="menuItem.redirectUrl | async" target="_blank">
                                <ng-container
                                    [ngTemplateOutlet]="menuItemTemplate"
                                    [ngTemplateOutletContext]="{ menuItem }"
                                />
                            </a>
                        </ng-container>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="menu.calloutType">
                <ng-container [ngSwitch]="menu.calloutType">
                    <ng-container
                        *ngSwitchCase="menuCalloutType.DOWNLOAD_APP"
                        [ngTemplateOutlet]="calloutTemplate"
                        [ngTemplateOutletContext]="{ calloutData: calloutData[menuCalloutType.DOWNLOAD_APP] }"
                    ></ng-container>
                    <ng-container
                        *ngSwitchCase="menuCalloutType.MANAGED_OFFICE"
                        [ngTemplateOutlet]="calloutTemplate"
                        [ngTemplateOutletContext]="{ calloutData: calloutData[menuCalloutType.MANAGED_OFFICE] }"
                    ></ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #menuItemTemplate let-menuItem="menuItem">
    <div class="menu-item padding-left-12 padding-vertical-12 padding-right-10 border-radius-4 d-flx gap-8 c-pointer">
        <div class="menu-item__icon-cont padding-4 bg-color-blue border-radius-2">
            <div class="menu-item__icon-cont__inner position-relative">
                <app-optimized-image
                    cssClass="image-contain svg-cont d-flx"
                    fill
                    [image]="{ url: menuItem.imageUrl, alt: menuItem.title }"
                ></app-optimized-image>
            </div>
        </div>
        <div class="d-flx flx-d-clm gap-4">
            <p class="font-14 font-bold">{{ menuItem.title }}</p>
            <p class="font-12 text-color-medium-grey" *ngIf="menuItem.description">
                {{ menuItem.description }}
            </p>
        </div>
    </div>
</ng-template>

<ng-template #calloutTemplate let-item="calloutData">
    <div class="callout-container d-flx flx-d-clm gap-16 bg-color-blue padding-16 border-radius-4">
        <div class="d-flx gap-8">
            <div class="callout-container__icon position-relative">
                <app-optimized-image
                    cssClass="image-contain svg-cont d-flx"
                    fill
                    [image]="item.icon"
                ></app-optimized-image>
            </div>
            <div class="d-flx flx-d-clm gap-4">
                <p class="font-16 font-bold">{{ item.title }}</p>
                <p class="font-12 text-color-medium-grey">{{ item.description }}</p>
            </div>
        </div>
        <app-button [field]="item.buttonConfig" (onClick)="handleCTAClick(item.type)"></app-button>
    </div>
</ng-template>

@defer (when showOptions) {
    <app-managed-office-form
        [config]="moModalFormConfig"
        (changeModalState)="handleMoRequestCallbackCTAClick($event)"
    />
}
