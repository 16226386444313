export enum bookVisitFormStages {
    STAGE_1 = 'INPUT_STAGE_1',
    STAGE_2 = 'INPUT_STAGE_2',
}

export enum requestCallbackFormStages {
    STAGE_1 = 'INPUT_STAGE_1',
    STAGE_2 = 'INPUT_STAGE_2',
    STAGE_3 = 'INPUT_STAGE_3',
}

export enum formName {
    BOOK_VISIT = 'bookVisit',
    REQUEST_CALLBACK = 'contactExpert',
    HOME_PAGE_FORM = 'homePageForm',
}

export enum dedicatedWorkspaceListTypes {
    WORKSPACE_LIST = 'WORKSPACE_LIST',
    NEARBY_WORKSPACE_LIST = 'NEARBY_WORKSPACE_LIST',
}

export enum dedicatedFormSource {
    WHY_MYHQ_BANNER = 'WhyMyHQBanner',
    FILTER = 'Filter',
    BOTTOM_STICKY = 'BottomSticky',
    SPACE_CARD = 'SpaceCard',
    MAP_SPACE_CARD = 'MapSpaceCard',
    INDUSTRY_REPORTS = 'Insights',
    MO_CALLOUT_LISTING = 'ManagedOfficeCalloutListing',
    MO_CALLOUT_SPACE_DETAIL = 'ManagedOfficeCalloutSpaceDetail',
    MO_CALLOUT_LISTING_MAP = 'ManagedOfficeListingPageMapCallout',
    ALL_SOLUTION_HEADER = 'AllSolutionsHeader',
    HOMEPAGE_HERO_TALK_TO_EXPERT = 'HomepageHeroTalkToExpert',
    HOMEPAGE_MIDDLE_CTA = 'HomepageMiddleCTA',
    WHATSAPP_CALLOUT = 'WhatsAppCallout',
    REQUEST_CALLBACK = 'RequestCallback',
    POPUP = 'PopUp',
    PROMOTION_CARD = 'PromotionCard',
    LISTING_POPUP = 'ListingPopup',
    AVERAGE_PRICING_BANNER = 'AveragePricingBanner',
    STILL_NOT_SURE = 'StillNotSure',
    WARM_SHELL_VS_READY_TO_MOVE_IN = 'WarmShellVsReadyToMoveIn',
    CENTER_DETAILS_CARD = 'CenterDetailsCard',
    INTERIOR_DESIGNS_BANNER_CALLOUT = 'InteriorDesignsBannerCallout',
    STICKY_FREE_QUOTE = 'StickyFreeQuote',
    TESTIMONIALS = 'Testimonials',
    COWORKING_SOLUTIONS = 'CoworkingSolutions',
    POC_CARD = 'PocCard',
    FLOATING_BUTTON = 'FloatingButton',
    INVENTORY_CARD = 'InventoryCard',
    UPGRADE_CUSTOM_SOLUTION = 'UpgradeCustomSolution',
    ABOUT_BRAND = 'AboutBrand',
    MANAGED_OFFICE_OR_COWORKING_SPACE_BANNER = 'ManagedOfficeOrCoworkingSpaceBannen',
    IS_MO_RIGHT_SOLUTION_BANNER = 'IsMoRightSolutionBanner',
    HOW_MO_WORKS = 'HowMoWorks',
    EXPERT_BANNER = 'ExpertBanner',
    CLIENTS_BANNER = 'ClientsBanner',
    SALES_INFO_BANNER = 'SalesInfoBanner',
}

export enum HomePageFormSource {
    WHY_MYHQ_BANNER = 'WhyMyHQBanner',
    MIDDLE_CTA = 'MiddleCTA',
    FOOTER_STICKY = 'FooterSticky',
}
export enum DedicatedPanelType {
    DEDICATED_AVERAGE_PRICING = 'DEDICATED_AVERAGE_PRICING',
}
