import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { ITabsConfig } from '../../interfaces/tabs.interface';
import { TabVariant } from '../../enums/enums';
import { UtilsService } from '../../../core/services/utils/utils.service';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
    cssClass = '';
    deviceInfo = this.utilsService.getDeviceInfo();
    config: ITabsConfig;
    @Input('config') set updateConfig(data: ITabsConfig) {
        if (data) {
            this.config = data;
        }
    }
    @Output() updateSelectedTab: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('tabset') tabset;
    @ViewChild('container', { static: true }) container;
    constructor(private utilsService: UtilsService) {}

    ngOnInit() {
        switch (this.config.variant) {
            case TabVariant.FILLED:
                this.cssClass = `${this.cssClass} tab-filled`;
                break;
            case TabVariant.CONTAINED:
                this.cssClass = `${this.cssClass} tab-contained`;
                break;
            case TabVariant.CONTAINED_DESK_SMALL:
                this.cssClass = `${this.cssClass} tab-contained desk-small-contained`;
                break;
            case TabVariant.CONTAINED_DARK:
                this.cssClass = `${this.cssClass} tab-contained-dark`;
            case TabVariant.UNDERLINED:
            default:
        }
    }

    // makes the tab come to center if there is a overflow and the tab is partially visible in the viewport
    focusTab() {
        if (this.container) {
            const container = this.container.nativeElement;
            const tabs = container.querySelectorAll('.nav-item') as HTMLElement[];
            const activeTab = Array.from(tabs).find(tab => tab.classList.contains('active'));
            if (activeTab) {
                const containerRect = container.getBoundingClientRect();
                const headingRect = activeTab.getBoundingClientRect();

                const scrollLeft = container.scrollLeft;
                const selectedLeft = headingRect.left - containerRect.left + scrollLeft;
                const selectedWidth = headingRect.width;
                const containerWidth = container.offsetWidth;

                const scrollOffset = (containerWidth - selectedWidth) / 2;
                const targetScroll = selectedLeft - scrollOffset;

                if (targetScroll > 0) {
                    container.scrollTo({
                        left: targetScroll,
                        behavior: 'smooth',
                    });
                } else {
                    container.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                    });
                }
            }
        }
    }

    onSelect(tabData: TabDirective): void {
        if (this.deviceInfo.isBrowser) {
            setTimeout(() => {
                this.focusTab();
            });
        }
        if (this.config.selectedTabIndex !== +tabData.id) {
            this.config.selectedTabIndex = +tabData.id;
            setTimeout(() => {
                this.updateSelectedTab.emit(tabData.id);
            });
        }
    }
}
