import { Component, Input, OnDestroy } from '@angular/core';
import { CommonIconsUrl } from '../../../../../core/constants/common-icons-url';
import { UtilsService } from '../../../../../core/services/utils/utils.service';
import { HeaderService } from '../../services/header.service';
import { IProductMap } from '../../../../interfaces/product-interface';
import { ICity } from '../../../../interfaces/location.interface';
import { DirectivesModule } from '../../../../directives/directives.module';
import { NgIf, NgFor, NgClass, NgTemplateOutlet } from '@angular/common';
import { LinkWithIconComponent } from '../../../../ui-components/link-with-icon/link-with-icon.component';
import { RouterLink } from '@angular/router';
@Component({
    selector: 'app-location-dropdown',
    templateUrl: './location-dropdown.component.html',
    styleUrls: ['./location-dropdown.component.scss'],
    standalone: true,
    imports: [
        DirectivesModule,
        NgIf,
        NgFor,
        NgClass,
        LinkWithIconComponent,
        NgTemplateOutlet,
        RouterLink,
    ],
})
export class LocationDropdownComponent implements OnDestroy {
    constructor(
        private utilsService: UtilsService,
        private headerService: HeaderService
    ) {}
    @Input() title: string;
    @Input() selectedCity: ICity;
    @Input() product: IProductMap;
    commonIconsUrl = CommonIconsUrl;
    showOptions = false;
    showLoader = false;
    locationHeading = 'Popular locations in ';
    workspaceHeading = 'Popular workspaces';
    optionsModalClass = 'full-width';
    popularWorkspacesByCity;
    popularWorkspacesSub;
    routerEventSub = this.utilsService.getRouterEvents$().subscribe(event => {
        this.showOptions = false;
    });

    getPopularWorkspacesForCity() {
        this.showLoader = true;
        this.popularWorkspacesSub = this.headerService
            .getPopularWorkspacesForCity(this.selectedCity._id, this.product)
            .subscribe(data => {
                this.showLoader = false;
                this.popularWorkspacesByCity = data;
                if (data.onlyWorkspaces) {
                    this.optionsModalClass = 'half-width';
                } else {
                    this.optionsModalClass = 'full-width';
                    this.locationHeading = `Popular locations in ${this.selectedCity.name}`;
                }
            });
    }

    onLocationClick(index: number) {
        this.popularWorkspacesByCity.localities.map((location, i) => {
            location.isSelected = index === i;
        });
    }

    toggleOptions(toggle: boolean) {
        this.showOptions = toggle;
        if (toggle) {
            this.getPopularWorkspacesForCity();
        }
    }

    ngOnDestroy() {
        this.routerEventSub.unsubscribe();
        if (this.popularWorkspacesSub) {
            this.popularWorkspacesSub.unsubscribe();
        }
    }
}
