import { ApplicationRef, ComponentRef, createComponent, EnvironmentInjector, Injectable } from '@angular/core';
import { SnackbarComponent } from '../snackbar.component';
import { ISnackbarConfig } from '../snackbar.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    private snackbarQueue: ISnackbarConfig[] = [];
    private snackbarQueue$: BehaviorSubject<ISnackbarConfig[]> = new BehaviorSubject<ISnackbarConfig[]>(
        this.snackbarQueue
    );
    private currentSnackbar: ComponentRef<SnackbarComponent> | null = null;

    constructor(
        private appRef: ApplicationRef,
        private environmentInjector: EnvironmentInjector
    ) {}

    private createSnackbar(): ComponentRef<SnackbarComponent> {
        return createComponent(SnackbarComponent, { environmentInjector: this.environmentInjector });
    }

    private showSnackbar(config: Omit<ISnackbarConfig, 'id'>, _insertionIndex: number): void {
        // only creating a snackbar list container if a list container is not already present
        if (!this.currentSnackbar) {
            this.currentSnackbar = this.createSnackbar();
            this.appRef.attachView(this.currentSnackbar.hostView);
            document.body.appendChild(this.currentSnackbar.location.nativeElement);
        }
        const id = this.generateSnackbarId();
        this.snackbarQueue.push({ ...config, id });
        setTimeout(() => {
            this.hideSnackbar(id);
        }, config.timeout || 3000);
    }

    hideSnackbar(id: string): void {
        if (this.currentSnackbar) {
            const idx = this.snackbarQueue.findIndex(item => {
                return item.id === id;
            });
            this.snackbarQueue.splice(idx, 1);
            if (!this.snackbarQueue.length) {
                // removing the list container from dom if the queue items are exhausted
                this.currentSnackbar.instance.hide();
                this.appRef.detachView(this.currentSnackbar.hostView);
                this.currentSnackbar = null;
            }
            this.snackbarQueue$.next(this.snackbarQueue);
        }
    }

    open(config: Omit<ISnackbarConfig, 'id'>): void {
        this.showSnackbar(config, this.snackbarQueue.length);
        this.snackbarQueue$.next(this.snackbarQueue);
    }

    getSnackBarItems(): ISnackbarConfig[] {
        return this.snackbarQueue;
    }

    getSnackBarItemsSub(): Observable<ISnackbarConfig[]> {
        return this.snackbarQueue$;
    }

    private generateSnackbarId() {
        return Math.floor(Math.random() * 10000).toString();
    }
}
