import { Injectable } from '@angular/core';
import { IFieldConfig } from '../../interfaces/field.interface';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FormService {
    private formFieldTrigger$: BehaviorSubject<{
        show: boolean;
        fieldName: string;
    }> = new BehaviorSubject(null);
    constructor() {}
    createOTPFormGroup(field: IFieldConfig) {
        const formInputs = Array.from({ length: field.maxLength }, (_, i) => `input${i + 1}`);
        const group: any = {};
        formInputs.forEach(key => {
            group[key] = new UntypedFormControl(null, {
                validators: this.bindValidations(field.validations || []),
            });
        });
        const form = new UntypedFormGroup(group);
        return {
            form,
            formInputs,
        };
    }

    bindValidations(validations: any) {
        if (!validations.length) {
            return null;
        }
        const validatorList = [];
        validations.forEach(valid => {
            validatorList.push(valid.validator);
        });
        return Validators.compose(validatorList);
    }

    toggleFormFieldItem(fieldName: string, show: boolean) {
        this.formFieldTrigger$.next({ fieldName, show });
    }

    getFormFieldTriggerSub(): Observable<{ fieldName: string; show: boolean }> {
        return this.formFieldTrigger$;
    }
}
