import { ButtonsModule as BootstrapButtonsModule } from 'ngx-bootstrap/buttons';
import { NgModule } from '@angular/core';
import { ButtonsComponent } from './buttons.component';
import { LoadersModule } from '../loaders/loaders.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ButtonsComponent],
    imports: [BootstrapButtonsModule.forRoot(), CommonModule, ReactiveFormsModule, LoadersModule],
    exports: [ButtonsComponent],
})
export class ButtonsModule {} // TODOANGULAR14
