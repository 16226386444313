<div class="app-container section-container cms-metadata-container" *ngIf="cmsMetaDataConfig?.length">
    <app-footer-cms-metadata [config]="cmsMetaDataConfig"></app-footer-cms-metadata>
</div>
<footer *ngIf="showFooter">
    <div class="primary-footer" *ngIf="showPrimaryFooter">
        <div class="app-container">
            <div class="footer d-flx">
                <div class="products d-flx">
                    <ng-container *ngFor="let footerItem of primaryFooterLinks$ | async">
                        <div class="section flx-1">
                            <div *ngIf="footerItem.url" class="logo-cont">
                                <a [routerLink]="homepageUrl | async" aria-label="myhq">
                                    <img
                                        appImageShimmerLazyLoad
                                        class="icon image-cover object-fit-contain"
                                        [imageSrc]="footerItem.url"
                                        alt="myhq"
                                    />
                                </a>
                            </div>
                            <div *ngIf="footerItem.title" class="title-cont">
                                <h3 class="title">{{ footerItem.title }}</h3>
                            </div>
                            <div class="list d-flx">
                                <ng-container *ngFor="let item of footerItem.items">
                                    <div class="d-flx a-items-c item-container">
                                        <a
                                            *ngIf="item.isExternal"
                                            class="item"
                                            aria-label="{{ item.label }}"
                                            href="{{ item.url | async }}"
                                            target="_blank"
                                            >{{ item.label }}</a
                                        >
                                        <a
                                            *ngIf="!item.isExternal"
                                            class="item"
                                            aria-label="{{ item.label }}"
                                            [routerLink]="item.url | async"
                                            >{{ item.label }}</a
                                        >
                                        <div class="tag d-flx a-items-c" *ngIf="item.tag">{{ item.tag }}</div>
                                        <div class="tag-grey d-flx a-items-c" *ngIf="item.comingSoon">
                                            <i>- Coming soon</i>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="communication">
                    <div class="show-desk">
                        <div *ngIf="!successResponse.success">
                            <div class="title-cont"><h3 class="title">Join the newsletter</h3></div>
                            <app-input-with-button
                                [fieldObj]="formConfig"
                                (submit)="subscribeNewsletter($event)"
                            ></app-input-with-button>
                        </div>
                        <div class="success-msg" *ngIf="successResponse.success">
                            <app-lead-success-portrait
                                [config]="successResponse.successConfig"
                            ></app-lead-success-portrait>
                        </div>
                        <div class="error-msg" *ngIf="errorResponse.visible">{{ errorResponse.content }}</div>
                    </div>
                    <div>
                        <div class="title-cont">
                            <h3 class="title">Feel free to connect with us</h3>
                        </div>
                        <div class="contacts d-flx">
                            <div class="contact d-flx a-items-c" *ngFor="let contactButton of contactButtons">
                                <div class="contact-icon-cont d-flx a-items-c">
                                    <div class="svg-cont d-flx" [innerHTML]="contactButton.icon"></div>
                                </div>
                                <a class="text" aria-label="{{ contactButton.label }}" [href]="contactButton.link">{{
                                    contactButton.label
                                }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="external-links d-flx j-cont-space-bet">
                <div class="d-flx a-items-c">
                    <div class="text">Keep in touch</div>
                    <div *ngFor="let item of externalLinks.socialMedia">
                        <a href="{{ item.url }}" target="_blank" aria-label="myhq">
                            <div class="d-flx contact-link-icon-cont svg-cont" [innerHTML]="item.icon"></div>
                        </a>
                    </div>
                </div>
                <div class="d-flx flx-d-row a-items-c anarock-logo-container">
                    <div [innerHTML]="boltIcon" class="d-flx logo a-items-c j-cont-cen"></div>
                    <p class="label">Powered by</p>
                    <img
                        [height]="22"
                        [src]="anarockLogo"
                        alt="anarock"
                        [imageWidthSize]="'largethumbnail'"
                        appImageProgressiveLazyLoad
                        class="anarock-logo object-fit-contain c-pointer"
                        (click)="handleAnarockLogoClick()"
                    />
                </div>
                <div class="show-desk">
                    <div class="d-flx a-items-c">
                        <div class="text">Download myHQ App</div>
                        <div class="app-download-link-cont" *ngFor="let item of externalLinks.appDowload">
                            <a
                                href="{{ item.url }}"
                                target="_blank"
                                (click)="trackAppDownloadAnalytics(item.redirectType)"
                                aria-label="myhq"
                            >
                                <div class="app-download-image-cont">
                                    <img
                                        appImageShimmerLazyLoad
                                        [width]="108"
                                        [height]="32"
                                        class="image"
                                        [imageSrc]="item.imageUrl"
                                        [alt]="item.name"
                                    />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="secondaryFooterLinks$ | async as secondaryFooterLinks">
        <div
            class="secondary-footer"
            *ngIf="secondaryFooterLinks.productCategories?.length"
            [ngClass]="{ 'primary-footer-colour': !showPrimaryFooter }"
        >
            <div class="app-container">
                <!--              hide the product links if disabled -- only show about myHQ in such cases-->
                <div class="popular-workspaces" *ngIf="showProductCategoryLinks">
                    <ng-container *ngFor="let footerItem of secondaryFooterLinks.productCategories">
                        <div class="workspace" *ngIf="footerItem.links.length">
                            <h2 class="title">{{ footerItem.label }}</h2>
                            <div class="list">
                                <ul class="list-horizontal">
                                    <ng-container *ngFor="let item of footerItem.links; let i = index">
                                        <li>
                                            <a
                                                class="item"
                                                aria-label="{{ item.label }}"
                                                [routerLink]="item.redirectUrl"
                                                >{{ item.label }}</a
                                            >
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="about-myhq">
                    <div class="logo-cont">
                        <a [routerLink]="homepageUrl | async" aria-label="myhq">
                            <img
                                appImageShimmerLazyLoad
                                class="image-cover"
                                [imageSrc]="commonIconsUrl.LOGO_LARGE"
                                alt="myhq"
                            />
                        </a>
                    </div>
                    <p class="text">
                        myHQ provides affordable, hyperlocal and flexible office space solutions for businesses and
                        individuals. Choose between pay-per-use plans or fixed desks.
                    </p>
                </div>
                <hr />
                <div class="copyright">
                    Copyright &copy; {{ currentYear }}, Upflex Anarock India Private Limited. All Rights Reserved.
                </div>
            </div>
        </div>
    </ng-container>
</footer>
