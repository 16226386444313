import { isPlatformBrowser } from '@angular/common';
import { UtilsService } from '../../.././core/services/utils/utils.service';
import {
    Directive,
    AfterViewInit,
    NgZone,
    OnInit,
    ElementRef,
    Renderer2,
    Input,
    Inject,
    PLATFORM_ID,
    Output,
    EventEmitter,
} from '@angular/core';
import { map, pairwise, distinctUntilChanged, share, filter } from 'rxjs/operators';
enum Direction {
    Up = 'Up',
    Down = 'Down',
    Top = 'Top',
}

@Directive({
    standalone: true,
    selector: '[appReactiveStickyHeader]',
})
export class ReactiveStickyHeaderDirective implements OnInit, AfterViewInit {
    @Input() classLogic: {
        Up?: { Add?: []; Remove?: [] };
        Down?: { Add?: []; Remove?: [] };
        Top?: { Add?: []; Remove?: [] };
    };
    @Output() positionEvent = new EventEmitter();
    private filterFunctionGeneratorAndSubscriber: (direction: Direction) => () => void;
    isBrowser: boolean;
    ngOnInit() {}

    constructor(
        public ngZone: NgZone,
        private el: ElementRef,
        private renderer: Renderer2,
        @Inject(PLATFORM_ID) platformId: string,
        private utilsService: UtilsService
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }
    emitScroll(direction: string) {
        this.positionEvent.emit(direction);
        if (this.classLogic[direction].Add) {
            this.classLogic[direction].Add.map(addClass => this.renderer.addClass(this.el.nativeElement, addClass));
        }
        if (this.classLogic[direction].Remove) {
            this.classLogic[direction].Remove.map(removeClass =>
                this.renderer.removeClass(this.el.nativeElement, removeClass)
            );
        }
    }

    ngAfterViewInit() {
        if (this.utilsService.getDeviceInfo().isBrowser) {
            this.ngZone.runOutsideAngular(() => {
                const scroll$ = this.utilsService.getScroll().throttledScroll$.pipe(
                    pairwise(),
                    map(([y1, y2]): Direction => (y2 === 0 ? Direction.Top : y2 < y1 ? Direction.Up : Direction.Down)),
                    distinctUntilChanged(),
                    share()
                );

                this.filterFunctionGeneratorAndSubscriber = (direction: Direction) => {
                    return () => {
                        scroll$
                            .pipe(filter(receivedDirection => direction === receivedDirection))
                            .subscribe(() => this.emitScroll(direction));
                    };
                };
                Object.values(Direction).map(direction => this.filterFunctionGeneratorAndSubscriber(direction)());
            });
        }
    }
}
