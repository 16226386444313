import { Injectable } from '@angular/core';
import { IGeolocation } from '../../../shared/interfaces/geolocation.interface';
import { GeoLocationOptions } from '../../constants/constants';
import { UtilsService } from '../utils/utils.service';
import { geoLocationPermissionStatus } from '../../../shared/enums/enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IGeoLocationDataResp {
    success: boolean;
    geolocation: IGeolocation;
}

@Injectable({
    providedIn: 'root',
})
export class GeolocationService {
    geoLocationData: IGeolocation;
    geoLocationPermissionStatus = geoLocationPermissionStatus;
    geoLocationPermission: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    geoLocationOptions = {
        timeout: GeoLocationOptions.TIMEOUT,
        enableHighAccuracy: GeoLocationOptions.ENABLE_HIGH_ACCURACY,
        maximumAge: GeoLocationOptions.MAXIMUM_AGE,
    };

    constructor(private utilsService: UtilsService) {}

    getGeolocationPermission(): Observable<string> {
        return this.geoLocationPermission.asObservable();
    }

    private setGeolocationPermission(permissionStatus: string) {
        this.geoLocationPermission.next(permissionStatus);
    }

    getGeoLocationData(): Promise<IGeolocation> {
        return new Promise((resolve, reject) => {
            if (this.geoLocationData && this.geoLocationData.longitude && this.geoLocationData.latitude) {
                resolve(this.geoLocationData);
            }
            if (this.utilsService.isBrowser && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.setGeoLocationData(position.coords);
                        resolve(position.coords);
                    },
                    err => {
                        if (err.code === 1) {
                            this.setGeolocationPermission(this.geoLocationPermissionStatus.DENIED);
                        } else if (err.code === 2) {
                            this.setGeolocationPermission(this.geoLocationPermissionStatus.UNABLE_TO_FETCH);
                        }
                        reject(err);
                    },
                    this.geoLocationOptions
                );
            } else {
                reject('navigator not present');
            }
        });
    }

    getGeoLocationDataSync(): IGeoLocationDataResp {
        if (this.geoLocationData?.longitude && this.geoLocationData?.latitude) {
            return { success: true, geolocation: this.geoLocationData };
        } else {
            return { success: false, geolocation: null };
        }
    }

    clearGeolocationData(): void {
        this.geoLocationData = null;
    }

    getGeolocationIfAllowed(): Observable<IGeoLocationDataResp> {
        return this.getGeolocationPermission().pipe(
            map(permission => {
                if (permission === this.geoLocationPermissionStatus.GRANTED) {
                    return this.getGeoLocationDataSync();
                } else {
                    return { success: false, geolocation: null };
                }
            })
        );
    }

    setGeoLocationData(data: IGeolocation) {
        this.geoLocationData = data;
    }
}
