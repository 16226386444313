import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { IBannerConfig } from './prefix-banner.interface';

@Injectable({
    providedIn: 'root',
})
export class PrefixBannerService {
    private bannerConfig$ = new BehaviorSubject<IBannerConfig | null>(null);
    private visible$ = new BehaviorSubject(false);
    private bannerInstance$ = combineLatest({ bannerConfig: this.bannerConfig$, visible: this.visible$ });

    setAndShow(value: IBannerConfig) {
        this.bannerConfig$.next(value);
        this.visible$.next(true);
    }

    set(value: IBannerConfig) {
        this.bannerConfig$.next(value);
    }

    hide() {
        this.visible$.next(false);
    }

    getBannerInstance() {
        return this.bannerInstance$;
    }
}
