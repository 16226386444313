import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { IFilterConfig } from '../filters/interfaces/filters.interface';
import { ITimeSlot } from '../../component-modules/date-duration-time-filter/date-duration-time-filter.interface';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { TimeSlotsValue } from '../../component-modules/date-duration-time-filter/date-duration-time-filter.enum';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives/directives.module';
import { CheckModule } from '../../ui-components/check/check.module';

@Component({
    standalone: true,
    selector: 'app-time-filter',
    templateUrl: './time-filter.component.html',
    styleUrls: ['./time-filter.component.scss'],
    imports: [CommonModule, forwardRef(() => DirectivesModule), CheckModule],
})
export class TimeFilterComponent implements OnInit {
    CommonIconsUrl = CommonIconsUrl;
    config: IFilterConfig;
    timeSlots: ITimeSlot[];
    @Input('config') set updateConfig(data: IFilterConfig) {
        if (data) {
            this.config = data;
            if (this.config.selected) {
                this.selectedSlots = this.config.selected as TimeSlotsValue[];
            }
            this.parseTimeSlots(this.config);
        }
    }
    @Output() onSlotChange: EventEmitter<TimeSlotsValue[]> = new EventEmitter();
    selectedSlots: TimeSlotsValue[];
    constructor() {}

    ngOnInit(): void {}

    parseTimeSlots(filterConfig: IFilterConfig) {
        this.timeSlots = filterConfig.data.map(dataItem => {
            return {
                label: dataItem.title,
                value: dataItem.id,
                description: dataItem.description,
            };
        });
    }

    handleSelectSlot(value: TimeSlotsValue) {
        if (this.selectedSlots.includes(value)) {
            this.selectedSlots = this.selectedSlots.filter(slot => {
                return slot !== value;
            });
        } else {
            this.selectedSlots = [...this.selectedSlots, value];
        }
        this.onSlotChange.emit(this.selectedSlots);
    }

    isSlotSelected(value: TimeSlotsValue) {
        return this.selectedSlots.includes(value);
    }
}
