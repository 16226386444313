import {
    Component,
    OnInit,
    Input,
    TemplateRef,
    ElementRef,
    Output,
    EventEmitter,
    OnDestroy,
    AfterViewInit,
    Renderer2,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UtilsService } from '../../../core/services/utils/utils.service';

@Component({
    selector: 'app-modals',
    templateUrl: './modals.component.html',
    styleUrls: ['./modals.component.scss'],
})
export class ModalsComponent implements OnInit, OnDestroy, AfterViewInit {
    modalRef: BsModalRef;
    @Input() cssClass: string;
    @Input() template: TemplateRef<ElementRef>;
    @Output() hideModal? = new EventEmitter();

    constructor(
        private modalService: BsModalService,
        private utilsService: UtilsService
    ) {}

    ngAfterViewInit() {}

    ngOnInit() {
        if (this.utilsService.isBrowser) {
            this.modalRef = this.modalService.show(
                this.template,
                Object.assign({}, { class: this.cssClass, animated: false })
            );
            this.modalService.onHide.subscribe((reason: string) => {
                if (this.hideModal) {
                    this.hideModal.emit(null);
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.utilsService.isBrowser) {
            this.modalRef.hide();
        }
    }
}
