import { ICity } from '../../../interfaces/location.interface';
import { IFormHeaderConfig } from '../../../ui-components/form-header/form-header.interface';

export enum MOFormType {
    MODAL = 'MODAL',
}
export interface IManagedOfficeFormConfig {
    formType: MOFormType;
    formSource?: string;
    city?: ICity;
    showModal?: boolean;
    formHeaderConfig?: IFormHeaderConfig;
    workspaceSlug?: string;
}

export interface ICreateMOLeadFormData {
    name: string;
    phone: string;
    email: string;
}
