import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { IFilterConfig } from '../filters/interfaces/filters.interface';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives/directives.module';

@Component({
    standalone: true,
    selector: 'app-duration-filter',
    templateUrl: './duration-filter.component.html',
    styleUrls: ['./duration-filter.component.scss'],
    imports: [CommonModule, forwardRef(() => DirectivesModule)],
})
export class DurationFilterComponent implements OnInit {
    duration = 1;
    CommonIconsUrl = CommonIconsUrl;
    filterConfig: IFilterConfig;
    @Input('config') set updateConfig(data: IFilterConfig) {
        this.filterConfig = data;
        this.duration = +this.filterConfig.selected || (+this.filterConfig.defaultValue as number);
    }
    @Output() onChangeDuration: EventEmitter<number> = new EventEmitter();
    constructor() {}

    ngOnInit(): void {}

    decrement() {
        if (this.filterConfig.config.minLimit < this.duration) {
            this.duration--;
            this.onChangeDuration.emit(this.duration);
        }
    }

    increment() {
        if (this.filterConfig.config.maxLimit > this.duration) {
            this.duration++;
            this.onChangeDuration.emit(this.duration);
        }
    }
}
