<!-- URL and internal URL -->
<a
    *ngIf="url && !isExternalUrl"
    [routerLink]="url"
    [queryParams]="queryParams"
    class="link-with-icon c-pointer"
    [ngClass]="cssConfigArray"
    [target]="target"
    aria-label="myhq"
>
    <ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
</a>
<!-- URL and external URL -->
<a
    *ngIf="url && isExternalUrl"
    [href]="url"
    class="link-with-icon c-pointer"
    [ngClass]="cssConfigArray"
    [target]="target"
    aria-label="myhq"
>
    <ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
</a>
<!-- emits event -->
<div
    *ngIf="!url"
    class="link-with-icon c-pointer"
    [ngClass]="cssConfigArray"
    (click)="onClickEmitEvent($event)"
    aria-label="myhq"
    role="button"
>
    <ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
</div>
<ng-template #linkTemplate>
    <div class="link d-flx a-items-c">
        <span class="text" [title]="LinkText" [innerHTML]="LinkText"></span>
        <span class="icon-container d-flx a-items-c" [innerHTML]="rightArrowIcon"> </span>
    </div>
</ng-template>
