import { Injectable } from '@angular/core';
import { UrlUtilsService } from '../../../core/services/utils/url-utils.service';
import { interpolateString } from '../../../core/constants/interpolate-string';
import { CommonService } from '../common/common.service';
import { SelectCityService } from '../select-city/select-city.service';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { IProductMap } from '../../interfaces/product-interface';
import { nearMeLocationObj, productKeys } from '../../enums/enums';
import { RecentSearchesService } from '../recent-searches/recent-searches.service';

@Injectable({
    providedIn: 'root',
})
export class LocalitiesService {
    citySlug: string;
    constructor(
        private commonService: CommonService,
        private urlUtilsService: UrlUtilsService,
        private selectCityService: SelectCityService,
        private route: ActivatedRoute,
        private recentSearchesService: RecentSearchesService
    ) {}
    selectCitySub = this.selectCityService.getSelectedCitySync().subscribe(({ city }) => {
        if (city) {
            this.citySlug = city.slug;
        }
    });

    generateLocalitiesRedirectUrl(location: any, productMap?: IProductMap) {
        const params = {};
        const productUrlString = productMap ? productMap.productBaseUrl : '';
        const listingUrlSlug = productMap.listingUrlSlug;
        params[interpolateString.PRODUCT_TYPE] = productUrlString;
        params[interpolateString.SPACE_TYPE] = listingUrlSlug;
        const options: NavigationExtras = {};
        let urlData: any = {};
        const isNearMe = location.place_id === nearMeLocationObj.PLACE_ID;
        if (!location.isLocation && location.redirectUrl) {
            return { redirectUrl: location.redirectUrl, options };
        }

        if (location.redirectUrl) {
            // TODO: ADDED FOR BACKWARDS COMPATIBILITY, REMOVE on OCTOBER 2023
            if (location.redirectUrl.includes('coworking-space-in-')) {
                location.redirectUrl = location.redirectUrl.replace('coworking-space-in-', '%{spaceType}-in-');
                this.recentSearchesService.updateDeprecatedRecentSearchItems();
            }
            const redirectUrl = this.urlUtilsService.interpolateParams(location.redirectUrl, params);
            urlData = this.commonService.getQueryParamsFromString(redirectUrl);
        } else if (location.place_id) {
            urlData.url = `/${this.citySlug}/${productUrlString}/${listingUrlSlug}-in-${this.citySlug}`;
            urlData.queryParams = isNearMe
                ? {
                      latitude: location.latitude,
                      longitude: location.longitude,
                  }
                : {
                      placeId: location.place_id,
                  };
        } else if (location.slug && location.city) {
            urlData.url = `/${location.city.slug}/${productUrlString}/${listingUrlSlug}-in-${location.slug}`;
        } else {
            urlData.url = `/${this.citySlug}/${productUrlString}/${listingUrlSlug}-in-${this.citySlug}`;
        }
        if (urlData.queryParams?.placeId) {
            options.skipLocationChange = true;
            options.relativeTo = this.route;
        }
        return { redirectUrl: urlData.url, queryParams: urlData.queryParams, options };
    }
}
