import { NgModule } from '@angular/core';
import { LoadersComponent } from './loaders.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [LoadersComponent],
    imports: [CommonModule],
    exports: [LoadersComponent],
})
export class LoadersModule {}
