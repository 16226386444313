export enum MOCenterType {
    COWORKING = 'COWORKING',
    MANAGED_OFFICE = 'MANAGED_OFFICE',
}

export enum MOCenterTypeMap {
    COWORKING = 'Coworking Centre',
    MANAGED_OFFICE = 'Managed Office',
}

export enum LockInUnitType {
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export enum LockInUnitTypeMap {
    MONTH = 'month',
    YEAR = 'year',
}
