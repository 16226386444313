import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Observable, Observer, of } from 'rxjs';

import { ITypeaheadConfig } from '../../interfaces/typeahead.interface';
import { switchMap } from 'rxjs/operators';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { TypeaheadConfig } from 'ngx-bootstrap/typeahead';
import { DomSanitizer } from '@angular/platform-browser';
// @ts-ignore
import SearchIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/search.svg';
// @ts-ignore
import LeftArrowIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/left-arrow-dark.svg';
// @ts-ignore
import CrossIcon from '!!raw-loader!svgo-loader!../../../../assets/web/ssr/common/assets/cross.svg';
import { PrefixIcon, TypeaheadTypes } from '../../enums/enums';

export function getTypeaheadConfig(): TypeaheadConfig {
    return Object.assign(new TypeaheadConfig(), { cancelRequestOnFocusLost: true });
}

@Component({
    selector: 'app-typeahead',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./typeahead.component.scss'],
    providers: [{ provide: TypeaheadConfig, useFactory: getTypeaheadConfig }],
})
export class TypeaheadComponent implements OnInit, AfterViewInit {
    CommonIconsUrl = CommonIconsUrl;
    searchString: any = '';
    suggestions$: Observable<any>;
    typeaheadLoading: boolean;
    noResult = false;
    deviceInfo;
    config: ITypeaheadConfig;

    @Input() isSearchModalInFocus?: boolean;
    @Input('config') set updateConfig(data: ITypeaheadConfig) {
        if (data) {
            this.config = data;
            if (this.config.selectedItems && this.config.selectedItems.length > this.config?.visibleItemCountLimit) {
                this.selectedMoreTitle = `+ ${this.config.selectedItems.length - 1} more`;
            }
        }
    }
    @Input() listTemplate: TemplateRef<any>;
    @Input() loadingTemplate?: TemplateRef<any>;
    @Input() focusOnInput?: boolean;
    @Input() noResultTemplate?: TemplateRef<any>;
    @Output() onSelect = new EventEmitter();
    @Output() iconClickEvent = new EventEmitter();
    @Output() onFocus? = new EventEmitter();
    @Output() onSearchStringChangeEvent? = new EventEmitter();
    @Output() onRemoveSelectedItem? = new EventEmitter();
    @Input() set initValue(value: string) {
        this.searchString = value;
    }
    @ViewChild('typeaheadInput') typeaheadInput: ElementRef;
    constructor(
        private utilsService: UtilsService,
        private sanitizer: DomSanitizer
    ) {}

    cssConfig = {
        [TypeaheadTypes.SMALL]: 'typeahead-field-small',
        [TypeaheadTypes.LOCALITY_FILTER]: 'locality-filter-typeahead-field',
    };

    prefixIconMap = {
        [PrefixIcon.SEARCH_ICON]: this.sanitizer.bypassSecurityTrustHtml(SearchIcon),
        [PrefixIcon.LEFT_ICON]: this.sanitizer.bypassSecurityTrustHtml(LeftArrowIcon),
    };
    crossIcon = this.sanitizer.bypassSecurityTrustHtml(CrossIcon);
    selectedMoreTitle = '+ more';

    ngOnInit() {
        this.suggestions$ = new Observable((observer: Observer<string>) => {
            observer.next(this.searchString);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.config.getDataAsObservable?.(query);
                }
                return of([]);
            })
        );
        this.deviceInfo = this.utilsService.getDeviceInfo();
    }

    ngAfterViewInit() {
        if (this.focusOnInput) {
            setTimeout(() => this.typeaheadInput.nativeElement.focus());
        }
    }

    toggleFocusAndInitializeResults() {
        if (this.onFocus) {
            this.onFocus.emit();
        }
        if (this.config.scrollTypeaheadDropDown && !this.deviceInfo.isMobile) {
            this.utilsService.scrollToTargetAdjusted(this.typeaheadInput.nativeElement, 100);
        }
        if (this.searchString) {
            this.typeaheadInput.nativeElement.dispatchEvent(new Event('input'));
        }
    }

    select(event: any) {
        this.onSelect.emit(event.item);
    }

    clearValue() {
        this.searchString = '';
        this.onSearchStringChangeEvent.emit('');
        this.typeaheadInput.nativeElement.focus();
    }

    typeaheadChangeHandler(value: string): void {
        this.onSearchStringChangeEvent.emit(value);
    }

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    onIconClickEvent() {
        this.iconClickEvent.emit();
    }

    handleRemoveSelectedItem(id: string) {
        this.onRemoveSelectedItem.emit(id);
    }

    isIOSSafari() {
        return this.utilsService.isiOSSafari();
    }
}
