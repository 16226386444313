<ng-container *ngIf="!deviceInfo.isMobile">
    <div class="login-signup-btn-container" *ngIf="!isLoggedIn">
        <app-button class="width-100" [field]="loginBtnConfig" (onClick)="handleLoginCTA()"></app-button>
    </div>

    <div *ngIf="isLoggedIn" (clickOutside)="toggleDropdown(false)" appClickOutside>
        <div class="d-flx flx-d-clm my-account-dropdown-container">
            <div class="d-flx a-items-c my-account-dropdown-label-container c-pointer" (click)="toggleDropdown(true)">
                <p class="label-text font-bold font-12 text-color-dark">{{ dropdownLabel }}</p>
                <div class="d-flx a-items-c j-cont-cen my-account-dropdown-icon-container">
                    <span class="d-flx caret-down"></span>
                </div>
            </div>
            <div class="dropdown-wrapper" *ngIf="showDropdown">
                <ng-container [ngTemplateOutlet]="dropdownList"></ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="deviceInfo.isMobile">
    <div class="d-flx a-items-c login-signup-mob-cta" (click)="handleMobileLoginCTA()" *ngIf="!isLoggedIn">
        <div class="d-flx a-items-c j-cont-cen mob-cta-icon-container">
            <img
                class="height-100 width-100 object-fit-contain"
                appImageShimmerLazyLoad
                [imageSrc]="CommonIconsUrl.PERSON_OUTLINED_GREY"
                [alt]="dropdownLabel"
            />
        </div>
        <p class="mob-cta-label font-12 font-bold text-color-dark">{{ dropdownLabel }}</p>
    </div>

    <div *ngIf="isLoggedIn" (clickOutside)="toggleDropdown(false)" appClickOutside>
        <div class="margin-vertical-16 width-100">
            <div class="font-12 text-color-light-grey margin-bottom-8">My Account</div>

            <ng-container
                [ngTemplateOutlet]="dropdownItem"
                [ngTemplateOutletContext]="{ item, level: 1 }"
                *ngFor="let item of dropdownListItems | filterBy: 'show'; trackBy: itemById"
            ></ng-container>
        </div>
    </div>
</ng-container>

<!--templates-->
<ng-template #dropdownList>
    <div class="d-flx flx-d-clm bg-color-white my-account-dropdown-list-container">
        <ng-container
            [ngTemplateOutlet]="dropdownItem"
            [ngTemplateOutletContext]="{ item, level: 1 }"
            *ngFor="let item of dropdownListItems | filterBy: 'show'; trackBy: itemById"
        ></ng-container>
        <hr class="divider" />
        <ng-container
            [ngTemplateOutlet]="dropdownItem"
            [ngTemplateOutletContext]="{ item: logoutDropdownItem }"
        ></ng-container>
    </div>
</ng-template>

<ng-template #dropdownItem let-item="item" let-level="level">
    <div
        class="d-flx a-items-c j-cont-space-bet my-account-dropdown-item c-pointer"
        [ngClass]="item.cssClass"
        (click)="item.onClick?.()"
        [style]="{ 'margin-left': calcMargin(level) }"
    >
        <div class="d-flx a-items-c gap-8">
            <div class="d-flx a-items-c j-cont-cen dropdown-icon-container" *ngIf="item.icon">
                <img
                    class="height-100 width-100 object-fit-contain"
                    appImageShimmerLazyLoad
                    [imageSrc]="item.icon.url"
                    [alt]="item.icon.alt"
                />
            </div>

            <p class="dropdown-label text-color-dark font-bold font-12">
                {{ item.label }}
                <span class="coming-soon-text text-color-x-lighter-grey font-italic" *ngIf="item.comingSoon"
                    >-Coming Soon</span
                >
            </p>
        </div>

        <div
            *ngIf="item.type === menuType.ACCORDION"
            class="arrow arrow-down margin-right-16 margin-bottom-8"
            [ngClass]="{ 'arrow-down': !item.isOpen, 'arrow-up': item.isOpen }"
        ></div>
    </div>

    <div *ngIf="item.isOpen">
        <ng-container
            [ngTemplateOutlet]="dropdownItem"
            [ngTemplateOutletContext]="{ item, level: level + 1 }"
            *ngFor="let item of item.items | filterBy: 'show'; trackBy: itemById"
        ></ng-container>
    </div>
</ng-template>
