import { IHomeProductDetails } from './home.interface';
import { productKeys } from '../../../shared/enums/enums';
import { GreyInfoBannerCtaType } from '../../../shared/standalone-components/grey-info-banner/interface';

export enum HomeProductCardType {
    DEFAULT = 'DEFAULT',
    CAROUSEL = 'CAROUSEL',
}

type AllowedProducts =
    | productKeys.FLEXI
    | productKeys.DEDICATED
    | productKeys.MEETING_ROOM
    | productKeys.VIRTUAL_OFFICE;
export const HomeProductsDetails: Record<AllowedProducts, IHomeProductDetails> = {
    [productKeys.FLEXI]: {
        searchBannerLabel: 'Desks-by-the-day in coworking spaces. Starting at ₹200/day',
        searchBannerLabelMob: 'Desk-by-the-day in spaces from ₹200/day',
        greyBannerLabel: '250+ workspaces to choose from pan-India. Claim your free day pass now!',
        greyBannerCtaType: GreyInfoBannerCtaType.DOWNLOAD_MYHQ_APP,
    },
    [productKeys.DEDICATED]: {
        searchBannerLabel: 'Rent dedicated seats and private cabins in fully-equipped coworking spaces',
        searchBannerLabelMob: 'Rent desks & private cabins in coworking spaces',
        greyBannerLabel:
            'Find your perfect office space with assistance from our advisors / dedicated enterprise managers',
        greyBannerCtaType: GreyInfoBannerCtaType.TALK_TO_AN_EXPERT,
    },
    [productKeys.MEETING_ROOM]: {
        searchBannerLabel: 'On-demand meeting, conference and training rooms for teams',
        searchBannerLabelMob: 'On-demand meeting, conference and training rooms',
        greyBannerLabel: 'Ensure your meetings are seamless and well-prepared with our dedicated support',
        greyBannerCtaType: GreyInfoBannerCtaType.NONE,
    },
    [productKeys.VIRTUAL_OFFICE]: {
        searchBannerLabel: 'Professional address & business services',
        searchBannerLabelMob: 'Professional address & business services',
        greyBannerLabel:
            'Find your perfect Virtual Office with personalised assistance and strategic insights from our team',
        greyBannerCtaType: GreyInfoBannerCtaType.NONE,
    },
};
