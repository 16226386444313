import { ImageBaseUrl, PromotionConstants } from '../../../core/constants/constants';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IHttpResponse } from '../../interfaces/HTTPResponse.interface';
import { Injectable } from '@angular/core';
import { ErrorService } from '../../../core/services/error/error.service';
import { IWhyChooseMyHq } from '../../interfaces/why-choose-my-hq.interface';
import { buttonType, fontSizes, fontWeights } from '../../enums/enums';
import { IButtonConfig } from '../../interfaces/button.interface';

@Injectable({
    providedIn: 'root',
})
export class CommonXhrService {
    constructor(
        private http: HttpClient,
        private errorService: ErrorService
    ) {}

    sendAppLink(payload: any): Observable<IHttpResponse<any>> {
        const url = `${environment.baseUrl}/services/downloadapplink/web`;
        return this.http.post<any>(url, payload).pipe(
            tap((res: IHttpResponse<any>) => res),
            catchError(this.errorService.handleError)
        );
    }

    subscribeNewsletter(payload) {
        return this.http
            .post<IHttpResponse<any>>(`${environment.baseUrl}/campaigns/subscribeNewsLetter`, payload)
            .pipe(catchError(this.errorService.handleError));
    }

    getmyHQExpertCTAConfig(): Observable<any> {
        const ctaConfig = {
            heading: 'Didn’t find what you are looking for? Share custom requirements with our team',
            btn: {
                text: 'Request Callback',
                showArrow: true,
                key: 'chat',
            },
        };
        return new Observable(observer => {
            observer.next(ctaConfig);
        });
    }

    getmyHQExpertVOListingCTAConfig(): Observable<any> {
        const ctaConfig = {
            heading: `Didn’t find what you are looking for? Share custom requirements with our team`,
            btn: {
                text: 'Request Callback',
                showArrow: false,
                key: 'callback',
            },
        };
        return new Observable(observer => {
            observer.next(ctaConfig);
        });
    }

    getMyHQExpertMeetingRoomsListingCTAConfig(): Observable<{
        heading: string;
        description: string;
        button: IButtonConfig;
    }> {
        const ctaConfig = {
            heading: 'Looking for a meeting room?',
            description: 'We’ve got you covered. Connect with our experts now.',
            button: {
                type: buttonType.OUTLINE,
                label: 'Request Callback',
            },
        };
        return new Observable(observer => {
            observer.next(ctaConfig);
        });
    }

    getWhyMyHQContent(): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why choose myHQ by ANAROCK?`,
            description: `We make your office-search, a hassle-less experience.  Your one-stop platform and the industry's largest online player.`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/brokerage-flat.svg`,
                        alt: `Zero Brokerage Fees`,
                    },
                    heading: 'Zero Brokerage Fees',
                    description: 'We charge zero brokerage fees for our office space rentals',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest Coverage',
                    description: `An expansive network of ${PromotionConstants.WORKSPACE_COUNT}+ coworking spaces, you’re sure to find what you’re looking for`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/fastest-tat.svg`,
                        alt: `Quick Turnaround Time`,
                    },
                    heading: 'Quick Turnaround Time',
                    description: 'Experience swift and effective solutions with us',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                        alt: `Dedicated Office Spaces Experts`,
                    },
                    heading: 'Dedicated Office Spaces Experts',
                    description:
                        'From consulting, shortlisting to documentation, our office space experts will help you in finding the perfect office space.',
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
        });
    }

    getWhyMyHQContentOfficeSpaceV2(): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why choose myHQ by ANAROCK?`,
            description: `We make your office-search, a hassle-less experience.  Your one-stop platform and the industry's largest online player.`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/brokerage-flat.svg`,
                        alt: `Zero Brokerage Fees`,
                    },
                    heading: 'Zero Brokerage Fees',
                    description: 'We charge zero brokerage fees for our office space rentals',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest Coverage',
                    description: `Pan India coverage with 5000+ landlords, coworking operators`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/fastest-tat.svg`,
                        alt: `Quick Turnaround Time`,
                    },
                    heading: 'Quick Turnaround Time',
                    description: `Experience swift and effective solutions with us`,
                },

                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                        alt: `Dedicated Workspace Experts`,
                    },
                    heading: 'Dedicated Office Spaces Experts',
                    description:
                        'From consulting, shortlisting to documentation, our office space experts will help you in finding the perfect office space.',
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
        });
    }

    getWhyMyHQFlexiContent(): Observable<IWhyChooseMyHq> {
        const whymyHQFlexiContent: IWhyChooseMyHq = {
            heading: `Why book Desks with myHQ?`,
            description: `We’re a team of workspace solution experts working towards making office-search a hassle free experience.`,
            hideDescriptionsMob: true,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/flexi/promotional/unique-spaces-flat.svg`,
                        alt: `Unique spaces`,
                    },
                    heading: `Unique spaces`,
                    description: `Uncover new, creative spaces - from artistic cafes to hidden rooftops and beyond.`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/flexi/promotional/economical-pricing-flat.svg`,
                        alt: `Economical pricing`,
                    },
                    heading: 'Economical pricing',
                    description:
                        'Our spaces are priced to fit your budget. Pay by the day without worrying about monthly rents.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/flexi/promotional/hassle-free-booking-flat.svg`,
                        alt: `Hassle free bookings`,
                    },
                    heading: 'Hassle free bookings',
                    description: 'Book a workspace on demand with the click of a button.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/flexi/promotional/productive-work-day-flat.svg`,
                        alt: `Productive Workday`,
                    },
                    heading: 'Productive Workday',
                    description:
                        'Get all work amenities like high speed wifi, power backup, ample plug points & printing.',
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whymyHQFlexiContent);
        });
    }

    getWhyMyHQVOContent(): Observable<IWhyChooseMyHq> {
        const whyMyhqVO: IWhyChooseMyHq = {
            heading: 'What does myHQ guarantee in your Virtual Office?',
            description: `We make your office-search, a hassle-less experience.
    With an in depth knowledge, let our workspace solution experts find what you’re looking for.`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/lowest-price-flat.svg`,
                        alt: `Lowest Price Guarantee`,
                    },
                    heading: 'Lowest Price Guarantee',
                    description:
                        'Get an additional 5% discount if you can find a cheaper solution at the same partner space',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/turnaround-v2-flat.svg`,
                        alt: `72-hour Document TAT`,
                    },
                    heading: 'Fastest Document Turnaround Time',
                    description: 'Our average document delivery time is just 5 to 7 working days',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/kyc.svg`,
                        alt: `Digital KYC and Agreement`,
                    },
                    heading: 'Digital KYC and Agreement',
                    description: `Experience hassle-free onboarding with our new digital KYC and agreement process`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/money-back-guarentee.svg`,
                        alt: `hassle free`,
                    },
                    heading: 'Money Back Guarantee',
                    description: 'Get your money back in case of GST/ Company registration rejection',
                },
            ],
        };

        return new Observable(observer => {
            observer.next(whyMyhqVO);
        });
    }

    getWhyMyHQDedicatedVisitSuccessContent(): Observable<IWhyChooseMyHq> {
        const whyMyHQDedicatedSuccess: IWhyChooseMyHq = {
            heading: 'Why choose myHQ?',
            description: ``,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/largest-coverage-flat.svg`,
                        alt: `Largest coverage`,
                    },
                    heading: 'Largest coverage',
                    description: `An expansive network of ${PromotionConstants.WORKSPACE_COUNT}+ coworking spaces and managed offices to choose from`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/unique-spaces.svg`,
                        alt: `Workspaces for everyone`,
                    },
                    heading: 'Workspaces for everyone',
                    description:
                        'From startups to large enterprises, our workspaces are designed for teams & businesses of all sizes.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/no-brokerage-fee.svg`,
                        alt: `Zero Brokerage Fees`,
                    },
                    heading: 'Zero Brokerage Fees',
                    description: `We charge zero brokerage fees for office space rentals.`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/transparency.svg`,
                        alt: `Transparent & Ethical`,
                    },
                    heading: 'Transparent & Ethical',
                    description: 'Our pricing is transparent with no hidden charges and honest space reviews.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/dedicated-experts.svg`,
                        alt: `Dedicated Workspace Experts`,
                    },
                    heading: 'Dedicated Workspace Experts',
                    description:
                        'From consulting, shortlisting to documentation, our workspaces experts with help you in finding the perfect workspace.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/hassle-free-transparent.svg`,
                        alt: `Hassle Free Experience`,
                    },
                    heading: 'Hassle Free Experience',
                    description: '100% digital process with no running around for compliances or unnecessary calls',
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whyMyHQDedicatedSuccess);
        });
    }

    getWhyMyHQVOListingContent(): Observable<IWhyChooseMyHq> {
        const whyMyHQVOListingContent: IWhyChooseMyHq = {
            heading: 'Why choose myHQ?',
            description: '',
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/largest-coverage-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest Coverage',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/money-flat.svg`,
                        alt: `Lowest Price Guarantee`,
                    },
                    heading: 'Lowest Price Guarantee',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/refund-flat.svg`,
                        alt: `Money Back Guarantee`,
                    },
                    heading: 'Money Back Guarantee',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/hassle-free-transparent.svg`,
                        alt: `Hassle Free`,
                    },
                    heading: 'Hassle Free',
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whyMyHQVOListingContent);
        });
    }

    getDedicatedWhyMyHQContent(): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why choose myHQ by ANAROCK?`,
            description: `We make your office-search, a hassle-less experience. With an in depth knowledge, let our workspace solution experts find what you’re looking for.`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/brokerage-flat.svg`,
                        alt: `Zero Brokerage Fees`,
                    },
                    heading: 'Zero Brokerage Fees',
                    description: 'We charge zero brokerage fees for office space rentals.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest Coverage',
                    description: `Pan India coverage with 5000+ landlords & professional consultants`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/fastest-tat.svg`,
                        alt: `Quick Turnaround Time`,
                    },
                    heading: 'Quick Turnaround Time',
                    description: "Quick and Efficient- That's How We Roll!",
                },

                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                        alt: `Dedicated Workspace Experts`,
                    },
                    heading: 'Dedicated Workspace Experts',
                    description:
                        'From consulting, shortlisting to documentation, our office space experts will help you in finding the perfect office space.',
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
        });
    }

    getManagedOfficeWhyMyHQContent(): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why choose myHQ by ANAROCK?`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/new-banner/zero-brokerage.svg`,
                        alt: `Zero Brokerage`,
                    },
                    heading: 'Zero Brokerage',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/new-banner/portfolio-of-workspaces.svg`,
                        alt: `Largest Portfolio of Workspaces`,
                    },
                    heading: 'Largest Portfolio of Workspaces',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/new-banner/dedicated-account-manager.svg`,
                        alt: `Free, Dedicated Account Managers`,
                    },
                    heading: 'Free, Dedicated Account Managers',
                },

                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/new-banner/layout-design.svg`,
                        alt: `Layout Design Assistance & Move-in support`,
                    },
                    heading: 'Layout Design Assistance & Move-in support',
                },
            ],
            image: {
                url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/new-banner/managed-office.png`,
                alt: 'Managed Office',
            },
            buttonConfig: {
                label: 'Get Free Consultation',
                size: fontSizes.MEDIUM,
                type: buttonType.OUTLINE,
                fontWeight: fontWeights.BOLD,
            },
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
        });
    }
    getEnterprisesMyHQContent(): Observable<IWhyChooseMyHq> {
        const whymyHQ: IWhyChooseMyHq = {
            heading: `Why businesses choose myHQ by ANAROCK?`,
            description: `We make your office-search, a hassle-less experience. With an in depth knowledge, let our office space solution experts find what you’re looking for.`,
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/brokerage-flat.svg`,
                        alt: `Zero Brokerage Fees`,
                    },
                    heading: 'Zero Brokerage Fees',
                    description: 'We charge zero brokerage fees for office space rentals.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                        alt: `Largest Coverage`,
                    },
                    heading: 'Largest Coverage',
                    description: `Pan India coverage with 5000+ landlords & professional consultants.`,
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/fastest-tat.svg`,
                        alt: `Quick Turnaround Time`,
                    },
                    heading: 'Quick Turnaround Time',
                    description: "Quick and Efficient- That's How We Roll!",
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                        alt: `Dedicated Office Spaces Experts`,
                    },
                    heading: 'Dedicated Office Spaces Experts',
                    description:
                        'From consulting, shortlisting to documentation, our office space experts will help you in finding the perfect office space.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/ethical-flat.svg`,
                        alt: `Transparent & Ethical`,
                    },
                    heading: 'Transparent & Ethical',
                    description: 'Honest office space feedbacks and transparent communication.',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/partnership.svg`,
                        alt: `Part of the ANAROCK Group`,
                    },
                    heading: 'Part of the ANAROCK Group',
                    description: "Powered by ANAROCK Group – India's Leading Real Estate Giant.",
                },
            ],
        };
        return new Observable(observer => {
            observer.next(whymyHQ);
        });
    }

    getMeetingRoomsWhyMyHQContent(): Observable<IWhyChooseMyHq> {
        const whyMyHQVOListingContent: IWhyChooseMyHq = {
            heading: 'Why book Meeting Rooms with myHQ?',
            description: '',
            benefits: [
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/turnaround-v2-flat.svg`,
                        alt: `Instant confirmation`,
                    },
                    heading: 'Instant confirmation',
                    description: 'Secure a meeting room on the go with guaranteed reservation',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/currency-white.svg`,
                        alt: `Lowest Price Guarantee`,
                    },
                    heading: 'Lowest Price & Exciting Offers',
                    description: 'Enjoy Lowest rates and attractive discounts',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/meeting-rooms/why-my-hq/premium-offerings.svg`,
                        alt: `Premium Offerings`,
                    },
                    heading: 'Fully Equipped & Verified Listings',
                    description: 'Projectors, VC facilities with a pleasing ambiance',
                },
                {
                    image: {
                        url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/meeting-rooms/why-my-hq/receipt-white.svg`,
                        alt: `Instant Invoice Generation`,
                    },
                    heading: 'Instant Invoice Generation',
                    description: 'For easier corporate reimbursements',
                },
            ],
            hideDescriptionsMob: true,
        };
        return new Observable(observer => {
            observer.next(whyMyHQVOListingContent);
        });
    }

    getWhyMeetingRoomsContent() {
        return [
            {
                image: {
                    url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/map-flat.svg`,
                    alt: `Space partners`,
                },
                heading: '',
                description: '250+ meeting spaces offered by our partner brands',
                cssClasses: {
                    container: 'flx-d-clm text-align-center flx-1 a-items-c flx-gap-16',
                },
            },
            {
                image: {
                    url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/fastest-tat.svg`,
                    alt: `Instant confirmation`,
                },
                heading: '',
                description: 'Instant & verified confirmation on bookings',
                cssClasses: {
                    container: 'flx-d-clm text-align-center flx-1 a-items-c flx-gap-16',
                },
            },
            {
                image: {
                    url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/pages/virtual-office/why-myhq/flexible-plan-v2-flat.svg`,
                    alt: `Dedicated Office Spaces Experts`,
                },
                heading: '',
                description: 'Book for multiple guests and multiple days in one go',
                cssClasses: {
                    container: 'flx-d-clm text-align-center flx-1 a-items-c flx-gap-16',
                },
            },
            {
                image: {
                    url: `${ImageBaseUrl.IMAGE_URL_BASE}/web/ssr/common/whymyhq/dedicated-flat.svg`,
                    alt: `Dedicated Office Spaces Experts`,
                },
                heading: '',
                description: 'Dedicated expert assistance for specialised requirements',
                cssClasses: {
                    container: 'flx-d-clm text-align-center flx-1 a-items-c flx-gap-16',
                },
            },
        ];
    }
}
