import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { buttonType, geoLocationPermissionStatus } from '../../enums/enums';
import { CommonService } from '../../services/common/common.service';
import { SelectCityService } from '../../services/select-city/select-city.service';
import { ICity } from '../../interfaces/location.interface';
import { GeolocationService } from '../../../core/services/geolocation/geolocation.service';
import { IGeolocation } from '../../interfaces/geolocation.interface';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives/directives.module';
import { ButtonsModule } from '../../ui-components/buttons/buttons.module';
import { ErrorMsg } from '../../../core/constants/error-msg';

export interface INearMeConfig {
    label?: string;
}

@Component({
    standalone: true,
    selector: 'app-near-me',
    templateUrl: './near-me.component.html',
    styleUrls: ['./near-me.component.scss'],
    imports: [CommonModule, DirectivesModule, ButtonsModule],
})
export class NearMeComponent implements OnInit, OnDestroy {
    @Input() config: INearMeConfig;
    @Output() callback = new EventEmitter();
    @Output() fetchStatus: EventEmitter<'loading' | 'done'> = new EventEmitter();
    buttonType = buttonType;
    disableNearMeBtn = false;
    geoLocationPermissionStatus = geoLocationPermissionStatus;
    showLoader = false;
    showError = false;
    selectedCity: ICity;
    selectCitySub;
    geoLocationPermissionSub;

    constructor(
        private commonService: CommonService,
        private geolocationService: GeolocationService,
        private selectCityService: SelectCityService
    ) {
        this.selectCitySub = this.selectCityService.getSelectedCitySync().subscribe(({ city }) => {
            if (city) {
                this.selectedCity = city;
            }
        });
        this.geoLocationPermissionSub = this.geolocationService.getGeolocationPermission().subscribe(permission => {
            if (
                permission &&
                (permission === this.geoLocationPermissionStatus.DENIED ||
                    permission === this.geoLocationPermissionStatus.UNABLE_TO_FETCH)
            ) {
                this.showError = true;
            }
        });
    }

    ngOnInit() {}

    ngOnDestroy() {
        if (this.geoLocationPermissionSub) {
            this.geoLocationPermissionSub.unsubscribe();
        }
        if (this.selectCitySub) {
            this.selectCitySub.unsubscribe();
        }
    }

    onClickSelectLocation() {
        this.showLoader = true;
        this.fetchStatus.emit('loading');
        this.disableNearMeBtn = true;
        this.geolocationService
            .getGeoLocationData()
            .then((location: IGeolocation) => {
                const nearMeLocObj = this.commonService.getNearMeLocationObj();
                this.callback.emit({
                    ...nearMeLocObj,
                    longitude: location.longitude,
                    latitude: location.latitude,
                });
                this.fetchStatus.emit('done');
                this.disableNearMeBtn = false;
            })
            .catch(err => {
                this.fetchStatus.emit('done');
                this.showError = true;
                this.handleDeniedLocationPermission();
            })
            .finally(() => {
                this.showLoader = false;
                this.fetchStatus.emit('done');
                this.disableNearMeBtn = false;
            });
    }

    handleDeniedLocationPermission() {
        alert(ErrorMsg.locationPermissionDenied);
    }
}
