import { Subject } from 'rxjs';

export class ObserverQueue extends Subject<any> {
    private items = [];
    push(item) {
        if (this.observers.length > 0) {
            this.next(item);
        } else {
            this.items.push(item);
        }
    }

    subscribe(observer) {
        const s = super.subscribe(observer);
        this.items.forEach(item => this.next(item));
        this.items = [];
        return s;
    }
}
