import { FormsModule } from '../forms/forms.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputWithButtonComponent } from './input-with-button.component';
import { DirectivesModule } from '../../directives/directives.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [InputWithButtonComponent],
    imports: [CommonModule, ReactiveFormsModule, DirectivesModule, FormsModule],
    exports: [InputWithButtonComponent],
})
export class InputWithButtonModule {}
