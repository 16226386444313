import { Component, OnInit, ChangeDetectorRef, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { ICity } from '../../interfaces/location.interface';
import { ISelectCityModalConfig } from '../select-city-modal/select-city-modal.interface';
import { SelectCityService } from '../../services/select-city/select-city.service';
@Component({
    selector: 'app-select-city',
    templateUrl: './select-city.component.html',
    styleUrls: ['./select-city.component.scss'],
})
export class SelectCityComponent implements OnInit, AfterViewInit, OnDestroy {
    selectedCity: ICity;
    selectCityModalConfig: ISelectCityModalConfig;
    selectCitySub = this.selectCityService.getSelectedCitySync().subscribe(({ city }) => {
        if (city) {
            this.selectedCity = city;
        }
    });

    constructor(
        private cdRef: ChangeDetectorRef,
        private selectCityService: SelectCityService
    ) {}

    ngOnInit() {}

    ngAfterViewInit(): void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy() {
        this.selectCitySub.unsubscribe();
    }

    toggleSelectCityModal(action: boolean) {
        this.selectCityModalConfig = {
            showModal: action,
        };
    }
}
