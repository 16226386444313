import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IMultiDropdownFieldConfig, ISingleOption } from '../../interfaces/field.interface';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import {IButtonConfig} from '../../interfaces/button.interface';
import {buttonType, fontSizes, fontWeights} from '../../enums/enums';

@Component({
    selector: 'app-multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements OnInit {
    field: IMultiDropdownFieldConfig;
    @Input() form: UntypedFormGroup;

    @Input('field') set updateConfig(data: IMultiDropdownFieldConfig) {
        this.field = data;
    }

    updateBtnConfig: IButtonConfig = {
        label: 'Select',
        type: buttonType.LINK,
        size: fontSizes.MEDIUM_MOB_DESK,
        fontWeight: fontWeights.BOLD,
    };
    commonIconsUrl = CommonIconsUrl;

    isDropdownOpen = false;
    selectedOptions: ISingleOption[] = [];
    selectedLabels: string[] = [];

    @ViewChild('container') container: ElementRef;
    @ViewChild('dropdown') dropdown: ElementRef;

    constructor() {}

    ngOnInit() {
        const defaultOptions = this.field.default;
        // Setting the selected value as the default one in the dropdown if it exists
        if (defaultOptions) {
            this.form.controls[this.field.name].setValue(defaultOptions);
        } else {
            this.form.controls[this.field.name].setValue([]);
        }
    }

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    closeDropdown(event): void {
        if (!this.dropdown?.nativeElement.contains(event.relatedTarget)) {
            this.isDropdownOpen = false;
        }
    }

    toggleOption(option: ISingleOption): void {
        if (this.isSelected(option)) {
            this.selectedOptions = this.selectedOptions.filter(v => v.value !== option.value);
        } else {
            this.selectedOptions.push(option);
        }
        const selectedValues = this.selectedOptions.map(o => o.value) ?? [];
        this.selectedLabels = this.selectedOptions.map(o => o.label) ?? [];
        this.form.controls[this.field.name].setValue(selectedValues);
    }

    isSelected(option: ISingleOption): boolean {
        return this.selectedLabels.includes(option.label);
    }

    updateSelection() {
        this.isDropdownOpen = false;
    }
}
