import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-sticky-container',
    template: `<div class="sticky" #stickyContainer></div>`,
})
export class StickyContainerComponent {
    @ViewChild('stickyContainer', { read: ElementRef, static: true }) stickyContainer: ElementRef;
    private elements: HTMLElement[] = [];

    constructor(
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef
    ) {}

    addElement(elementRef: ElementRef, containerRef: ElementRef, className?: string) {
        if (elementRef.nativeElement) {
            if (className) {
                this.renderer.addClass(elementRef.nativeElement, className);
            }
            this.renderer.appendChild(this.stickyContainer.nativeElement, elementRef.nativeElement);
            this.elements = [...this.elements, elementRef.nativeElement];
            this.cdr.detectChanges();
        }
    }

    removeElement(elementRef: ElementRef, containerRef: ElementRef, className?: string) {
        if (elementRef.nativeElement) {
            if (className) {
                this.renderer.removeClass(elementRef.nativeElement, className);
            }
            this.renderer.removeChild(this.stickyContainer.nativeElement, elementRef.nativeElement);
            this.renderer.appendChild(containerRef.nativeElement, elementRef.nativeElement);
            this.elements = this.elements.filter(element => element !== elementRef.nativeElement);
            this.cdr.detectChanges();
        }
    }

    clearElements() {
        this.elements.forEach(element => {
            this.renderer.removeChild(this.stickyContainer.nativeElement, element);
        });
        this.cdr.detectChanges();
    }
}
