import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalsModule } from '../../ui-components/modals/modals.module';
import { DirectivesModule } from '../../directives/directives.module';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { ButtonsModule } from '../../ui-components/buttons/buttons.module';
import { IButtonConfig } from '../../interfaces/button.interface';
import { buttonType, fontSizes, LocationCoordinatesErrorCodes, productKeys } from '../../enums/enums';
import {
    IUnsupportedCityProductContentConfig,
    IUnsupportedCityProductModalConfig,
} from './unsupported-city-product-modal.interface';
import { SelectCityService } from '../../services/select-city/select-city.service';
import { Router } from '@angular/router';

@Component({
    standalone: true,
    selector: 'app-unsupported-city-product-modal',
    templateUrl: './unsupported-city-product-modal.component.html',
    styleUrls: ['./unsupported-city-product-modal.component.scss'],
    imports: [CommonModule, ModalsModule, DirectivesModule, ButtonsModule],
})
export class UnsupportedCityProductModalComponent implements OnInit {
    config: IUnsupportedCityProductModalConfig;
    CommonIconsUrl = CommonIconsUrl;
    requirementCtaConfig: IButtonConfig = {
        label: 'Share your requirement with myHQ Experts',
        type: buttonType.PRIMARY,
        size: fontSizes.LARGE,
    };
    modalContentConfig: IUnsupportedCityProductContentConfig;
    cityNotSupportedContent: IUnsupportedCityProductContentConfig = {
        heading: 'Sorry, we are not operational in your city',
        description: 'Adjust your filters or contact myHQ Experts to find options that suit your requirement',
    };
    @Input('config') set updateConfig(data: IUnsupportedCityProductModalConfig) {
        if (data) {
            this.config = data;
            this.modalContentConfig = this.getModalContent(data);
        }
    }
    @Output() hideModal: EventEmitter<void> = new EventEmitter();
    constructor(
        private selectCityService: SelectCityService,
        private router: Router
    ) {}
    ngOnInit() {}

    getModalContent(config: IUnsupportedCityProductModalConfig): IUnsupportedCityProductContentConfig {
        switch (config.errorCode) {
            case LocationCoordinatesErrorCodes.CITY_NOT_SUPPORTED:
                return this.cityNotSupportedContent;
            case LocationCoordinatesErrorCodes.PRODUCT_NOT_SUPPORTED:
                return this.getProductNotSupportedContent(config);
        }
    }

    getProductNotSupportedContent(config: IUnsupportedCityProductModalConfig): IUnsupportedCityProductContentConfig {
        let heading = '';
        switch (config.product.productNameFrontend) {
            case productKeys.FLEXI:
                heading = `Sorry, Day Pass is not available in ${config.city.name}`;
                break;
            case productKeys.DEDICATED:
                heading = `Sorry, Coworking Space are not available in ${config.city.name}`;
                break;
            case productKeys.VIRTUAL_OFFICE:
                heading = `Sorry, Virtual Office is not available in ${config.city.name}`;
                break;
            case productKeys.MEETING_ROOM:
                heading = `Sorry, Meeting Rooms are not available in ${config.city.name}`;
                break;
        }

        return {
            heading,
            description: 'Adjust your filters or contact myHQ Experts to find options that suit your requirement',
        };
    }

    closeModal() {
        this.hideModal.emit();
    }

    handleShareRequirementCtaClick() {
        this.closeModal();
        this.router.navigate(['/contact']);
    }
}
