import { Pipe, PipeTransform } from '@angular/core';
import { dateLabel } from '../enums/enums';
import dayjs from 'dayjs';

@Pipe({ name: 'dayFormat' })
export class DayFormatPipe implements PipeTransform {
    transform(date: string): string {
        const relativeDay = [dateLabel.YESTERDAY, dateLabel.TODAY, dateLabel.TOMORROW];
        const absoluteDay = dayjs(date).format('dddd');
        const dayDiff = dayjs(date).diff(dayjs().startOf('day'), 'days');
        return dayDiff > -2 && dayDiff < 2 ? relativeDay[dayDiff + 1] : absoluteDay;
    }
}
