<div class="form-field" [formGroup]="form" [ngClass]="{ 'field-error': isInvalid }">
    <input
        #inputElement
        [type]="field.type"
        [id]="field.name + uniqueId"
        [formControlName]="field.name"
        [autocomplete]="field.name"
        [(ngModel)]="inputTextData"
        [maxlength]="field.maxLength"
        [readonly]="field.readonly"
    />
    <label
        [for]="field.name + uniqueId"
        [ngClass]="{ floating: form.value[field.name] || inputTextData }"
        class="label"
        *ngIf="!(showValidationErrors && showValidationErrors.length)"
        >{{ field.label }}</label
    >
    <ng-container *ngFor="let validation of field.validations; let i = index">
        <div
            *ngIf="showValidationErrors && showValidationErrors.length && showValidationErrors[i]"
            class="label floating label__danger"
        >
            {{ validation.message }}
        </div>
    </ng-container>
    <ng-container
        [ngTemplateOutlet]="defaultIcons"
        *ngIf="field.fieldType !== FormFieldType.PASSWORD; else passwordIconTemplate"
    ></ng-container>
</div>

<!--Templates-->
<ng-template #passwordIconTemplate>
    <span class="password-icon-container c-pointer" (click)="togglePasswordView($event)">
        <div class="password-icon" [innerHTML]="passwordIcon"></div>
    </span>
</ng-template>

<ng-template #defaultIcons>
    <span class="invalid-icon" *ngIf="isInvalid"
        ><img
            [src]="ImageBaseUrl.IMAGE_URL_BASE + '/web/ssr/common/assets/input-invalid-outlined.svg'"
            alt="input invalid icon"
    /></span>
    <span class="valid-icon" *ngIf="isValid"
        ><img
            [src]="ImageBaseUrl.IMAGE_URL_BASE + '/web/ssr/common/assets/input-valid-outlined.svg'"
            alt="input valid icon"
    /></span>
</ng-template>
