import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFooterComponent } from './common-footer.component';
import { InputWithButtonModule } from '../../ui-components/input-with-button/input-with-button.module';
import { LeadSuccessPortraitModule } from '../../standalone-components/lead-success-portrait/lead-success-portrait.module';
import { DirectivesModule } from '../../directives/directives.module';
import { RouterModule } from '@angular/router';
import { FooterCmsMetadataComponent } from '../footer-cms-metadata/footer-cms-metadata.component';

@NgModule({
    declarations: [CommonFooterComponent, FooterCmsMetadataComponent],
    imports: [CommonModule, InputWithButtonModule, LeadSuccessPortraitModule, RouterModule, DirectivesModule],
    exports: [CommonFooterComponent, FooterCmsMetadataComponent],
})
export class CommonFooterModule {}
